<h3>{{ returnHeadingByType(type) | translate }}</h3>
<ng-container *ngIf="case">
  <button mat-raised-button color="primary" [matMenuTriggerFor]="bulkActionMenu">
    {{ "SHARED.bulk-actions" | translate }}
  </button>
  <mat-menu #bulkActionMenu="matMenu">
    <div [title]="creditorVerifyDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="creditorVerifyDisabled" type="button"
              (click)="verifyCreditor(creditorsSelection, type)">
        {{ "CASES.details.verify-send-mandates" | translate }}
      </button>
    </div>
    <div [title]="creditorResendDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="creditorResendDisabled" type="button"
              (click)="verifyCreditor(creditorsSelection, type)">
        {{ "CASES.details.resend-mandates" | translate }}
      </button>
    </div>
    <div [title]="creditorRecoveryDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <a mat-menu-item [disabled]="creditorRecoveryDisabled"
         (click)="sendMandatesRecoveryAgent($event, creditorsSelection)">
        {{ "CASES.details.send-mandates-recovery" | translate }}
      </a>
    </div>
    <div
      [title]="creditorSendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">
      <button mat-menu-item [disabled]="creditorSendAepDisabled"
              (click)="sendAep($event, creditorsSelection, type)">
        {{ "CASES.details.send-aep" | translate }}
      </button>
    </div>
    <div
      [title]="creditorResendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">
      <button mat-menu-item [disabled]="creditorResendAepDisabled"
              (click)="sendAep($event, creditorsSelection, type)">
        {{ "CASES.details.resend-aep" | translate }}
      </button>
    </div>
    <div
      [title]="creditorProposalAccordDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="creditorProposalAccordDisabled"
              (click)="sendProposalAndAccordSelection(creditorsSelection)">
        {{ "CASES.details.send-proposal-accord" | translate }}
      </button>
    </div>
    <div
      [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!creditorsSelection.selected.length"
              (click)="changeStatus($event, creditorsSelection, type)">
        {{ "CASES.details.change-status" | translate }}
      </button>
    </div>
    <div
      [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!creditorsSelection.selected.length"
              (click)="sendLastChance($event, creditorsSelection)">
        {{ "CASES.details.send-last-chance" | translate }}
      </button>
    </div>
    <!-- Right of Access -->
    <div
      [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!creditorsSelection.selected.length"
              (click)="sendRightOfAccess(creditorsSelection)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access' | translate }}
      </button>
    </div>
    <!--Right of Access  PPI-->
    <div
      [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!creditorsSelection.selected.length"
              (click)="sendRightOfAccessPpi(creditorsSelection)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access_ppi' | translate }}
      </button>
    </div>
    <!-- Send PPI Claim -->
    <div
      [title]="!creditorsSelection.selected.length ? 'One or more selected creditors does not have this action available' : ''">
      <button mat-menu-item [disabled]="!creditorsSelection.selected.length"
              (click)="sendPpiClaim(creditorsSelection)">
        {{ 'SHARED.send' | translate }} PPI Claim
      </button>
    </div>
    <!-- Azcarate Loan -->
    <div
      [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!creditorsSelection.selected.length"
              (click)="sendAzcarateLoanLetter(creditorsSelection)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_loan_letter' | translate }}
      </button>
    </div>
    <!-- Azcarate Revolving-->
    <div
      [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!creditorsSelection.selected.length"
              (click)="sendAzcarateRevolvingLetter(creditorsSelection)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_revolving_letter' | translate }}
      </button>
    </div>
    <!-- Send Email-->
    <!-- <div
       [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
       <button mat-menu-item [disabled]="!isEmailEnabled && !creditorsSelection.selected.length"
               (click)="sendEmail($event, creditorsSelection, type)">
         {{ "CASES.details.send-azcarate" | translate }}
       </button>
     </div>-->
    <!--Send Delete number-->
    <!--<div
      [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!creditorsSelection.selected.length"
              (click)="sendDeleteNumber($event, '', type)">
        {{ "CASES.details.send-delete-number" | translate }}
      </button>
    </div>-->
    <!--<div
      [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!creditorsSelection.selected.length"
              (click)="sendAntiHarassment($event, [], 'unsecured')">
        {{ "CASES.details.send-azkarate-acoso" | translate }}
      </button>
    </div>-->
    <!--<div
      [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!creditorsSelection.selected.length"
              (click)="sendLoanCancellationEmail($event, [], 'unsecured')">
        {{ "CASES.details.send-azkarate-cancelar" | translate }}
      </button>
    </div>-->
    <!--<div
      [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!creditorsSelection.selected.length"
              *ngIf="isGetRightOfAccessClientEnabled"
              (click)="getRightOfAccess('client', 0, $event, type)">
        {{'CASES.details.get-right-of-access' | translate}} 1
      </button>
    </div>-->
    <!--   <div
         [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
         <button mat-menu-item [disabled]="!creditorsSelection.selected.length"
                 *ngIf="isGetRightOfAccessClientEnabled"
                 (click)="getRightOfAccess('partner', 0, $event, type)">
           {{'CASES.details.get-right-of-access' | translate}} 2
         </button>
       </div>-->
    <div
      [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <a mat-menu-item class="clickable"
         [disabled]="!creditorsSelection.selected.length || creditorsSelection.selected.length > 1 "
         (click)="previewCreditorProposal()">
        {{ 'CASES.details.preview-creditor-proposal-and-accord' | translate }}
      </a>
    </div>
    <div
      [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <a mat-menu-item class="clickable"
         [disabled]="!creditorsSelection.selected.length || creditorsSelection.selected.length > 1 "
         (click)="downloadFile('proposal')">
        {{ 'CASES.details.download_creditor_proposal' | translate }}
      </a>
    </div>
    <div
      [title]="!creditorsSelection.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <a mat-menu-item class="clickable"
         [disabled]="!creditorsSelection.selected.length || creditorsSelection.selected.length > 1 "
         (click)="downloadFile('proposal_accord')">
        {{ 'CASES.details.download_proposal_accord' | translate }}
      </a>
    </div>
  </mat-menu>
</ng-container>
<!--[ngClass]="[(!!(case.product?.group_slug === 'dm'
|| case.product?.group_slug === 'dgs' || case.product?.group_slug === 'cajaplus') && (type === 'unsecured' || type === 'loan')) ? '' : 'table-responsive']"-->
<div *ngIf="case" class="mat-elevation-z8 case-creditor-table-wrapper">
  <table class="case-creditor-table" mat-table matSort [dataSource]="creditors">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="p-r-10">
        <mat-checkbox (change)="$event ? masterToggle(creditorsSelection, creditors) : null" color="primary"
                      [checked]="creditorsSelection.hasValue() && isAllSelected(creditorsSelection, creditors)"
                      [indeterminate]="creditorsSelection.hasValue() && !isAllSelected(creditorsSelection, creditors)">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                      (change)="$event ? creditorsSelection.toggle(row) : null"
                      [checked]="creditorsSelection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.creditor-name" | translate }}</th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        {{ element.creditor?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ownership">
      <th mat-header-cell *matHeaderCellDef
          class="p-r-10">{{ "CASE_CREDITOR.model.ownership.label" | translate }}
      </th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        <ng-container
          *ngIf="element.ownership === 'applicant'">{{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}
        </ng-container>
        <ng-container
          *ngIf="element.ownership === 'partner'">{{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}
        </ng-container>
        <ng-container
          *ngIf="element.ownership === 'joint'">{{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="debt_type">
      <th mat-header-cell *matHeaderCellDef>{{ 'CASE_CREDITOR.model.debt_type.label' | translate }}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        {{ 'CASE_CREDITOR.model.debt_type.options.' + element.debt_type | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="initial_balance">
      <th mat-header-cell *matHeaderCellDef>
        <span>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        {{ element.initial_balance | number: '1.2-2' }}€
      </td>
    </ng-container>

    <ng-container matColumnDef="current_balance">
      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        {{ element.current_balance | number: '1.2-2' }}€
      </td>
    </ng-container>


    <ng-container matColumnDef="amount_repaid">
      <th mat-header-cell *matHeaderCellDef>{{ 'CASE_CREDITOR.model.total_repaid' | translate }}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{ element.amount_repaid | number: '1.2-2' }}
        €
      </td>
    </ng-container>

    <ng-container matColumnDef="claimed_amount">
      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.claimed_amount" | translate }}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{ element.claimed_amount | number: '1.2-2' }}
        €
      </td>
    </ng-container>

    <ng-container matColumnDef="reference_number">
      <th mat-header-cell *matHeaderCellDef
          class="p-r-10">{{ "CASE_CREDITOR.model.reference_number" | translate }}
      </th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        {{ element.reference_number }}
      </td>
    </ng-container>

    <ng-container matColumnDef="response_received">
      <th mat-header-cell *matHeaderCellDef
          class="p-r-10">{{ "CASE_CREDITOR.model.response_received.label" | translate }}
      </th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        {{ ("CASE_CREDITOR.model.response_received.options." + element.response_received) | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="additional_partner">
      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.additional_partner" | translate }}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        <mat-icon *ngIf="element.additional_partner" class="mat-icon-size" color="primary">check_circle
        </mat-icon>
        <mat-icon *ngIf="!element.additional_partner" class="mat-icon-size" color="warn">cancel</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="making_payments">
      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.making_payments" | translate }}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        <mat-icon *ngIf="element.making_payments" class="mat-icon-size" color="primary">check_circle</mat-icon>
        <mat-icon *ngIf="!element.making_payments" class="mat-icon-size" color="warn">cancel</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="azcarate" *ngIf="type === 'secured' || type === 'claim'">
      <th mat-header-cell *matHeaderCellDef>Azcarate</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.total_repaid > element.initial_balance" class="mat-icon-size"
                  color="primary">check_circle
        </mat-icon>
      </td>
    </ng-container>

    <form *ngIf="excludedForm && type === 'unsecured'" [formGroup]="excludedForm" class="p-2">
      <ng-container matColumnDef="debt_agreed">
        <th mat-header-cell *matHeaderCellDef>{{ 'CASE_CREDITOR.model.debt_agreed' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i =  index"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <ng-container>
            <app-input *ngIf="isEditing && currentRow === i" type="number" formControlName="debt_agreed"
                       [showLabel]="false" [extraLabel]="false" appearance="standard" [fullWidth]="false"
                       flexClass="align-items-center">
            </app-input>
            <p *ngIf="!isEditing || currentRow != i">{{ element.debt_agreed }}</p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="debt_reduction">
        <th mat-header-cell *matHeaderCellDef>{{ 'CASE_CREDITOR.model.debt_reduction' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i =  index"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <ng-container>
            {{ element.current_balance - element.debt_agreed }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="monthly_repayment">
        <th mat-header-cell *matHeaderCellDef>{{ 'CASE_CREDITOR.model.monthly_repayments' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i =  index"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <ng-container>
            <app-input *ngIf="isEditing && currentRow === i" type="number" formControlName="monthly_repayment"
                       [showLabel]="false" [extraLabel]="false" appearance="standard" [fullWidth]="false"
                       flexClass="align-items-center">
            </app-input>
            <p *ngIf="!isEditing || currentRow != i">{{ element.monthly_repayment }}</p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="term">
        <th mat-header-cell *matHeaderCellDef class="p-r-10">{{ 'CASE_CREDITOR.model.term' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i =  index"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <ng-container>
            {{ +(+(element.debt_agreed) / +(element.monthly_repayment)) }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="p-r-10">{{ 'CASE_CREDITOR.model.status' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i =  index"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <ng-container>
            <app-input *ngIf="isEditing && currentRow === i" type="select" formControlName="status"
                       appearance="standard" fullWidth="false" [searchable]="true"
                       [label]="'CASE_CREDITOR.model.status' | translate"
                       [selectOptions]="statuses" [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>
            <p *ngIf="!isEditing || currentRow != i">{{ element.status }}</p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef class="p-r-10">Reference</th>
        <td mat-cell *matCellDef="let element; let i = index"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <ng-container>
            <app-input *ngIf="isEditing && currentRow === i" type="text" formControlName="reference"
                       appearance="standard" fullWidth="false"
                       [label]="'Reference'">
            </app-input>
            <p *ngIf="!isEditing || currentRow != i">{{ element.reference }}</p>
          </ng-container>
        </td>
      </ng-container>
    </form>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
      <td mat-cell *matCellDef="let element; let i = index"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>
        <mat-menu #menu="matMenu">
          <button *ngIf="!element.verified" mat-menu-item type="button"
                  (click)="verifyCreditorIds([element.id], 'unsecured_creditors')">
            {{ "CASES.details.verify-send-mandates" | translate }}
          </button>
          <button *ngIf="element.verified" mat-menu-item type="button"
                  (click)="verifyCreditorIds([element.id], 'unsecured_creditors')">
            {{ "CASES.details.resend-mandates" | translate }}
          </button>
          <button *ngIf="element.recovery_creditor_id" mat-menu-item
                  (click)="sendMandatesRecoveryAgent($event, [element.id])">
            {{ "CASES.details.send-mandates-recovery" | translate }}
          </button>

          <div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
            <button *ngIf="!element.aep_sent" mat-menu-item
                    (click)="sendAep($event, [element.id], 'unsecured_creditors')">
              {{ "CASES.details.send-aep" | translate }}
            </button>
          </div>
          <div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
            <button *ngIf="element.aep_sent" mat-menu-item [disabled]="missingAepFiles"
                    (click)="sendAep($event, [element.id], 'unsecured_creditors')">
              {{ "CASES.details.resend-aep" | translate }}
            </button>
          </div>

          <button [disabled]="!isSigned || !element.proposal_location"
                  mat-menu-item
                  (click)="sendProposalAndAccord([element.id])">
            {{ "CASES.details.send-proposal-accord" | translate }}
          </button>
          <button mat-menu-item
                  [disabled]="!(element.total_repaid > element.initial_balance)"
                  (click)="sendEmail($event, element.id)">
            {{ "CASES.details.send-azcarate" | translate }}
          </button>
          <button mat-menu-item
                  (click)="changeStatus($event, [element.id], 'unsecured_creditors')">
            {{ "CASES.details.change-status" | translate }}
          </button>
          <button mat-menu-item
                  (click)="sendLastChance($event, [element.id])">
            {{ "CASES.details.send-last-chance" | translate }}
          </button>
          <button mat-menu-item
                  (click)="sendDeleteNumber($event, [element.id])">
            {{ "CASES.details.send-delete-number" | translate }}
          </button>
          <button mat-menu-item
                  (click)="sendAntiHarassment($event, [element.id])">
            {{ "CASES.details.send-azkarate-acoso" | translate }}
          </button>
          <button mat-menu-item
                  (click)="sendLoanCancellationEmail($event, [element.id])">
            {{ "CASES.details.send-azkarate-cancelar" | translate }}
          </button>
          <button mat-menu-item
                  *ngIf="element.ownership === 'applicant' || element.ownership === 'joint' "
                  (click)="getRightOfAccess('client', element.id, $event)">
            {{ 'CASES.details.get-right-of-access' | translate }} 1
          </button>
          <button mat-menu-item
                  *ngIf="element.ownership === 'partner' || element.ownership === 'joint' "
                  (click)="getRightOfAccess('partner', element.id, $event)">
            {{ 'CASES.details.get-right-of-access' | translate }} 2
          </button>
          <button mat-menu-item
                  [routerLink]="['/creditor-proposal-preview']"
                  [queryParams]="{uuid: case.uuid, case_creditor_id:element.id, ownership: element.ownership}">
            {{ 'CASES.details.preview-creditor-proposal-and-accord' | translate }}
          </button>
          <a mat-menu-item class="clickable"
             href="{{storageUrl + element.proposal_location}}">
            {{ 'CASES.details.download_creditor_proposal' | translate }}
          </a>
          <a mat-menu-item class="clickable"
             href="{{storageUrl + element.proposal_location_accord}}">
            {{ 'CASES.details.download_proposal_accord' | translate }}
          </a>
        </mat-menu>
        <button mat-stroked-button class="ml-1"
                *ngIf="authUser.packager?.master && (type === 'unsecured' || type === 'secured')"
                (click)="toggleType(element, type)">
          {{ 'CASE_CREDITOR.claim.make_as_claim' | translate }}
        </button>
        <button mat-stroked-button *ngIf="authUser.packager?.master && type === 'claim'"
                [matMenuTriggerFor]="returnToUnsecuredSecuredMenu">
          {{ 'CASES.details.make_as' | translate }}
        </button>
        <mat-menu #returnToUnsecuredSecuredMenu="matMenu">
          <button mat-menu-item (click)="toggleType(element,  'claim', 'unsecured')">
            {{ "CASE_CREDITOR.model.type.options.unsecured" | translate }}
          </button>
          <button mat-menu-item (click)="toggleType(element, 'claim', 'secured')">
            {{ "CASE_CREDITOR.model.type.options.secured" | translate }}
          </button>
        </mat-menu>
        <button mat-stroked-button class="ml-2" *ngIf="type === 'unsecured'"
                (click)="onExclude(element)">{{ 'CASES.single.creditors.actions.exclude' | translate }}
        </button>
        <button mat-stroked-button class="ml-2" *ngIf="type === 'unsecured' && !!(case.product.group_slug === 'dm' || case.product.group_slug ===
        'dgs' || case.product.group_slug === 'cajaplus')" (click)="toggleEditMode(i, element)">
          {{ ! isEditing || currentRow != i ? 'Edit' : 'Save' }}
        </button>
        <button mat-stroked-button class="ml-2" *ngIf="type === 'excluded'"
                (click)="onInclude(element)">{{ 'CASES.single.creditors.actions.include' | translate }}
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="loader">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        <mat-spinner *ngIf="isLoading && isLoadingIds.length && isLoadingIds.indexOf(element.id) !== -1"
                     diameter="20" color="accent"></mat-spinner>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="isFetching" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="!isFetching && creditors && !creditors.data.length" style="padding: 50px">
    <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>
  </div>
</div>
