import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../_base-shared/contracts/common.interface';

import { Affiliate } from '../../../../../../_base-shared/models/Affiliate/Affiliate';
import { AffiliateCampaign } from '../../../../../../_base-shared/models/Affiliate/AffiliateCampaign';
import { AffiliateProduct } from '../../../../../../_base-shared/models/Affiliate/AffiliateProductPivot';
import { Packager } from '../../../../../../_base-shared/models/Packager/Packager';
import { Product } from '../../../../../../_base-shared/models/Product';
import { Status } from '../../../../../../_base-shared/models/Status/Status';
import { User } from '../../../../../../_base-shared/models/User/User';
import { AdminPackagerService } from '../../admin-packager/admin-packager.service';
import { UploadService } from '../../app-file/upload.service';
import { ProductService } from '../../case/product.service';
import { StatusService } from '../../status/status.service';
import { UserService } from '../../user/user.service';
import { AffiliateService } from '../affiliate.service';
import { AffiliateMarketingChannel } from '../../../../../../_base-shared/models/Affiliate/AffiliateMarketingChannel';
import { BonusTier } from '../../../../../../_base-shared/models/Affiliate/BonusTier';
import {
  AffiliatePostback,
  AffiliatePostbackPostData
} from '../../../../../../_base-shared/models/Affiliate/AffiliatePostback';


@Component({
  selector:    'app-affiliate-editor',
  templateUrl: './affiliate-editor.component.html',
  styleUrls:   ['./affiliate-editor.component.scss']
})
export class AffiliateEditorComponent implements OnInit, OnDestroy {
  public editorType: 'create' | 'edit';
  public form: UntypedFormGroup;
  public statuses: Array<Status>                          = [];
  public products: Array<Product>                         = [];
  public affiliateProducts: Array<AffiliateProduct>       = [];
  public affiliateUsers: Array<User>                      = [];
  public packagers: Array<Packager>                       = [];
  public channelOptions: Array<AffiliateMarketingChannel> = [];
  public affiliateVariables: Array<string>                = [];
  public trackingOptions: Array<AppSelectOption>          = [];
  public leadConversionTypes: Array<AppSelectOption>;
  public statusTypes: Array<AppSelectOption>;
  public type: Array<AppSelectOption>;
  public editor;
  public bonusTiers: Array<BonusTier>;

  public isLoading                           = 0;
  public affiliate: Affiliate                = null;
  private subscriptions: Array<Subscription> = [];

  public quillModules = {
    imageUploader: {
      upload: (file) => this.uploadFile(file)
    }
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private formBuilder: UntypedFormBuilder,
              private toastr: ToastrService,
              private affiliateService: AffiliateService,
              private statusService: StatusService,
              private translate: TranslateService,
              private productService: ProductService,
              private userService: UserService,
              private packagerService: AdminPackagerService,
              private fb: UntypedFormBuilder,
              private uploadService: UploadService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.editorType = data.editorType;

      if (this.editorType === 'create') {
        this.affiliate                                   = new Affiliate();
        this.affiliate.active                            = true;
        this.affiliate.allow_without_debt                = true;
        this.affiliate.allow_under_5000                  = false;
        this.affiliate.allow_duplicates                  = false;
        this.affiliate.is_df_affiliate                   = false;
        this.affiliate.allow_overwrite_df_affiliates     = false;
        this.affiliate.allow_overwrite_non_df_affiliates = false;
        this.affiliate.campaigns                         = [this.getDefaultCampaign(this.affiliate)];
        this.fetchProducts();
      }

      if (this.editorType === 'edit') {
        this.route.paramMap.subscribe(params => {
          this.fetchAffiliate(+params.get('id'));
        });
      }
    });
    this.fetchLeadConversionTypes();
    this.getStatusTypes();
    this.getPostbackSelectTypes();
    this.fetchAffiliateVariables();
    this.fetchAffiliateUsers();
    this.fetchPackagers();
    this.getMarketingChannels();
    this.setTrackingOptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchAffiliate(affiliateId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.affiliateService.get(affiliateId, ['users', 'campaigns', 'postbacks', 'bonus_tiers'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.affiliate = result.data;
          this.fetchAffiliateProducts(affiliateId);
        })
    );
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.index({ all: 1 }).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.statuses = result.data;
          this.buildForm();
        }
      )
    );
  }

  private fetchProducts(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.productService.index({ select_all: 1 }).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          const responseProducts = result.data;
          this.products          = responseProducts.map(product => {
            if (product.slug === 'lso-te' || product.slug === 'dm') {
              product.name    = product.group_slug;
              product.name_es = product.group_slug;
              product.name_en = product.group_slug;
            }
            return product;
          });
          this.fetchStatuses();
        }
      )
    );
  }

  private fetchAffiliateProducts(affiliateId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.affiliateService.getProducts(affiliateId).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.affiliateProducts = result.data;
          this.fetchProducts();
        }
      )
    );
  }

  private buildForm(): void {
    this.form            = this.formBuilder.group({
      packager_id:                         [this.affiliate.packager_id, [Validators.required]],
      overload_status_id:                  [this.affiliate.overload_status_id, []],
      overwrite_cliente_regreso_status_id: [this.affiliate.overwrite_cliente_regreso_status_id, []],
      name:                                [this.affiliate.name, []],
      active:                              [this.affiliate.active, []],
      allow_without_debt:                  [this.affiliate.allow_without_debt, []],
      allow_under_5000:                    [this.affiliate.allow_under_5000, []],
      allow_duplicates:                    [this.affiliate.allow_duplicates, []],
      is_df_affiliate:                     [this.affiliate.is_df_affiliate, []],
      allow_overwrite_df_affiliates:       [this.affiliate.allow_overwrite_df_affiliates, []],
      allow_overwrite_non_df_affiliates:   [this.affiliate.allow_overwrite_non_df_affiliates, []],
      postback_url:                        [this.affiliate.postback_url, []],
      lead_tracking:                       this.formBuilder.array([]),
      volume_bonuses:                      this.formBuilder.array([]),
      volume_bonus_conversion_status_id:   [this.affiliate.volume_bonus_conversion_status_id, []],
      user_ids:                            [this.affiliate.users?.map(user => user.id), []],
      affiliate_campaigns:                 this.fb.array([]),
      postbacks:                           this.fb.array([])
    });
    const campaignsArray = this.form.get('affiliate_campaigns') as FormArray;
    const postbacksArray = this.form.get('postbacks') as FormArray;

    this.affiliate.campaigns?.forEach(campaign => {
      campaignsArray.push(this.initCampaignFormGroup(campaign));
    });

    this.affiliate.postbacks?.forEach((postback) => {
      postbacksArray.push(this.initPostbackFormGroup(postback));
    });
    this.addProducts();
    this.fetchBonusTiers();
  }

  public addProducts(): void {
    this.products.forEach(product => {
      if (product.slug === 'lso-te' || product.slug === 'dm') {
        let affiliateProduct = this.affiliateProducts.find(affiliateP => affiliateP.pivot.product_id === product.id);
        if (typeof affiliateProduct !== 'undefined') {
          this.addProduct(affiliateProduct);
        } else {
          affiliateProduct            = new AffiliateProduct();
          affiliateProduct.id         = product.id;
          affiliateProduct.name_es    = product.name_es;
          affiliateProduct.name_en    = product.name_en;
          affiliateProduct.group_slug = product.group_slug;
          this.addProduct(affiliateProduct);
        }
      }
    });
  }

  public addProduct(product: AffiliateProduct): void {
    const formArray = this.getTrackingFormArray();

    const formGroup = this.formBuilder.group({
      product_id:          [product.id],
      name:                [product.group_slug.toUpperCase()],
      cost:                [product?.pivot?.cost || null],
      status_id:           [product?.pivot?.status_id || null],
      tracking_type:       [product?.pivot?.tracking_type || null],
      secondary_cost:      [product?.pivot?.secondary_cost || null],
      secondary_status_id: [product?.pivot?.secondary_status_id || null]
    });
    formArray.push(formGroup);
  }

  public getTrackingFormArray(): FormArray<any> {
    return this.form.get('lead_tracking') as UntypedFormArray;
  }

  public getVolumeBonusFormArray(): FormArray<any> {
    return this.form.get('volume_bonuses') as UntypedFormArray;
  }

  private addBonusTier(bonusTier: BonusTier, bonusTierIndex: number): void {
    const bonusTierData = this.affiliate.bonus_tiers[bonusTierIndex].pivot;
    const formArray     = this.getVolumeBonusFormArray();
    const formGroup     = this.formBuilder.group({
      bonus_tier_id:     [bonusTier.id],
      name:              [{ value: bonusTier.name_es, disabled: true }],
      volume_bonus_cost: [bonusTierData?.volume_bonus_cost],
      number_of_cases:   [bonusTierData?.number_of_cases || null]
    });
    formArray.push(formGroup);
  }

  public formSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const formData = this.form.value;

    if (formData.overload_status_id === 'null') {
      formData.overload_status_id = null;
    }

    if (formData.postback_url) {
      formData.postback_url = encodeURI(formData.postback_url);
    }

    if (this.editorType === 'create') {
      this.affiliateService.store(formData).subscribe(
        res => this.handleSuccessResponse(res),
        error => this.handleErrorResponse(error)
      );
    }

    if (this.editorType === 'edit') {
      this.affiliateService.update(this.affiliate.id, formData).subscribe(
        res => this.handleSuccessResponse(res),
        error => this.handleErrorResponse(error)
      );
    }
  }

  private handleErrorResponse(error): void {
    if (this.editorType === 'create') {
      this.toastr.error(
        this.translate.instant('AFFILIATES.add-new-error'),
        this.translate.instant('SHARED.error')
      );
    }

    if (this.editorType === 'edit') {
      this.toastr.error(
        this.translate.instant('AFFILIATES.edit-aff-error'),
        this.translate.instant('SHARED.error')
      );
    }
  }

  private handleSuccessResponse(response): void {
    if (response.status === 200) {
      this.router.navigateByUrl('/affiliates');

      if (this.editorType === 'create') {
        this.toastr.success(
          this.translate.instant('AFFILIATES.add-new-success'),
          this.translate.instant('SHARED.success')
        );
      }

      if (this.editorType === 'edit') {
        this.toastr.success(
          this.translate.instant('AFFILIATES.edit-aff-success'),
          this.translate.instant('SHARED.success')
        );
      }
    }
  }

  private fetchAffiliateUsers(): void {
    this.isLoading++;
    this.userService.index({ select_all: 1, role_slugs: ['affiliate'], all_packager_users: 1 })
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => this.affiliateUsers = result.data,
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong'))
      );
  }

  private fetchPackagers(): void {
    this.isLoading++;
    this.packagerService.index({ select_all: 1 }).pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => this.packagers = result.data,
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong'))
      );
  }

  public addCampaign(affiliate: Affiliate): void {
    const formArray     = this.getCampaignFormArray();
    const newCampaign   = this.getDefaultCampaign(affiliate);
    newCampaign.default = false;

    formArray.push(this.initCampaignFormGroup(newCampaign));
  }

  private initPostbackFormGroup(postback: AffiliatePostback): FormGroup {
    const postbackFormGroup = this.fb.group({
      id:                      [postback.id],
      type:                    [postback.type],
      lead_conversion_type_id: [postback.lead_conversion_type_id],
      destination:             [postback.destination],
      mail_subject:            [postback.mail_subject],
      mail_content:            [postback.mail_content],
      post_data:               this.fb.array([])
    });
    const postDataArray     = postbackFormGroup.get('post_data') as FormArray;

    postback.post_data?.forEach(postData => {
      postDataArray.push(this.initPostDataFormGroup(postData));
    });

    return postbackFormGroup;
  }

  private initPostDataFormGroup(postData?: AffiliatePostbackPostData): FormGroup {
    postData = postData ? postData : this.getDefaultPostData();
    return this.fb.group({
      id:         [postData.id ? postData.id : ''],
      data_field: [postData.data_field ? postData.data_field : ''],
      data_value: [postData.data_value ? postData.data_value : '']
    });
  }

  private getDefaultCampaign(affiliate: Affiliate): AffiliateCampaign {
    const campaign        = new AffiliateCampaign();
    campaign.affiliate_id = affiliate.id;
    campaign.default      = true;

    return campaign;
  }

  public getCampaignFormArray(): FormArray<any> {
    return this.form.get('affiliate_campaigns') as UntypedFormArray;
  }

  private getMarketingChannels(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.affiliateService.getMarketingChannels().pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.channelOptions = result.data;
        }
      )
    );
  }

  private initCampaignFormGroup(campaign: AffiliateCampaign): FormGroup {
    return this.fb.group({
      id:                   [campaign.id],
      affiliate_id:         [campaign.affiliate_id],
      tracking_id:          [campaign.tracking_id],
      marketing_channel_id: [campaign.marketing_channel_id, [Validators.required]], // TODO: set the `default` marketing channel
      name:                 [campaign.name, [Validators.required]],
      default:              [campaign.default ? campaign.default : false]
    });
  }

  public removeCampaign(index: number): void {
    const formArray = this.getCampaignFormArray();
    formArray.removeAt(index);
  }

  public removePostData(i: number, index: number): void {
    const formArray = this.getPostData(i);
    formArray.removeAt(index);
  }

  private fetchAffiliateVariables(): void {
    this.isLoading++;
    this.affiliateService.getAffiliateVariables().pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => this.affiliateVariables = result.data,
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong'))
      );
  }

  private setTrackingOptions(): void {
    this.trackingOptions = [
      { value: 'cpa', label: 'CPA' },
      { value: 'cpl', label: 'CPL' }
    ];
  }

  public getPostbacksFormArray(): FormArray<any> {
    return this.form.get('postbacks') as UntypedFormArray;
  }

  public addPostback(postback: AffiliatePostback): void {
    const formArray   = this.getPostbacksFormArray();
    const newPostback = this.getDefaultPostback(postback);

    formArray.push(this.initPostbackFormGroup(newPostback));
  }

  public addPostData(postback: AffiliatePostbackPostData = null, i?): void {
    postback        = postback ? postback : this.getDefaultPostData();
    const formArray = this.getPostData(i);
    const formGroup = this.fb.group({
      id:         [postback.id ? postback.id : ''],
      data_field: [postback.data_field ? postback.data_field : ''],
      data_value: [postback.data_value ? postback.data_value : '']
    });
    formArray.push(formGroup);
  }

  public getPostData(i): FormArray {
    const postbacksArray = this.getPostbacksFormArray();
    const postbacksGroup = postbacksArray.at(i) as FormGroup;
    return postbacksGroup.get('post_data') as FormArray;
  }


  private getDefaultPostback(postback?: AffiliatePostback): AffiliatePostback {
    return new AffiliatePostback();
  }

  private getDefaultPostData(): AffiliatePostbackPostData {
    const postbackData      = new AffiliatePostbackPostData();
    postbackData.data_field = '';
    postbackData.data_value = '';

    return postbackData;
  }


  private getPostbackSelectTypes(): void {
    this.type = [
      { value: 'http-post', label: this.translate.instant('Postback URL') },
      {
        value: 'email',
        label: this.translate.instant('Email')
      }
    ];
  }

  private fetchLeadConversionTypes(): void {
    this.isLoading++;
    this.affiliateService.getLeadConversionTypes().pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => this.leadConversionTypes = result.data,
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong'))
      );
  }


  public removePostback(index: number): void {
    const formArray = this.getPostbacksFormArray();
    formArray.removeAt(index);
  }

  public changePostbackType(i: number, $event): void {
    if ($event === 'email') {
      this.getPostbacksFormArray().at(i).get('post_data').reset();
    } else {
      const postbacksArray = this.getPostbacksFormArray().at(i).get('post_data') as FormArray;
      postbacksArray.push(this.initPostDataFormGroup());
      this.getPostbacksFormArray().at(i).get('mail_content').reset();
    }
    this.form.updateValueAndValidity();
  }

  private getStatusTypes(): void {
    this.type = [
      { value: true, label: this.translate.instant('AFFILIATES.active') },
      { value: 'false', label: this.translate.instant('AFFILIATES.inactive') }
    ];
  }

  public getEditorInstance(editor): void {
    this.editor = editor;
  }

  private uploadFile(file: any): Promise<any> {
    return this.uploadService.quillImgUpload(file);
  }

  private fetchBonusTiers(): void {
    this.isLoading++;
    this.affiliateService.getBonusTiers().pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => {
          this.bonusTiers = result.data;
          this.bonusTiers.forEach((bonusTier, bonusTierIndex) => {
            this.addBonusTier(bonusTier, bonusTierIndex);
          });
        },
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong'))
      );
  }
}
