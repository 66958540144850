import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CaseAsset } from '../../../../../../../_base-shared/models/Case/CaseAsset';

@Component({
  selector: 'app-assets-snapshot-list',
  templateUrl: './assets-snapshot-list.component.html',
  styleUrls: ['./assets-snapshot-list.component.scss']
})
export class AssetsSnapshotListComponent implements OnInit{
  @Input() assets: Array<CaseAsset>;
  public bankAccounts: Array<any> = [];
  public properties: Array<any> = [];
  public vehicles: Array<any> = [];
  public other: Array<any> = [];

  constructor(
    private fb: UntypedFormBuilder,
  ) {
  }

  ngOnInit(): void {
    if (this.assets.length > 0){
      console.log(this.assets);
      this.assets.map(asset => {
        if (asset.type === 'properties'){
          this.properties.push(asset);
        }else if (asset.type === 'vehicles'){
          this.vehicles.push(asset);
        }else if (asset.type === 'bank_accounts'){
          this.bankAccounts.push(asset);
        } else if (asset.type === 'other'){
          this.other.push(asset);
        }
      });
    }
  }
}
