import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { UserService } from '../../../../user/user.service';

@Component({
  selector:    'app-case-id-card-editor',
  templateUrl: './case-id-card-editor.component.html',
  styles:      [],
})
export class CaseIdCardEditorComponent implements OnInit {
  @Input() case: Case;
  @Input() user: User;
  @Input() inputLabel: string;
  @Output() updatedIdCard: EventEmitter<string> = new EventEmitter<string>();
  @Output() updatedClient: EventEmitter<User>   = new EventEmitter<User>();
  public clientCaseRole: 'client' | 'partner';
  public form: UntypedFormGroup;
  public isLoading                              = 0;
  public isSubmitting                           = false;
  public takenByUsers: Array<User>              = [];
  private validateClientEmailTimeout: any;

  constructor(private router: Router,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private translate: TranslateService,
              private dialog: MatDialog,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.clientCaseRole = this.case.user_id === this.user.id ? 'client' : 'partner';
    this.buildForm(this.user);
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.isSubmitting = true;
    this.userService.updateIdCard(this.user.id, form.get('id_card').value)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        () => {
          this.toastr.success(this.translate.instant('CASES.editor.payment.id_card.result.success'));
          this.updatedIdCard.emit(form.get('id_card').value);
        },
        () => this.toastr.error(this.translate.instant('CASES.editor.payment.id_card.result.error')),
      );
  }

  private buildForm(user: User) {
    this.form = this.fb.group({
      id_card: [
        user.id_card,
        // [Validators.required, Validators.maxLength(9), CustomValidators.idCardPattern()],
        [Validators.required, Validators.maxLength(9)],
        [this.validateClientIdCard.bind(this)]],
    });
  }

  private validateClientIdCard(control: AbstractControl): Promise<any> | Observable<any> {
    if (this.validateClientEmailTimeout) {
      clearTimeout(this.validateClientEmailTimeout);
    }
    return new Promise<any>((resolve, reject) => {
      this.validateClientEmailTimeout = setTimeout(() => {
        this.userService.validateField('id_card', control.value, this.user?.id).subscribe(
          () => {
            this.takenByUsers = [];
            resolve(null);
          },
          err => {
            resolve({unique: err.error.data?.existing_model});
            this.takenByUsers    = [];
            const takenByUserIds = err.error.data?.existing_model_ids?.length ? err.error.data?.existing_model_ids : [];
            if (takenByUserIds.length) {
              takenByUserIds.forEach(takenUserId => {
                this.userService.get(takenUserId).subscribe(result => this.takenByUsers.push(result.data));
              });
            }
          },
        );
      }, 500);
    });
  }
}
