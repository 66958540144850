<div class="d-flex flex-row justify-content-between">
  <div class="d-flex">
    <button mat-stroked-button type="button" color="primary" class="btn mt-2 ml-2"
            (click)="clearFilters()">{{ "SHARED.reset-filters" | translate }}</button>
    <div *ngIf="case && authUser?.packager?.master" class="d-flex flex-row mt-2 ml-1">
      <button mat-raised-button [mat-menu-trigger-for]="referCaseMenu" color="primary">
        {{'CASE_CREDITOR.refer_case.heading' | translate}}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #referCaseMenu class="refer-case-menu">
        <button mat-menu-item color="primary" [disabled]="!!case.dvl_ref_number" (click)="sendCase(case, 'dvl')">
          <span
            *ngIf="!case.dvl_ref_number">{{'CASE_CREDITOR.refer_case.referral_to_dvl.refer_case_to_dvl' | translate}}</span>
          <span
            *ngIf="case.dvl_ref_number">{{'CASE_CREDITOR.refer_case.referral_to_dvl.referred_to' | translate}} {{ case.dvl_ref_number }}</span>
        </button>

        <button mat-menu-item color="primary" [disabled]="!!case.uni_ref_number" (click)="sendCase(case, 'uni')">
          <span
            *ngIf="!case.uni_ref_number">{{'CASE_CREDITOR.refer_case.referral_to_uni.refer_case_to_uni' | translate}}</span>
          <span
            *ngIf="case.uni_ref_number">{{'CASE_CREDITOR.refer_case.referral_to_uni.referred_to' | translate}} {{ case.uni_ref_number }}</span>
        </button>

        <button mat-menu-item
                color="primary" [disabled]="!!case.epi_ref_number" (click)="sendCase(case, 'epi')">
          <span *ngIf="!case.epi_ref_number">{{'CASE_CREDITOR.refer_case.referral_to_epi.refer_case_to_epi' | translate}}</span>
          <span *ngIf="case.epi_ref_number">{{'CASE_CREDITOR.refer_case.referral_to_epi.referred_to' | translate}} {{ case.epi_ref_number }}</span>
        </button>
      </mat-menu>
    </div>
    <button [matMenuTriggerFor]="proposalMenu" mat-raised-button color="primary" class="ml-2 mt-2"
            *ngIf="case?.product?.group_slug === 'unified' || case?.product?.group_slug === 'dm' ">
      {{ 'CASES.single.draft.proposal.label' | translate }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #proposalMenu="matMenu">
      <button mat-menu-item color="primary" class="mb-1 mr-md-4" (click)="generateProposal()">
        {{ "CASES.details.generate-proposal" | translate }}
      </button>
      <a mat-menu-item (click)="previewProposal('client')">
        {{ 'CASES.single.draft.proposal.preview_client' | translate }}
      </a>
      <button mat-menu-item (click)="previewProposal('partner')" *ngIf="case?.joint_application">
        {{ 'CASES.single.draft.proposal.preview_partner' | translate }}
      </button>
      <button mat-menu-item (click)="requestProposalSignature()">
        {{ 'CASES.single.draft.proposal.request_signature.label' | translate }}
      </button>
      <button mat-menu-item (click)="sendProposalToCreditors(case.id)">
        {{ 'CASES.single.draft.proposal.send_to_creditors' | translate }}
      </button>
    </mat-menu>
  </div>
</div>
<div class="filters advanced-filters pb-5 pt-3" *ngIf="form" [formGroup]="form">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "PAYMENTS.advanced-filters" | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-input type="select" appearance="standard" [searchable]="false" [fullWidth]="true"
                     formControlName="ownership" [extraLabel]="false" [selectOptions]="ownershipOptions"
                     [label]="'CASE_CREDITOR.model.ownership.label' | translate" multiple="true" [showClear]="true"
                     [selectLabel]="['label']" [selectValue]="'value'">
          </app-input>
        </div>
        <div class="col-md-6 col-lg col-xl">
          <app-input type="select" appearance="standard" [searchable]="false" [fullWidth]="true"
                     formControlName="verified" [extraLabel]="false" [selectOptions]="verifiedOptions"
                     [label]="'CASE_CREDITOR.model.verified.label' | translate" multiple="true" [showClear]="true"
                     [selectLabel]="['label']" [selectValue]="'value'">
          </app-input>
        </div>
        <div class="col-md-6 col-lg col-xl">
          <app-input type="select" appearance="standard" [searchable]="true" [fullWidth]="true"
                     formControlName="response_received" [extraLabel]="false" [selectOptions]="responseReceivedOptions"
                     [label]="'CASE_CREDITOR.model.response_received.label' | translate" multiple="true"
                     [showClear]="true"
                     [selectLabel]="['label']" [selectValue]="'value'">
          </app-input>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="creditors-section" *ngIf="!useCreditorLoanFields && case">
  <!-- Secured Creditors -->
  <!--<h3>{{ "CASE_CREDITOR.model.type.options.secured" | translate }}</h3>
  <button mat-raised-button color="primary"
          [matMenuTriggerFor]="menuSecured">{{ "SHARED.bulk-actions" | translate }}</button>
  <mat-menu #menuSecured="matMenu">
    <div [title]="securedVerifyDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="securedVerifyDisabled" type="button"
              (click)="verifyCreditor(selectionSecured, 'secured_creditors')">
        {{ "CASES.details.verify-send-mandates" | translate }}
      </button>
    </div>
    <div [title]="securedResendDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="securedResendDisabled" type="button"
              (click)="verifyCreditor(selectionSecured, 'secured_creditors')">
        {{ "CASES.details.resend-mandates" | translate }}
      </button>
    </div>
    <div [title]="securedRecoveryDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="securedRecoveryDisabled"
              (click)="sendMandatesRecoveryAgent($event, selectionSecured)">
        {{ "CASES.details.send-mandates-recovery" | translate }}
      </button>
    </div>
    <div
      [title]="securedSendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">
      <button mat-menu-item [disabled]="securedSendAepDisabled"
              (click)="sendAep($event, selectionSecured, 'secured_creditors')">
        {{ "CASES.details.send-aep" | translate }}
      </button>
    </div>
    <div
      [title]="securedResendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">
      <button mat-menu-item [disabled]="securedResendAepDisabled"
              (click)="sendAep($event, selectionSecured, 'secured_creditors')">
        {{ "CASES.details.resend-aep" | translate }}
      </button>
    </div>
    <div
      [title]="securedProposalAccordDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="securedProposalAccordDisabled"
              (click)="sendProposalAndAccordSelection(selectionSecured)">
        {{ "CASES.details.send-proposal-accord" | translate }}
      </button>
    </div>
    <div
      [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionSecured.selected.length"
              (click)="changeStatus($event, selectionSecured, 'secured_creditors')">
        {{ "CASES.details.change-status" | translate }}
      </button>
    </div>
    <div
      [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionSecured.selected.length"
              (click)="sendLastChance($event, selectionSecured)">
        {{ "CASES.details.send-last-chance" | translate }}
      </button>
    </div>
    &lt;!&ndash; Right of Access &ndash;&gt;
    <div
      [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionSecured.selected.length"
              (click)="sendRightOfAccess(selectionSecured)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access' | translate }}
      </button>
    </div>
    &lt;!&ndash;Right of Access  PPI&ndash;&gt;
    <div
      [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionSecured.selected.length"
              (click)="sendRightOfAccessPpi(selectionSecured)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access_ppi' | translate }}
      </button>
    </div>
    &lt;!&ndash; Send PPI Claim &ndash;&gt;
    <div
      [title]="!selectionSecured.selected.length ? 'One or more selected creditors does not have this action available' : ''">
      <button mat-menu-item [disabled]="!selectionSecured.selected.length"
              (click)="sendPpiClaim(selectionSecured)">
        {{ 'SHARED.send' | translate }} PPI Claim
      </button>
    </div>
    &lt;!&ndash; Azcarate Loan &ndash;&gt;
    <div
      [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionSecured.selected.length"
              (click)="sendAzcarateLoanLetter(selectionSecured)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_loan_letter' | translate }}
      </button>
    </div>
    &lt;!&ndash; Azcarate Revolving&ndash;&gt;
    <div
      [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionSecured.selected.length"
              (click)="sendAzcarateRevolvingLetter(selectionSecured)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_revolving_letter' | translate }}
      </button>
    </div>

  </mat-menu>
  <div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">
    <table style="width: 100%" mat-table matSort [dataSource]="securedCreditors">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle(selectionSecured, securedCreditors) : null" color="primary"
                        [checked]="selectionSecured.hasValue() && isAllSelected(selectionSecured, securedCreditors)"
                        [indeterminate]="selectionSecured.hasValue() && !isAllSelected(selectionSecured, securedCreditors)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                        (change)="$event ? selectionSecured.toggle(row) : null"
                        [checked]="selectionSecured.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.creditor-name" | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="ownership">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.ownership === 'applicant'">
            {{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}
          </ng-container>
          <ng-container *ngIf="element.ownership === 'partner'">
            {{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}
          </ng-container>
          <ng-container *ngIf="element.ownership === 'joint'">
            {{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="debt_type">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.debt_type.label" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ "CASE_CREDITOR.model.debt_type.options." + element.debt_type | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="initial_balance">
        <th mat-header-cell *matHeaderCellDef>
          <span>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</span>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.initial_balance | number: '1.2-2' }}€
        </td>
      </ng-container>

      <ng-container matColumnDef="current_balance">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.current_balance | number: '1.2-2'}}€</td>
      </ng-container>

      <ng-container matColumnDef="claimed_amount">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.claimed_amount" | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.claimed_amount | number: '1.2-2'}}€</td>
      </ng-container>

      <ng-container matColumnDef="reference_number">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.reference_number}} </td>
      </ng-container>

      <ng-container matColumnDef="response_received">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container>
            {{ ("CASE_CREDITOR.model.response_received.options." + element.response_received) | translate }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="additional_partner">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.additional_partner" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.additional_partner" class="mat-icon-size" color="primary">check_circle
          </mat-icon>
          <mat-icon *ngIf="!element.additional_partner" class="mat-icon-size" color="warn">cancel</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="making_payments">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.making_payments" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.making_payments" class="mat-icon-size" color="primary">check_circle</mat-icon>
          <mat-icon *ngIf="!element.making_payments" class="mat-icon-size" color="warn">cancel</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="azcarate">
        <th mat-header-cell *matHeaderCellDef>Azcarate</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.total_repaid > element.initial_balance" class="mat-icon-size"
                    color="primary">check_circle
          </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>
          <mat-menu #menu="matMenu">
            <button *ngIf="!element.verified" mat-menu-item type="button"
                    (click)="verifyCreditorIds([element.id], 'secured_creditors')">
              {{ "CASES.details.verify-send-mandates" | translate }}
            </button>
            <button *ngIf="element.verified" mat-menu-item type="button"
                    (click)="verifyCreditorIds([element.id], 'secured_creditors')">
              {{ "CASES.details.resend-mandates" | translate }}
            </button>
            <button *ngIf="element.recovery_creditor_id" mat-menu-item
                    (click)="sendMandatesRecoveryAgent($event, [element.id])">
              {{ "CASES.details.send-mandates-recovery" | translate }}
            </button>
            <div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
              <button *ngIf="!element.aep_sent" mat-menu-item
                      [disabled]="missingAepFiles"
                      (click)="sendAep($event, [element.id], 'secured_creditors')">
                {{ "CASES.details.send-aep" | translate }}
              </button>
            </div>
            <div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
              <button *ngIf="element.aep_sent" mat-menu-item
                      [disabled]="missingAepFiles"
                      (click)="sendAep($event, [element.id], 'secured_creditors')">
                {{ "CASES.details.resend-aep" | translate }}
              </button>
            </div>

            <button [disabled]="!isSigned || !element.proposal_location"
                    mat-menu-item
                    (click)="sendProposalAndAccord([element.id])">
              {{ "CASES.details.send-proposal-accord" | translate }}
            </button>
            <button mat-menu-item
                    [disabled]="!(element.total_repaid > element.initial_balance)"
                    (click)="sendEmail($event, element.id)">
              {{ "CASES.details.send-azcarate" | translate }}
            </button>
            <button mat-menu-item
                    (click)="changeStatus($event, [element.id], 'secured_creditors')">
              {{ "CASES.details.change-status" | translate }}
            </button>
            <button mat-menu-item
                    (click)="sendLastChance($event, [element.id])">
              {{ "CASES.details.send-last-chance" | translate }}
            </button>
            <button mat-menu-item
                    (click)="sendDeleteNumber($event, [element.id])">
              {{ "CASES.details.send-delete-number" | translate }}
            </button>
            <button mat-menu-item
                    (click)="sendAntiHarassment($event, [element.id])">
              {{ "CASES.details.send-azkarate-acoso" | translate }}
            </button>
            <button mat-menu-item
                    (click)="sendLoanCancellationEmail($event, [element.id])">
              {{ "CASES.details.send-azkarate-cancelar" | translate }}
            </button>
            <button mat-menu-item
                    *ngIf="element.ownership === 'applicant' || element.ownership === 'joint' "
                    (click)="getRightOfAccess('client', element.id, $event)">
              {{'CASES.details.get-right-of-access' | translate}} 1
            </button>
            <button mat-menu-item
                    *ngIf="element.ownership === 'partner' || element.ownership === 'joint' "
                    (click)="getRightOfAccess('partner', element.id, $event)">
              {{'CASES.details.get-right-of-access' | translate}} 2
            </button>
            <a mat-menu-item [routerLink]="['/creditor-proposal-preview']" target="_blank"
                    [queryParams]="{uuid: case.uuid, case_creditor_id:element.id, ownership: element.ownership}">
              {{'CASES.details.preview-creditor-proposal-and-accord' | translate}}
            </button>
            <a mat-menu-item class="clickable"
               href="{{storageUrl + element.proposal_location}}">
              {{'CASES.details.download_creditor_proposal' | translate}}
            </a>
            <a mat-menu-item class="clickable"
               href="{{storageUrl + element.proposal_location_accord}}">
              {{'CASES.details.download_proposal_accord' | translate}}
            </a>
          </mat-menu>
          <button mat-stroked-button class="ml-1" *ngIf="authUser.packager?.master"
                  (click)="toggleType(element, 'secured')">
            {{'CASE_CREDITOR.claim.make_as_claim' | translate}}
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="loader">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px">
          <mat-spinner *ngIf="isLoading && isLoadingIds.length && isLoadingIds.indexOf(element.id) !== -1"
                       diameter="20" color="accent"></mat-spinner>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="isFetching" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="!isFetching && securedCreditors && !securedCreditors.data.length" style="padding: 50px">
      <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>
    </div>
  </div>-->
  <app-case-creditor [creditors]="securedCreditors" *ngIf="securedCreditors && case"
                     [originalCreditors]="originalSecuredCreditors" [case]="case"
                     [type]="'secured'" (typeToggle)="toggleTypeClicked($event)"
  ></app-case-creditor>

  <!-- Unsecured Creditors -->
  <app-case-creditor [creditors]="unsecuredCreditors" *ngIf="unsecuredCreditors"
                     [originalCreditors]="originalUnsecuredCreditors"
                     [case]="case" [type]="'unsecured'" (moveToExcluded)="moveToExcludedClicked($event)"
                     (editToggle)="editToggleClicked($event)"
                     (typeToggle)="toggleTypeClicked($event)" [missingAepFiles]="missingAepFiles" [isSigned]="isSigned"
  ></app-case-creditor>

  <!-- Claim Creditors -->
  <app-case-creditor [creditors]="claimCreditors" *ngIf="claimCreditors" [originalCreditors]="originalClaimCreditors"
                     [type]="'claim'" [case]="case" (typeToggle)="toggleTypeClicked($event)"
                     [missingAepFiles]="missingAepFiles"
                     [isSigned]="isSigned"
  ></app-case-creditor>


  <!-- Excluded Creditors -->
  <app-case-creditor [creditors]="excludedCreditors" *ngIf="excludedCreditors && (case?.product?.group_slug === 'dm' ||
                      case?.product?.group_slug === 'cajaplus' || case?.product?.group_slug === 'dgs')"
                     [originalCreditors]="originalExcludedCreditors" [case]="case" [missingAepFiles]="missingAepFiles"
                     [type]="'excluded'" (moveToIncluded)="moveTonIncludedClicked($event)" [isSigned]="isSigned">
  </app-case-creditor>


  <!-- Public Debts -->
  <app-public-debt [publicDebts]="publicDebts" [isFetchingPublicDebt]="isFetchingPublicDebt"
                   [isPublicLoading]="isPublicLoading" [isPublicLoadingId]="isPublicLoadingId"></app-public-debt>
</div>

<!-- Loan creditors -->
<div class="loan-creditors-section" *ngIf="useCreditorLoanFields && case && loanCreditors.data.length > 0">
  <app-loan-case-creditor [loanCreditors]="loanCreditors" [originalLoanCreditors]="originalLoanCreditors" [case]="case"
                          [missingAepFiles]="missingAepFiles" [isSigned]="isSigned"
                          (loanEditToggle)="editLoanToggleClicked($event)"></app-loan-case-creditor>
</div>

