import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePublicDebt } from '../../../../../../../_base-shared/models/Case/CasePublicDebt';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../../environments/environment';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { CreditorService } from '../../../creditor/creditor.service';
import { StatusService } from '../../../status/status.service';
import { CaseCreditorService } from '../../case-creditor.service';
import { CaseDocumentService } from '../../case-document.service';
import { CaseService } from '../../case.service';
import { ProposalModalComponent } from '../case-draft/proposal-modal/proposal-modal.component';

@Component({
  selector:    'app-case-creditor-list',
  templateUrl: './case-creditor-list.component.html',
  styles:      [
    `
      .dvl-push-btn {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        line-height: 36px;
        padding: 0 16px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
      }

      .mat-icon-size {
        font-size: 25px;
        width: 25px;
        height: 25px;
      }

      .mat-form-field {
        width: 100%;
      }

      .p-r-10 {
        padding-right: 10px !important;
      }

      /* th{
         min-width: 107px !important;
       }*/

      a[target]:not(.btn) {
        font-weight: 400 !Important
      }
    `],
})
export class CaseCreditorListComponent implements OnInit, OnDestroy {
  public authUser: User;
  public case: Case;
  public form: UntypedFormGroup;

  public securedCreditors: MatTableDataSource<CaseCreditor>;
  public unsecuredCreditors: MatTableDataSource<CaseCreditor>;
  public claimCreditors: MatTableDataSource<CaseCreditor>;
  public loanCreditors: MatTableDataSource<CaseCreditor>;
  public excludedCreditors: MatTableDataSource<CaseCreditor>;
  public publicDebts: MatTableDataSource<CasePublicDebt>;
  public originalSecuredCreditors: Array<CaseCreditor>;
  public originalUnsecuredCreditors: Array<CaseCreditor>;
  public originalClaimCreditors: Array<CaseCreditor>;
  public originalLoanCreditors: Array<CaseCreditor>;
  public originalExcludedCreditors: Array<CaseCreditor>;

  public isLoading                   = 0;
  public isFetching                  = 0;
  public isFetchingPublicDebt        = 0;
  public isPublicLoading             = 0;
  public isPublicLoadingId: number[] = [];

  //  Enable/Disabled flags
  public isSigned = false;

  public missingAepFiles = true;
  private propuestoModelo: any;

  public isSubmitting                        = false;
  public storageUrl                          = environment.STORAGE_URL + '/';
  public useCreditorLoanFields: boolean;
  public ownershipOptions: Array<AppSelectOption>;
  public verifiedOptions: Array<AppSelectOption>;
  public responseReceivedOptions: Array<AppSelectOption>;
  public categorizedCreditors                = {
    secured:     [],
    excluded:    [],
    notExcluded: [],
    claim:       [],
    unsecured:   [],
  };
  private subscriptions: Array<Subscription> = [];

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private toast: ToastrService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private globalEventService: MainGlobalEventService,
    private caseService: CaseService,
    private creditorService: CreditorService,
    private caseCreditorService: CaseCreditorService,
    private caseDocumentService: CaseDocumentService,
    private statusService: StatusService,
    private el: ElementRef,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.globalEventService.authUser$.subscribe(user => this.authUser = user),
    );
    this.route.parent.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.subscriptions.push(
        this.caseService.get(caseId, ['product']).pipe(finalize(() => this.isLoading--)).subscribe(result => {
          this.isLoading++;
          this.useCreditorLoanFields = result.data.product.group_slug === 'dm' || result.data.product.group_slug ===
            'unified';
          this.indexCaseDocuments(caseId, result);
        }),
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private getLegalDocuments(caseId: number): void {
    this.subscriptions.push(
      this.caseDocumentService.index(caseId, ['legal_documents'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(res => {
          this.case.legal_documents = res.data.legal_documents;
          this.missingAepFiles      = !! (this.case.legal_documents.filter(
              file => file.document_type === 'aep').length === 0 &&
            (this.case.legal_documents.filter(
                file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
              this.case.legal_documents.filter(
                file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0));
        }),
    );
  }

  private showPropuestoModelo(caseId: number): void {
    this.subscriptions.push(this.caseDocumentService.showPropuestoModelo(caseId)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
        this.propuestoModelo = res.data;
        if (this.propuestoModelo) {
          this.isSigned = this.case.joint_application ?
            !! (this.propuestoModelo.signature && this.propuestoModelo.signature_partner) :
            !! this.propuestoModelo.signature;
        } else {
          this.isSigned = false;
        }
      }));
  }

  private indexCaseDocuments(caseId: number, result): void {
    this.subscriptions.push(
      this.caseDocumentService.indexCaseDocuments(caseId)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(res => {
          this.isLoading++;
          this.case         = res.data;
          this.case.product = result.data.product;
          this.fetchData();
          this.buildForm();
          this.buildSelectOptions();
          this.getLegalDocuments(caseId);
          this.showPropuestoModelo(caseId);
          this.form.valueChanges.subscribe(value => {
            const filters = this.getFilters();
            this.fetchData(filters);
          });
        }),
    );
  }

  private buildForm(): void {
    this.form = this.fb.group({
      ownership:         new UntypedFormControl(''),
      verified:          new UntypedFormControl(''),
      response_received: new UntypedFormControl(''),
    });
  }

  public toggleEditMode(isEditing: boolean, element, excludedForm): void {
    if (isEditing) {
      this.el.nativeElement.querySelector('td').classList.add('.p-r-10');
    }
    if ( ! isEditing) {
      this.onExclude(element, excludedForm.value);
    }
  }

  public getFilters(): {} {
    const {ownership, verified, response_received} = this.form.value;
    const data                                     = {};
    if (ownership) {
      data['ownership[]'] = ownership;
    }
    if (verified) {
      data['verified[]'] = verified;
    }
    if (response_received) {
      data['response_received[]'] = response_received;
    }
    return data;
  }

  private fetchData(data = {}): void {
    this.fetchCreditors(data);
    this.fetchPublicDebts(data);
  }

  private fetchCreditors(data): void {
    this.isFetching++;
    this.securedCreditors   = new MatTableDataSource<CaseCreditor>([]);
    this.unsecuredCreditors = new MatTableDataSource<CaseCreditor>([]);
    this.claimCreditors     = new MatTableDataSource<CaseCreditor>([]);
    this.excludedCreditors  = new MatTableDataSource<CaseCreditor>([]);
    this.loanCreditors      = new MatTableDataSource<CaseCreditor>([]);

    this.subscriptions.push(
      this.caseCreditorService.indexCaseCreditors(this.case.id, ['creditor'], {...data, sort_by: 'date'})
        .pipe(finalize(() => this.isFetching--))
        .subscribe(value => {
          this.categorizeCreditors(value.data);
          this.securedCreditors           = new MatTableDataSource<CaseCreditor>(this.categorizedCreditors.secured);
          this.claimCreditors             = new MatTableDataSource<CaseCreditor>(this.categorizedCreditors.claim);
          this.unsecuredCreditors         = new MatTableDataSource<CaseCreditor>(this.categorizedCreditors.notExcluded);
          this.excludedCreditors          = new MatTableDataSource<CaseCreditor>(this.categorizedCreditors.excluded);
          this.originalSecuredCreditors   = this.categorizedCreditors.secured ? this.categorizedCreditors.secured : [];
          this.originalClaimCreditors     = this.categorizedCreditors.claim ? this.categorizedCreditors.claim : [];
          this.originalUnsecuredCreditors = this.categorizedCreditors.notExcluded;
          this.originalExcludedCreditors  = this.categorizedCreditors.excluded ? this.categorizedCreditors.excluded : [];
          if (this.useCreditorLoanFields) {
            const loanCreditors        = this.categorizedCreditors.unsecured ?
              this.categorizedCreditors.unsecured.map(loanCreditor => {
                loanCreditor.debt_amount = loanCreditor.debt_amount ?
                  loanCreditor.debt_amount : loanCreditor.current_balance;
                return loanCreditor;
              }) :
              [];
            this.originalLoanCreditors = loanCreditors;
            this.loanCreditors         = new MatTableDataSource<CaseCreditor>(loanCreditors);
          }
        })
    );
  }


  private categorizeCreditors(caseCreditors: Array<CaseCreditor>): void {
    caseCreditors.forEach(caseCreditor => {
      if (caseCreditor.type === 'secured') {
        this.categorizedCreditors.secured.push(caseCreditor);
      } else if (caseCreditor.type === 'unsecured') {
        this.categorizedCreditors.unsecured.push(caseCreditor);
        if (caseCreditor.excluded === true) {
          this.categorizedCreditors.excluded.push(caseCreditor);
        } else {
          this.categorizedCreditors.notExcluded.push(caseCreditor);
        }
      } else if (caseCreditor.type === 'claim') {
        this.categorizedCreditors.claim.push(caseCreditor);
      }
    });
  }

  private fetchPublicDebts(data): void {
    this.isFetchingPublicDebt++;
    this.publicDebts = new MatTableDataSource<CasePublicDebt>([]);
    this.subscriptions.push(
      this.caseCreditorService.indexCasePublicDebts(this.case.id, [], data)
        .pipe(finalize(() => this.isFetchingPublicDebt--))
        .subscribe(value => {
          this.publicDebts = new MatTableDataSource<CasePublicDebt>(value.data);
        })
    );
  }

  public clearFilters(): void {
    this.form.setValue({
      ownership:         '',
      verified:          '',
      response_received: '',
    });
    this.fetchData({});
  }

  public saveLoanCreditorData(element, formValue): void {
    this.subscriptions.push(
      this.caseCreditorService.update(this.case.id, element.id, formValue)
        .subscribe(res => {
          const updatedCreditor             = this.originalLoanCreditors.find(
            creditor => creditor.id === element.id);
          const updatedCreditorIndex        = this.originalLoanCreditors.findIndex(
            creditor => creditor.id === element.id);
          updatedCreditor.debt_amount       = formValue.debt_amount;
          updatedCreditor.negotiated_amount = formValue.negotiated_amount;
          /*updatedCreditor.total_reduction = formValue.total_reduction;
           updatedCreditor.percent_reduction = formValue.percent_reduction;*/
          this.originalLoanCreditors[updatedCreditorIndex] = updatedCreditor;
          this.loanCreditors                               = new MatTableDataSource<CaseCreditor>(this.originalLoanCreditors);

          this.toast.success(this.translate.instant('SHARED.success'));
        }, err => {
          this.toast.error(this.translate.instant('SHARED.error'));
        })
    );
  }

  public moveToExcludedClicked($event): void {
    const {element, form} = $event;
    this.onExclude(element, form);
  }

  public editToggleClicked($event): void {
    const {isEditing, element, excludedForm} = $event;
    this.toggleEditMode(isEditing, element, excludedForm);
  }

  public editLoanToggleClicked($event): void {
    const {isEditing, element, loanCreditorForm} = $event;
    this.saveLoanCreditorData(element, loanCreditorForm.getRawValue());
  }

  public moveTonIncludedClicked($event): void {
    const {element, isExcluded} = $event;
    this.onInclude(element, isExcluded);
  }

  public onExclude(element, data): void {
    this.subscriptions.push(
      this.caseCreditorService.update(this.case.id, element.id, data)
        .pipe(finalize(() => {
          this.isLoading--;
        }))
        .subscribe(
          value => {
            this.originalUnsecuredCreditors = this.originalUnsecuredCreditors.filter(
              creditor => creditor.id !== element?.id);
            this.unsecuredCreditors         = new MatTableDataSource<CaseCreditor>(this.originalUnsecuredCreditors);
            this.originalExcludedCreditors.push(element);
            this.excludedCreditors = new MatTableDataSource<CaseCreditor>(this.originalExcludedCreditors);
          }, error => {
            this.toast.error(this.translate.instant('SHARED.error'));
          })
    );
  }

  public onInclude(element, isExcluded): void {
    this.subscriptions.push(
      this.caseCreditorService.update(this.case.id, element.id, {excluded: isExcluded})
        .pipe(finalize(() => {
          this.isLoading--;
        }))
        .subscribe(
          value => {
            this.toast.success(this.translate.instant('SHARED.success'));
            this.originalExcludedCreditors = this.originalExcludedCreditors.filter(
              creditor => creditor.id !== element?.id);
            this.excludedCreditors         = new MatTableDataSource<CaseCreditor>(this.originalExcludedCreditors);
            this.originalUnsecuredCreditors.push(element);
            this.unsecuredCreditors = new MatTableDataSource<CaseCreditor>(this.originalUnsecuredCreditors);
          }, error => {
            this.toast.error(this.translate.instant('SHARED.error'));
          })
    );
  }

  private pushToCreditorTable(value: CaseCreditor, nextTable: 'secured' | 'unsecured' | 'claim'): void {
    if (nextTable === 'claim') {
      this.originalClaimCreditors.push(value);
      this.claimCreditors = new MatTableDataSource<CaseCreditor>(this.originalClaimCreditors);
    } else if (nextTable === 'secured') {
      this.originalSecuredCreditors.push(value);
      this.securedCreditors = new MatTableDataSource<CaseCreditor>(this.originalSecuredCreditors);
    } else if (nextTable === 'unsecured') {
      this.originalUnsecuredCreditors.push(value);
      this.unsecuredCreditors = new MatTableDataSource<CaseCreditor>(this.originalUnsecuredCreditors);
    }
  }

  public changeCreditorTable(value: CaseCreditor, creditorType: 'secured' | 'unsecured' | 'claim',
                             prevCreditorType: 'unsecured' | 'secured' = null): void {
    if (creditorType === 'claim' && prevCreditorType === 'secured') {
      this.originalClaimCreditors = this.originalClaimCreditors.filter(
        creditor => creditor.id !== value?.id);
      this.claimCreditors         = new MatTableDataSource<CaseCreditor>(this.originalClaimCreditors);
      this.pushToCreditorTable(value, prevCreditorType);
    }
    if (creditorType === 'claim' && prevCreditorType === 'unsecured') {
      this.originalClaimCreditors = this.originalClaimCreditors.filter(
        creditor => creditor.id !== value?.id);
      this.claimCreditors         = new MatTableDataSource<CaseCreditor>(this.originalClaimCreditors);
      this.pushToCreditorTable(value, prevCreditorType);
    }
    if (creditorType === 'unsecured') {
      this.originalUnsecuredCreditors = this.originalUnsecuredCreditors.filter(
        creditor => creditor.id !== value?.id);
      this.unsecuredCreditors         = new MatTableDataSource<CaseCreditor>(this.originalUnsecuredCreditors);
      this.pushToCreditorTable(value, 'claim');
    }
    if (creditorType === 'secured') {
      this.originalSecuredCreditors = this.originalSecuredCreditors.filter(
        creditor => creditor.id !== value?.id);
      this.securedCreditors         = new MatTableDataSource<CaseCreditor>(this.originalSecuredCreditors);
      this.pushToCreditorTable(value, 'claim');
    }
  }

  public toggleType(value: CaseCreditor, creditorType: 'unsecured' | 'claim' | 'secured',
                    prevCreditorType = null): void {
    if (creditorType === 'unsecured') {
      this.caseCreditorService.update(this.case.id, value?.id, {type: 'claim'})
        .pipe(first())
        .subscribe(
          (data) => {
            this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
          },
          error => {
            this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
          },
        );
      this.changeCreditorTable(value, creditorType);
    } else if (creditorType === 'secured') {
      this.caseCreditorService.update(this.case.id, value?.id, {type: 'claim'})
        .pipe(first())
        .subscribe(
          (data) => {
            this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
          },
          error => {
            this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
          },
        );
      this.changeCreditorTable(value, creditorType);
    } else if (creditorType === 'claim') {
      this.caseCreditorService.update(this.case.id, value?.id, {type: prevCreditorType})
        .pipe(first())
        .subscribe(
          (data) => {
            this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
          },
          error => {
            this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
          },
        );
      this.changeCreditorTable(value, creditorType, prevCreditorType);
    } else if (creditorType === 'claim') {
      this.caseCreditorService.update(this.case.id, value?.id, {type: prevCreditorType})
        .pipe(first())
        .subscribe(
          (data) => {
            this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
          },
          error => {
            this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
          },
        );
      this.changeCreditorTable(value, creditorType, prevCreditorType);
    }
  }

  public sendCase(clientCase: Case, projectId: string): void {
    Swal.fire({
      title:             this.translate.instant('CASE_CREDITOR.refer_case.heading'),
      text:              this.translate.instant('CASE_CREDITOR.refer_case.warning-text') + ' ' +
                           projectId.toUpperCase(),
      icon:              'warning',
      showCancelButton:  true,
      confirmButtonText: 'OK',
      cancelButtonText:  this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.subscriptions.push(
          this.caseService.sendCase(clientCase.id, {destination_project_id: projectId})
            .pipe(finalize(() => this.isLoading--))
            .subscribe(result => {
                if (projectId === 'dvl') {
                  this.case.dvl_ref_number = result.data.case.dvl_ref_number;
                }
                this.toast.success('Sent case successfully');
              },
              error => {
                let errorMessage = 'Failed to send case';
                if (error.error.status === 422) {
                  const parsedError = JSON.parse(error.error.message);
                  errorMessage      = parsedError[Object.keys(parsedError)[0]][0];
                }
                errorMessage = errorMessage.replace('.', ' ');
                this.toast.error(errorMessage);
              })
        );
      }
    });
  }

  private throwMultipleErrors(errorsArray: Array<string> | Array<{
    errors: string, id: number, name: string,
    reference_number: string
  }>): void {
    errorsArray.forEach(caseCreditorError => {
      this.toast.error(caseCreditorError, '',
        {disableTimeOut: true});
    });
  }

  public generateProposal(): void {
    const dialogRef = this.dialog.open(ProposalModalComponent, {
      width:  '50%',
      height: '50%',
      data:   {
        case: this.case,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && ! result.dismissed && result.data) {
        this.case.proposal = result.data;
      }
    });
  }

  public previewProposal(clientRole: string): void {
    const url = (environment.APP_URL.replace('app.', 'clientes.')) +
      '/public/sign-proposal?uuid=' + this.case.uuid + '&sign=' + clientRole;
    window.open(url, '_blank');
  }

  public requestProposalSignature(): void {
    this.subscriptions.push(
      this.caseDocumentService.requestProposalSignature(this.case.id).subscribe(res => {
        this.toastr.success(this.translate.instant('CASES.single.draft.proposal.request_signature.response.success'));
      }, err => {
        this.toastr.error(this.translate.instant('SHARED.went-wrong'));
      })
    );
  }

  public sendProposalToCreditors(caseId: number): void {
    this.caseDocumentService.sendProposalToCreditors(this.case.id).subscribe(res => {
      this.toastr.success(this.translate.instant('CASES.single.draft.proposal.request_signature.response.success'));
    }, err => {
      this.toastr.error(this.translate.instant('SHARED.went-wrong'));
    });
  }

  public buildSelectOptions(): void {
    this.ownershipOptions = [
      {
        label: this.translate.instant('CASE_CREDITOR.model.ownership.options.applicant_joint'),
        value: 'applicant',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.ownership.options.partner'),
        value: 'joint',
      },
    ];

    this.verifiedOptions = [
      {
        label: this.translate.instant('CASE_CREDITOR.model.verified.options.true'),
        value: 1,
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.verified.options.false'),
        value: 0,
      },
    ];

    this.responseReceivedOptions = [
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.mandate_sent'),
        value: 'mandate_sent',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.chase_required'),
        value: 'chase_required',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.mandate_rejected'),
        value: 'mandate_rejected',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.balance_received'),
        value: 'balance_received',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.balance_outstanding"'),
        value: 'balance_outstanding',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.proposal_sent'),
        value: 'proposal_sent',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.proposal_rejected'),
        value: 'proposal_rejected',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.modification_required'),
        value: 'modification_required',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.proposal_accepted'),
        value: 'proposal_accepted',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.extrajudicial_claim_sent'),
        value: 'extrajudicial_claim_sent',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.extrajudicial_claim_accepted'),
        value: 'extrajudicial_claim_accepted',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.extrajudicial_claim_rejected'),
        value: 'extrajudicial_claim_rejected',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.extrajudicial_claim_unanswered'),
        value: 'extrajudicial_claim_unanswered',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.claim_filed'),
        value: 'claim_filed',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.claim_rejected'),
        value: 'claim_approved',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.claim_rejected'),
        value: 'claim_rejected',
      }
    ];
  }

  public toggleTypeClicked($event): void {
    const {value, creditorType, prevCreditorType} = $event;
    this.toggleType(value, creditorType, prevCreditorType);
  }
}
