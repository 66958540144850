<mat-expansion-panel
  class="{{getDocumentStatus(instance.value)}}"
  style="transition: none"
  *ngFor="let instance of courtFilesFiltered | keyvalue"
  (afterCollapse)="openAccordionTab(instance.key, false)"
  (afterExpand)="openAccordionTab(instance.key, true)"
  [expanded]="isAccordionTabOpen[instance.key]"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{instance.key | getLabelFromName}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="pt-lg-4" [formGroup]="docInfo" cdkDropList (cdkDropListDropped)="drop($event, instance)">
    <div class="row align-items-center" *ngFor="let document of instance.value; let i = index" style="padding-bottom: 15px" cdkDrag>
      <div class="col-md-1">
        <div class="example-handle" cdkDragHandle>
          <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
            <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>
      <div class="col-md-3">
        <div class="text-right d-flex flex-row align-items-center" *ngIf="document.id === toEdit">
          <label class="pr-3" for="document_type">{{ 'SHARED.model.name' | translate }}: </label>
          <input type="text" class="form-control" formControlName="name">
        </div>
        <ng-container *ngIf="document.id !== toEdit">
          <div class="row">
            <div class="col-8">
              <a target="_blank" href="{{storageUrl + document.location}}" (click)="selectFile($event, (storageUrl + document.location), document.mime_type)">{{document.name}}</a>
            </div>
            <div class="col-4">
              <button class="ml-2" mat-button color="primary"  (click)="downloadFile($event, document)">{{"CASES.single.download" | translate}}</button>
              <button class="ml-2" mat-button color="primary"  (click)="downloadPdfFile($event, document)">{{"CASES.single.download" | translate}} PDF</button>
            </div>
          </div>
        </ng-container>
      </div>

      <div *ngIf="case" class="col-md-6">
        <div class="row pb-1">
          <div class="col-md-6 text-right d-flex flex-row align-items-center" *ngIf="document.id === toEdit">
            <label class="pr-3" for="document_type">Type: </label>
            <select class="form-control" id="document_type" formControlName="type">
              <option *ngFor="let t of allDocTypes"
                      value="{{t.name}}">{{t.label}}</option>
            </select>
          </div>
          <div class="col-md-6 text-right d-flex flex-row align-items-center" *ngIf="document.id === toEdit">
            <label class="pr-3" for="uploaded_by">Uploaded by: </label>
            <select class="form-control" id="uploaded_by" formControlName="uploaded_by">
              <option value="client">Client</option>
              <option value="partner">Partner</option>
            </select>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="case" class="col-md-2 text-right"> -->
      <!--   <button mat-button color="primary" *ngIf="document.id !== toEdit" (click)="editDocument(document)"> -->
      <!--     {{ "SHARED.edit" | translate }} -->
      <!--   </button> -->
      <!--   <button mat-button color="primary" *ngIf="document.id === toEdit" (click)="cancelEdit()"> -->
      <!--     {{ "SHARED.cancel" | translate }} -->
      <!--   </button> -->
      <!--   <button mat-button color="primary" *ngIf="document.id === toEdit" -->
      <!--           (click)="saveDoc(document, document.document_type)"> -->
      <!--     {{ "SHARED.save" | translate }} -->
      <!--   </button> -->
      <!-- </div> -->
      <div class="col-md-12 d-flex">
        <!-- <div *ngIf="document.id === toEdit && document.mime_type?.includes('image')"> -->
        <!--   <button class="ml-2" mat-raised-button color="primary" (click)="openEditModal($event, document)"> -->
        <!--     {{"CASES.single.edit-image" | translate}} -->
        <!--   </button> -->
        <!-- </div> -->
        <!-- <div *ngIf="document.id === toEdit"> -->
        <!--   <button mat-raised-button color="warn" -->
        <!--           (click)="deleteFile($event, document.id)" class="ml-2"> -->
        <!--     {{ "SHARED.delete" | translate }} -->
        <!--   </button> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="form-group" [formGroup]="mergedDoc">
        <input type="text" class="form-control" formControlName="fileName" placeholder="File name...">
      </div>
    </div>
    <div class="col-md-4">
      <!-- <button type="submit" class="mat-focus-indicator mat-raised-button mat-button-base mat-primary" (click)="saveMergedFiles($event, instance.key)"> -->
      <!--   <span *ngIf="!mergingFiles; else notMerging">Merge</span> -->
      <!--   <ng-template #notMerging> -->
      <!--     <mat-spinner diameter="20" color="accent" class="m-2"></mat-spinner> -->
      <!--   </ng-template> -->
      <!-- </button> -->
    </div>
  </div>
</mat-expansion-panel>
