<mat-accordion multi class="mt-2">
  <mat-expansion-panel class="mt-2" [expanded]="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ "CASES.single.required-documents-v2" | translate }} {{ ("CASES.single." + clientRole) | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <!-- <pre *ngIf="form"> -->
    <!--   {{ form  }} -->
    <!-- </pre> -->
    <form *ngIf="formReady" [formGroup]="form">
      <div class="d-flex justify-content-end">
        <button mat-raised-button [hidden]="requestingDocuments" [matMenuTriggerFor]="requestDocumentsMenu"
                color="primary" class="ml-2 mt-2">
          {{ "SHARED.send" | translate }}
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #requestDocumentsMenu="matMenu">
          <button mat-menu-item color="primary" class="mb-1 mr-md-4" (click)="submitForm(form,'email')">Email</button>
          <button mat-menu-item (click)="submitForm(form,'sms')">SMS</button>
          <button mat-menu-item (click)="submitForm(form,'all')">Email & SMS</button>
          <button mat-menu-item (click)="submitForm(form, 'none')">None</button>
        </mat-menu>
        <div class="spinner-container p-2">
          <mat-spinner *ngIf="requestingDocuments" diameter="30"></mat-spinner>
        </div>
      </div>
      <div class="m-3" [formArrayName]="'categories'">
        <div class="row mt-3" *ngFor="let category of formCategories.controls; let i = index">
          <div class="col-12" [formGroupName]="i" style="max-width: 705px">
            <div class="document-type-wrapper w-100 d-flex justify-content-between">
              <mat-checkbox class="document-type-control w-100" (change)="toggleAllDocTypes($event, i)"
                            [labelPosition]="'before'">
                <h4 class="m-0 text-primary">
                  <b>{{ category.get('name_' + currentLanguage).value }}</b>
                </h4>
              </mat-checkbox>
            </div>
            <div class="w-100" [formArrayName]="'document_types'">
              <div class="w-100" *ngFor="let documentType of category.get('document_types')['controls']; let j = index">
                <div class="document-type-wrapper w-100" [formGroupName]="j">
                  <!-- Basic and Circumstantial -->
                  <ng-container *ngIf="!documentType.get('custom_input_logic').value">
                    <mat-checkbox class="document-type-control w-100" color="primary" formControlName="selected"
                                  [labelPosition]="'before'">
                      {{ documentType.get('name_' + currentLanguage).value }}
                      <small *ngIf="documentType.get('last_requested_at').value">
                        (requested: {{ documentType.get('last_requested_at').value | date }})
                      </small>
                    </mat-checkbox>
                  </ng-container>
                  <!-- Case Creditor / Public Debt / Case Asset -->
                  <ng-container *ngIf="documentType.get('custom_input_logic').value === 'case_creditor_selection' ||
                  documentType.get('custom_input_logic').value === 'case_public_debt_selection' ||
                  documentType.get('custom_input_logic').value === 'case_asset_selection'">
                    <div [formArrayName]="'documentables'">
                      <div
                        *ngFor="let documentable of documentType.get('documentables')['controls']; let k = index">
                        <div [formGroupName]="k">
                          <mat-checkbox class="document-type-control w-100" color="primary" formControlName="selected"
                                        [labelPosition]="'before'">
                            {{ documentable.get('name').value }} {{ documentable.get('reference_number').value }}
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- Custom -->
                  <ng-container *ngIf="documentType.get('custom_input_logic').value === 'custom_selection'">
                    <div [formArrayName]="'custom_documents'">
                      <div class="d-flex justify-content-end">
                        <button type="button" mat-stroked-button color="primary" class="btn mt-2 ml-2"
                                (click)="addCustomDocument(documentType)">
                          Add Document
                        </button>
                      </div>
                      <div class=""
                           *ngFor="let customDoc of documentType.get('custom_documents')['controls']; let k = index">
                        <div [formGroupName]="k" class="d-flex align-items-center mt-1">
                          <div class="w-50">
                            <mat-form-field [appearance]="'outline'">
                              <input matInput type="text" formControlName="name" [readonly]="customDoc.get('id').value">
                            </mat-form-field>
                            <button *ngIf="!customDoc.get('id').value" type="button" mat-icon-button color="warn"
                                    (click)="removeCustomDocument(documentType, k)">
                              <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">
                                remove_circle
                              </mat-icon>
                            </button>
                          </div>
                          <div class="w-50">
                            <mat-checkbox class="document-type-control w-100" color="primary" formControlName="selected"
                                          [labelPosition]="'before'">
                              <!-- {{ customDoc.get('name').value }}: => {{ customDoc.get('selected').value }} -->
                            </mat-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-expansion-panel>
</mat-accordion>
