<div class="col-6" *ngIf="filterType !== 'legal'">
  <button mat-raised-button color="primary" (click)="newCasePrefill()">
    + {{ "CASES.add-new" | translate }}
  </button>
  <button mat-stroked-button color="primary" class="ml-2" (click)="clearFilters()">
    {{ "SHARED.reset-filters" | translate }}
  </button>
</div>

<app-case-list-filters (filtersReady)="handleFiltersState($event)" (submitFilters)="handleFilters($event)"
                       [onlyRelated]="onlyRelated" [type]="filterType" [newPartner]="newPartner">
</app-case-list-filters>

<!-- Bulk Notifications Form -->
<div class="d-flex justify-content-between" *ngIf="filterType !== 'legal'">
  <form *ngIf="bulkActionForm && (authUser.role_id === 1 || authUser.role_id === 5)" [formGroup]="bulkActionForm"
        #ngBulkForm
        (ngSubmit)="sendNotifications(bulkActionForm)">
    <mat-form-field appearance="standard">
      <mat-label>{{ "CASES.list.notifications.label" | translate }}</mat-label>
      <mat-select id="action" formControlName="action">
        <mat-option value="">{{ "CASES.list.notifications.options.none" | translate }}</mat-option>
        <mat-option value="sms">{{ "CASES.list.notifications.options.sms" | translate }}</mat-option>
        <mat-option value="email">{{ "CASES.list.notifications.options.email" | translate }}</mat-option>
        <mat-option value="all">{{ "CASES.list.notifications.options.all" | translate }}</mat-option>
      </mat-select>
      <div *ngIf="bulkActionForm.get('action').errors && ngBulkForm.submitted">
        <mat-error [hidden]="!bulkActionForm.get('action').hasError('required')">
          {{ "SHARED.field-required" | translate }}
        </mat-error>
      </div>
    </mat-form-field>
    <button mat-raised-button class="ml-3" type="submit" color="primary"
            [disabled]="bulkActionForm.invalid || selection.selected.length === 0">
      {{ 'SHARED.send' | translate }}
    </button>
  </form>
  <div class="text-right">
    <button mat-raised-button color="primary" (click)="applyFilters()">
      {{ 'SHARED.apply_filters' | translate }}
    </button>
  </div>
</div>
<!--Apply button on legal page-->
<div class="text-right mb-3" *ngIf="filterType === 'legal'">
  <button mat-raised-button color="primary" (click)="applyFilters()">
    {{ 'SHARED.apply_filters' | translate }}
  </button>
</div>

<!--If all are selected and there is more to select on another page -->
<div *ngIf="filtersReady && caseListFilter && pageSelected && (totalPages > 1)" class="row mb-3">
  <div class="col-12">
    <div class="global-select-all">
      <p class="m-0" *ngIf="!caseListFilter.select_all">
        Selected <b>{{ selection.selected.length }}</b> cases on this page.
        <button mat-button color="primary" (click)="globalSelectAll($event)">
          Select all {{ totalResults | number }} cases
        </button>
      </p>
      <p class="m-0" *ngIf="caseListFilter.select_all">
        All <b>{{ totalResults | number }}</b> cases selected.
        <button mat-button color="primary" (click)="globalClearSelectAll($event)">Clear selection</button>
      </p>
    </div>
  </div>
</div>
<div *ngIf="filtersReady && filterType !== 'customer_contact' && dataSource; else contactTable"
     class="mat-elevation-z8">
  <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/cases', row.id]"></tr>
    <!-- Selection -->
    <ng-container matColumnDef="select">
      <!-- Select All On Page -->
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox color="primary" (change)="$event ? togglePageSelect($event) : null"
                      [checked]="selection.hasValue() && allRowsSelected()"
                      [indeterminate]="selection.hasValue() && !allRowsSelected()">
        </mat-checkbox>
      </th>
      <!-- Select Row -->
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" color="primary" [checked]="selection.isSelected(row)"
                      (change)="toggleRow($event, row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <!-- Ref Number -->
    <ng-container matColumnDef="ref_number">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <a class="pr-3" (click)="$event.stopPropagation()" [routerLink]="['/cases', element.id, 'general']">
          {{ element.ref_number }}</a></td>
    </ng-container>
    <!-- First Name -->
    <ng-container matColumnDef="first_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "USERS.model.first_name" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.client.first_name }}</td>
    </ng-container>
    <!-- Last Name -->
    <ng-container matColumnDef="last_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "USERS.model.last_name" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.client.last_name }}</td>
    </ng-container>
    <!-- Product Group Slug -->
    <ng-container matColumnDef="product_group_slug">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "PRODUCT.model.group_slug" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.product?.group_name_es  | uppercase }}</td>
    </ng-container>
    <!-- Product Name -->
    <ng-container matColumnDef="product_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "PRODUCT.model.name" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.product?.name }}</td>
    </ng-container>
    <!-- Package Name -->
    <ng-container matColumnDef="packager_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "PACKAGER.model_name.singular" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.packager?.name_es }}</td>
    </ng-container>
    <!-- Status and Payment Status -->
    <ng-container matColumnDef="status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.case-status" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="badge badge-primary">{{ element.status?.name }}</span>
        </div>
        <div>
          <span class="badge badge-info">{{ element.payment_status?.name }}</span>
        </div>
        <div *ngIf="element.case_invoice_status_id">
          <span class="badge badge-danger" *ngIf="this.authUser?.id === 1 || this.authUser?.id === 22 || this.authUser?.id === 211750 ||
           this.authUser?.id === 27 || this.authUser?.id === 34 || this.authUser?.id === 36 || this.authUser?.id === 2497">
            {{ element.case_invoice_status?.name }}
          </span>
        </div>
      </td>
    </ng-container>
    <!-- Time in Status -->
    <ng-container matColumnDef="entered_at_status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASES.time-in-status' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <p class="m-0">{{ element.latest_case_status_log?.created_at | dateDifference }}</p>
        </div>
      </td>
    </ng-container>
    <!-- Time since docs uploaded -->
    <ng-container matColumnDef="pending_doc_uploaded_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASES.doc-timer' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <p
            class="m-0">{{ element.pending_doc_uploaded_at ? (element.pending_doc_uploaded_at | dateDifference) : 'N/A' }}</p>
        </div>
      </td>
    </ng-container>
    <!-- Packager Status -->
    <ng-container matColumnDef="packager_status" *ngIf="!authUser.packager.master">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASES.packager-status' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="badge badge-primary">{{ element.packager_status?.name }}</span>
        </div>
      </td>
    </ng-container>
    <!-- Created At -->
    <ng-container matColumnDef="created_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.model.created_at" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <p class="m-0">{{ element.created_at | toDateObject | date:'dd/MM/yy' }}</p>
        <p class="m-0">{{ element.created_at | toDateObject | date:'HH:mm' }}</p>
      </td>
    </ng-container>
    <!-- Affiliate -->
    <ng-container matColumnDef="affiliate">
      <th mat-header-cell *matHeaderCellDef>{{ "CASES.affiliates" | translate }}</th>
      <td mat-cell *matCellDef="let element" class="pr-2">{{ element.affiliate[0]?.name }}</td>
    </ng-container>
    <!-- Amount Paid -->
    <ng-container matColumnDef="amount_paid">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.amount_paid" | translate }}</th>
      <td mat-cell *matCellDef="let element" class="pr-2">{{ +element.amount_paid ?? 0 | currency }}</td>
    </ng-container>
    <!-- Fees outstanding -->
    <ng-container matColumnDef="fees_outstanding">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.fees_outstanding" | translate }}</th>
      <td mat-cell *matCellDef="let element" class="pr-2">{{ +element.amount_unpaid ?? 0 | currency }}</td>
    </ng-container>
    <!-- Debt Amount -->
    <ng-container matColumnDef="debt_amount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.debt-amount" | translate }}</th>
      <td mat-cell *matCellDef="let element" class="pr-2">{{ +element.lead?.debt_amount ?? 0 | currency }}</td>
    </ng-container>
    <!-- Application Type -->
    <ng-container matColumnDef="joint_application">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">
        {{ "CASES.model.joint_application" | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <mat-icon *ngIf="element.joint_application" class="text-success mat-icon">check</mat-icon>
        <mat-icon *ngIf="!element.joint_application" class="text-danger mat-icon">clear</mat-icon>
      </td>
    </ng-container>
    <!-- Full name -->
    <ng-container matColumnDef="full_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASES.single.full-name' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.client.first_name }} {{ element.client.last_name }}</td>
    </ng-container>
    <!-- Email -->
    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'SHARED.model.email' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.client.email }}</td>
    </ng-container>
    <!-- Phone -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'SHARED.model.phone' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.client.phone }}</td>
    </ng-container>
    <!-- Primary Conversion Status -->
    <ng-container matColumnDef="primary_conversion_status">
      <th mat-header-cell *matHeaderCellDef>{{ 'AFFILIATES.editor.primary_conversion_status' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.lead?.primary_conversion?.converted_at && ! element.lead?.primary_conversion?.cancelled_at ? 'TRUE' : 'FALSE' }}
      </td>
    </ng-container>
    <!-- Secondary Conversion Status -->
    <ng-container matColumnDef="secondary_conversion_status">
      <th mat-header-cell *matHeaderCellDef>{{ 'AFFILIATES.editor.secondary_conversion_status' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.lead?.secondary_conversion?.converted_at && ! element.lead?.secondary_conversion?.cancelled_at ? 'TRUE' : 'FALSE' }}
      </td>
    </ng-container>
    <!-- Value -->
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>{{ 'AFFILIATES.editor.value' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ +element.total_conversion_cost_sum ?? 0 | currency: 'EUR' }}</td>
    </ng-container>
    <!-- Value Bonus -->
    <ng-container matColumnDef="volume_bonus">
      <th mat-header-cell *matHeaderCellDef>{{ 'AFFILIATES.editor.volume_bonus' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ +element.lead?.volume_bonus_conversion?.cost ?? 0| currency: 'EUR' }}
      </td>
    </ng-container>
    <!-- Invoice status -->
    <ng-container matColumnDef="invoice_status">
      <th mat-header-cell *matHeaderCellDef>{{ 'AFFILIATES.editor.invoice_status' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.case_invoice_status?.name_es }}</td>
    </ng-container>
    <!-- Paid date -->
    <ng-container matColumnDef="paid_date">
      <th mat-header-cell *matHeaderCellDef>{{ 'AFFILIATES.editor.paid_date' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.latest_payment?.payment_date }}</td>
    </ng-container>
    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="pl-2">{{ "SHARED.actions" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex" class="pl-2"
             *ngIf="!(!authUser.packager.master && element.packager_id === element.original_packager_id)">
          <a class="pr-3" style="color: #79a303"
             (click)="clickToCall(element.client.phone, $event)">{{ 'CASES.single.call' | translate }}</a>
          <a class="pr-3" (click)="$event.stopPropagation()"
             [routerLink]="['/cases', element.id, 'edit', 'general']">{{ "SHARED.edit" | translate }}</a>
          <a class="pr-3" href="#" (click)="openDeleteDialog(element, $event)">{{ "SHARED.delete" | translate }}</a>
        </div>
      </td>
    </ng-container>
  </table>


  <!-- Loader -->
  <div *ngIf="isLoadingCases > 0" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <!-- No Data -->
  <div *ngIf="isLoadingCases < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
    <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
  </div>
  <!-- Pagination -->
  <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                 [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="paginatorChange($event)">
  </mat-paginator>
</div>

<ng-template #contactTable>
  <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/cases', row.id]"></tr>
    <!-- Selection -->
    <ng-container matColumnDef="select">
      <!-- Select All On Page -->
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox color="primary" (change)="$event ? togglePageSelect($event) : null"
                      [checked]="selection.hasValue() && allRowsSelected()"
                      [indeterminate]="selection.hasValue() && !allRowsSelected()">
        </mat-checkbox>
      </th>
      <!-- Select Row -->
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" color="primary" [checked]="selection.isSelected(row)"
                      (change)="toggleRow($event, row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <!-- Ref Number -->
    <ng-container matColumnDef="ref_number">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
      <td mat-cell *matCellDef="let element"><a class="pr-3" (click)="$event.stopPropagation()"
                                                [routerLink]="['/cases', element.id, 'general']">
        {{ element.ref_number }}</a></td>
    </ng-container>
    <!-- First Name -->
    <ng-container matColumnDef="first_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "USERS.model.first_name" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.client.first_name }}</td>
    </ng-container>
    <!-- Last Name -->
    <ng-container matColumnDef="last_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'USERS.model.last_name' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.client.last_name }}</td>
    </ng-container>
    <!-- Product Group Slug -->
    <ng-container matColumnDef="product_group_slug">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "PRODUCT.model.group_slug" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.product?.group_name_es | uppercase }}</td>
    </ng-container>
    <!-- Case Status -->
    <ng-container matColumnDef="case_status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.case-status" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="badge badge-primary">{{ element.status?.name }}</span>
        </div>
      </td>
    </ng-container>
    <!-- Payment Status -->
    <ng-container matColumnDef="payment_status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'CASES.single.general.status_editor.payment_status' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="badge badge-info">{{ element?.payment_status?.name }}</span>
        </div>
      </td>
    </ng-container>
    <!-- Time in Status -->
    <ng-container matColumnDef="entered_at_status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASES.time-in-status' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <p class="m-0">{{ element.latest_case_status_log?.created_at | dateDifference }}</p>
        </div>
      </td>
    </ng-container>
    <!-- Time since docs uploaded -->
    <ng-container matColumnDef="pending_doc_uploaded_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASES.time_since_doc_uploaded' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <p
            class="m-0">{{ element.pending_doc_uploaded_at ? (element.pending_doc_uploaded_at | dateDifference) : 'N/A' }}</p>
        </div>
      </td>
    </ng-container>
    <!-- Amount Paid -->
    <ng-container matColumnDef="amount_paid">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.single.amount-paid" | translate }}</th>
      <td mat-cell *matCellDef="let element" class="pr-2">{{ +element.amount_paid ?? 0 | currency }}</td>
    </ng-container>
    <!-- Last Call Contact-->
    <ng-container matColumnDef="last_call_contact">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.single.last-call-contact" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.history_call }}</td>
    </ng-container>
    <!-- Last Successful Call Contact -->
    <ng-container matColumnDef="last_successful_call_contact">
      <th mat-header-cell mat-sort-header
          *matHeaderCellDef>{{ "CASES.single.last-successful-call-contact" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.history_successful_call }}</td>
    </ng-container>
    <!-- Agent -->
    <ng-container matColumnDef="agent">
      <th mat-header-cell *matHeaderCellDef>{{ "USERS.agent" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element?.agent }}</td>
    </ng-container>
    <!-- Call duration -->
    <ng-container matColumnDef="call_duration">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "CASES.single.general.relation_list.notification_list.call.duration" | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="pr-2">{{ element.call_duration }}</td>
    </ng-container>
    <!-- Last WhatsApp Contact -->
    <ng-container matColumnDef="last_whatsapp_contact">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.single.last-whatsapp-contact" | translate }}</th>
      <td mat-cell *matCellDef="let element"></td>
    </ng-container>
    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="pl-2">{{ "SHARED.actions" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex" class="pl-2">
          <a class="pr-3" (click)="$event.stopPropagation()" target="_blank" style="font-weight: 400"
             [routerLink]="['/cases', element.id, 'general']">{{ "SHARED.view" | translate }}</a>
          <a class="pr-3" (click)="$event.stopPropagation()"
             [routerLink]="['/cases', element.id, 'edit', 'general']">{{ "SHARED.edit" | translate }}</a>
          <a class="pr-3" href="#" (click)="openDeleteDialog(element, $event)">{{ "SHARED.delete" | translate }}</a>
        </div>
      </td>
    </ng-container>
  </table>


  <!-- Loader -->
  <div *ngIf="isLoadingCases > 0" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <!-- No Data -->
  <div *ngIf="isLoadingCases < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
    <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
  </div>
  <!-- Pagination -->
  <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                 [pageSizeOptions]="[5, 10, 20, 50]" (page)="paginatorChange($event)">
  </mat-paginator>
</ng-template>
<!-- Footer Actions -->
<div class="mt-2 pb-3 pl-1"
     *ngIf="authUser.role_id === 1 || authUser.role_id === 5 || authUser.role_id === 7 || isUserCreditorNegotiator
      || authUser.id === 678731 || authUser.id === 2597 || authUser.id === 704999 || authUser.id === 760962 || authUser.id === 632387
          || authUser.id === 130104 || authUser.id === 759674">
  <!-- Export Cases -->
  <button *ngIf="filterType !== 'customer_contact' && (authUser.role_id === 1 || authUser.role_id === 5 || authUser.role_id === 7 ||
          authUser.role_id === 26 || authUser.id === 644922 || authUser.id === 661765 || authUser.id === 664434 || authUser.id === 678731
          || authUser.id === 2597 || authUser.id === 704999 || authUser.id === 406931 || authUser.id === 760962 || authUser.id === 632387
          || authUser.id === 130104)"
          mat-raised-button color="primary" class="mr-3"
          [matMenuTriggerFor]="menuSecured" [disabled]="selection.selected.length === 0">
    {{ "CASES.list.export.button-name" | translate }}
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #menuSecured="matMenu">
    <a mat-menu-item class="dropdown-item" (click)="exportRecords(caseListFilter)">
      {{ "CASES.list.export.button-name" | translate }}
    </a>
    <a [hidden]="authUser.id === 678731" mat-menu-item class="dropdown-item" (click)="exportRecords(caseListFilter, 1)">
      {{ "CASES.list.export.button-name-marketing" | translate }}
    </a>
    <a [hidden]="authUser.id === 678731" mat-menu-item class="dropdown-item"
       (click)="openGCLIDModal($event, caseListFilter)">
      {{ 'CASES.list.export.button-name-gclid' | translate }}
    </a>
    <a [hidden]="authUser?.role.slug !== 'super-admin'" mat-menu-item class="dropdown-item"
       (click)="exportRecordsDataAnalysis(caseListFilter)">
      {{ "CASES.list.export.button-name-data-analysis" | translate }}
    </a>
  </mat-menu>
  <!-- Export Customer Contact-->
  <button
    *ngIf="filterType === 'customer_contact' && authUser.role_id === 1 || authUser.role_id === 5 || authUser.role_id === 7"
    mat-raised-button color="primary"
    (click)="exportRecordsCustomerContact(caseListFilter)" class="mr-3">
    {{ "CASES.list.export.button-name" | translate }}
  </button>
  <!-- Export Last Actions -->
  <button
    *ngIf="filterType !== 'legal' && filterType !== 'customer_contact' && authUser.role_id === 1 || authUser.role_id === 5 || authUser.role_id === 7"
    mat-raised-button color="primary"
    (click)="exportLastActionRecords(caseListFilter)" [disabled]="selection.selected.length === 0" class="mr-3">
    {{ "CASES.list.export.button-name-last-action" | translate }}
  </button>
  <ng-container *ngIf="(authUser.role_id === 5 || authUser.role_id === 7) || isUserCreditorNegotiator">
    <!-- Push to Dialer -->
    <button mat-raised-button color="primary" (click)="dialerListIdSelector()"
            *ngIf="authUser.id === 27 || authUser.id === 36 || authUser.id === 238987 || authUser.id === 613764
            || authUser.id === 34 || authUser.id === 336521 || authUser.id === 1"
            [disabled]="filterType !== 'customer_contact' && selection.selected.length === 0">
      {{ "CASES.list.push_to_dialer.button" | translate }}
    </button>
    <!-- Assign Distribution -->
    <button mat-raised-button color="primary" class="ml-3" (click)="moveToDistribution(caseListFilter)"
            [disabled]="selection.selected.length === 0" *ngIf="authUser.role_id === 5 || authUser.role_id === 7">
      {{ "CASES.list.assign_distribution.button" | translate }}
    </button>
    <!-- Bulk Updates -->
    <button mat-raised-button color="primary" class="ml-3"
            [matMenuTriggerFor]="bulkActionsMenu" [disabled]="selection.selected.length === 0">
      {{ "CASES.list.bulk_actions.button" | translate }}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #bulkActionsMenu="matMenu">
      <a *ngIf="authUser.role_id === 5 || authUser.role_id === 7" mat-menu-item class="dropdown-item"
         (click)="openChangeModal('status', caseListFilter)">
        {{ "CASES.list.bulk_actions.change_case_status" | translate }}
      </a>
      <a *ngIf="this.authUser?.id === 1 || this.authUser?.id === 22 || this.authUser?.id === 27 ||
       this.authUser?.id === 34 || this.authUser?.id === 36 || this.authUser?.id === 2497"
         mat-menu-item class="dropdown-item" (click)="openChangeModal('case_invoice_status', caseListFilter)">
        {{ "CASES.list.bulk_actions.change_case_invoice_status" | translate }}
      </a>
      <a mat-menu-item class="dropdown-item" (click)="openChangeModal('department_assignments', caseListFilter)"
         *ngIf="authUser.role_id === 5 || authUser.role_id === 7">
        {{ "CASES.list.bulk_actions.change_department_status" | translate }}
      </a>
      <a mat-menu-item class="dropdown-item" (click)="openChangeModal('product_type', caseListFilter)"
         *ngIf="authUser.role_id === 5 || authUser.role_id === 7">
        {{ 'CASES.list.bulk_actions.change_product_type' | translate }}
      </a>
      <a mat-menu-item class="dropdown-item" (click)="downloadDocuments()"
         *ngIf="(authUser.role_id === 5 || authUser.role_id === 7) || isUserCreditorNegotiator">
        {{ 'CASES.list.bulk_actions.download_documents' | translate }}
      </a>
    </mat-menu>
  </ng-container>
  <!--Doesnt meet requirements btn-->
  <button mat-raised-button color="primary" class="ml-3" (click)="exportDoesntMeetRequirements(caseListFilter)">
    {{ "CASES.list.export.button-name-doesnt-meet-requirements" | translate }}
  </button>
  <!--Uploaded docs export-->
  <button mat-raised-button color="primary" class="ml-3" (click)="exportUploadedDocs(caseListFilter)">
    {{ "CASES.list.export.button-name-uploaded-docs" | translate }}
  </button>
</div>
