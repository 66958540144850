import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { Affiliate } from '../../../../../../_base-shared/models/Affiliate/Affiliate';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { Department } from '../../../../../../_base-shared/models/Department/Department';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { AffiliateService } from '../../affiliate/affiliate.service';
import { DepartmentService } from '../../department/department.service';
import { UserService } from '../../user/user.service';

@Component({
  selector:    'app-case-editor-prefill',
  templateUrl: './case-editor-prefill.component.html',
  styles:      []
})
export class CaseEditorPrefillComponent implements OnInit {
  public isLoading                      = 0;
  public form: UntypedFormGroup;
  public case: Case;
  public departments: Array<Department> = [];
  public allLegalAdvisors: Array<User>  = [];
  public allVerifiers: Array<User>      = [];
  public affiliates: Array<Affiliate>   = [];
  public authUser: User;

  constructor(private router: Router,
              private formBuilder: UntypedFormBuilder,
              public dialogRef: MatDialogRef<CaseEditorPrefillComponent>,
              private globalEventsService: MainGlobalEventService,
              private userService: UserService,
              private affiliateService: AffiliateService,
              private departmentService: DepartmentService) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    this.fetchAffiliates();
    this.fetchDepartments();
    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      affiliate_id:     [null, Validators.required],
      legal_advisor_id: [],
      verifier_id:      []
    });
  }

  public submitForm(form): void {
    if (form.invalid) {
      return;
    }
    this.router.navigate(['cases', 'create', 'general'], { state: { prefillData: form.value } });
    this.dialogRef.close();
  }

  public fetchDepartments(): void {
    this.isLoading++;
    this.departmentService.index(['users']).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.departments = result.data;
        this.departments.forEach(department => {
          if (department.type === 'verifier') {
            this.allVerifiers = department.users;
          }
          if (department.type === 'legal-advisor') {
            this.allLegalAdvisors = department.users;
          }
        });
      }
    );
  }

  public fetchAffiliates(): void {
    this.affiliateService.index({ all: 1 }).subscribe(result => this.affiliates = result.data);
  }
}
