import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { LaravelResourceResponse, } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { DatabaseNotification } from '../../../../../../_base-shared/models/Notification/DatabaseNotification';
import { NotificationListFilter } from '../../../../../../_base-shared/models/Notification/NotificationListFilter';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { UserService } from '../../user/user.service';
import { NotificationDetailComponent } from '../notification-detail/notification-detail.component';
import { NotificationService } from '../notification.service';

@Component({
  selector:    'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls:   ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit, AfterViewInit, OnDestroy {
  public authUser: User;
  public onlyRelated: boolean = null;

  public form: UntypedFormGroup;
  public formReady          = false;
  public filtersReady: boolean;
  public users: Array<User> = [];

// Datatable
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public isLoading                           = 0;
  public displayedColumns: Array<string>     = [
    'authorable',
    'notifiable',
    'subject',
    'created_at',
    'read_at',
    'actions',
  ];
  public totalResults: number;
  public totalPages: number;
  public dataSource: MatTableDataSource<DatabaseNotification>;
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  // End Datatable
  private subscriptions: Array<Subscription> = [];
  private formChangeSubscriber: Subscription;
  private notificationListFilter: NotificationListFilter;

  constructor(private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              public dialog: MatDialog,
              private globalEventsService: MainGlobalEventService,
              private notificationService: NotificationService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.onlyRelated            = this.route.snapshot.data.hasOwnProperty('onlyRelated') ?
        this.route.snapshot.data.onlyRelated :
        true;
    this.defaultPaginatorConfig = {pageIndex: 0, pageSize: 20, length: 1};
    this.defaultSort            = {direction: 'desc', active: 'created_at'};
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      if (!this.onlyRelated && this.authUser.role_id !== 5) {
        this.onlyRelated = true;
      }
      this.notificationListFilter = {
        notifiables:  this.onlyRelated ? [this.authUser.id] : [],
        only_related: this.onlyRelated,
        unread:       true,
        time:         'current',
      };
      this.buildForm(this.notificationListFilter);
    }));

    this.fetchUsers();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.formChangeSubscriber.unsubscribe();
  }

  public handleFiltersState($event: boolean): void {
    this.filtersReady = $event;
  }

  public handleFilters($event: NotificationListFilter): void {
    this.notificationListFilter = $event;
    this.resetPagination();
    this.resetSort();
    this.fetchNotifications();
  }

  private resetPagination(): void {
    this.paginatorConfig                 = this.defaultPaginatorConfig;
    this.notificationListFilter.per_page = this.paginatorConfig.pageSize;
    this.notificationListFilter.page     = this.paginatorConfig.pageIndex;
  }

  private resetSort(): void {
    this.notificationListFilter.sort_by = this.defaultSort.active;
    this.notificationListFilter.order   = this.defaultSort.direction;
  }

  private buildForm(notificationListFilter: NotificationListFilter) {
    if (this.formChangeSubscriber) {
      this.formChangeSubscriber.unsubscribe();
    }
    this.form = this.fb.group({
      notifiables:  [{value: notificationListFilter.notifiables, disabled: notificationListFilter.only_related}],
      time:         [notificationListFilter.time],
      unread:       [notificationListFilter.unread],
      only_related: [notificationListFilter.only_related],
    });

    this.formReady = true;
    this.handleFiltersState(true);
    this.handleFilters(notificationListFilter);
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges() {
    this.formChangeSubscriber = this.form.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(
        result => {
          // Note: temp left for debugging
          if (this.form.invalid) {
            return;
          }
          this.notificationListFilter = this.form.value;
          this.handleFilters(this.notificationListFilter);
        },
    );
  }

  // TODO: save paginate settings to localStorage
  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.notificationListFilter.page     = this.paginatorConfig.pageIndex + 1;
    this.notificationListFilter.per_page = this.paginatorConfig.pageSize;
    this.fetchNotifications();
  }

  public sortData(sort) {
    this.notificationListFilter.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.notificationListFilter.order   = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchNotifications();
  }

  private fetchNotifications(): void {
    const requestData = MainBaseApiService.convertFiltersForRequest(this.notificationListFilter, 'get');

    this.dataSource = new MatTableDataSource<DatabaseNotification>([]); //  Empty data source
    this.isLoading++;
    this.subscriptions.push(
        this.notificationService.index(requestData).pipe(finalize(() => this.isLoading--)).subscribe(
            (result: LaravelResourceResponse<Array<DatabaseNotification>>) => {
              this.dataSource             = new MatTableDataSource<DatabaseNotification>(result.data);
              this.dataSource.sort        = this.sort;
              this.paginatorConfig.length = result.meta.total;
              this.totalResults           = result.meta.total;
              this.totalPages             = result.meta.last_page;
            },
            err => console.error(err),
        ),
    );
  }

  private fetchUsers(): void {
    this.userService.index({is_staff: 1, select_all: 1}).subscribe(result => this.users = result.data);
  }

  // TODO: remove if control self clears
  public clearSelect($event, name) {
    $event.stopPropagation();
    this.form.get(name).patchValue('');
  }

  public onlyRelatedChange($event: any) {
    this.form.get('notifiables').patchValue($event ? [this.authUser.id] : []);
    $event ?
        this.form.get('notifiables').disable({onlySelf: true, emitEvent: false}) :
        this.form.get('notifiables').enable({onlySelf: true, emitEvent: false});
  }

  public openNotification(notification: DatabaseNotification) {
    this.dialog.open(NotificationDetailComponent, {
      width:     '40%',
      autoFocus: false,
      data:      {notification},
    });
  }

  public markAsRead($event: MouseEvent, readNotificationIds: Array<string>) {
    $event.preventDefault();
    const currentNotifications = this.dataSource.data;
    this.dataSource            = new MatTableDataSource<DatabaseNotification>([]); //  Empty data source
    this.notificationService.markAsRead({notification_ids: readNotificationIds}).subscribe(
        result => {
          result.data.forEach(resultNotification => {
            const index = currentNotifications.findIndex(
                localNotification => localNotification.id === resultNotification.id);
            if (index) {
              currentNotifications[index] = resultNotification;
            }
          });
          this.dataSource      = new MatTableDataSource<DatabaseNotification>(currentNotifications);
          this.dataSource.sort = this.sort;
        },
    );
  }

}
