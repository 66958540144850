import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { filter, finalize, map, mergeMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LaravelResourceResponse } from '../../../_base-shared/contracts/laravel-response.interface';
import { DatabaseNotification } from '../../../_base-shared/models/Notification/DatabaseNotification';
import { ScheduledTaskNotification } from '../../../_base-shared/models/Task/ScheduledTaskNotification';
import { User } from '../../../_base-shared/models/User/User';
import { UserExternalService } from '../../../_base-shared/models/User/UserExternalService';
import { UserServiceIntegration } from '../../../_base-shared/models/User/UserServiceIntegration';
import { environment } from '../environments/environment';
import { SplashModalComponent } from './_shared/components/splash-modal/splash-modal.component';
import { MainGlobalEventService } from './_shared/services/main-global-event.service';
import {
  CustomNotificationToastComponent,
} from './admin/notification/custom-notification-toast/custom-notification-toast.component';
import { NotificationService } from './admin/notification/notification.service';
import { ScheduledTaskNotificationService } from './admin/task/scheduled-task-notification.service';
import { TaskDetailComponent } from './admin/task/task-detail/task-detail.component';
import { UserService } from './admin/user/user.service';
import { AuthService } from './auth/auth.service';

@Component({
  selector:    'app-root',
  templateUrl: './app.component.html',
  styles:      [],
})
export class AppComponent implements OnInit, OnDestroy {
  public currentTitle: string;
  public defaultTitle: string;
  public authUser: User;
  public isLoading                                              = 0;
  public scheduledTaskNotifications: Array<ScheduledTaskNotification>;
  public allNotifications: Array<DatabaseNotification>          = [];
  public toastNotifications: Array<DatabaseNotification>        = [];
  public modalNotifications: Array<DatabaseNotification>        = [];
  public modalNotificationShown: boolean;
  private refreshSource: Subscription;
  private shouldRefreshNotifications: boolean;
  public isLoadingIntegrations                                  = 0;
  public userServiceIntegrations: Array<UserServiceIntegration> = [];
  public externalServices: Array<UserExternalService>           = [];
  public serverResponse: LaravelResourceResponse;
  private appEnv                                                = environment.APP_ENV;
  private isUserDistributionProvider: boolean;
  private isUserAffiliate: boolean;

  private subscriptions: Array<Subscription> = [];
  private currentLanguage: 'en' | 'es';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private titleService: Title,
              private authService: AuthService,
              private cookieService: CookieService,
              private dialog: MatDialog,
              private translate: TranslateService,
              private toastr: ToastrService,
              private globalEventsService: MainGlobalEventService,
              private notificationService: NotificationService,
              private scheduledTaskNotificationService: ScheduledTaskNotificationService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private userService: UserService) {
    const storageLanguage = this.cookieService.get('lang');
    this.currentLanguage  = (storageLanguage === 'es' || storageLanguage === 'en') ? storageLanguage : 'es';

    this.translate.setDefaultLang(this.currentLanguage);
    this.cookieService.set('lang', this.currentLanguage, 365, '/');

    this.shouldRefreshNotifications = environment.REFRESH_NOTIFICATIONS;
    this.matIconRegistry.addSvgIcon(
      'light-bulb',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/svg/light-bulb.svg'),
    );
  }

  ngOnInit(): void {
    this.defaultTitle = environment.APP_NAME;
    this.currentTitle = this.defaultTitle;
    this.watchRouterChanges();
    this.isLoading++;
    this.authService.checkLogin()
      .pipe(finalize(() => this.isLoading--))
      .subscribe();  // Init client-auth user check
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser                   = user;
      this.isUserAffiliate            = this.authUser?.role?.slug === 'affiliate' ? true : false;
      this.isUserDistributionProvider = this.authUser?.role?.slug === 'distribution-provider' ? true : false;

      if (!this.authUser) {
        return;
      }

      if (this.authUser.packager?.master && this.appEnv === 'production' && !(this.authUser.id === 36 || this.authUser.id === 27
          || this.authUser.id === 2497 || this.authUser.id === 976) && !this.isUserAffiliate && !this.isUserDistributionProvider) {
        this.checkGmailIntegration();
      }

      if (this.authUser.locale !== this.currentLanguage) {
        this.currentLanguage = this.authUser.locale;
        this.cookieService.set('lang', this.currentLanguage, 365, '/');
        this.translate.use(this.currentLanguage);
      }

      if (this.authUser.role.is_staff) {
        if (localStorage.getItem('splashScreen') === '1') {
          this.showSplashModal();
        }

        // TODO: toast notifications for clients
        this.observeNotifications(this.authUser.id);
        if (this.shouldRefreshNotifications) {
          this.refreshNotifications(this.authUser.id);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private watchRouterChanges() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe((routeData) => this.setTitle(routeData));
  }

  private observeNotifications(userId: number) {
    // Task Notifications
    this.scheduledTaskNotificationService.index(userId, {}, [], [], ['task.case']).subscribe(result => {
      this.scheduledTaskNotifications = result.data;
      this.scheduledTaskNotifications.forEach(scheduledTaskNotification => {
        this.toastTaskNotification(userId, scheduledTaskNotification);
      });
    });

    // Flash Notifications
    this.notificationService.index({select_all: 1, unread: 1}).subscribe(
      (result: LaravelResourceResponse<Array<DatabaseNotification>>) => {
        this.allNotifications = result.data;

        result.data.forEach(flashNotification => {
          if (flashNotification.presenter === 'toast') {
            this.toastNotifications.push(flashNotification);
            this.toastNotification(flashNotification);
          }
          if (flashNotification.presenter === 'modal') {
            this.modalNotifications.push(flashNotification);
            this.modalNotification(flashNotification);
          }
        });
      },
    );
  }

  private refreshNotifications(userId: number) {
    this.refreshSource = interval(15000).subscribe(iteration => {
      // if (iteration > 50) {
      //   this.refreshSource.unsubscribe(); // Unsub from long running refresh subscription
      // }
      // Task Notifications
      this.scheduledTaskNotificationService.index(userId, {}, [], [], ['task.case']).subscribe(result => {
        result.data.forEach(remoteNotification => {
          if (!this.scheduledTaskNotifications.find(
            localNotification => localNotification.id === remoteNotification.id)
          ) {
            this.scheduledTaskNotifications.push(remoteNotification);
            this.toastTaskNotification(userId, remoteNotification);
          }
        });
      }, error => {
        this.refreshSource.unsubscribe();
        console.error('Failed to refresh task notifications');
      });

      // Flash Notifications
      this.notificationService.index({select_all: 1, unread: 1}).subscribe(
        (result: LaravelResourceResponse<Array<DatabaseNotification>>) => {
          this.allNotifications = result.data;
          result.data.forEach(remoteNotification => {
            if (remoteNotification.presenter === 'toast') {
              if (!this.toastNotifications.find(tN => tN.id === remoteNotification.id)) {
                this.toastNotifications.push(remoteNotification);
                this.toastNotification(remoteNotification);
              }
            }
            if (remoteNotification.presenter === 'modal') {
              if (!this.modalNotifications.find(mN => mN.id === remoteNotification.id)) {
                this.modalNotifications.push(remoteNotification);
                this.modalNotification(remoteNotification);
              }
            }
          });
        },
        error => {
          this.refreshSource.unsubscribe();
          console.error('Failed to refresh task notifications');
        },
      );
    });
  }

  private toastTaskNotification(userId: number, scheduledTaskNotification: ScheduledTaskNotification) {
    const activeToast                                    = this.toastr.show(
      'Task: ' + scheduledTaskNotification.task.name,
      'Case: ' + scheduledTaskNotification.task.case.ref_number,
      {
        toastComponent: CustomNotificationToastComponent,
        disableTimeOut: true,
        tapToDismiss:   false,
      },
    );
    activeToast.toastRef.componentInstance.customActions = [
      {label: this.translate.instant('TASK.scheduled-notification-toast.actions.snooze-15'), value: 'snooze'},
      {label: this.translate.instant('TASK.scheduled-notification-toast.actions.view-task'), value: 'view-task'},
      {label: this.translate.instant('TASK.scheduled-notification-toast.actions.view-case'), value: 'view-case'},
      {label: this.translate.instant('TASK.scheduled-notification-toast.actions.dismiss'), value: 'dismiss'},
    ];
    activeToast.onAction.subscribe(action => {
      switch (action) {
        case 'snooze':
          this.scheduledTaskNotificationService.snooze(userId, [+scheduledTaskNotification.id], 900)
            .subscribe(result => this.removeScheduledNotification(scheduledTaskNotification.id));
          this.toastr.clear(activeToast.toastId);
          break;
        case 'view-case':
          this.router.navigate(['cases', scheduledTaskNotification.task.case_id]);
          break;
        case 'view-task':
          const dialogRef = this.dialog.open(TaskDetailComponent, {
            width:     '40%',
            autoFocus: false,
            data:      {
              task: scheduledTaskNotification.task,
            },
          });
          dialogRef.afterClosed().subscribe(result => {
            this.removeScheduledNotification(scheduledTaskNotification.id);
            this.toastr.clear(activeToast.toastId);
          });
          break;
        case 'dismiss':
          this.scheduledTaskNotificationService.markAsCompleted(userId, [+scheduledTaskNotification.id])
            .subscribe(result => {
              this.removeScheduledNotification(scheduledTaskNotification.id);
              this.toastr.clear(activeToast.toastId);
            });
          break;
      }
    });
  }

  private modalNotification(flashNotification: DatabaseNotification) {
    if (this.modalNotificationShown) {
      return null;
    }
    this.modalNotificationShown = true;
    Swal.fire({
      allowOutsideClick:  false,
      title:              flashNotification.data.subject,
      html:               flashNotification.data.message,
      showCancelButton:   false,
      confirmButtonText:  this.translate.instant('NOTIFICATION.toast.actions.mark-as-read'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.notificationService.markAsRead({notification_ids: [flashNotification.id]}).subscribe(next => {
          const notificationIndex = this.modalNotifications.findIndex(n => n.id === flashNotification.id);
          this.modalNotifications.splice(notificationIndex, 1);
          this.allNotifications       = this.allNotifications.filter(not => not.id !== flashNotification.id);
          this.modalNotificationShown = false;
          this.showNextModalNotification();
        });
      } else {
        this.modalNotificationShown = false;
        this.showNextModalNotification();
      }
    });
  }

  private showNextModalNotification() {
    if (this.modalNotifications.length) {
      this.modalNotification(this.modalNotifications[0]);
    }
  }

  private toastNotification(flashNotification: DatabaseNotification) {
    const activeToast = this.toastr.show(flashNotification.data.message, flashNotification.data.subject, {
        toastComponent: CustomNotificationToastComponent,
        disableTimeOut: true,
        tapToDismiss:   false,
        enableHtml:     true,
        closeButton:    false,
      },
    );

    activeToast.toastRef.componentInstance.customActions = [
      {label: this.translate.instant('NOTIFICATION.toast.actions.mark-as-read'), value: 'mark-as-read'},
    ];

    this.subscriptions.push(
      activeToast.onAction.subscribe(action => {
        switch (action) {
          case 'mark-as-read':
            this.notificationService.markAsRead({notification_ids: [flashNotification.id]}).subscribe(next => {
              const notificationIndex = this.toastNotifications.findIndex(n => n.id === flashNotification.id);
              this.toastNotifications.splice(notificationIndex, 1);
              this.toastr.clear(activeToast.toastId);
              this.allNotifications = this.allNotifications.filter(not => not.id !== flashNotification.id);
            });
            break;
        }
      }),
    );

  }

  public setTitle(routeData: any) {
    if (routeData.title) {
      this.currentTitle = this.defaultTitle + ' | ' + routeData.title;
    } else {
      this.currentTitle = this.defaultTitle;
    }

    this.titleService.setTitle(this.currentTitle);
  }

  private showSplashModal() {
    const dialogRef = this.dialog.open(SplashModalComponent, {
      width:      '50%',
      minHeight:  '400px',
      panelClass: 'splash-wrap',
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result, 'resilt');
      // this.globalEventsService.setSplashScreen(false);
      // console.log(this.globalEventsService.showSplashScreen);
      localStorage.setItem('splashScreen', '0');
    });
  }

  private removeScheduledNotification(notificationId: number): number {
    const notificationIndex = this.scheduledTaskNotifications.findIndex(n => n.id === notificationId);
    this.scheduledTaskNotifications.splice(notificationIndex, 1);

    return notificationIndex;
  }

  private checkGmailIntegration(): void {
    this.fetchExternalServices();
  }

  private fetchExternalServices(): void {
    this.isLoadingIntegrations++;
    this.userService.externalServices().pipe(finalize(() => this.isLoadingIntegrations--))
      .subscribe(result => this.fetchIntegrations(this.authUser.id, result.data));
  }

  private fetchIntegrations(userId: number, externalServices: Array<UserExternalService>): void {
    this.isLoadingIntegrations++;
    this.userService.indexIntegrations(userId).pipe(finalize(() => this.isLoadingIntegrations--)).subscribe(result => {
      this.userServiceIntegrations = result.data;

      this.externalServices = externalServices.map(externalService => {
        const integratedService = this.userServiceIntegrations.find(
          iService => iService.service_id === externalService.id,
        );
        return {...externalService, integrated_id: integratedService ? integratedService.id : 0};
      });
      if (this.userServiceIntegrations.length === 0) {
        this.externalServices.map(externalService => {
          if (externalService.type === 'gmail') {
            this.authorize(externalService);
          }
        });
      }
    });
  }

  private authorize(service: UserExternalService): Subscription {
    this.isLoadingIntegrations++;
    return this.userService.requestAuthorization(this.authUser.id, service.id)
      .pipe(finalize(() => this.isLoadingIntegrations--))
      .subscribe(
        result => {
          if (service.type === 'gmail') {
            return window.location.href = result.data.redirect_url;
          }
        },
        error => {
          this.serverResponse = error.error;
          this.toastr.error(error.code + ' Could not request service authorization');
        },
      );
  }
}
