import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { DocumentType } from '../../../../../../../../../_base-shared/models/DocumentType';
import { DocumentTypeCategory } from '../../../../../../../../../_base-shared/models/DocumentTypeCategory';
import { environment } from '../../../../../../../environments/environment';
import { ImgEditModalComponent } from '../../../../../../_shared/components/img-edit-modal/img-edit-modal.component';
import { CaseDocumentService } from '../../../../case-document.service';

@Component({
  selector:    'app-all-files-v2',
  templateUrl: './all-files-v2.component.html',
  styleUrls:   ['./all-files-v2.component.scss'],
})
export class AllFilesV2Component implements OnInit {
  // @Input() requestedDocuments;
  @Input() userDocuments;
  @Input() documentTypeCategories: Array<DocumentTypeCategory>;
  @Input() case: Case;
  @Input() clientRole: 'client' | 'partner';
  @Input() allCreditors;
  @Output() documentInfoChange$: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentStatusChange$: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentResendRequest$: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentDeleteRequest$: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentsMerged$: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedFileEvent: EventEmitter<object> = new EventEmitter<object>();

  public documentTypes: Array<DocumentType>;
  public allDocTypes;
  public editingDocumentId: number;
  public newType = null;
  public newName = '';
  public storageUrl = environment.STORAGE_URL + '/';
  public isAccordionTabOpen = {};
  public mergingFiles = false;
  public docInfo = this.fb.group({
    name:        '',
    type:        '',
    uploaded_by: '',
  });
  public mergedDoc = this.fb.group({
    fileName: '',
    type:     '',
  });
  public completedForm = new UntypedFormGroup({});
  public completedFormPartner = new UntypedFormGroup({});
  public selectedFiles: Array<any> = [];
  public uploadingFileSpinner: boolean | number;
  public uploadForm: FormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private documentService: CaseDocumentService,
  ) {
  }

  ngOnInit(): void {
    this.documentTypes = [];
    this.documentTypeCategories.forEach(documentTypeCategory => {
      this.documentTypes = this.documentTypes.concat(documentTypeCategory.document_types);
    });
    this.allDocTypes = this.documentTypes;
    if (this.clientRole === 'client') {
      const customFiles = this.case.file_requests.filter(file => file.custom);
      this.allDocTypes = this.allDocTypes.concat(customFiles);
      // tslint:disable-next-line:forin no-shadowed-variable
      for (const property in this.userDocuments) {
        this.completedForm.addControl(property + '-completed', new UntypedFormControl(this.isDocCompleted(property)));
        if (this.userDocuments.hasOwnProperty(property)) {
          this.isAccordionTabOpen[property] = false;
        }
      }
    } else {
      const customFiles = this.case.partner_file_requests.filter(file => file.custom);
      this.allDocTypes = this.allDocTypes.concat(customFiles);
      // tslint:disable-next-line:forin
      for (const property in this.userDocuments) {
        this.completedFormPartner.addControl(property + '-completed',
          new UntypedFormControl(this.isDocCompleted(property, true)));
        if (this.userDocuments.hasOwnProperty(property)) {
          this.isAccordionTabOpen[property] = false;
        }
      }
    }
    this.buildForm();
  }

  openAccordionTab(name) {
    //  Update object to keep accordion open
    this.isAccordionTabOpen[name] = true;
  }

  public getTypeLabel(type): string {
    let translatedLabel = '';

    let document = this.allDocTypes.find(t => t.name === type);
    if (this.documentTypes) {
      document = this.documentTypes.find(t => t.name === type);
    }
    // @ts-ignore
    if (document && ! document.custom) {
      translatedLabel = document.label;
    } else {
      translatedLabel = type;
    }

    return translatedLabel;
  }

  getDocumentStatus(documents) {
    let status = '';
    documents.map(document => {
      if (status !== 'pending' && status !== 'declined') {
        status = document.status;
      }
    });
    if (status === 'pending') {
      return 'pending-document';
    } else if (status === 'accepted') {
      return 'accepted-document';
    } else if (status === 'declined') {
      return 'declined-document';
    } else {
      return 'empty-docs';
    }
  }

  editDocument(document) {
    this.docInfo.setValue({
      name:        document.name,
      type:        document.type.name,
      uploaded_by: document.uploaded_by,
    });
    this.newName = document.name;
    this.editingDocumentId = document.id;
  }

  deleteFile($event, id) {
    $event.preventDefault();
    const data = {
      uuId:   this.case.uuid,
      fileId: id,
    };
    this.documentDeleteRequest$.emit(data);
  }

  cancelEdit() {
    this.docInfo.setValue({
      name:        '',
      type:        '',
      uploaded_by: '',
    });
    this.editingDocumentId = null;
    this.newType = null;
  }

  saveDoc(document, oldType) {
    if (this.docInfo.value.name === '') {
      return;
    }
    if (this.docInfo.value.type === oldType &&
      this.docInfo.value.name === document.name &&
      this.docInfo.value.uploaded_by === document.uploaded_by
    ) {
      this.editingDocumentId = null;
      this.docInfo.setValue({
        name:        '',
        type:        '',
        uploaded_by: '',
      });
      return;
    }
    const newTypeDoc = this.allDocTypes.find(type => type.name === this.docInfo.value.type);
    const newTypeCred = this.allCreditors.find(type => type.name === this.docInfo.value.type);
    const newTypePublic = this.allCreditors.find(type => type.public_organisation === this.docInfo.value.type);
    this.documentInfoChange$.emit({
      document,
      newTypeDoc,
      newTypeCred,
      newTypePublic,
      oldType,
      name:        this.docInfo.value.name,
      uploaded_by: this.docInfo.value.uploaded_by,
    });
    this.editingDocumentId = null;
    this.docInfo.setValue({
      name:        '',
      type:        '',
      uploaded_by: '',
    });
  }

  changeStatus(fileId, $event, status: string) {
    $event.preventDefault();
    const data = {
      status,
      caseId: this.case.id,
      fileId,
    };
    this.documentStatusChange$.emit(data);
  }

  resendRequest(fileId, $event) {
    const data = {
      fileId,
      type: this.clientRole,
    };

    this.documentResendRequest$.emit(data);
    $event.preventDefault();
  }

  public openEditModal(event, document) {
    event.preventDefault();
    //  Open dialog modal
    const dialogRef = this.dialog.open(ImgEditModalComponent, {
      width: '50%',
      data:  {
        document,
        case: this.case,
      },
    });
    //  On close fire handle response
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const newTypeDoc = this.allDocTypes.find(type => type.name === this.docInfo.value.type);
        const newTypeCred = this.allCreditors.find(type => type.name === this.docInfo.value.type);
        const newTypePublic = this.allCreditors.find(type => type.public_organisation === this.docInfo.value.type);
        this.documentInfoChange$.emit({
          document,
          newTypeDoc,
          newTypeCred,
          newTypePublic,
          oldType:     document.type.name,
          name:        this.docInfo.value.name,
          uploaded_by: this.docInfo.value.uploaded_by,
        });
        this.editingDocumentId = null;
        this.docInfo.setValue({
          name:        '',
          type:        '',
          uploaded_by: '',
        });
      }
    });
  }

  downloadFile($event: MouseEvent, document: any) {
    saveAs(this.storageUrl + document.location, document.name + '.' + document.extension);
  }

  downloadPdfFile($event: MouseEvent, document: any) {
    saveAs(this.storageUrl + document.pdf_location, document.name + '.pdf');
  }

  drop(event: CdkDragDrop<string[]>, instance) {
    moveItemInArray(instance.value, event.previousIndex, event.currentIndex);
  }

  saveMergedFiles($event: MouseEvent, key: string) {
    this.mergingFiles = true;
    const documentsIds = this.userDocuments[key].map((document) => {
      return document.id;
    });

    const data = {
      document_ids:  documentsIds,
      name:          this.mergedDoc.value.fileName,
      document_type: key,
      uploaded_by:   this.clientRole,
    };

    this.documentService.mergeDocuments(this.case.id, data).subscribe(res => {
      this.mergingFiles = false;
      this.documentsMerged$.emit(res.data);
      this.toastr.success(this.translate.instant('DOCUMENTS.merged-toastr-success'));
    }, error => {
      this.mergingFiles = false;
      this.toastr.error(this.translate.instant('DOCUMENTS.merged-toastr-error'));
    });
  }

  selectFile(e: Event, url: string, type: string) {
    e.preventDefault();
    this.selectedFileEvent.emit({
      url,
      type: type?.includes('image') ? 'image' : 'object',
    });
  }

  public setDocAsCompleted($event, value, userType: 'client' | 'partner') {
    const data = {
      client_type:     userType,
      completed:       $event,
      file_request_id: this.findFileRequestId(value, userType === 'partner'),
    };
    this.documentService.setDocAsCompleted(data, this.case.id).subscribe((response) => {
        this.toastr.success('Successfully updated!');
      },
      err => this.toastr.error(this.translate.instant('SHARED.went-wrong')));
  }

  public findFileRequestId(documentType: string, isPartner = false) {
    const allFiles = isPartner ? this.case.partner_file_requests : this.case.file_requests;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < allFiles.length; i++) {
      if (allFiles[i].name === documentType && allFiles[i].pivot?.id) {
        return allFiles[i].pivot.id;
      }
    }

    return null;
  }

  private isDocCompleted(documentType: string, isPartner = false) {
    const allFiles = isPartner ? this.case.partner_file_requests : this.case.file_requests;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < allFiles.length; i++) {
      if (allFiles[i].name === documentType && !! allFiles[i].hasOwnProperty('pivot')) {
        return allFiles[i].pivot.completed;
      }
    }

    return false;
  }

  private buildForm() {
    this.uploadForm = this.fb.group({
      slug:             null,
      document_type_id: [null],
      files:            [[]],
    });
  }

  public onFileChange(event, documentTypeSlug) {
    const documentType = this.documentTypes.find(docType => docType.name === documentTypeSlug);
    if ( ! documentType || this.uploadForm.get('document_type_id').value !== documentType.id) {
      this.selectedFiles = [];
      this.uploadForm.reset();
    }
    this.uploadForm.get('slug').patchValue(documentTypeSlug);
    this.uploadForm.get('document_type_id').patchValue(documentType.id);
    const filesArray = [...this.selectedFiles];
    const files = event.target.files;
    // Push files to array
    if (files && files.length) {
      const arr = [...files];
      arr.forEach(file => {
        filesArray.push(file);
      });
    }
    filesArray.map((file, index) => file.index = index);
    this.selectedFiles = filesArray;
  }

  removeSelectedFile($event, index, type = 'file') {
    $event.preventDefault();
    this.selectedFiles = this.selectedFiles.filter(file => file.index !== index);
  }

  public uploadFiles(documentTypeSlug) {
    const documentType = this.documentTypes.find(docType => docType.name === documentTypeSlug);
    if ( ! documentType || this.uploadForm.get('document_type_id').value !== documentType.id) {
      this.selectedFiles = [];
      this.uploadForm.reset();

      return;
    }

    if (this.selectedFiles.length > 0) {
      this.uploadingFileSpinner = true;
      const formData = new FormData();
      this.selectedFiles.forEach(file => {
        formData.append(`files[]`, file);
      });
      formData.append(`uploaded_by`, this.clientRole);
      formData.append(`document_type_id`, this.uploadForm.get('document_type_id').value);

      this.documentService.adminUploadFiles(this.case.id, formData)
        .pipe(finalize(() => {
          this.uploadingFileSpinner = false;
          this.selectedFiles = [];
        }))
        .subscribe(
          next => {
            this.uploadForm.get('document_type_id').patchValue(null);
            this.uploadForm.get('slug').patchValue(null);
            this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
          },
          error => {
            this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
          });
    }
  }


}
