import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AppSelectOption } from '../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { CaseListFilter } from '../../../../../../_base-shared/models/Case/CaseListFilter';
import {
  DistributionBatch,
  DistributionBatchStatus,
} from '../../../../../../_base-shared/models/Distribution/DistributionBatch';
import { PaymentStatus } from '../../../../../../_base-shared/models/Status/PaymentStatus';
import { StatusCategory } from '../../../../../../_base-shared/models/Status/StatusCategory';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { CaseService } from '../../case/case.service';
import { PaymentStatusService } from '../../payment-status/payment-status.service';
import { StatusService } from '../../status/status.service';
import { BatchSelectorComponent } from '../batch-selector/batch-selector.component';
import { DistributionBatchService } from '../distribution-batch.service';
import { DistributionService } from '../distribution.service';

@Component({
  selector:    'app-distribution-list',
  templateUrl: './distribution-list.component.html',
  styles:      [
    `
      mat-table {
        display: table;
        width: 100%;
      }

      .global-select-all {
        background-color: rgba(241, 243, 244, 0.871);
        padding: 6px;
        border-radius: 4px;
        text-align: center;
      }
    `,
  ],
})
export class DistributionListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public filtersReady = false;
  public caseListFilter: CaseListFilter;
  public cases: MatTableDataSource<Case>;
  public isLoading    = 0;
  public serverResponse: LaravelResourceResponse;
  public isAssigningBatch: boolean;
  public displayedColumns: Array<string>;
  public selection    = new SelectionModel(true, []);
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  public totalResults: number;
  public totalPages: number;
  public pageSelected: boolean;

  // Filters
  public distributionBatches: Array<DistributionBatch>;
  public batchStatusOptions: Array<AppSelectOption> = [];
  public batchesFilter: UntypedFormControl          = new UntypedFormControl(null);
  public batchStatusFilter: UntypedFormControl      = new UntypedFormControl(null);
  public searchFilter: UntypedFormControl           = new UntypedFormControl(null);
  public paymentStatuses: UntypedFormControl        = new UntypedFormControl(null);
  public caseStatusFilter: UntypedFormControl       = new UntypedFormControl(null);
  public paymentStatusOptions: PaymentStatus[];
  public statusCategories: Array<StatusCategory>    = [];

  private subscriptions: Array<Subscription> = [];

  constructor(private dialog: MatDialog,
              private translate: TranslateService,
              private toastr: ToastrService,
              private caseService: CaseService,
              private distributionService: DistributionService,
              private distributionBatchService: DistributionBatchService,
              private paymentStatusService: PaymentStatusService,
              private statusService: StatusService,
  ) {

  }

  ngOnInit(): void {
    this.displayedColumns       = this.getTableColumns();
    this.defaultPaginatorConfig = {pageIndex: 0, pageSize: 20, length: 1};
    this.defaultSort            = {direction: 'desc', active: 'created_at'};
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.caseListFilter         = this.getDefaultFilters(new CaseListFilter());
    this.fetchCases();
    this.fetchPaymentStatuses();
    this.fetchDistributionBatches();
    this.buildFilterOptions();
    this.fetchStatuses();
    this.subscribeToFilterChanges();
  }

  ngAfterViewInit(): void {
    this.cases.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private resetPagination(): void {
    this.paginatorConfig         = this.defaultPaginatorConfig;
    this.caseListFilter.per_page = this.paginatorConfig.pageSize;
    this.caseListFilter.page     = this.paginatorConfig.pageIndex;
  }

  private resetSort(): void {
    this.caseListFilter.sort_by = this.defaultSort.active;
    this.caseListFilter.order   = this.defaultSort.direction;
  }

  private clearSelection() {
    this.selection.clear();
    this.pageSelected = false;  //  Hide global select
    this.patchSelectAllFilter(0);
    delete this.caseListFilter.case_ids;
  }

  private fetchCases(): void {
    this.clearSelection();
    this.caseListFilter.start_date = this.caseListFilter.start_date && this.caseListFilter.start_date instanceof Date ?
      this.caseListFilter.start_date.toISOString() :
      this.caseListFilter.start_date;
    this.caseListFilter.end_date   = this.caseListFilter.end_date && this.caseListFilter.end_date instanceof Date ?
      this.caseListFilter.end_date.toISOString() :
      this.caseListFilter.end_date;
    const withRelations            = [
      'product',
      'client',
      'status',
      'payment_status',
      'debt_payment_plan',
      'distribution.batch',
    ];

    const requestData = MainBaseApiService.convertFiltersForRequest({...this.caseListFilter, with: withRelations},
      'get');

    this.cases = new MatTableDataSource<Case>([]);
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.index(requestData).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.clearSelection();
          this.cases                  = new MatTableDataSource<Case>(result.data);
          this.cases.sort             = this.sort;
          this.paginatorConfig.length = result.meta.total;
          this.totalResults           = result.meta.total;
          this.totalPages             = result.meta.last_page;
        },
        err => console.error(err),
      ),
    );
  }

  private getTableColumns(): Array<string> {
    return [
      'select',
      'distribution_batch_name',
      'ref_number',
      'first_name',
      'last_name',
      'joint_application',
      'product_group_slug',
      'product_name',
      'status',
      'phase_2_installment_amount',
      'amount_paid',
      'contract_amount',
      'balance',
      'payment_day',
      'entered_at',
    ];
  }

  private getDefaultFilters(caseListFilter: CaseListFilter): CaseListFilter {
    caseListFilter.start_date             = null;
    caseListFilter.end_date               = null;
    caseListFilter.has_distribution       = 1;
    caseListFilter.only_related           = 0;
    caseListFilter.distribution_batch_ids = null;
    caseListFilter.case_ids               = null;
    caseListFilter.payment_statuses       = null;

    return caseListFilter;
  }

  public assignToBatchModal() {
    const dialogRef = this.dialog.open(BatchSelectorComponent, {
      minWidth:    '300px',
      minHeight:   '230px',
      hasBackdrop: false,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.data?.distribution_batch) {
        this.confirmBatchSelection(result.data.distribution_batch);
      }
    });
  }

  public unassignFromBatchesPrompt() {
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('DISTRIBUTION.cases.list.actions.remove_batch.prompt'),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translate.instant('SHARED.no'),
      confirmButtonText:  this.translate.instant('SHARED.yes'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.unassignFromBatch(this.caseListFilter);
      } else {
        this.clearSelection();
      }
    });
  }

  public removeFromDistributionPrompt(markNonviable: 0 | 1 = 0) {
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('DISTRIBUTION.cases.list.actions.remove_from_distribution.prompt'),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translate.instant('SHARED.no'),
      confirmButtonText:  this.translate.instant('SHARED.yes'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.removeFromDistribution({...this.caseListFilter, flag_distribution_nonviable: markNonviable});
      } else {
        this.clearSelection();
      }
    });
  }

  public assignToBatch(selectedBatch: DistributionBatch, caseListFilter: CaseListFilter) {
    caseListFilter.case_ids = [];
    this.selection.selected.forEach(selectedCase => caseListFilter.case_ids.push(selectedCase.id));
    caseListFilter.expected_count = caseListFilter.select_all ? this.totalResults : caseListFilter.case_ids.length;
    const requestFilter           = MainBaseApiService.convertFiltersForRequest(caseListFilter, 'post');

    this.isAssigningBatch = true;
    this.subscriptions.push(
      this.distributionService.assignToBatch(selectedBatch.id, requestFilter)
        .pipe(finalize(() => this.isAssigningBatch = false))
        .subscribe(
          result => {
            this.toastr.success(
              this.translate.instant('DISTRIBUTION.cases.list.actions.assign_batch.result.success'));
            if (caseListFilter.select_all) {
              this.resetTable();
              this.fetchCases();
              return;
            }
            const localCases = this.cases.data;
            result.data.forEach(clientCase => {
              const localCaseIndex = localCases.findIndex(sCase => sCase.id === clientCase.id);
              if (localCaseIndex >= 0) {
                localCases[localCaseIndex].distribution = clientCase.distribution;
              } else {
                localCases.unshift(clientCase);
              }
            });
            this.cases = new MatTableDataSource<Case>(localCases);
            this.clearSelection();
          },
          err => {
            this.toastr.error(
              this.translate.instant('DISTRIBUTION.cases.list.actions.assign_batch.result.error'));
            this.serverResponse = err.error;
          },
        ),
    );
    this.caseListFilter.cases = null;
  }

  public unassignFromBatch(caseListFilter: CaseListFilter) {
    caseListFilter.case_ids = [];
    this.selection.selected.forEach(selectedCase => caseListFilter.case_ids.push(selectedCase.id));
    caseListFilter.expected_count = caseListFilter.select_all ? this.totalResults : caseListFilter.case_ids.length;
    const requestFilter           = MainBaseApiService.convertFiltersForRequest(caseListFilter, 'post');

    this.isAssigningBatch = true;
    this.subscriptions.push(
      this.distributionService.unassignFromBatch(requestFilter)
        .pipe(finalize(() => this.isAssigningBatch = false))
        .subscribe(
          result => {
            this.toastr.success(
              this.translate.instant('DISTRIBUTION.cases.list.actions.remove_batch.result.success'),
            );
            if (caseListFilter.select_all) {
              this.resetTable();
              this.fetchCases();
              return;
            }
            const localCases = this.cases.data;
            result.data.forEach(clientCase => {
              const localCaseIndex = localCases.findIndex(sCase => sCase.id === clientCase.id);
              if (localCaseIndex >= 0) {
                localCases[localCaseIndex].distribution = clientCase.distribution;
                console.log(localCases[localCaseIndex]);
              } else {
                localCases.unshift(clientCase);
              }
            });
            this.cases = new MatTableDataSource<Case>(localCases);
            this.clearSelection();
          },
          err => {
            this.toastr.error(
              this.translate.instant('DISTRIBUTION.cases.list.actions.remove_batch.result.error'),
            );
            this.serverResponse = err.error;
          },
        ),
    );
    this.caseListFilter.cases = null;
  }

  public removeFromDistribution(caseListFilter: CaseListFilter) {
    caseListFilter.case_ids = [];
    this.selection.selected.forEach(selectedCase => caseListFilter.case_ids.push(selectedCase.id));
    caseListFilter.expected_count = caseListFilter.select_all ? this.totalResults : caseListFilter.case_ids.length;
    const requestFilter           = MainBaseApiService.convertFiltersForRequest(caseListFilter, 'post');

    this.subscriptions.push(
      this.distributionService.unassignFromDistribution(requestFilter)
        .subscribe(
          result => {
            this.toastr.success(
              this.translate.instant('DISTRIBUTION.cases.list.actions.remove_from_distribution.result.success'),
            );
            if (caseListFilter.select_all) {
              this.resetTable();
              this.fetchCases();
              return;
            }
            const localCases = this.cases.data;
            result.data.forEach(clientCase => {
              const localCaseIndex = localCases.findIndex(sCase => sCase.id === clientCase.id);
              if (localCaseIndex >= 0) {
                localCases.splice(localCaseIndex, 1);
              }
            });
            this.cases = new MatTableDataSource<Case>(localCases);
            this.clearSelection();
          },
          err => {
            this.toastr.error(
              this.translate.instant('DISTRIBUTION.cases.list.actions.remove_from_distribution.result.error'),
            );
            this.serverResponse = err.error;
          },
        ),
    );
    this.caseListFilter.cases = null;
  }

  public generateReport(caseListFilter: CaseListFilter,
                        reportType: 'scheduled_fee' | 'payments_received' | 'claims' | 'pledge') {
    caseListFilter.case_ids = [];
    this.selection.selected.forEach(selectedCase => caseListFilter.case_ids.push(selectedCase.id));
    caseListFilter.expected_count = caseListFilter.select_all ? this.totalResults : caseListFilter.case_ids.length;

    this.subscriptions.push(
      this.distributionService.export(reportType, caseListFilter).subscribe(
        result => {
          this.toastr.success(
            this.translate.instant('DISTRIBUTION.cases.list.actions.generate_report.result.success', {
              email: result.data.sent_to,
            }),
          );
          this.clearSelection();
        },
        err => {
          this.toastr.error(
            this.translate.instant('DISTRIBUTION.cases.list.actions.generate_report.result.error'),
          );
        },
      ),
    );
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.caseListFilter.page     = this.paginatorConfig.pageIndex + 1;
    this.caseListFilter.per_page = this.paginatorConfig.pageSize;
    this.fetchCases();
  }

  public sortData(sort) {
    this.caseListFilter.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.caseListFilter.order   = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchCases();
  }

  // Select All
  public toggleRow(event: MatCheckboxChange, clientCase: Case) {
    this.selection.toggle(clientCase);
    if (!event.checked) {
      this.pageSelected = false;
      this.patchSelectAllFilter(0);
    }
  }

  public allRowsSelected() {
    return this.selection.selected.length === this.cases.data.length;
  }

  public togglePageSelect($event) {
    this.pageSelected = $event.checked;
    if (!this.pageSelected) {
      this.patchSelectAllFilter(0);
    }
    if (this.allRowsSelected()) {
      this.cases.data.forEach(clientCase => this.selection.deselect(clientCase));
    } else {
      this.cases.data.forEach(clientCase => this.selection.select(clientCase));
    }
  }

  public globalSelectAll($event) {
    $event.preventDefault();
    this.patchSelectAllFilter(1);
  }

  public globalClearSelectAll($event) {
    $event.preventDefault();
    this.clearSelection();
  }

  // End Select All

  // On filter change
  public handleFilters(): void {
    // this.caseListFilter = $event;
    this.resetPagination();
    this.resetSort();
    this.fetchCases();
  }

  private patchSelectAllFilter(state: boolean | 0 | 1) {
    if (this.caseListFilter) {
      // this.caseListFiltersComponent.patchFilter('select_all', state, {emitEvent: false, onlySelf: true});
      this.caseListFilter.select_all = state;
    } else {
      console.log('Filter component not ready for form pControl patching');
    }
  }

  private confirmBatchSelection(selectedBatch: DistributionBatch) {
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('DISTRIBUTION.cases.list.actions.assign_batch.prompt', {
        batchName: selectedBatch.name,
      }),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translate.instant('SHARED.no'),
      confirmButtonText:  this.translate.instant('SHARED.yes'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.assignToBatch(selectedBatch, this.caseListFilter);
      } else {
        this.clearSelection();
      }
    });
  }

  private fetchDistributionBatches() {
    this.isLoading++;
    this.subscriptions.push(
      this.distributionBatchService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.distributionBatches = result.data),
    );
  }

  private fetchPaymentStatuses() {
    this.isLoading++;
    this.subscriptions.push(
      this.paymentStatusService.index().pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.paymentStatusOptions = result.data),
    );
  }

  private buildFilterOptions() {
    this.batchStatusOptions = [
      {value: 'not_in_batch', label: 'Not in batch'},
      {value: 'in_batch', label: 'In batch'},
      {value: 'pending', label: 'Pending'},
      {value: 'submitted', label: 'Submitted'},
      {value: 'funded', label: 'Funded'},
      {value: 'rejected', label: 'Rejected'},
    ];
  }

  private subscribeToFilterChanges() {
    this.subscriptions.push(
      this.batchesFilter.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe((batchIds: Array<number>) => {
        this.selectedBatchesChanged(batchIds);
      }),
    );
    this.subscriptions.push(
      this.batchStatusFilter.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe((batchStatus: DistributionBatchStatus) => {
        this.batchStatusChanged(batchStatus);
      }),
    );

    this.subscriptions.push(
      this.searchFilter.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe((batchStatus: DistributionBatchStatus) => {
        this.searchChanged(batchStatus);
      }),
    );

    this.subscriptions.push(
      this.paymentStatuses.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe((paymentStatuses) => {
        this.paymentStatusChanged(paymentStatuses);
      }),
    );

    this.subscriptions.push(
      this.caseStatusFilter.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe(caseStatusIds => {
        this.caseStatusChanged(caseStatusIds);
      }),
    );
  }

  // Filters
  public selectedBatchesChanged(batchIds: Array<number>): void {
    this.caseListFilter.distribution_batch_ids = batchIds;
    this.handleFilters();
  }

  public batchStatusChanged(batchStatus: DistributionBatchStatus): void {
    this.caseListFilter.distribution_batch_statuses = [batchStatus];
    this.handleFilters();
  }

  public searchChanged(keyword: string): void {
    this.caseListFilter.search = keyword;
    this.handleFilters();
  }

  public paymentStatusChanged(paymentStatuses): void {
    this.caseListFilter.payment_statuses = paymentStatuses;
    this.handleFilters();
  }

  private resetTable(): void {
    this.clearSelection();
    this.resetSort();
    this.resetPagination();
    this.caseListFilter = this.getDefaultFilters(this.caseListFilter);
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.statusCategories = result.data),
    );
  }

  private caseStatusChanged(caseStatusIds: Array<number>): void {
    this.caseListFilter.case_statuses = caseStatusIds ? caseStatusIds : [];
    this.handleFilters();
  }
}
