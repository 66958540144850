import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { DepartmentCategory } from '../../../../../../_base-shared/models/Department/DepartmentCategory';
import { Role } from '../../../../../../_base-shared/models/Role';
import { Team } from '../../../../../../_base-shared/models/User/Team';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { DepartmentService } from '../../department/department.service';
import { UserNotificationComponent } from '../../notification/user-notification/user-notification.component';
import { TeamService } from '../../team/team.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public filterForm: UntypedFormGroup;
  displayedColumns: Array<string>;
  actions: string[]                                      = ['Edit', 'Delete'];
  public isLoading                                       = 0;
  public dataSource: MatTableDataSource<User>;
  public paginatorConfig                                 = {
    pageIndex: 0,
    pageSize: 10,
    length: 1,
  };
  public search                                          = new UntypedFormControl('');
  public searchFocus                                     = false;
  public roles: Array<Role>                              = [];
  public departmentCategories: Array<DepartmentCategory> = [];
  public teamsCategories: Array<Team>                    = [];
  public teamLeaderCategories: Array<Team>               = [];
  private subscriptions: Array<Subscription>             = [];
  private initialQueryComplete: boolean;
  public listType: 'admin-user-list' | 'admin-employee-list';
  public authUser: User;

  constructor(private fb: UntypedFormBuilder,
              public dialog: MatDialog,
              private translate: TranslateService,
              private globalEventsService: MainGlobalEventService,
              private userService: UserService,
              private departmentService: DepartmentService,
              private route: ActivatedRoute,
              private teamService: TeamService) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      this.route.data.subscribe(data => {
        this.listType         = data.type;
        this.displayedColumns = this.listType === 'admin-user-list' ?
          this.getDisplayedColumnsUsers() : this.getDisplayedColumnsEmployees();
        this.fetchRoles();
        this.fetchDepartmentCategories();
        this.fetchTeamsCategories();
      });
    });

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  buildForm() {
    const staffRoleIds = this.roles.filter(role => role.is_staff).map(role => role.id);
    this.filterForm    = this.fb.group({
      role_ids: [staffRoleIds],
      department_ids: [[]],
      member_in_teams_ids: [[]],
      leader_in_teams_ids: [[]],
      active: [1]
    });

    this.filterForm.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(next => {
        this.fetchUsers(null);
      });
    this.search.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(
      searchString => {
        this.fetchUsers(searchString);
      },
    );

    if (!this.initialQueryComplete) {
      this.fetchUsers();
      this.initialQueryComplete = true;
    }
  }

  private fetchRoles() {
    this.isLoading++;
    this.userService.indexRoles().pipe(finalize(() => this.isLoading--)).subscribe(
      roles => {
        this.roles = roles.data;
        this.buildForm();
      },
    );
  }

  private fetchDepartmentCategories() {
    this.isLoading++;
    this.subscriptions.push(
      this.departmentService.categoryIndex({}, ['departments']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.departmentCategories = result.data),
    );
  }

  private fetchTeamsCategories() {
    this.isLoading++;
    this.subscriptions.push(
      this.teamService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.teamsCategories      = result.data;
          this.teamLeaderCategories = result.data;
        }),
    );
  }

  private fetchUsers(searchString = null) {
    this.dataSource = new MatTableDataSource<User>([]);
    this.isLoading++;
    if (searchString) {
      this.paginatorConfig.pageIndex = 0;  //  Set page index to 0 (reset paginator)
    }

    const requestData = this.getRequestData();
    if (this.authUser.packager.master && this.authUser.role.slug === 'super-admin') {
      requestData.all_packager_users = 1;
    }
    const observable = this.listType === 'admin-user-list' ?
      this.userService.index(requestData, ['teams', 'leader_in_teams']) :
      this.userService.index(requestData,
        ['department_assignments', 'teams', 'leader_in_teams', 'member_in_teams']);
    observable.pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.dataSource             = new MatTableDataSource<User>(result.data);
        this.paginatorConfig.length = result.meta.total;
      },
      error => console.log(error),
    );
  }

  private getRequestData() {
    return {
      select_all: 0,
      per_page: this.paginatorConfig.pageSize,
      page: this.paginatorConfig.pageIndex + 1,
      search: this.search.value,
      ...this.filterForm.value
    };
  }

  public deleteUser($event, userId) {
    $event.preventDefault();

    Swal.fire({
      position: 'top',
      title: this.translate.instant('USERS.delete-user'),
      text: this.translate.instant('USERS.delete-user-confirm'),
      icon: 'warning',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: this.translate.instant('SHARED.yes'),
      cancelButtonText: this.translate.instant('SHARED.no'),
      confirmButtonColor: '#886ab5',
      preConfirm: () => {
        return this.userService.delete(userId)
          .subscribe(
            response => this.fetchUsers(),
            error => Swal.showValidationMessage(
              this.translate.instant('USERS.delete-user-error'),
            ),
          );
      },
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          position: 'top',
          title: this.translate.instant('USERS.delete-user-success'),
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#886ab5',
        });
      }
    });
  }

  public paginatorChange($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchUsers();
  }

  onFocus() {
    this.searchFocus = true;
  }

  onBlur() {
    this.searchFocus = false;
  }

  openGroupNotificationsModal() {
    const dialogRef = this.dialog.open(UserNotificationComponent, {
      width: '50%',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  private getDisplayedColumnsUsers(): Array<string> {
    return [
      'id',
      'name',
      'email',
      'role',
      'team',
      'team_leader',
      'service_integrations',
      'created_at',
      'actions',
    ];
  }

  private getDisplayedColumnsEmployees(): Array<string> {
    return [
      'first_name',
      'email',
      'department',
      'team',
      'team_leader',
    ];
  }

}
