import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../_base-shared/contracts/common.interface';
import { Creditor } from '../../../../../../_base-shared/models/Entity/Creditor';
import { User } from '../../../../../../_base-shared/models/User/User';
import { DepartmentService } from '../../department/department.service';
import { UserService } from '../../user/user.service';
import { CreditorService } from '../creditor.service';
import { CreditorReassignComponent } from './creditor-reassign/creditor-reassign.component';

@Component({
  selector:    'app-creditor-editor',
  templateUrl: './creditor-editor.component.html',
  styleUrls:   ['./creditor-editor.component.scss'],
})
export class CreditorEditorComponent implements OnInit {
  public authUser: User;
  public isLoading         = 0;
  public editorType: string;
  public test;
  public form: UntypedFormGroup;
  public formSubmitted: boolean;
  public creditor: Creditor;
  public formActive        = false;
  public dayOptions        = [1, 2, 3, 4, 5, 6, 7];
  public hourOptions       = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  public dayOptionsMonthly = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
    24, 25, 26, 27, 28, 29, 30, 31];
  public creditorStatuses: Array<AppSelectOption>;
  public approvedStatuses: Array<AppSelectOption>;
  public creditorTypes: Array<AppSelectOption>;
  public lsoVotingOutcomes: Array<AppSelectOption>;
  public dmVotingOutcomes: Array<AppSelectOption>;
  public creditorNegotiators: Array<User>;

  constructor(private dialog: MatDialog,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private creditorService: CreditorService,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private userService: UserService,
              private departmentService: DepartmentService
  ) {
  }

  ngOnInit(): void {
    this.editorType = this.route.snapshot.data.editorType;
    this.test       = this.route.snapshot.paramMap.get('id');
    if (this.editorType === 'edit') {
      this.route.paramMap.subscribe(params => this.fetchCreditor(+params.get('id')));
    } else {
      this.creditor = new Creditor();
      this.buildForm();
    }
    this.fetchDepartmentCategories();
    this.creditorStatuses  = this.getCreditorStatuses();
    this.approvedStatuses  = this.getApprovedStatuses();
    this.creditorTypes     = this.getCreditorType();
    this.lsoVotingOutcomes = this.getVotingOutcomes();
    this.dmVotingOutcomes  = this.getVotingOutcomes();
    this.translateService.onLangChange.subscribe(() => {
      this.creditorStatuses  = this.getCreditorStatuses();
      this.approvedStatuses  = this.getApprovedStatuses();
      this.creditorTypes     = this.getCreditorType();
      this.lsoVotingOutcomes = this.getVotingOutcomes();
      this.dmVotingOutcomes  = this.getVotingOutcomes();
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name:                          [this.creditor.name, [Validators.required]],
      active:                        [this.creditor.active, [Validators.required]],
      approved:                      [this.creditor.approved, [Validators.required]],
      address:                       [this.creditor.address, []],
      company_name:                  [this.creditor.company_name, []],
      contact_person_1:              [this.creditor.contact_person_1, []],
      contact_person_2:              [this.creditor.contact_person_2, []],
      email:                         [this.creditor.email, [Validators.email]],
      email_data_protection:         [this.creditor.email_data_protection, [Validators.email]],
      email_complaints:              [this.creditor.email_complaints, [Validators.email]],
      email_payments:                [this.creditor.email_payments, [Validators.email]],
      email_mandates:                [this.creditor.email_mandates, [Validators.email]],
      email_proposals:               [this.creditor.email_proposals, [Validators.email]],
      phone_1:                       [this.creditor.phone_1, []],
      phone_2:                       [this.creditor.phone_2, []],
      lender_type:                   [this.creditor.lender_type || 'standard', []],
      voting_outcomes_lso:           [this.creditor.voting_outcomes_lso, []],
      voting_outcomes_dm:            [this.creditor.voting_outcomes_dm, []],
      minimum_dividend_required_lso: [this.creditor.minimum_dividend_required_lso, []],
      minimum_dividend_required_dm:  [this.creditor.minimum_dividend_required_dm, []],
      can_be_recovery:               [this.creditor.can_be_recovery, []],
      show_config:                   [!!this.creditor.schedule_config],
      payment_account:               [this.creditor.payment_account],
      payment_reference:             [this.creditor.payment_reference],
      payment_date:                  [this.creditor.payment_date],
      assigned_to_id:                [this.creditor.assigned_to_id],
    });

    //  Dynamic add new config control
    if (this.creditor.schedule_config) {
      this.form.addControl('schedule_config', this.formBuilder.group({
        type: [this.creditor.schedule_config?.type || 'daily'],
        day:  [this.creditor.schedule_config?.day || '1'],
        hour: [this.creditor.schedule_config?.hour || '6'],
      }));
    }

    this.formActive = true;

    //  Subscribe to toggle `show_config` control
    //  And add or remove `schedule_config`
    this.form.get('show_config').valueChanges.subscribe(next => {
      if (next) {
        this.form.addControl('schedule_config', this.formBuilder.group({
          type: [this.creditor.schedule_config?.type || 'daily'],
          day:  [this.creditor.schedule_config?.day] || '1',
          hour: [this.creditor.schedule_config?.hour || '6'],
        }));
      } else {
        this.form.removeControl('schedule_config');
      }
    });
  }

  fetchCreditor(creditorId) {
    this.isLoading++;
    this.creditorService.get(creditorId)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
        this.creditor = res.data;
        this.buildForm();
      });
  }

  submitForm(form: UntypedFormGroup) {
    this.formSubmitted = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toastr.error('Please check all fields', 'Success');
      return;
    }
    if (this.editorType === 'create') {
      this.isLoading++;
      this.creditorService.store(form.value)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(res => {
          this.router.navigateByUrl('/creditor');
          this.toastr.success('Creditor successfully created', 'Success');
        }, error => {
          this.toastr.error(error.error?.message, 'Something went wrong');
        });
    } else {
      this.isLoading++;
      this.creditorService.update(this.creditor.id, form.value)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(res => {
          this.router.navigateByUrl('/creditor');
          this.toastr.success('Creditor successfully edited', 'Success');
        }, error => {
          this.toastr.error(error.error?.message, 'Something went wrong');
        });
    }
  }

  public deleteAndReassign() {
    const dialogRef = this.dialog.open(CreditorReassignComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '500px',
      data:      {
        creditor: this.creditor,
      },
    });

    dialogRef.afterClosed()
      .subscribe(res => {
        if (res) {
          this.router.navigate(['/creditor']);
        }
      });
  }

  private getCreditorStatuses() {
    return [
      {label: this.translateService.instant('AFFILIATES.select-status'), value: null},
      {label: this.translateService.instant('AFFILIATES.active'), value: 1},
      {label: this.translateService.instant('AFFILIATES.inactive'), value: 2}];
  }

  private getApprovedStatuses() {
    return [
      {label: this.translateService.instant('CREDITORS.approved'), value: 1},
      {label: this.translateService.instant('CREDITORS.unapproved'), value: 0}];
  }

  private getCreditorType() {
    return [
      {label: this.translateService.instant('CREDITORS.standard'), value: 'standard'},
      {label: this.translateService.instant('CREDITORS.high-interest'), value: 'high_interest'},
      {label: this.translateService.instant('CREDITORS.collections'), value: 'collections'}];
  }

  private getVotingOutcomes() {
    return [
      {label: this.translateService.instant('CREDITORS.accept'), value: 'accept'},
      {label: this.translateService.instant('CREDITORS.reject'), value: 'reject'},
      {label: this.translateService.instant('CREDITORS.case-by-case'), value: 'case-by-case'},
      {label: this.translateService.instant('CREDITORS.doesnt-vote'), value: 'doesnt-vote'}];
  }

  private fetchDepartmentCategories(): void {
      this.departmentService.categoryIndex({}, ['departments.users'])
        .subscribe(result => {
         result.data.forEach(departmentCategory => {
           if (departmentCategory.type === 'creditor-negotiation'){
             departmentCategory.departments.forEach(department => {
               if (department.type === 'creditor-negotiator'){
                 this.creditorNegotiators = department.users;
               }
             });
           }
         });
        });
  }
}
