import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyCheckboxChange } from '@angular/material/legacy-checkbox';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseAsset } from '../../../../../../../../../_base-shared/models/Case/CaseAsset';
import { CaseCreditor } from '../../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePublicDebt } from '../../../../../../../../../_base-shared/models/Case/CasePublicDebt';
import { AppDocument } from '../../../../../../../../../_base-shared/models/Document/AppDocument';
import { AppDocumentRequest } from '../../../../../../../../../_base-shared/models/Document/AppDocumentRequest';
import { AppDocumentType } from '../../../../../../../../../_base-shared/models/Document/AppDocumentType';
import {
  AppDocumentTypeCategory
} from '../../../../../../../../../_base-shared/models/Document/AppDocumentTypeCategory';
import { User } from '../../../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../../../_shared/services/main-global-event.service';
import { DocumentTypeService } from '../../../../../document/document-type.service';
import { CaseAssetService } from '../../../../case-asset.service';
import { CaseCreditorService } from '../../../../case-creditor.service';

@Component({
  selector:    'app-case-document-request-list',
  templateUrl: './case-document-request-list.component.html',
  styles:      [`
    .document-type-control > .mat-checkbox-layout {
      width: 100%;
    }
  `]
})
export class CaseDocumentRequestListComponent implements OnInit, OnDestroy {
  @Input() case: Case;
  @Input() clientRole!: 'client' | 'partner';
  @Input() documentableType!: 'case_creditor' | 'case_public_debt' | 'case_asset';
  @Input() documentableId!: number;
  @Output() requestedDocuments: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
  public authUser: User;
  public currentLanguage                                 = 'es';

  public caseDocumentTypeCategories: Array<AppDocumentTypeCategory> = [];
  public documentRequests: Array<AppDocumentRequest>                = [];
  public caseCreditors: Array<CaseCreditor>;
  public casePublicDebts: Array<CasePublicDebt>;
  public caseAssets: Array<CaseAsset>;

  public isLoading = 0;
  public form: FormGroup;
  public formReady = false;

  public requestingDocuments: boolean;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private translate: TranslateService,
    private globalEventsService: MainGlobalEventService,
    private documentTypeService: DocumentTypeService,
    private caseCreditorService: CaseCreditorService,
    private caseAssetService: CaseAssetService
  ) {
  }

  get formCategories(): FormArray {
    return this.form?.get('categories') as FormArray;
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    const storageLanguage = this.cookieService.get('lang');
    this.currentLanguage  = (storageLanguage === 'es' || storageLanguage === 'en') ? storageLanguage : 'es';
    this.translate.onLangChange.subscribe(next => this.currentLanguage = next.lang);

    const requestData: any = {
      types:       ['client_case', 'client_case_creditor', 'client_case_public_debt', 'client_case_asset', 'client_case_custom'],
      client_role: this.clientRole
    };
    const relations        = ['document_types', 'document_types.document_type_requests', 'document_types.documents'];

    if (this.documentableType && this.documentableId) {
      requestData.documentable_type = this.documentableType;
      requestData.documentable_id   = this.documentableId;
    }

    this.isLoading++;
    this.subscriptions.push(
      this.documentTypeService.indexCaseDocumentRequests(this.case.id)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.documentRequests = result.data;
          this.isLoading++;
          this.subscriptions.push(
            this.documentTypeService.indexCaseDocumentTypeCategories(this.case.id, requestData, relations)
              .pipe(finalize(() => this.isLoading--))
              .subscribe(result => {
                this.caseDocumentTypeCategories = result.data;
                const caseCreditors$            = this.caseCreditorService.indexCaseCreditors(this.case.id, ['creditor'], {select_all: 1});
                const casePublicDebts$          = this.caseCreditorService.indexCasePublicDebts(this.case.id, ['town_hall'], {select_all: 1});
                const caseAssets$               = this.caseAssetService.index(this.case.id, ['entity'], {
                  'types[]':  'bank_accounts',
                  select_all: 1
                });

                this.isLoading += 3;
                const resources$ = forkJoin([caseCreditors$, casePublicDebts$, caseAssets$])
                  .pipe(finalize(() => this.isLoading -= 3))
                  .subscribe(res => {
                    this.caseCreditors   = res[0].data;
                    this.casePublicDebts = res[1].data;
                    this.caseAssets      = res[2].data;
                    this.buildForm(this.caseDocumentTypeCategories);
                  });
                this.subscriptions.push(resources$);
              })
          );
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public toggleAllDocTypes(modelChange: MatLegacyCheckboxChange, categoryIndex: number): void {
    this.formCategories.at(categoryIndex)?.get('document_types')['controls'].forEach(documentTypeControl => {
      documentTypeControl.get('selected').patchValue(modelChange.checked);
      if (documentTypeControl.get('custom_input_logic').value) {
        if (documentTypeControl.get('custom_input_logic').value === 'custom_selection' &&
          documentTypeControl.get('custom_documents')?.value?.length > 0
        ) {
          const customDocsArray = documentTypeControl.get('custom_documents') as FormArray;
          customDocsArray.controls.forEach(customDocFormGroup => {
            customDocFormGroup.get('selected').patchValue(modelChange.checked);
          })
        }

        if (documentTypeControl.get('custom_input_logic').value !== 'custom_selection' &&
          documentTypeControl.get('documentables')?.value?.length > 0
        ) {
          const documentablesArray = documentTypeControl.get('documentables') as FormArray;
          documentablesArray.controls.forEach(documentableFormGroup => {
            documentableFormGroup.get('selected').patchValue(modelChange.checked);
          });
        }
      }
    });
  }

  public submitForm(form: FormGroup, channel: 'email' | 'sms' | 'all' | 'none'): void {
    if (form.invalid) {
      form.markAllAsTouched();
    }
    const formData   = form.value;
    formData.channel = channel;

    this.requestingDocuments = true;
    this.subscriptions.push(
      this.documentTypeService.requestDocumentUpload(this.case.id, formData)
        .pipe(finalize(() => this.requestingDocuments = false))
        .subscribe(result => {
          this.toastr.success(this.translate.instant('CASES.single.request-sent-success'),
            this.translate.instant('SHARED.success'));
          this.requestedDocuments.emit(result.data);
        }, error => {
          this.toastr.error(this.translate.instant('CASES.single.request-sent-error'),
            this.translate.instant('SHARED.error'));
        })
    );
  }

  private buildForm(documentTypeCategories: Array<AppDocumentTypeCategory>): void {
    this.formReady = false;
    this.form      = this.fb.group({
      client_role: [this.clientRole, [Validators.required]],
      channel:     ['none', [Validators.required]],
      categories:  this.fb.array([])
    });

    const categories = this.form.get('categories') as FormArray;

    documentTypeCategories.forEach(documentTypeCategory => {
      categories.push(this.initDocumentTypeCategory(documentTypeCategory, false));
    });
    setTimeout(() => this.formReady = true, 200);
  }

  private initDocumentTypeCategory(documentTypeCategory: AppDocumentTypeCategory, hasChildCategories = false): FormGroup {
    const categoryGroup = this.fb.group({
      id:               [documentTypeCategory.id ? documentTypeCategory.id : null],
      name_es:          [documentTypeCategory.name_es ? documentTypeCategory.name_es : null],
      name_en:          [documentTypeCategory.name_en ? documentTypeCategory.name_en : null],
      document_types:   this.fb.array([]),
      child_categories: this.fb.array([])
    });

    const categoryDocumentTypesGroup = categoryGroup.get('document_types') as UntypedFormArray;

    documentTypeCategory.document_types.forEach(documentType => {
      categoryDocumentTypesGroup.push(this.initDocumentType(documentType));
    });

    if (hasChildCategories && documentTypeCategory.child_categories?.length) {
      const childCategoriesGroup = categoryGroup.get('child_categories') as UntypedFormArray;

      documentTypeCategory.child_categories.forEach(childCategory => {
        childCategoriesGroup.push(this.initDocumentTypeCategory(childCategory, false));
      });
    }

    return categoryGroup;
  }

  private initDocumentType(documentType: AppDocumentType): FormGroup {
    const latestRequest = ! documentType.custom_input_logic && documentType.document_type_requests?.length ?
      documentType.document_type_requests[0] :
      null;

    const documentTypeGroup = this.fb.group({
      id:                 [documentType.id, [Validators.required]],
      name_es:            [documentType.name_es],
      name_en:            [documentType.name_en],
      selected:           [documentType.preselected || !! latestRequest, [Validators.required]],
      expires_after:      [documentType.expires_after],
      expires_after_unit: [documentType.expires_after_unit],
      custom_input_logic: [documentType.custom_input_logic],
      last_requested_at:  [latestRequest?.last_requested_at],

      // Optional fields based on custom_input_logic
      documentable_type: [null],
      documentables:     this.fb.array([]),
      custom_documents:  this.fb.array([])
    });

    if (documentType.custom_input_logic) {
      documentTypeGroup.get('selected').patchValue(true);
    }

    if (documentType.custom_input_logic === 'case_creditor_selection') {
      documentTypeGroup.get('documentable_type').patchValue('case_creditor');
      const documentablesArray = documentTypeGroup.get('documentables') as FormArray;

      this.caseCreditors.forEach(caseCreditor => {
        const isSelected = this.checkIfDocumentableRequested(documentType, 'case_creditor', caseCreditor);
        documentablesArray.push(this.fb.group({
          id:               [caseCreditor.id, [Validators.required]],
          selected:         [isSelected, [Validators.required]],
          name:             [caseCreditor.creditor?.name],
          reference_number: [caseCreditor.reference_number]
        }));
      });
    }

    if (documentType.custom_input_logic === 'case_public_debt_selection') {
      documentTypeGroup.get('documentable_type').patchValue('case_public_debt');
      const documentablesArray = documentTypeGroup.get('documentables') as FormArray;

      this.casePublicDebts.forEach(publicDebt => {
        const isSelected = this.checkIfDocumentableRequested(documentType, 'case_public_debt', publicDebt);
        let label        = '';
        if (publicDebt.public_organisation === 'town-hall' && publicDebt.town_hall) {
          label = publicDebt.town_hall.name;
        } else {
          label = this.translate.instant('CASE_CREDITOR.model.public_organization.options.' + publicDebt.public_organisation);
        }

        documentablesArray.push(this.fb.group({
          id:               [publicDebt.id, [Validators.required]],
          selected:         [isSelected, [Validators.required]],
          name:             [label],
          reference_number: [publicDebt.reference_number]
        }));
      });
    }

    if (documentType.custom_input_logic === 'case_asset_selection') {
      documentTypeGroup.get('documentable_type').patchValue('case_asset');
      const documentablesArray = documentTypeGroup.get('documentables') as FormArray;

      this.caseAssets.forEach(caseAsset => {
        const isSelected = this.checkIfDocumentableRequested(documentType, 'case_asset', caseAsset);
        documentablesArray.push(this.fb.group({
          id:               [caseAsset.id, [Validators.required]],
          selected:         [isSelected, [Validators.required]],
          name:             [caseAsset.entity?.name],
          reference_number: [caseAsset.account_number]
        }));
      });
    }

    if (documentType.custom_input_logic === 'custom_selection') {
      const customDocsArray = documentTypeGroup.get('custom_documents') as FormArray;

      documentType.documents?.forEach(customDocument => {
        const isSelected = this.checkIfDocumentRequested(documentType, customDocument);
        customDocsArray.push(this.fb.group({
          id:       [customDocument.id],
          name:     [{value: customDocument.name, disabled: false}, [Validators.required]],
          selected: [isSelected, [Validators.required]]
        }));
      });
    }

    return documentTypeGroup;
  }

  public addCustomDocument(documentType: FormControl): void {
    const customDocsArray = documentType.get('custom_documents') as FormArray;
    customDocsArray.push(this.fb.group({
      id:       [null],
      name:     [{value: '', disabled: false}, [Validators.required]],
      selected: [false, [Validators.required]]
    }));
  }

  public removeCustomDocument(documentType: FormControl, index: number): void {
    const customDocsArray = documentType.get('custom_documents') as FormArray;
    customDocsArray.removeAt(index);
  }

  private checkIfDocumentableRequested(
    documentType: AppDocumentType,
    documentableType: 'case_creditor' | 'case_public_debt' | 'case_asset',
    documentable: CaseCreditor | CasePublicDebt | CaseAsset | null
  ): boolean {
    const clientId        = this.clientRole === 'partner' ? this.case.partner_user_id : this.case.user_id;
    const documentRequest = this.documentRequests.find(docRequest => {
      return docRequest.app_document_type_id === documentType.id && docRequest.client_id === clientId &&
        docRequest.documentable_type === documentableType && docRequest.documentable_id === documentable?.id;
    });

    return !! documentRequest;
  }

  private checkIfDocumentRequested(documentType: AppDocumentType, document: AppDocument): boolean {
    const clientId = this.clientRole === 'partner' ? this.case.partner_user_id : this.case.user_id;

    return document.user_id === clientId && !! document.app_document_request_id
  }
}
