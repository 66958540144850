import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { DistributionBatch } from '../../../../../../_base-shared/models/Distribution/DistributionBatch';
import { DistributionProvider } from '../../../../../../_base-shared/models/Distribution/DistributionProvider';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { CaseService } from '../../case/case.service';
import { DistributionAmountCalculatorService } from '../distribution-amount-calculator.service';
import { DistributionBatchService } from '../distribution-batch.service';
import { DistributionProviderService } from '../distribution-provider.service';
import { DistributionService } from '../distribution.service';

@Component({
  selector:    'app-distribution-batch-list',
  templateUrl: './distribution-batch-list.component.html',
  styles:      [
    `
      mat-table {
        display: table;
        width: 100%;
      }

      mat-progress-bar {
        height: 16px;
      }
    `,
  ],
})
export class DistributionBatchListComponent extends DistributionAmountCalculatorService implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public authUser: User;
  public componentType: 'distribution' | 'admin';
  public filtersReady                                       = false;
  public requestFilters: any;
  public form: UntypedFormGroup;
  public batches: MatTableDataSource<DistributionBatch>     = new MatTableDataSource<DistributionBatch>([]);
  public distributionProviders: Array<DistributionProvider> = [];
  public isLoading                                          = 0;
  public serverResponse: LaravelResourceResponse;
  public displayedColumns: Array<string>;
  public selection                                          = new SelectionModel(true, []);
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  public totalResults: number;
  public totalPages: number;

  // Filters
  public batchStatusOptions: Array<AppSelectOption> = [];

  private subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog,
              private translate: TranslateService,
              private toastr: ToastrService,
              private globalEventService: MainGlobalEventService,
              private caseService: CaseService,
              private distributionService: DistributionService,
              private distributionBatchService: DistributionBatchService,
              private distributionProviderService: DistributionProviderService) {
    super();
  }

  ngOnInit(): void {
    this.defaultPaginatorConfig = {pageIndex: 0, pageSize: 20, length: 1};
    this.defaultSort            = {direction: 'desc', active: 'created_at'};
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.requestFilters         = this.getDefaultFilters();
    this.buildFilterOptions();
    this.fetchDistributionProviders();
    this.buildForm();

    this.globalEventService.authUser$.subscribe(user => {
      if (user) {
        this.authUser         = user;
        this.componentType    = this.authUser.role.slug === 'distribution-provider' ? 'distribution' : 'admin';
        this.displayedColumns = this.getTableColumns(this.componentType);
        this.fetchBatches();
      }
    });
  }

  ngAfterViewInit(): void {
    this.batches.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private resetPagination(): void {
    this.paginatorConfig         = this.defaultPaginatorConfig;
    this.requestFilters.per_page = this.paginatorConfig.pageSize;
    this.requestFilters.page     = this.paginatorConfig.pageIndex;
  }

  private resetSort(): void {
    this.requestFilters.sort_by = this.defaultSort.active;
    this.requestFilters.order   = this.defaultSort.direction;
  }

  private clearSelection() {
    this.selection.clear();
    delete this.requestFilters.cases;
  }

  private fetchBatches(): void {
    this.clearSelection();

    this.isLoading++;
    this.subscriptions.push(
      this.distributionBatchService.index(this.requestFilters, ['distribution_provider', 'case_distributions'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
          result => {
            this.clearSelection();
            this.batches                = new MatTableDataSource<DistributionBatch>(result.data);
            this.batches.sort           = this.sort;
            this.paginatorConfig.length = result.meta.total;
            this.totalResults           = result.meta.total;
            this.totalPages             = result.meta.last_page;
          },
          err => console.error(err),
        ),
    );
  }

  private getTableColumns(type: 'distribution' | 'admin'): Array<string> {
    return [
      'name',
      'cases_count',
      'status',
      'status_updated_at',
      'total_monthly_fee_amount',
      'total_batch_contract_amount',
      'total_current_funded_amount',
      'total_funded_amount',
      'total_cash_hurdle_amount',
      'total_distributed_amount',
      'total_fees_retained',
      'progress_bar',
      'actions',
    ];
  }

  private getDefaultFilters() {
    return {
      select_all:                0,
      statuses:                  null,
      distribution_provider_ids: null,
      search:                    null,
    };
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.requestFilters.page     = this.paginatorConfig.pageIndex + 1;
    this.requestFilters.per_page = this.paginatorConfig.pageSize;
    this.fetchBatches();
  }

  public sortData(sort) {
    this.requestFilters.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.requestFilters.order   = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchBatches();
  }

  // On filter change
  public handleFilters(): void {
    // this.requestFilters = $event;
    this.resetPagination();
    this.resetSort();
    this.fetchBatches();
  }

  private buildForm() {
    this.form = this.fb.group({
      statuses:                  [null],
      distribution_provider_ids: [null],
      search:                    [null],
    });
    this.subscribeToFilterChanges();
  }

  private buildFilterOptions() {
    this.batchStatusOptions = [
      {value: 'pending', label: 'Pending'},
      {value: 'submitted', label: 'Submitted'},
      {value: 'funded', label: 'Funded'},
      {value: 'rejected', label: 'Rejected'},
    ];
  }

  private fetchDistributionProviders() {
    this.isLoading++;
    this.distributionProviderService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.distributionProviders = result.data,
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
      );
  }

  private subscribeToFilterChanges() {
    this.subscriptions.push(
      this.form.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe(formValue => {
        this.requestFilters = {...this.requestFilters, ...formValue};
        this.handleFilters();
      }),
    );
  }

  public changeComponentType(type: 'distribution' | 'admin') {
    this.componentType    = type;
    this.displayedColumns = this.getTableColumns(type);
  }

}
