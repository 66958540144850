<div class="card mb-3">
  <div class="card-header">
    <div class="d-flex justify-content-between">
      <div class="card-title">
        <h2>{{ 'CASES.editor.incomes.heading' | translate }}</h2>
      </div>
      <!-- Page Actions -->
      <div *ngIf="form">
        <app-spinner-btn type="button" [loading]="isSubmitting" [name]="'SHARED.save' | translate"
                         (click)="submitForm(form)">
        </app-spinner-btn>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="isLoading || serverResponse">
    <div class="row">
      <div [hidden]="!isLoading" class="col-12">
        <mat-spinner class="m-0 m-auto"></mat-spinner>
      </div>
      <div *ngIf="serverResponse" class="col-12">
        <app-server-response [response]="serverResponse"></app-server-response>
      </div>
    </div>
  </div>
</div>
<form *ngIf="form" [formGroup]="form">
  <div class="row incomes-wrapper">
    <!-- Salary and Pensions -->
    <div class="col-6">
      <!-- Salary -->
      <div class="card shadow mb-4">
        <div class="card-body">
          <!-- SalaryDay -->
          <app-input type="number" formControlName="salary_day" [label]="'CASES.single.salary-day' | translate"
                     [extraLabel]="true" [fullWidth]="true">
          </app-input>
          <!-- Total Salary -->
          <div class="form-group row border-bottom pb-2">
            <label for="salary" class="col-6 col-form-label">
              <b>{{"CASES.single.wage-salary" | translate | uppercase}}</b>
            </label>
            <div class="col-6">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input [value]="totalSalary" type="number" class="form-control" id="salary" disabled>
              </div>
            </div>
          </div>
          <!-- Salary Client -->
          <app-input type="number" formControlName="salary_client" [label]="'CASES.single.client-salary' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalIncomes('salary')">
          </app-input>
          <!-- Salary Partner -->
          <app-input type="number" formControlName="salary_partner" [label]="'CASES.single.partner-salary' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalIncomes('salary')">
          </app-input>
          <!-- Subsidy Benefits -->
          <app-input type="number" formControlName="subsidy_benefit" [extraLabel]="true" [fullWidth]="true"
                     [label]="'CASES.single.subsidy-benefits' | translate"
                     (ngModelChange)="updateTotalIncomes('salary')">
          </app-input>
          <!-- Unemployment Benefit -->
          <app-input type="number" formControlName="unemployment_benefit" [extraLabel]="true" [fullWidth]="true"
                     [label]="'CASES.single.unemployment-benefit' | translate"
                     (ngModelChange)="updateTotalIncomes('salary')">
          </app-input>
          <!-- State Pension -->
          <app-input type="number" formControlName="pension_state" [extraLabel]="true" [fullWidth]="true"
                     [label]="'CASES.single.state-pensions' | translate" (ngModelChange)="updateTotalIncomes('salary')">
          </app-input>
        </div>
      </div>
      <!-- Pensions -->
      <div class="card shadow">
        <div class="card-body">
          <!-- Total Pensions -->
          <div class="form-group row border-bottom pb-2">
            <label for="pensions" class="col-6 col-form-label"><b>{{"CASES.single.pensions" | translate}}</b></label>
            <div class="col-6">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="number" [value]="totalPension" class="form-control" id="pensions" disabled>
              </div>
            </div>
          </div>
          <!-- Pension Private -->
          <app-input type="number" formControlName="pension_private" [extraLabel]="true" [fullWidth]="true"
                     [label]="'CASES.single.private-pensions' | translate"
                     (ngModelChange)="updateTotalIncomes('pension')">
          </app-input>
          <!-- Pension Credit -->
          <app-input type="number" formControlName="pension_credit" [extraLabel]="true" [fullWidth]="true"
                     [label]="'CASES.single.pension-credit' | translate"
                     (ngModelChange)="updateTotalIncomes('pension')">
          </app-input>
          <!-- Pension Other -->
          <app-input type="number" formControlName="pension_other" [extraLabel]="true" [fullWidth]="true"
                     [label]="'CASES.single.other-pensions' | translate"
                     (ngModelChange)="updateTotalIncomes('pension')">
          </app-input>
        </div>
      </div>
    </div>
    <!-- Benefits and Other -->
    <div class="col-6">
      <!-- Other -->
      <div class="card shadow mb-4">
        <div class="card-body">
          <!-- Total Other  -->
          <div class="form-group row border-bottom pb-2">
            <label for="other_income" class="col-6 col-form-label">
              <b>{{"CASES.single.other-income" | translate}}</b>
            </label>
            <div class="col-6">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input [value]="totalOther" type="number" class="form-control" id="other_income" disabled>
              </div>
            </div>
          </div>
          <!-- Other Boarders or Ledgers -->
          <app-input type="number" formControlName="other_boarders_or_lodgers" [extraLabel]="true" [fullWidth]="true"
                     [label]="'CASES.single.boarders' | translate" (ngModelChange)="updateTotalIncomes('other')">
          </app-input>
          <!-- Other Non Dependent Contributions -->
          <app-input type="number" formControlName="other_non_dependent_contributions"
                     [extraLabel]="true" [fullWidth]="true" [label]="'CASES.single.non-dependant' | translate"
                     (ngModelChange)="updateTotalIncomes('other')">
          </app-input>
          <!-- Other Student Loans and Grants -->
          <app-input type="number" formControlName="other_student_loans_and_grants"
                     [extraLabel]="true" [fullWidth]="true" [label]="'CASES.single.student-loans-grants' | translate"
                     (ngModelChange)="updateTotalIncomes('other')">
          </app-input>
        </div>
      </div>
      <!-- Benefits-->
      <div class="card shadow mb-4" style="min-height: 297px">
        <div class="card-body">
          <!-- Total Benefits -->
          <div class="form-group row border-bottom pb-2">
            <label for="benefits" class="col-6 col-form-label"><b>{{"CASES.single.benefits" | translate}}</b></label>
            <div class="col-6">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input [value]="totalBenefits" type="number" class="form-control" id="benefits" disabled>
              </div>
            </div>
          </div>
          <!-- Compensatory Pension -->
          <app-input type="number" formControlName="compensatory_pension" [extraLabel]="true" [fullWidth]="true"
                     [label]="'CASES.single.compensatory-pension' | translate"
                     (ngModelChange)="updateTotalIncomes('benefits')">
          </app-input>
          <!-- Alimony -->
          <app-input type="number" formControlName="alimony" [extraLabel]="true" [fullWidth]="true"
                     [label]="'CASES.single.alimony' | translate" (ngModelChange)="updateTotalIncomes('benefits')">
          </app-input>
        </div>
      </div>
      <!-- Special Income -->
      <div class="card shadow mt-4">
        <div class="card-body">
          <!-- Total Special Income -->
          <div class="form-group row border-bottom pb-2">
            <label for="special" class="col-6 col-form-label">
              <b>{{"CASES.single.special-income" | translate}}</b>
            </label>
            <div class="col-6">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input [value]="totalSpecialIncome" type="number" class="form-control" id="special" disabled>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <h4>{{"CASES.single.special-income" | translate}}</h4>
            <button mat-button mat-raised-button color="primary" (click)="addSpecialIncome()">
              + {{"CASES.single.add-special-income" | translate}}
            </button>
          </div>
          <ng-container *ngFor="let formGroup of getFormArray('special_incomes').controls let i = index"
                        formArrayName="special_incomes">
            <div class="row" [formGroupName]="i" style="padding-bottom: 10px">
              <!-- Label -->
              <div class="col-5">
                <app-input type="text" formControlName="label" [label]="'PAYMENTS.label' | translate"
                           [extraLabel]="false" [fullWidth]="true">
                </app-input>
              </div>
              <!-- Value -->
              <div class="col-5">
                <app-input type="number" formControlName="value" [label]="'CASE_ASSET.model.value' | translate"
                           [extraLabel]="false" [fullWidth]="true"
                           (ngModelChange)="updateTotalIncomes('special_incomes')">
                </app-input>
              </div>
              <!-- Remove Expense -->
              <div class="col-2 d-flex justify-content-center">
                <button mat-icon-button color="warn" (click)="removeSpecialExpenses(i)">
                  <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</form>

