<h2 class="m-3">
  Case Creditor
  <span *ngIf="caseCreditor?.reference_number" class="primary-color-text" style="font-size: 18px;">
    {{ caseCreditor.reference_number }}
  </span>
  <span *ngIf="!caseCreditor?.reference_number" class="primary-color-text" style="font-size: 18px;">
    #{{ caseCreditor?.id }}
  </span>
</h2>
<nav *ngIf="case" mat-tab-nav-bar dynamicHeight>
  <ng-container>
    <a mat-tab-link [routerLink]="['/cases', case.id, 'creditors', caseCreditor?.id, 'general']" routerLinkActive
       #rla="routerLinkActive" [active]="rla.isActive">
      {{ 'CASES.single.general.heading'  | translate }}
    </a>
    <a mat-tab-link [routerLink]="['/cases', case.id, 'creditors', caseCreditor?.id,  'documents']" routerLinkActive
       #rla1="routerLinkActive" [active]="rla1.isActive">
      {{ 'CASES.single.documents.heading'  | translate }}
    </a>
  </ng-container>
</nav>
<router-outlet></router-outlet>
