<div class="card mb-3">
  <div class="card-header">
    <div class="d-flex justify-content-between">
      <div class="card-title">
        <h2>{{ 'CASES.editor.assets.heading' | translate }}</h2>
      </div>
      <!-- Page Actions -->
      <div *ngIf="form">
        <app-spinner-btn type="button" [loading]="isSubmitting" [name]="'SHARED.save' | translate"
                         (click)="submitForm(form)">
        </app-spinner-btn>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="isLoading || serverResponse">
    <div class="row">
      <div [hidden]="!isLoading" class="col-12">
        <mat-spinner class="m-0 m-auto"></mat-spinner>
      </div>
      <div *ngIf="serverResponse" class="col-12">
        <app-server-response [response]="serverResponse"></app-server-response>
      </div>
    </div>
  </div>
</div>
<form *ngIf="form" [formGroup]="form">
  <!-- Bank Accounts -->
  <div class="row">
    <div class="col-12">
      <div class="card shadow">
        <div class="card-header">
          <div class="card-title">
            <h3>{{ 'CASES.editor.assets.table.bank_accounts' | translate }}</h3>
            <span class="small">
              {{ 'CASES.financial_overview.table.assets' | translate }}:
              {{ totalNetBankAccounts | number: '1.2-2' }}€
            </span>
          </div>
        </div>
        <div class="card-body">
          <table class="table assets-editor">
            <thead *ngIf="getFormArray('bank_accounts').controls.length">
            <tr>
              <th>{{ 'CASE_CREDITOR.model.ownership.label' | translate }}</th>
              <th>{{ 'CASE_CREDITOR.model.relations.creditor' | translate }}</th>
              <th>{{ 'CASE_ASSET.model.office' | translate }}</th>
              <th>{{ 'CASE_ASSET.model.account_number' | translate }}</th>
              <th>{{ 'CASE_ASSET.model.balance' | translate }}</th>
              <th>{{ 'CASE_ASSET.model.exposed' | translate }}</th>
              <th>{{ 'CASES.financial_overview.table.assets' | translate }}</th>
              <th>{{ 'CASE_ASSET.model.liquidable' | translate }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let formGroup of getFormArray('bank_accounts').controls let i = index"
                          formArrayName="bank_accounts">
              <tr [formGroupName]="i">
                <!-- Ownership -->
                <td>
                  <app-input type="select" *ngIf="creditors?.length" formControlName="ownership" appearance="outline"
                             [label]="'CASE_CREDITOR.model.ownership.label' | translate" class="full-width"
                             [selectOptions]="!case.joint_application ?
                             [{value: 'applicant', label: 'CASE_CREDITOR.model.ownership.options.applicant' | translate}] : ownershipOptions"
                             [selectLabel]="['label']" [selectValue]="'value'" [showClear]="false" [fullWidth]="true">
                  </app-input>
                </td>
                <!-- Creditor -->
                <td class="text-center" style="width: 30%">
                  <app-input type="select" *ngIf="creditors?.length" formControlName="creditor_id" appearance="outline"
                             [searchable]="true" [label]="'CASE_CREDITOR.model.relations.creditor' | translate"
                             class="full-width" [selectOptions]="creditors" [selectLabel]="['name']"
                             [selectValue]="'id'" [showClear]="false" [fullWidth]="true" [showLabel]="true">
                  </app-input>
                </td>
                <!-- Office -->
                <td>
                  <app-input formControlName="office" [label]="'CASE_ASSET.model.office' | translate"></app-input>
                </td>
                <!-- Account Number -->
                <td>
                  <app-input formControlName="account_number" [label]="'CASE_ASSET.model.account_number' | translate">
                  </app-input>
                </td>
                <!-- Balance -->
                <td>
                  <app-input type="number" formControlName="balance" [label]="'CASE_ASSET.model.balance' | translate"
                             (ngModelChange)="updateAssetTotalNetValue('bank_accounts')">
                  </app-input>
                </td>
                <!-- Exposed -->
                <td>
                  <app-input type="number" formControlName="exposed" [label]="'CASE_ASSET.model.exposed' | translate"
                             (ngModelChange)="updateAssetTotalNetValue('bank_accounts')">
                  </app-input>
                </td>
                <!-- Exposed Total -->
                <td>
                  <input type="text" class="form-control mt-1" readonly style="height: 53px"
                         [value]="getFormArray('bank_accounts').controls[i].value.balance -
                          getFormArray('bank_accounts').controls[i].value.exposed">
                </td>
                <!-- Liquidable -->
                <td>
                  <app-input type="checkbox" formControlName="liquidable"></app-input>
                </td>
                <!-- Remove Asset -->
                <td>
                  <button type="button" mat-icon-button color="warn" (click)="removeCaseAsset('bank_accounts', i)">
                    <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
          <!-- Add New Asset -->
          <button type="button" mat-raised-button color="primary" (click)="addNewCaseAsset('bank_accounts')">
            + {{ 'CASES.editor.assets.actions.add_bank_account' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Properties -->
  <div class="row mt-3">
    <div class="col-md-12">
      <div class="card shadow">
        <div class="card-header">
          <div class="card-title">
            <h3>{{ 'CASES.editor.assets.table.properties' | translate }}</h3>
            <span class="small">
              {{ 'CASES.financial_overview.table.assets' | translate }}:
              {{ totalNetProperties | number: '1.2-2' }}€
            </span>
          </div>
        </div>
        <div class="card-body">
          <table class="table assets-editor">
            <thead *ngIf="getFormArray('properties').controls.length">
            <tr>
              <th>{{ 'CASE_CREDITOR.model.ownership.label' | translate }}</th>
              <th>{{ 'CASE_CREDITOR.model.additional_partner' | translate }}</th>
              <th>{{ 'CASE_ASSET.model.home_type.label' | translate }}</th>
              <th>{{ 'ADDRESS.address_1' | translate }}</th>
              <th>{{ 'ADDRESS.postcode' | translate }}</th>
              <th>{{ 'CASE_ASSET.model.value' | translate }}</th>
              <th>{{ 'CASE_ASSET.model.acquisition_value' | translate }}</th>
              <th>{{ 'CASE_ASSET.model.property_registration' | translate }}</th>
              <th>{{ 'CASE_CREDITOR.model.debt_type.options.mortgage' | translate }}</th>
              <th>{{ 'CASES.financial_overview.table.assets' | translate }}</th>
              <th>{{ 'CASE_ASSET.model.liquidable' | translate }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let formGroup of getFormArray('properties').controls let i = index"
                          formArrayName="properties">
              <tr [formGroupName]="i">
                <!-- Ownership -->
                <td>
                  <app-input type="select" *ngIf="creditors?.length" formControlName="ownership" appearance="outline"
                             [label]="'CASE_CREDITOR.model.ownership.label' | translate" class="full-width"
                             [selectOptions]="!case.joint_application ?
                             [{value: 'applicant', label: 'CASE_CREDITOR.model.ownership.options.applicant' | translate}] : ownershipOptions"
                             [selectLabel]="['label']" [selectValue]="'value'" [showClear]="false" [fullWidth]="true">
                  </app-input>
                </td>
                <!-- Additional Partner -->
                <td class="text-center">
                  <app-input type="checkbox" formControlName="additional_partner"></app-input>
                </td>
                <!-- Home Type -->
                <td>
                  <app-input type="select" formControlName="home_type" appearance="outline"
                             [label]="'CASE_CREDITOR.model.ownership.label' | translate" class="full-width"
                             [selectOptions]="homeTypes"
                             [selectLabel]="['label']" [selectValue]="'value'" [showClear]="false" [fullWidth]="true">
                  </app-input>
                </td>
                <!-- Address 1-->
                <td>
                  <app-input formControlName="address_1" [label]="'ADDRESS.address_1' | translate">
                  </app-input>
                </td>
                <!-- Postcode -->
                <td>
                  <app-input formControlName="post_code" [label]="'ADDRESS.postcode' | translate">
                  </app-input>
                </td>
                <!-- Value -->
                <td>
                  <app-input type="number" formControlName="value" [label]="'CASE_ASSET.model.value' | translate"
                             (ngModelChange)="updateAssetTotalNetValue('properties')">
                  </app-input>
                </td>
                <!-- Acquisition Value -->
                <td>
                  <app-input type="number" formControlName="acquisition_value"
                             [label]="'CASE_ASSET.model.acquisition_value' | translate">
                  </app-input>
                </td>
                <!-- Property Registration -->
                <td>
                  <app-input type="text" formControlName="property_registration"
                             [label]="'CASE_ASSET.model.property_registration' | translate">
                  </app-input>
                </td>
                <!-- Mortgage -->
                <td>
                  <app-input type="number" formControlName="mortgage"
                             [label]="'CASE_CREDITOR.model.debt_type.options.mortgage' | translate"
                             (ngModelChange)="updateAssetTotalNetValue('properties')">
                  </app-input>
                </td>
                <!-- Exposed Total -->
                <td>
                  <input type="text" class="form-control mt-1" readonly style="height: 53px"
                         [value]="getFormArray('properties').controls[i].value.value -
                           getFormArray('properties').controls[i].value.mortgage">
                </td>
                <!-- Liquidable -->
                <td>
                  <app-input type="checkbox" formControlName="liquidable"></app-input>
                </td>
                <!-- Remove Asset -->
                <td>
                  <button type="button" mat-icon-button color="warn" (click)="removeCaseAsset('properties', i)">
                    <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
          <!-- Add New Asset -->
          <button type="button" mat-raised-button color="primary" (click)="addNewCaseAsset('properties')">
            + {{ 'CASES.editor.assets.actions.add_property' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Vehicles -->
  <div class="row mt-3">
    <div class="col-md-12">
      <div class="card shadow">
        <div class="card-header">
          <div class="card-title">
            <h3>{{ 'CASES.editor.assets.table.vehicles' | translate }}</h3>
            <span class="small">
              {{ 'CASES.financial_overview.table.assets' | translate }}:
              {{ totalNetVehicles | number: '1.2-2' }}€
            </span>
          </div>
        </div>
        <div class="card-body">
          <table class="table assets-editor">
            <thead *ngIf="getFormArray('vehicles').controls.length">
            <tr>
              <th>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th>
              <th>{{ "CASE_CREDITOR.model.additional_partner" | translate }}</th>
              <th>{{ "CASE_ASSET.model.on_finance" | translate }}</th>
              <th>{{ "CASE_ASSET.model.registration" | translate }}</th>
              <th>{{ "CASE_ASSET.model.make" | translate }}</th>
              <th>{{ "CASE_ASSET.model.model" | translate }}</th>
              <th>{{ "CASE_ASSET.model.age" | translate }}</th>
              <th>{{ "CASE_ASSET.model.value" | translate }}</th>
              <th>{{ "CASE_ASSET.model.acquisition_value" | translate }}</th>
              <th>{{ "CASE_ASSET.model.outstanding_finance" | translate }}</th>
              <th>{{ 'CASES.financial_overview.table.assets' | translate }}</th>
              <th>{{ 'CASE_ASSET.model.liquidable' | translate }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let formGroup of getFormArray('vehicles').controls let i = index"
                          formArrayName="vehicles">
              <tr [formGroupName]="i">
                <!-- Ownership -->
                <td>
                  <app-input type="select" *ngIf="creditors?.length" formControlName="ownership" appearance="outline"
                             [label]="'CASE_CREDITOR.model.ownership.label' | translate" class="full-width"
                             [selectOptions]="!case.joint_application ?
                             [{value: 'applicant', label: 'CASE_CREDITOR.model.ownership.options.applicant' | translate}] : ownershipOptions"
                             [selectLabel]="['label']" [selectValue]="'value'" [showClear]="false" [fullWidth]="true">
                  </app-input>
                </td>
                <!-- Additional Partner -->
                <td class="text-center">
                  <app-input type="checkbox" formControlName="additional_partner"></app-input>
                </td>
                <!-- On Finance -->
                <td class="text-center">
                  <app-input type="checkbox" formControlName="on_finance"
                             (ngModelChange)="toggleOutstandingFinance($event, i)">
                  </app-input>
                </td>
                <!-- Registration -->
                <td>
                  <app-input formControlName="registration" [label]="'CASE_ASSET.model.registration' | translate">
                  </app-input>
                </td>
                <!-- Make -->
                <td>
                  <app-input formControlName="make" [label]="'CASE_ASSET.model.make' | translate">
                  </app-input>
                </td>
                <!-- Model -->
                <td>
                  <app-input formControlName="model" [label]="'CASE_ASSET.model.model' | translate">
                  </app-input>
                </td>
                <!-- Age -->
                <td>
                  <app-input type="number" formControlName="age" [label]="'CASE_ASSET.model.age' | translate">
                  </app-input>
                </td>
                <!-- Value -->
                <td>
                  <app-input type="number" formControlName="value" [label]="'CASE_ASSET.model.value' | translate"
                             (ngModelChange)="updateAssetTotalNetValue('vehicles')">
                  </app-input>
                </td>
                <!-- Acquisition Value -->
                <td>
                  <app-input type="number" formControlName="acquisition_value"
                             [label]="'CASE_ASSET.model.acquisition_value' | translate">
                  </app-input>
                </td>
                <!-- Outstanding Finance -->
                <td>
                  <app-input type="number" formControlName="outstanding_finance"
                             [label]="'CASE_ASSET.model.outstanding_finance' | translate"
                             (ngModelChange)="updateAssetTotalNetValue('vehicles')">
                  </app-input>
                </td>
                <!-- Exposed Total -->
                <td>
                  <input type="text" class="form-control mt-1" readonly style="height: 53px"
                         [value]="getFormArray('vehicles').controls[i].value.value -
                         (getFormArray('vehicles').controls[i].value.on_finance ?
                         getFormArray('vehicles').controls[i].value.outstanding_finance : 0)">
                </td>
                <!-- Liquidable -->
                <td>
                  <app-input type="checkbox" formControlName="liquidable"></app-input>
                </td>
                <!-- Remove Asset -->
                <td>
                  <button type="button" mat-icon-button color="warn" (click)="removeCaseAsset('vehicles', i)">
                    <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
          <!-- Add New Asset -->
          <button type="button" mat-raised-button color="primary" (click)="addNewCaseAsset('vehicles')">
            + {{ 'CASES.editor.assets.actions.add_vehicle' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Other -->
  <div class="row mt-3">
    <div class="col-md-12">
      <div class="card shadow">
        <div class="card-header">
          <div class="card-title">
            <h3>{{ "CASES.editor.assets.table.other" | translate }}</h3>
            <span class="small">
              {{ 'CASES.financial_overview.table.assets' | translate }}:
              {{ totalNetOther | number: '1.2-2' }}€
            </span>
          </div>
        </div>
        <div class="card-body">
          <table class="table assets-editor">
            <thead *ngIf="getFormArray('other').controls.length">
            <tr>
              <th>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th>
              <th>{{ "CASE_CREDITOR.model.additional_partner" | translate }}</th>
              <th>{{ "CASE_ASSET.model.other_assets_type" | translate }}</th>
              <th>{{ "CASE_ASSET.model.office" | translate }}</th>
              <th>{{ "CASE_ASSET.model.securities_account" | translate }}</th>
              <th>{{ "CASE_ASSET.model.estimated_value" | translate }}</th>
              <th>{{ "CASE_ASSET.model.acquisition_value" | translate }}</th>
              <th>{{ "CASE_ASSET.model.outstanding_finance" | translate }}</th>
              <th>{{ "CASE_ASSET.model.notes" | translate }}</th>
              <th>{{ 'CASES.financial_overview.table.assets' | translate }}</th>
              <th>{{ 'CASE_ASSET.model.liquidable' | translate }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let formGroup of getFormArray('other').controls let i = index"
                          formArrayName="other">
              <tr [formGroupName]="i">
                <!-- Ownership -->
                <td>
                  <app-input type="select" *ngIf="creditors?.length" formControlName="ownership" appearance="outline"
                             [label]="'CASE_CREDITOR.model.ownership.label' | translate" class="full-width"
                             [selectOptions]="!case.joint_application ?
                             [{value: 'applicant', label: 'CASE_CREDITOR.model.ownership.options.applicant' | translate}] : ownershipOptions"
                             [selectLabel]="['label']" [selectValue]="'value'" [showClear]="false" [fullWidth]="true">
                  </app-input>
                </td>
                <!-- Additional Partner -->
                <td class="text-center">
                  <app-input type="checkbox" formControlName="additional_partner"></app-input>
                </td>
                <!-- Other Assets Type -->
                <td>
                  <app-input formControlName="other_assets_type" [label]="'CASES.single.type' | translate"></app-input>
                </td>
                <!-- Office -->
                <td>
                  <app-input formControlName="office" [label]="'CASE_ASSET.model.office' | translate">
                  </app-input>
                </td>
                <!-- Securities Account -->
                <td>
                  <app-input formControlName="securities_account"
                             [label]="'CASE_ASSET.model.securities_account' | translate">
                  </app-input>
                </td>
                <!-- Estimated Value -->
                <td>
                  <app-input type="number" formControlName="estimated_value"
                             [label]="'CASE_ASSET.model.estimated_value' | translate"
                             (ngModelChange)="updateAssetTotalNetValue('other')">
                  </app-input>
                </td>
                <!-- Acquisition Value -->
                <td>
                  <app-input type="number" formControlName="acquisition_value"
                             [label]="'CASE_ASSET.model.acquisition_value' | translate">
                  </app-input>
                </td>
                <!-- Outstanding finance -->
                <td>
                  <app-input type="number" formControlName="outstanding_finance"
                             [label]="'CASE_ASSET.model.outstanding_finance' | translate"
                             (ngModelChange)="updateAssetTotalNetValue('other')">
                  </app-input>
                </td>
                <!-- Notes -->
                <td>
                  <app-input type="textarea" formControlName="notes"
                             [label]="'CASE_ASSET.model.notes' | translate"></app-input>
                </td>
                <!-- Exposed Total -->
                <td>
                  <input type="text" class="form-control mt-1" readonly style="height: 53px"
                         [value]="getFormArray('other').controls[i].value.estimated_value -
                           getFormArray('other').controls[i].value.outstanding_finance">
                </td>
                <!-- Liquidable -->
                <td>
                  <app-input type="checkbox" formControlName="liquidable"></app-input>
                </td>
                <!-- Remove Asset -->
                <td>
                  <button mat-icon-button color="warn" (click)="removeCaseAsset('other', i)">
                    <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
          <!-- Add New Asset -->
          <button mat-raised-button color="primary" (click)="addNewCaseAsset('other')">
            + {{ 'CASES.editor.assets.actions.add_other' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
