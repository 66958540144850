import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Status } from '../../../../../../_base-shared/models/Status/Status';
import { StatusService } from '../status.service';

@Component({
  selector:    'app-status-list',
  templateUrl: './status-list.component.html',
  styleUrls:   ['./status-list.component.scss'],
})
export class StatusListComponent implements OnInit {

  public status: Status;
  public statuses: Status[];
  displayedColumns: string[] = ['id', 'name', 'flag_case_active', 'parent_status', 'created_at', 'actions'];
  actions: string[]          = ['Edit', 'Delete'];
  public isLoading           = 0;
  public dataSource: MatTableDataSource<Status>;
  public paginatorConfig     = {
    pageIndex: 0,
    pageSize:  10,
    length:    1,
  };
  public search              = new UntypedFormControl('');
  public searchFocus         = false;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
      private statusService: StatusService,
      private toastr: ToastrService,
      public translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.fetchStatuses();
    this.search.valueChanges
        .pipe(
            debounceTime(300),
            distinctUntilChanged(),
        )
        .subscribe(() => this.fetchStatuses());
  }

  private fetchStatuses(): void {
    this.dataSource = new MatTableDataSource<Status>([]);
    this.isLoading++;
    const data = {
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1,
      search:   this.search.value,
    };

    const relations = ['parent'];

    this.statusService.index(data, relations)
        .pipe(
            finalize(() => this.isLoading--),
        )
        .subscribe(res => {
          this.statuses               = res.data;
          this.dataSource             = new MatTableDataSource<Status>(res.data);
          this.dataSource.sort        = this.sort;
          this.paginatorConfig.length = res.meta.total;
        });
  }

  openDeleteDialog(affiliateId, $event): void {
    $event.preventDefault();
    Swal.fire({
      title:              this.translateService.instant('SHARED.warning'),
      text:               this.translateService.instant('SHARED.delete-item'),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translateService.instant('SHARED.cancel'),
      confirmButtonText:  this.translateService.instant('SHARED.delete'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.deleteStatus(affiliateId);
      }
    });
  }

  deleteStatus(creditorId: number) {
    this.statusService.delete(creditorId)
        .subscribe(res => {
              this.fetchStatuses();
              this.toastr.success(this.translateService.instant('CONFIG.status.status-deleted'));
            },
            error => {
              this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
            });
  }

  public paginatorChange($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchStatuses();
  }

  toggleFocus(setFocus: boolean) {
    this.searchFocus = setFocus;
  }

  onChange() {
    this.fetchStatuses();
  }

}
