import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonPrettyPrint'
})
export class JsonPrettyPrintPipe implements PipeTransform {
  transform(transaction: any): string {
    if (!transaction) {
      return '';
    }
    return JSON.stringify({
      id: transaction.id,
      payment_type: transaction.payment_type,
      amount: transaction.amount,
      currency_code: transaction.currency_code,
      merchantTransactionId: transaction.oppwa_uid,
      build_number: transaction.oppwa_build_number,
      initial_cof: transaction.initial_cof,
      timestamp: transaction.oppwa_timestamp,
      ndc: transaction.oppwa_ndc,
      result: transaction.status_code,
      instruction_type: transaction.instruction_type,
      instruction_mode: transaction.instruction_mode,
      instruction_source: transaction.instruction_source
    }, null, 4);
  }
}
