<nav *ngIf="showNav" class="navbar navbar-expand-lg navigation-bar navbar-dark" [class.client-nav]="!authUser"
     [class.desktop]="desktopVersion" [class.mobile]="!desktopVersion">
  <div [ngClass]="{'d-flex flex-row w-100 justify-content-between align-items-end': !authUser}">
    <a class="navbar-brand" href="#" style="width: 125px">
      <a href='http://www.deudafix.es'>
        <img *ngIf="!authUser" class="img-fluid" src="assets/img/logos/deudafix-logo-new.png" alt="Deudafix Logo"
             style="width: 150px;margin-left: 5rem;margin-top: 1rem;"
             [ngStyle]="{'margin-left': desktopVersion ? '150px' : '-5px'}">
      </a>
      <img *ngIf="authUser && authUser.role_id !== 4" class="img-fluid"
           src="assets/img/logos/deudafix-logo-white.png" alt="Deudafix Logo" style="width: 150px">
    </a>
  </div>
  <app-staff-navigation [authUser]="authUser"></app-staff-navigation>
  <app-non-staff-navigation [authUser]="authUser" [desktopNav]=desktopVersion>
  </app-non-staff-navigation>
  <div class="full-width">
    <div class="form-inline d-flex justify-content-end">
      <app-header *ngIf="authUser && authUser.role_id !== 4" [notifications]="notifications"></app-header>
    </div>
  </div>
</nav>
