import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { AppDocument } from '../../../../../../_base-shared/models/Document/AppDocument';
import { AppFile } from '../../../../../../_base-shared/models/File/AppFile';
import { environment } from '../../../../environments/environment';
import { CaseDocumentService } from '../../../admin/case/case-document.service';

@Component({
  selector:    'app-image-editor-modal-v2',
  templateUrl: './image-editor-modal-v2.component.html',
  styles:      [],
})
export class ImageEditorModalV2Component implements OnInit, OnDestroy {
  public storageUrl                            = environment.STORAGE_URL + '/';
  public isLoading                             = 0;
  public imageChangedEvent: any                = '';
  public croppedImage: any                     = '';
  public canvasRotation                        = 0;
  public rotation                              = 0;
  public scale                                 = 1;
  public showCropper                           = false;
  public containWithinAspectRatio              = false;
  public transform: ImageTransform             = {};
  protected subscriptions: Array<Subscription> = [];

  constructor(private documentService: CaseDocumentService,
              public translate: TranslateService,
              public toastr: ToastrService,
              public dialogRef: MatDialogRef<ImageEditorModalV2Component>,
              @Inject(MAT_DIALOG_DATA) public data: { document: AppDocument, documentFile: AppFile, case: Case }) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  imageLoaded(): void {
    // show cropper
    // console.log('loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions): void {
    // cropper ready
    // console.log('rdy', sourceImageDimensions);
  }

  loadImageFailed($event): void {
    // show message
    // console.log('failed', $event);
  }

  rotateLeft(): void {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight(): void {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate(): void {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  public submitImage(): void {
    const data = {
      img: this.croppedImage,
    };
    this.isLoading++;
    this.subscriptions.push(
      this.documentService.storeImage(this.data.case.id, this.data.documentFile.id, data)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
          result => {
            this.dialogRef.close({data: result.data});
          },
          err => {
            this.toastr.error(this.translate.instant('DOCUMENTS.file-details-changed-error'));
          },
        ),
    );
  }
}
