<div class="card mb-3">
  <div class="card-header">
    <div class="d-flex justify-content-between">
      <div class="card-title">
        <h2>{{ 'CASES.editor.expenses.heading' | translate }}</h2>
      </div>
      <!-- Page Actions -->
      <div *ngIf="form">
        <app-spinner-btn type="button" [loading]="isSubmitting" [name]="'SHARED.save' | translate"
                         (click)="submitForm(form)">
        </app-spinner-btn>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="isLoading || serverResponse">
    <div class="row">
      <div [hidden]="!isLoading" class="col-12">
        <mat-spinner class="m-0 m-auto"></mat-spinner>
      </div>
      <div *ngIf="serverResponse" class="col-12">
        <app-server-response [response]="serverResponse"></app-server-response>
      </div>
    </div>
  </div>
</div>
<form *ngIf="form" [formGroup]="form">
  <div class="row expenses-wrapper">
    <!-- Essential, Living, Phone, Special-->
    <div class="col-6">
      <!-- Essential -->
      <div class="card shadow mb-4">
        <div class="card-body">
          <!-- Total Essential Expenses -->
          <div class="form-group row border-bottom pb-2">
            <label for="essential" class="col-6 col-form-label">
              <b>{{"CASES.single.essential-expenditure" | translate}}</b>
            </label>
            <div class="col-6">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input [value]="totalEssentialExpenses" type="number" class="form-control" id="essential" disabled>
              </div>
            </div>
          </div>
          <!-- Essential Rent -->
          <app-input type="number" formControlName="essential_rent" [label]="'CASES.single.rent' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('essentials')">
          </app-input>
          <!-- Essential Local Tax-->
          <app-input type="number" formControlName="essential_local_tax" [label]="'CASES.single.local-tax' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('essentials')">
          </app-input>
          <!-- Essential Mortgage -->
          <app-input type="number" formControlName="essential_mortgage"
                     [label]="'CASE_CREDITOR.model.debt_type.options.mortgage' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('essentials')">
          </app-input>
          <!-- Life Insurance -->
          <app-input type="number" formControlName="life_insurance" [label]="'CASES.single.life-insurance' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('essentials')">
          </app-input>
          <!-- Pension Plan -->
          <app-input type="number" formControlName="pension_plan" [label]="'CASES.single.pension-plan' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('essentials')">
          </app-input>
          <!-- Ibi -->
          <app-input type="number" formControlName="ibi" [label]="'CASES.single.ibi' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('essentials')">
          </app-input>
          <!-- Home Insurance -->
          <app-input type="number" formControlName="home_insurance" [label]="'CASES.single.home-insurance' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('essentials')">
          </app-input>
          <!-- School Expenses -->
          <app-input type="number" formControlName="school_expenses"
                     [label]="'CASES.single.school-expenses' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('essentials')">
          </app-input>
          <!-- Essential Other -->
          <app-input type="number" formControlName="essential_other" [label]="'CASES.single.other' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('essentials')">
          </app-input>
        </div>
      </div>
      <!-- Living Expenses -->
      <div class="card shadow mb-4">
        <div class="card-body">
          <!-- Total Living Expenses -->
          <div class="form-group row border-bottom pb-2">
            <label for="living" class="col-6 col-form-label">
              <b>{{"CASES.single.living-expenses" | translate}}</b>
            </label>
            <div class="col-6">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input [value]="totalLivingExpenses" type="number" class="form-control" id="living" disabled>
              </div>
            </div>
          </div>
          <!-- Essential Gas -->
          <app-input type="number" formControlName="essential_gas" [label]="'CASES.single.gas' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('living_expenses')">
          </app-input>
          <!-- Essential Electricity -->
          <app-input type="number" formControlName="essential_electricity"
                     [label]="'CASES.single.electricity' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('living_expenses')">
          </app-input>
          <!-- Essential Water -->
          <app-input type="number" formControlName="essential_water" [label]="'CASES.single.water' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('living_expenses')">
          </app-input>
        </div>
      </div>
      <!-- Phone Expenses -->
      <div class="card shadow mb-4">
        <div class="card-body">
          <!-- Total Phone Expenses -->
          <div class="form-group row border-bottom pb-2">
            <label for="phone" class="col-6 col-form-label">
              <b>{{"CASES.single.phone" | translate}}</b>
            </label>
            <div class="col-6">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input [value]="totalPhoneExpenses" type="number" class="form-control" id="phone" disabled>
              </div>
            </div>
          </div>
          <!-- Phone Home -->
          <app-input type="number" formControlName="phone_home" [label]="'CASES.single.home-phone' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('phone')">
          </app-input>
          <!-- Phone Mobile -->
          <app-input type="number" formControlName="phone_mobile" [label]="'CASES.single.mobile-phone' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('phone')">
          </app-input>
        </div>
      </div>
      <!-- Special Expenses -->
      <div class="card shadow mb-4">
        <div class="card-body">
          <!-- Total Special Expenses -->
          <div class="form-group row border-bottom pb-2">
            <label for="special" class="col-6 col-form-label">
              <b>{{"CASES.single.special-expenditure" | translate}}</b>
            </label>
            <div class="col-6">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input [value]="totalSpecialExpenses" type="number" class="form-control" id="special" disabled>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <h4>{{ 'CASES.details.special-expenses' | translate }}</h4>
            <button mat-button mat-raised-button color="primary" (click)="addSpecialExpenses()">
              + {{ 'CASES.details.special-expenses-add' | translate }}
            </button>
          </div>
          <ng-container *ngFor="let formGroup of getFormArray('special_expenses').controls let i = index"
                        formArrayName="special_expenses">
            <div class="row" [formGroupName]="i" style="padding-bottom: 10px">
              <!-- Label -->
              <div class="col-5">
                <app-input type="text" formControlName="label" [label]="'PAYMENTS.label' | translate"
                           [extraLabel]="false" [fullWidth]="true">
                </app-input>
              </div>
              <!-- Value -->
              <div class="col-5">
                <app-input type="number" formControlName="value" [label]="'CASE_ASSET.model.value' | translate"
                           [extraLabel]="false" [fullWidth]="true"
                           (ngModelChange)="updateTotalExpenses('special_expenses')">
                </app-input>
              </div>
              <!-- Remove Expense -->
              <div class="col-2 d-flex justify-content-center">
                <button mat-icon-button color="warn" (click)="removeSpecialExpenses(i)">
                  <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </div>
    <!-- Transport, Housekeeping, Other -->
    <div class="col-6">
      <!-- Transport -->
      <div class="card shadow mb-4">
        <div class="card-body">
          <!-- Total Transport Expenses -->
          <div class="form-group row border-bottom pb-2">
            <label for="travel" class="col-6 col-form-label"><b>{{"CASES.single.travel" | translate}}</b></label>
            <div class="col-6">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input [value]="totalTransportExpenses" type="number" class="form-control" id="travel" disabled>
              </div>
            </div>
          </div>
          <!-- Essential Hire Purchase -->
          <app-input type="number" formControlName="essential_hire_purchase"
                     [label]="'CASES.single.hire-purchase' | translate" [extraLabel]="true" [fullWidth]="true"
                     (ngModelChange)="updateTotalExpenses('transport')">
          </app-input>
          <!-- Travel Public Transport -->
          <app-input type="number" formControlName="travel_public_transport"
                     [label]="'CASES.single.public-transport' | translate" [extraLabel]="true" [fullWidth]="true"
                     (ngModelChange)="updateTotalExpenses('transport')">
          </app-input>
          <!-- Travel Car Insurance -->
          <app-input type="number" formControlName="travel_car_insurance"
                     [label]="'CASES.single.car-insurance' | translate" [extraLabel]="true" [fullWidth]="true"
                     (ngModelChange)="updateTotalExpenses('transport')">
          </app-input>
          <!-- Travel Vehicle Tax -->
          <app-input type="number" formControlName="travel_vehicle_tax"
                     [label]="'CASES.single.vehicle-tax' | translate" [extraLabel]="true" [fullWidth]="true"
                     (ngModelChange)="updateTotalExpenses('transport')">
          </app-input>
          <!-- Travel Fuel -->
          <app-input type="number" formControlName="travel_fuel" [label]="'CASES.single.fuel' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('transport')">
          </app-input>
          <!-- ITV -->
          <app-input type="number" formControlName="itv" [label]="'CASES.single.itv' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('transport')">
          </app-input>
        </div>
      </div>
      <!-- Housekeeping -->
      <div class="card shadow mb-4">
        <div class="card-body">
          <!-- Total Housekeeping Expenses -->
          <div class="form-group row border-bottom pb-2">
            <label for="housekeeping"
                   class="col-6 col-form-label"><b>{{"CASES.single.housekeeping" | translate}}</b></label>
            <div class="col-6">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input [value]="totalHousekeepingExpenses" type="number" class="form-control" id="housekeeping"
                       disabled>
              </div>
            </div>
          </div>
          <!-- Housekeeping Food and Groceries-->
          <app-input type="number" formControlName="housekeeping_food_and_groceries"
                     [label]="'CASES.single.food' | translate" [extraLabel]="true" [fullWidth]="true"
                     (ngModelChange)="updateTotalExpenses('housekeeping')">
          </app-input>
          <!-- Housekeeping Cleaning -->
          <app-input type="number" formControlName="housekeeping_cleaning" [label]="'CASES.single.cleaning' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('housekeeping')">
          </app-input>
          <!-- Housekeeping Newspapers -->
          <app-input type="number" formControlName="housekeeping_newspapers"
                     [label]="'CASES.single.newspapers' | translate" [extraLabel]="true" [fullWidth]="true"
                     (ngModelChange)="updateTotalExpenses('housekeeping')">
          </app-input>
          <!-- Housekeeping Alcohol -->
          <app-input type="number" formControlName="housekeeping_alcohol" [label]="'CASES.single.alcohol' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('housekeeping')">
          </app-input>
          <!-- Housekeeping Laundry -->
          <app-input type="number" formControlName="housekeeping_laundry" [label]="'CASES.single.laundry' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('housekeeping')">
          </app-input>
          <!-- Housekeeping Clothing Footwear -->
          <app-input type="number" formControlName="housekeeping_clothing_footwear"
                     [label]="'CASES.single.clothing' | translate" [extraLabel]="true" [fullWidth]="true"
                     (ngModelChange)="updateTotalExpenses('housekeeping')">
          </app-input>
        </div>
      </div>
      <!-- Other Expenses -->
      <div class="card shadow mb-4">
        <div class="card-body">
          <!-- Total Other Expenses -->
          <div class="form-group row border-bottom pb-2">
            <label for="other_expenditure" class="col-6 col-form-label">
              <b>{{"CASES.single.other-expenditures" | translate}}</b>
            </label>
            <div class="col-6">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input [value]="totalOtherExpenses" type="number" class="form-control" id="other_expenditure" disabled>
              </div>
            </div>
          </div>
          <!-- Other Hobbies and Leisure Sports -->
          <app-input type="number" formControlName="other_hobbies_leisure_sport"
                     [label]="'CASES.single.hobbies/leisure' | translate" [extraLabel]="true" [fullWidth]="true"
                     (ngModelChange)="updateTotalExpenses('other')">
          </app-input>
          <!-- Other Other -->
          <app-input type="number" formControlName="other_other" [label]="'CASES.single.other-postage' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('other')">
          </app-input>
          <!-- Medications -->
          <app-input type="number" formControlName="medications" [label]="'CASES.single.medications' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('other')">
          </app-input>
          <!-- Medical Expenses -->
          <app-input type="number" formControlName="medical_expenses"
                     [label]="'CASES.single.medical-expenses' | translate" [extraLabel]="true" [fullWidth]="true"
                     (ngModelChange)="updateTotalExpenses('other')">
          </app-input>
          <!-- Pet Expenses -->
          <app-input type="number" formControlName="pet_expenses" [label]="'CASES.single.pet-expenses' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('other')">
          </app-input>
          <!-- Cosmetics and Beauty -->
          <app-input type="number" formControlName="cosmetics_and_beauty"
                     [label]="'CASES.single.cosmetics-and-beauty' | translate" [extraLabel]="true" [fullWidth]="true"
                     (ngModelChange)="updateTotalExpenses('other')">
          </app-input>
          <!-- Legal Fees -->
          <app-input type="number" formControlName="legal_fees" [label]="'CASES.single.legal-fees' | translate"
                     [extraLabel]="true" [fullWidth]="true" (ngModelChange)="updateTotalExpenses('other')">
          </app-input>
        </div>
      </div>
    </div>
  </div>
</form>
