import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CaseListFilter } from '../../../../../../_base-shared/models/Case/CaseListFilter';
import { CaseService } from '../../case/case.service';
import { REPORT_DATA_TEMPLATE } from '../../reports/financial-report-list/financial-report-list.component';
import { DistributionReportModalComponent } from '../distribution-report-modal/distribution-report-modal.component';
import { DistributionService } from '../distribution.service';

@Component({
  selector:    'app-distribution-report-list',
  templateUrl: './distribution-report-list.component.html',
  styles: [
    `
      table {
        width: 100%;
      }
      .ml-20 {
        margin-left: 20px;
      }
      .mr-20 {
        margin-right: 20px;
      }

      table {
        table-layout: fixed;
        width: 100%;
      }

      th, td {
        width: 1%;
        white-space: nowrap;
      }

      th, td {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      th, td {
        min-width: 150px; /* Adjust this value as needed */
      }

    `
  ]
})
export class DistributionReportListComponent implements OnInit {
  public caseListFilter: CaseListFilter;
  // tslint:disable-next-line:max-line-length

  public performanceReports: Array<{
    reportType: 'claims' | 'scheduled_fee' | 'payments_received',
    description: string,
    title: string,
    enabled: boolean,
    reportGroup: 'performance'
  }>;
  public fundingReports: Array<{
    reportType: 'funding_claims' | 'funding_schedule_fee' | 'pledge',
    description: string,
    title: string,
    enabled: boolean,
    reportGroup: 'funding'
  }>;

  public isLoading = 0;
  public casesCount: number;
  public displayedColumns: string[] = ['reportName', 'description', 'actions'];
  public performanceDataSource: MatTableDataSource<any>;
  public fundingDataSource: MatTableDataSource<any>;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.buildReportOptions();
    this.caseListFilter = {has_distribution: 1, select_all: 1, only_count: 1};
  }

  private buildReportOptions(): void {
    this.performanceReports = [
      {
        title:       'DISTRIBUTION.report.claims.label',
        description: 'DISTRIBUTION.report.claims.description',
        reportType:  'claims',
        enabled:     true,
        reportGroup: 'performance',
      },
      {
        title:       'DISTRIBUTION.report.scheduled_fee.label',
        description: 'DISTRIBUTION.report.funding_scheduled_fee.description',
        reportType:  'scheduled_fee',
        enabled:     true,
        reportGroup: 'performance',
      },
      {
        title:       'DISTRIBUTION.report.payments_received.label',
        description: 'DISTRIBUTION.report.payments_received.description',
        reportType:  'payments_received',
        enabled:     true,
        reportGroup: 'performance',
      },
    ];

    this.fundingReports = [
      {
        title:       'DISTRIBUTION.report.funding_claims.label',
        description: 'DISTRIBUTION.report.funding_claims.description',
        reportType:  'funding_claims',
        enabled:     true,
        reportGroup: 'funding',
      },
      {
        title:       'DISTRIBUTION.report.funding_scheduled_fee.label',
        description: 'DISTRIBUTION.report.funding_scheduled_fee.description',
        reportType:  'funding_schedule_fee',
        enabled:     true,
        reportGroup: 'funding',
      },
      {
        title:       'DISTRIBUTION.report.pledge_report.label',
        description: 'DISTRIBUTION.report.pledge_report.description',
        reportType:  'pledge',
        enabled:     true,
        reportGroup: 'funding',
      },
    ];
    this.performanceDataSource = new MatTableDataSource(this.performanceReports);
    this.fundingDataSource = new MatTableDataSource(this.fundingReports);
  }

  public openDistributionModal($event, reportType, reportGroup): void {
    $event.preventDefault();
    console.log(reportType);
    const dialogRef = this.dialog.open(DistributionReportModalComponent, {
      width:  '50%',
      maxHeight: '100vh',
      data:   {
        reportType,
        reportGroup,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}
