import { NgModule } from '@angular/core';
import { CalendarCommonModule, CalendarDayModule, CalendarMonthModule, CalendarWeekModule } from 'angular-calendar';
import { NgChartsModule } from 'ng2-charts';
import { SharedModule } from '../../_shared/shared.module';
import { DashboardComponent } from './dashboard.component';
import { HeadsUpDashRoutingModule } from './heads-up-dash/heads-up-dash-routing.module';
import { HeadsUpDashComponent } from './heads-up-dash/heads-up-dash.component';
import { MiDashboardCustomerRoutingModule } from './mi-dashboard-customer/mi-dashboard-customer-routing.module';
import { MiDashboardCustomerComponent } from './mi-dashboard-customer/mi-dashboard-customer.component';
import { MiDashboardDetailsModule } from './mi-dashboard-details/mi-dashboard-detail.module';
import { MiDashboardRoutingModule } from './mi-dashboard/mi-dashboard-routing.module';
import { MiDashboardComponent } from './mi-dashboard/mi-dashboard.component';
import { PackagerDashboardRoutingModule } from './packager-dashboard/packager-dashboard-routing.module';
import { PackagerDashboardComponent } from './packager-dashboard/packager-dashboard.component';
import { SalesLeaderboardPageRoutingModule } from './sales-leaderboard-page/sales-leaderboard-page-routing.module';
import { SalesLeaderboardPageComponent } from './sales-leaderboard-page/sales-leaderboard-page.component';
import { SalesLeaderboardComponent } from './sales-leaderboard/sales-leaderboard.component';
import { TaskDashboardModule } from './task-dashboard/task-dashboard.module';
import { VerifierDashboardRoutingModule } from './verifier-dashboard/verifier-dashboard-routing.module';
import { VerifierDashboardComponent } from './verifier-dashboard/verifier-dashboard.component';
import { CaseManagerDashboardComponent } from './case-manager-dashboard/case-manager-dashboard.component';
import {CaseManagerDashboardRoutingModule} from './case-manager-dashboard/case-manager-dashboard-routing.module';
import { AdvisorLeaderboardComponent } from './v2/sales-leaderboard/advisor-leaderboard/advisor-leaderboard.component';
import { AdvisorLeaderboardRoutingModule} from './v2/sales-leaderboard/advisor-leaderboard/advisor-leaderboard-routing.module';

@NgModule({
  declarations: [
    DashboardComponent,
    SalesLeaderboardComponent,
    SalesLeaderboardPageComponent,
    MiDashboardComponent,
    MiDashboardCustomerComponent,
    VerifierDashboardComponent,
    HeadsUpDashComponent,
    PackagerDashboardComponent,
    CaseManagerDashboardComponent,
    AdvisorLeaderboardComponent
  ],
  imports:      [
    SharedModule,
    NgChartsModule,
    TaskDashboardModule,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarDayModule,
    CalendarCommonModule,
    MiDashboardDetailsModule,
    MiDashboardRoutingModule,
    MiDashboardCustomerRoutingModule,
    SalesLeaderboardPageRoutingModule,
    VerifierDashboardRoutingModule,
    HeadsUpDashRoutingModule,
    PackagerDashboardRoutingModule,
    CaseManagerDashboardRoutingModule,
    AdvisorLeaderboardRoutingModule
  ],
  exports:      [
    DashboardComponent,
  ],
})
export class DashboardModule {
}
