import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { Affiliate } from '../../../../../../../_base-shared/models/Affiliate/Affiliate';
import { CaseListFilter } from '../../../../../../../_base-shared/models/Case/CaseListFilter';
import { DepartmentCategory } from '../../../../../../../_base-shared/models/Department/DepartmentCategory';
import { DistributionBatch } from '../../../../../../../_base-shared/models/Distribution/DistributionBatch';
import { Court } from '../../../../../../../_base-shared/models/Entity/Court';
import { Creditor } from '../../../../../../../_base-shared/models/Entity/Creditor';
import { EntityAdministrator } from '../../../../../../../_base-shared/models/Entity/EntityAdministrator';
import { Notary } from '../../../../../../../_base-shared/models/Entity/Notary';
import { Solicitor } from '../../../../../../../_base-shared/models/Entity/Solicitor';
import { Packager } from '../../../../../../../_base-shared/models/Packager/Packager';
import { Product } from '../../../../../../../_base-shared/models/Product';
import { CallStatus } from '../../../../../../../_base-shared/models/Status/CallStatus';
import { CaseInvoiceStatus } from '../../../../../../../_base-shared/models/Status/CaseInvoiceStatus';
import { PackagerStatus } from '../../../../../../../_base-shared/models/Status/PackagerStatus';
import { PaymentStatus } from '../../../../../../../_base-shared/models/Status/PaymentStatus';
import { Status } from '../../../../../../../_base-shared/models/Status/Status';
import { StatusCategory } from '../../../../../../../_base-shared/models/Status/StatusCategory';
import { Team } from '../../../../../../../_base-shared/models/User/Team';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../../environments/environment';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { PackagerService } from '../../../../packager/packager.service';
import { AdministratorsService } from '../../../address-book/administrators/administrators.service';
import { CourtService } from '../../../address-book/court/court.service';
import { NotariesService } from '../../../address-book/notaries/notaries.service';
import { SolicitorsService } from '../../../address-book/solicitors/solicitors.service';
import { AdminPackagerService } from '../../../admin-packager/admin-packager.service';
import { AffiliateService } from '../../../affiliate/affiliate.service';
import { CallStatusService } from '../../../call-status/call-status.service';
import { CreditorService } from '../../../creditor/creditor.service';
import { DepartmentService } from '../../../department/department.service';
import { DistributionBatchService } from '../../../distribution/distribution-batch.service';
import { PaymentStatusService } from '../../../payment-status/payment-status.service';
import { StatusPickerTrait } from '../../../status/status-picker.trait';
import { StatusService } from '../../../status/status.service';
import { TeamService } from '../../../team/team.service';
import { ProductService } from '../../product.service';

const filterMappings = {
  case:                     [
    'Statuses',
    'Payment Statuses',
    'Call Statuses',
    'Case Invoice Statuses',
    'Products',
    'Product type',
    'Affiliates',
    'Creditors',
    'Last Action',
    'Has distribution',
    'Batch ID',
    'Teams',
    'Amount Paid',
    'Debt level',
    'Outstanding Balance',
    'User Department Assignments',
    'Affiliate Users',
    'Debt status',
    'Verifier',
    'Legal Advisor',
    'Case Manager',
    'Customer Contact Agent',
    'Creditor Negotiation',
    'Notary Manager',
    'Lawyer',
    'Draft Manager',
    'Collections Agent',
    'Creditor Negotiator',
    'Finance advisor',
    'Personal Finance Manager',
    'Packagers',
    'Digital signature',
    'Volume Filter',
    'No Department Assigned'
  ],
  affiliate_case:           [
    'Statuses',
    'Payment Statuses',
    'Case Invoice Statuses',
    'Affiliates',
    'Affiliate Users',
    'Product type',
    'Packagers',
    'Volume Filter'
  ],
  customer_contact:         [
    'Statuses',
    'Payment Statuses',
    'Call Statuses',
    'Case Invoice Statuses',
    'Products',
    'Product type',
    'Affiliates',
    'Creditors',
    'Last Action',
    'Has distribution',
    'Batch ID',
    'Teams',
    'Amount Paid',
    'Days No Contact',
    'Days No Contact Channels',
    'User Department Assignments',
    'Affiliate Users',
    'Debt status',
    'Verifier',
    'Legal Advisor',
    'Case Manager',
    'Customer Contact Agent',
    'Creditor Negotiation',
    'Notary Manager',
    'Lawyer',
    'Draft Manager',
    'Collections Agent',
    'Creditor Negotiator',
    'Finance advisor',
    'Personal Finance Manager',
    'Packagers',
    'Digital signature'
  ],
  packager_non_transferred: [
    'Statuses',
    'Payment Statuses',
    'Call Statuses',
    'Case Invoice Statuses',
    'Amount Paid',
    'Affiliate Users',
    'Verifier',
    'Legal Advisor',
    'Product type',
    'Packagers'
  ],
  legal:                    [
    'Statuses',
    'Payment Statuses',
    'Call Statuses',
    'Case Invoice Statuses',
    'Affiliate Users',
    'Debt status',
    'Verifier',
    'Legal Advisor',
    'Case Manager',
    'Customer Contact Agent',
    'Creditor Negotiation',
    'Notary Manager',
    'Lawyer',
    'Draft Manager',
    'Collections Agent',
    'Creditor Negotiator',
    'Finance advisor',
    'Personal Finance Manager',
    'Product type',
    'Packagers',
    'Digital signature',
    'Notary',
    'Administrators',
    'Courts',
    'Solicitors',
    'City',
    'Region',
    'Notary Appointed'
  ],
};

@Component({
  selector:    'app-case-list-filters',
  templateUrl: './case-list-filters.component.html',
  styleUrls:   ['./case-list-filters.component.scss'],
})
export class CaseListFiltersComponent extends StatusPickerTrait implements OnInit, OnDestroy {
  @Input() onlyRelated: boolean                                                                        = null;
  @Input() type: 'case' | 'legal' | 'customer_contact' | 'affiliate_case' | 'packager_non_transferred' = null;
  @Input() newPartner: 0 | 1                                                                           = 0;
  @Output() filtersReady                                                                               = new EventEmitter<boolean>();
  @Output() submitFilters                                                                              = new EventEmitter<CaseListFilter>();

  public isLoading                                    = 0;
  public isLoadingFilters                             = false;
  public form: UntypedFormGroup;
  public statusFormControlName                        = 'statuses';
  public dateRadioControl: UntypedFormControl         = new UntypedFormControl();
  public statusCategoryControl: UntypedFormControl    = new UntypedFormControl([]);
  public statusControl: UntypedFormControl            = new UntypedFormControl([]);
  public affiliateCategoryControl: UntypedFormControl = new UntypedFormControl([]);

  public caseListFilter: CaseListFilter;
  public users: Array<User>                              = [];
  public products: Array<Product>                        = [];
  public affiliates: Array<Affiliate>                    = [];
  public creditors: Array<Creditor>                      = [];
  public allStatuses: Array<Status>                      = [];
  public statusCategories: Array<StatusCategory>         = [];
  public filteredStatusCategories: Array<StatusCategory> = [];
  public paymentStatuses: Array<PaymentStatus>           = [];
  public callStatuses: Array<CallStatus>                 = [];
  public invoiceStatuses: Array<CaseInvoiceStatus>       = [];
  public notaries: Array<Notary>                         = [];
  public administrators: Array<EntityAdministrator>      = [];
  public courts: Array<Court>                            = [];
  public solicitors: Array<Solicitor>                    = [];
  public teams: Array<Team>                              = [];
  public departmentCategories: Array<DepartmentCategory> = [];
  public filteredCategories: Array<DepartmentCategory>   = [];

  public notificationChannels: Array<AppSelectOption>    = [];
  public distributionBatches: Array<DistributionBatch>   = [];
  public hasDistributionOptions: Array<AppSelectOption>  = [];
  public productTypeGroups: Array<AppSelectOption>       = [];
  public affiliateUsers: Array<User>                     = [];
  public debtStatusOptions: Array<AppSelectOption>       = [];
  public packagers: Array<Packager>                      = [];
  public packagerStatuses: Array<PackagerStatus>         = [];
  public digitalSignatureOptions: Array<AppSelectOption> = [];
  public volumeBonusOptions: Array<AppSelectOption>      = [];
  public notaryAppointedOptions: Array<AppSelectOption>  = [];

  private formChangeSubscriber: Subscription;
  private localStorageName: string;
  public authUser: User;

  selectedFilters: any[] = [];

  public affiliateCategories: Array<any>               = [];
  public filteredAffiliateCategories: Array<Affiliate> = [];
  public afiliateFormControlName                       = 'campaigns';
  public allAffiliates: Array<Affiliate>               = [];

  constructor(private fb: UntypedFormBuilder,
              private globalEventsService: MainGlobalEventService,
              private translate: TranslateService,
              private statusService: StatusService,
              private creditorService: CreditorService,
              private notaryService: NotariesService,
              private administratorsService: AdministratorsService,
              private courtService: CourtService,
              private solicitorsService: SolicitorsService,
              private paymentStatusService: PaymentStatusService,
              private affiliateService: AffiliateService,
              private departmentService: DepartmentService,
              private productService: ProductService,
              private distributionBatchService: DistributionBatchService,
              private teamService: TeamService,
              private callStatusService: CallStatusService,
              private packagerService: AdminPackagerService,
              private generalPackagerService: PackagerService) {
    super('statuses', true, true);
  }

  ngOnInit(): void {
    if (this.type === 'legal') {
      this.localStorageName = 'case-legal-list-filters_v5.0.0';
    } else if (this.type === 'customer_contact') {
      this.localStorageName = 'case-customer_contact-list-filters_v5.0.0';
    } else if (this.type === 'affiliate_case') {
      this.localStorageName = 'affiliate-case-list-filters_v5.0.0';
    } else if (this.type === 'packager_non_transferred') {
      this.localStorageName = 'packager_non_transferred-case-list-filters_v5.0.0';
    } else {
      this.localStorageName = 'case-list-filters_v5.0.0';
    }
    this.debtStatusOptions           = this.getDebtStatusOptions();
    // todo: get filters from query string
    this.caseListFilter              = this.getFiltersFromStorage();
    this.caseListFilter.only_related = this.onlyRelated !== null ?
      this.onlyRelated :
      this.caseListFilter.only_related;
    this.filtersReady.emit(false);
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      if (this.caseListFilter.only_related) {
        this.caseListFilter.user_department_assignments = this.authUser.department_assignments.map(
          departmentAssignment => departmentAssignment.department_id,
        );
      }
      this.buildForm(this.caseListFilter, this.authUser);
    }));
    this.buildDigitalSignatureOptions();
    this.buildNotaryAppointedOptions();
    this.buildSelectOptions();
    this.productTypeGroups = this.getProductTypeGroups();
    this.getVolumeBonusOptions();

    const excludeIsDFAff     = this.type === 'affiliate_case' ? 1 : 0;
    const dataSortedByNameEs = {
      sort_by:    'name_es',
      sort_order: 'asc',
      select_all: 1,
      all:        1
    };
    const dataSortedByName   = {
      sort_by:    'name',
      sort_order: 'asc',
      all:        1,
      select_all: 1
    };

    const departmentCategories$     = this.departmentService.categoryIndex(dataSortedByNameEs, ['departments.users'])
      .pipe(catchError(error => of({data: []})));
    const fetchProducts$            = this.productService.index(dataSortedByNameEs)
      .pipe(catchError(error => of({data: []})));
    const fetchAffiliates$          = this.affiliateService.index({
      ...dataSortedByName,
      exclude_is_df_affiliate: excludeIsDFAff
    }, ['campaigns']).pipe(catchError(error => of({data: []})));
    const fetchCreditors$           = this.creditorService.index(dataSortedByName).pipe(catchError(error => of({data: []})));
    const fetchStatuses$            = this.statusService.indexCategoriesWithStatuses(dataSortedByNameEs).pipe(catchError(error => of({data: []})));
    const fetchPaymentStatuses$     = this.paymentStatusService.index(dataSortedByName).pipe(catchError(error => of({data: []})));
    const fetchCallStatuses$        = this.callStatusService.index(dataSortedByNameEs).pipe(catchError(error => of({data: []})));
    const fetchCaseInvoiceStatuses$ = this.statusService.indexInvoiceStatuses(dataSortedByNameEs).pipe(catchError(error => of({data: []})));
    const fetchNotaries$            = this.notaryService.index(dataSortedByName).pipe(catchError(error => of({data: []})));
    const fetchAdministrators$      = this.administratorsService.index(dataSortedByName).pipe(catchError(error => of({data: []})));
    const fetchCourt$               = this.courtService.index(dataSortedByName).pipe(catchError(error => of({data: []})));
    const fetchSolicitors$          = this.solicitorsService.index(dataSortedByName).pipe(catchError(error => of({data: []})));
    const fetchDistributionBatches$ = this.distributionBatchService.index(dataSortedByName).pipe(catchError(error => of({data: []})));
    const fetchTeams$               = this.teamService.index(dataSortedByNameEs).pipe(catchError(error => of({data: []})));
    const getAffiliateUsers$        = this.affiliateService.getAffiliateUsers(dataSortedByName).pipe(catchError(error => of({data: []})));
    const fetchPackagers$           = this.packagerService.index(dataSortedByNameEs).pipe(catchError(error => of({data: []})));
    const fetchPackagerStatuses$    = this.generalPackagerService.getPackagerStatuses(dataSortedByNameEs).pipe(catchError(error => of({data: []})));

    this.isLoadingFilters = true;
    const resources$      = forkJoin([departmentCategories$, fetchProducts$, fetchAffiliates$, fetchCreditors$,
      fetchStatuses$, fetchPaymentStatuses$, fetchCallStatuses$, fetchCaseInvoiceStatuses$, fetchNotaries$, fetchAdministrators$,
      fetchCourt$, fetchSolicitors$, fetchDistributionBatches$, fetchTeams$, getAffiliateUsers$, fetchSolicitors$, fetchPackagers$,
      fetchPackagerStatuses$]
    )
      .pipe(finalize(() => this.isLoadingFilters = false))
      .subscribe(result => {
        this.resolveDepartmentCategories(result[0].data);
        console.log(this.departmentCategories);
        this.resolveStatuses(result[4].data);
        this.resolveAffiliates(result[2].data);

        this.products            = result[1].data;
        this.creditors           = result[3].data;
        this.paymentStatuses     = result[5].data;
        this.callStatuses        = result[6].data;
        this.invoiceStatuses     = result[7].data;
        this.notaries            = result[8].data;
        this.administrators      = result[9].data;
        this.courts              = result[10].data;
        this.solicitors          = result[11].data;
        this.distributionBatches = result[12].data;
        this.teams               = result[13].data;
        this.affiliateUsers      = result[14].data;
        this.solicitors          = result[15].data;
        this.packagers           = result[16].data;
        this.packagerStatuses    = result[17].data;
        this.selectedFilters     = this.getAvailableFilters(filterMappings[this.type], false);
        console.log(this.selectedFilters);
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.formChangeSubscriber.unsubscribe();
  }

  private getFiltersFromStorage(): CaseListFilter {
    const filter                              = new CaseListFilter();
    let data                                  = JSON.parse(localStorage.getItem(this.localStorageName));
    data                                      = data ? data : {};
    filter.search                             = data.search ? data.search : null;
    filter.activity_status                    = data.activity_status !== undefined ? data.activity_status :
      (this.type === 'customer_contact' ? 'active' : 'all');
    filter.start_date                         = data.start_date ? new Date(data.start_date) : null;
    filter.end_date                           = data.end_date ? new Date(data.end_date) : null;
    filter.status_date_type                   = data.status_date_type ? data.status_date_type : 'sign_up';
    filter.amount_paid                        = data.amount_paid ? data.amount_paid : null;
    filter.debt_level                         = data.debt_level ? data.debt_level : null;
    filter.outstanding_balance                = data.outstanding_balance ? data.outstanding_balance : null;
    filter.last_action                        = data.last_action ? data.last_action : null;
    filter.has_property                       = data.has_property !== undefined ? data.has_property : 0;
    filter.has_pending_docs                   = data.has_pending_docs !== undefined ? data.has_pending_docs : 0;
    filter.has_public_debt                    = data.has_public_debt !== undefined ? data.has_public_debt : 0;
    filter.region                             = data.region ? data.region : null;
    filter.city                               = data.city ? data.city : null;
    filter.case_distribution_status           = data.case_distribution_status ? data.case_distribution_status : null;
    filter.distribution_batch_statuses        = data.distribution_batch_statuses ?
      data.distribution_batch_statuses :
      null;
    filter.distribution_batch_ids             = data.distribution_batch_ids ? data.distribution_batch_ids : null;
    filter.team_ids                           = data.team_ids ? data.team_ids : null;
    filter.select_all                         = data.select_all !== undefined ? data.select_all : 0;
    filter.only_related                       = data.only_related !== undefined ? data.only_related : 0;
    // Case General Relations
    filter.products                           = data.products ? data.products : [];
    filter.affiliates                         = data.affiliates ? data.affiliates : [];
    filter.statuses                           = data.statuses ? data.statuses : [];
    filter.campaigns                          = data.campaigns ? data.campaigns : [];
    filter.payment_statuses                   = data.payment_statuses ? data.payment_statuses : [];
    filter.call_statuses                      = data.call_statuses ? data.call_statuses : [];
    filter.case_invoice_status_ids            = data.case_invoice_status_ids ? data.case_invoice_status_ids : [];
    filter.creditors                          = data.creditors ? data.creditors : [];
    filter.debt_status                        = data.debt_status ? data.debt_status : [];
    // Case Roles
    filter.user_department_assignments        = data.user_department_assignments ?
      data.user_department_assignments :
      [];
    filter.verifier                           = data.verifier ? data.verifier : [];
    filter['legal-advisor']                   = data['legal-advisor'] ? data['legal-advisor'] : [];
    filter['case-manager']                    = data['case-manager'] ? data['case-manager'] : [];
    filter['customer-contact']                = data['customer-contact'] ? data['customer-contact'] : [];
    filter['creditor-negotiator']             = data['creditor-negotiator'] ? data['creditor-negotiator'] : [];
    filter['notary-manager']                  = data['notary-manager'] ? data['notary-manager'] : [];
    filter.lawyer                             = data.lawyer ? data.lawyer : [];
    filter['draft-manager']                   = data['draft-manager'] ? data['draft-manager'] : [];
    filter['collections-agent']               = data['collections-agent'] ? data['collections-agent'] : [];
    filter['unifye-creditor-negotiator']      = data['unifye-creditor-negotiator'] ?
      data['unifye-creditor-negotiator'] :
      [];
    filter['unifye-finance-advisor']          = data['unifye-finance-advisor'] ? data['unifye-finance-advisor'] : [];
    filter['unifye-personal-finance-manager'] = data['unifye-personal-finance-manager'] ?
      data['unifye-personal-finance-manager'] :
      [];
    // Case Entities
    filter.notaries                           = data.notaries ? data.notaries : [];
    filter.administrators                     = data.administrators ? data.administrators : [];
    filter.courts                             = data.courts ? data.courts : [];
    filter.solicitors                         = data.solicitors ? data.solicitors : [];
    filter.notary_appointed                   = data.notary_appointed !== undefined ? data.notary_appointed : null;
    // Extra
    filter.days_no_contact                    = data.days_no_contact ? data.days_no_contact : null;
    filter.days_no_contact_channels           = data.days_no_contact_channels ? data.days_no_contact_channels : [];
    filter.product_group                      = data.product_group ? data.product_group : [];
    filter.product_group_slugs                = data.product_group_slugs ? data.product_group_slugs : [];
    filter.packagers                          = data.packagers ? data.packagers : [];
    filter.packager_statuses                  = data.packager_statuses ? data.packager_statuses : [];
    filter.product_ids                        = data.product_ids ? data.product_ids : [];
    filter.affiliate_user_ids                 = data.affiliate_user_ids ? data.affiliate_user_ids : [];
    filter.new_partner                        = this.newPartner !== undefined ? this.newPartner : false;
    filter.digital_signature                  = data.digital_signature ? data.digital_signature : null;
    filter.unassigned_department_ids          = data.unassigned_department_ids ? data.unassigned_department_ids : [];
    filter.volume_filter                      = data.volume_filter ? data.volume_filter : [];
    filter.has_redsys_has_no_cashflows_token  = data.has_redsys_has_no_cashflows_token !== undefined ? data.has_redsys_has_no_cashflows_token : 0

    if (this.type === 'case' || this.type === 'affiliate_case' || this.type === 'packager_non_transferred') {
      filter.page_type = this.type;
    }

    return filter;
  }

  private buildForm(caseListFilter: CaseListFilter, authUser: User): void {
    if (this.formChangeSubscriber) {
      this.formChangeSubscriber.unsubscribe();
    }

    this.form = this.fb.group({
      search:                  [caseListFilter.search],
      start_date:              [caseListFilter.start_date],
      end_date:                [caseListFilter.end_date],
      activity_status:         [caseListFilter.activity_status],
      status_date_type:        [caseListFilter.status_date_type],
      amount_paid:             [caseListFilter.amount_paid],
      debt_level:              [caseListFilter.debt_level],
      outstanding_balance:     [caseListFilter.outstanding_balance],
      last_action:             [caseListFilter.last_action],
      has_property:            [caseListFilter.has_property],
      has_pending_docs:        [caseListFilter.has_pending_docs],
      has_public_debt:         [caseListFilter.has_public_debt],
      region:                  [caseListFilter.region],
      city:                    [caseListFilter.city],
      products:                [caseListFilter.products],
      affiliates:              [caseListFilter.affiliates],
      creditors:               [caseListFilter.creditors],
      statuses:                [caseListFilter.statuses],
      campaigns:               [caseListFilter.campaigns],
      payment_statuses:        [caseListFilter.payment_statuses],
      call_statuses:           [caseListFilter.call_statuses],
      case_invoice_status_ids: [caseListFilter.case_invoice_status_ids],
      debt_status:             [caseListFilter.debt_status],
      // Distribution
      case_distribution_status:    [caseListFilter.case_distribution_status],
      distribution_batch_statuses: [caseListFilter.distribution_batch_statuses],
      distribution_batch_ids:      [caseListFilter.distribution_batch_ids],
      team_ids:                    [caseListFilter.team_ids],
      select_all:                  [caseListFilter.select_all],
      only_related:                [caseListFilter.only_related],
      // Case Roles
      user_department_assignments:       [caseListFilter.user_department_assignments],
      verifier:                          [caseListFilter.verifier],
      'legal-advisor':                   [caseListFilter['legal-advisor']],
      'case-manager':                    [caseListFilter['case-manager']],
      'customer-contact':                [caseListFilter['customer-contact']],
      'creditor-negotiator':             [caseListFilter['creditor-negotiator']],
      'notary-manager':                  [caseListFilter['notary-manager']],
      lawyer:                            [caseListFilter.lawyer],
      'draft-manager':                   [caseListFilter['draft-manager']],
      'collections-agent':               [caseListFilter['collections-agent']],
      'unifye-creditor-negotiator':      [caseListFilter['unifye-creditor-negotiator']],
      'unifye-finance-advisor':          [caseListFilter['unifye-finance-advisor']],
      'unifye-personal-finance-manager': [caseListFilter['unifye-personal-finance-manager']],
      // Case Entities
      notaries:         [caseListFilter.notaries],
      administrators:   [caseListFilter.administrators],
      courts:           [caseListFilter.courts],
      solicitors:       [caseListFilter.solicitors],
      notary_appointed: [caseListFilter.notary_appointed],
      // Extra
      days_no_contact:                   [caseListFilter.days_no_contact],
      days_no_contact_channels:          [caseListFilter.days_no_contact_channels],
      page_type:                         [this.type],
      product_group:                     [caseListFilter.product_group],
      product_group_slugs:               [caseListFilter.product_group_slugs],
      packagers:                         [caseListFilter.packagers],
      packager_statuses:                 [caseListFilter.packager_statuses],
      product_ids:                       [caseListFilter.product_ids],
      affiliate_user_ids:                [caseListFilter.affiliate_user_ids],
      new_partner:                       [caseListFilter.new_partner],
      digital_signature:                 [caseListFilter.digital_signature],
      unassigned_department_ids:         [caseListFilter.unassigned_department_ids],
      volume_filter:                     [caseListFilter.volume_filter],
      has_redsys_has_no_cashflows_token: [caseListFilter.has_redsys_has_no_cashflows_token],
    });

    this.filtersReady.emit(true);
    this.submitFilters.emit(this.caseListFilter);
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges(): void {
    this.formChangeSubscriber = this.form.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe(res => {
      if (this.form.invalid) {
        return;
      }
      this.caseListFilter = this.form.value;
      // TODO: update filters in query string
      this.storeFiltersToStorage(this.caseListFilter);
      this.submitFilters.emit(this.caseListFilter);
    });
  }

  private resolveDepartmentCategories(result: Array<DepartmentCategory>): void {
    this.departmentCategories = result;
    this.filteredCategories   = this.filterObjectsByType(this.departmentCategories, 'advice');
    if (this.type === 'packager_non_transferred') {
      this.departmentCategories = this.filterObjectsByType(result, 'advice');
    } else if (this.type === 'case' && !this.authUser.packager.master && this.authUser.role.slug === 'agent') {
      this.departmentCategories = this.filterObjectsByType(result, 'advice');
    } else {
      this.departmentCategories = result;
    }
  }

  private filterObjectsByType(objects: any[], type: string): any[] {
    return objects.filter(obj => obj.type === type);
  }

  private resolveStatuses(result): void {
    this.statusCategories         = result;
    this.filteredStatusCategories = result;
    this.statusCategories.forEach(category => {
      this.allStatuses.push(category);
      category.statuses.forEach(status => this.allStatuses.push(status));
    });
    this.setStatusControls(this.form.get(this.statusFormControlName).value);
  }

  private resolveAffiliates(result): void {
    this.affiliates                  = result;
    this.affiliateCategories         = result;
    this.filteredAffiliateCategories = result;
    this.affiliateCategories.forEach(category => {
      this.allAffiliates.push(category);
      category.campaigns.forEach(campaign => this.allStatuses.push(campaign));
    });
    this.setStatusControls(this.form.get(this.statusFormControlName).value);
  }

  private buildSelectOptions(): void {
    this.notificationChannels = [
      {label: 'Email', value: 'email'},
      {label: 'SMS', value: 'sms'},
      {label: 'Call', value: 'call'},
      {label: 'WhatsApp', value: 'whatsapp'},
    ];

    this.hasDistributionOptions = [
      {label: '/', value: null},
      {label: 'Yes', value: 'in_distribution'},
      {label: 'No', value: 'not_in_distribution_and_viable'},
      {label: 'Non-viable', value: 'nonviable'},
    ];
  }

  public clearFilters(): void {
    localStorage.removeItem(this.localStorageName);
    this.caseListFilter = this.getFiltersFromStorage();
    this.filtersReady.emit(false);
    this.buildForm(this.caseListFilter, this.authUser);
    this.dateRadioControl.patchValue(null);
    this.statusCategoryControl.patchValue([]);
    this.statusControl.patchValue([]);
  }

  public patchFilter(name: string, value, options = {}): void {
    this.form.get(name).patchValue(value, options);
  }

  private storeFiltersToStorage(filter: CaseListFilter): void {
    return localStorage.setItem(this.localStorageName, JSON.stringify(filter));
  }

  public clearFormControl($event, name): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.form.get(name).patchValue(null);
  }

  public clearMultiSelect($event, name: string): void {
    $event.stopPropagation();
    this.form.get(name).patchValue([]);
  }

  public dateModifierChange($event): void {
    this.form.get('start_date').setValue(DateTime.local().startOf($event.value).toJSDate());
    this.form.get('end_date').setValue(DateTime.local().endOf($event.value).toJSDate());
  }

  public onlyRelatedChange($event: any): void {
    const myDepartmentAssignments = $event ?
      this.authUser.department_assignments.map(departmentAssignment => departmentAssignment.department_id) :
      [];
    this.form.get('user_department_assignments').patchValue(myDepartmentAssignments);
    if ($event) {
      this.form.get('user_department_assignments').disable({onlySelf: true, emitEvent: false});
    } else {
      this.form.get('user_department_assignments').enable({onlySelf: true, emitEvent: false});
    }
  }

  public dateChanged($event: MatDatepickerInputEvent<any>, formControlName, endDay = false): void {
    if (endDay) {
      const inputValue = $event.value.toString();
      const dt         = DateTime.fromJSDate(new Date(inputValue));
      const endOfDay   = dt.endOf('day');
      const date       = endOfDay.toJSDate();
      this.form.get(formControlName).patchValue(date);
    }
  }

  public selectStatuses(active: boolean): void {
    const selectedStatusIds = [];
    this.allStatuses.forEach(status => {
      if (((active && status.flag_case_active) || (!active && !status.flag_case_active))) {
        selectedStatusIds.push(status.id);
      }
    });
    this.setStatusControls(selectedStatusIds);
    this.form.get(this.statusFormControlName).updateValueAndValidity();
  }

  public userIsAMami(): boolean {
    return environment.DISTRIBUTION_USER_IDS.includes(this.authUser.id);
  }

  public getDebtStatusOptions(): Array<AppSelectOption> {
    return [
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.balance_outstanding'),
        value: 'balance_outstanding',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.balance_received'),
        value: 'balance_received',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.chase_required'),
        value: 'chase_required',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.claim_approved'),
        value: 'claim_approve',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.claim_filed'),
        value: 'claim_filed',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.claim_rejected'),
        value: 'claim_rejected',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.extrajudicial_claim_accepted'),
        value: 'extrajudicial_claim_accepted',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.extrajudicial_claim_rejected'),
        value: 'extrajudicial_claim_rejected',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.extrajudicial_claim_sent'),
        value: 'extrajudicial_claim_sent',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.extrajudicial_claim_unanswered'),
        value: 'extrajudicial_claim_unanswered',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.mandate_rejected'),
        value: 'mandate_rejected',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.mandate_sent'),
        value: 'mandate_sent',
      }
    ];
  }

  private getProductTypeGroups(): Array<AppSelectOption> {
    return [
      {label: 'DM', value: 'dm'},
      {label: 'DGS', value: 'dgs'},
      {label: 'LSO', value: 'lso'}
    ];
  }

  private getVolumeBonusOptions(): void {
    this.volumeBonusOptions = [
      {
        label: this.translate.instant('CASES.list.filters.volume_filter.options.primary_conversion'),
        value: 'primary_conversion'
      },
      {
        label: this.translate.instant('CASES.list.filters.volume_filter.options.secondary_conversion'),
        value: 'secondary_conversion'
      },
      {label: this.translate.instant('CASES.list.filters.volume_filter.options.volume_bonus'), value: 'volume_bonus'}
    ];
  }

  public productGroupUpdated(productGroupSlugs: Array<string>): void {
    const productIds = [];
    productGroupSlugs.forEach(groupSlug => {
      this.products.forEach(product => {
        if (product.group_slug === groupSlug) {
          productIds.push(product.id);
        }
      });
    });
    this.form.get('product_ids').patchValue(productIds);
  }

  private buildDigitalSignatureOptions(): void {
    this.digitalSignatureOptions = [
      {
        label: this.translate.instant('CASES.single.general.status_editor.digital_signature.options.ask'),
        value: 'ask',
      },
      {
        label: this.translate.instant('CASES.single.general.status_editor.digital_signature.options.clave_pin'),
        value: 'clave_pin',
      },
      {
        label: this.translate.instant(
          'CASES.single.general.status_editor.digital_signature.options.digital_certificate'),
        value: 'digital_certificate',
      },
      {
        label: this.translate.instant('CASES.single.general.status_editor.digital_signature.options.none'),
        value: 'None',
      },
    ];
  }

  private buildNotaryAppointedOptions(): void {
    this.notaryAppointedOptions = [
      {
        label: this.translate.instant(
          'SHARED.yes'),
        value: 1,
      },
      {
        label: this.translate.instant('SHARED.no'),
        value: 0,
      }
    ];
  }

  public getAvailableFilters(filterNames: Array<string>, all?: boolean): any {
    const availableFilters  = [
      // {
      //  name:             'Statuses',
      //  formControlName:  this.statusFormControlName,
      //  type:             'select',
      //  label:            'CASES.single.general.status_editor.status',
      //  multiple:         true,
      //  searchable:       true,
      //  fullWidth:        true,
      //  selectOptions:    this.filteredStatusCategories,
      //  optGroupProperty: 'statuses',
      //  optGroupLabel:    'name',
      //  selectLabel:      'name',
      //  selectValue:      'id',
      //  appearance:       'standard',
      // },
      {
        name:            'Payment Statuses',
        formControlName: 'payment_statuses',
        type:            'select',
        label:           'CASES.single.general.status_editor.payment_status',
        multiple:        true,
        fullWidth:       true,
        selectOptions:   this.paymentStatuses,
        selectLabel:     'name',
        selectValue:     'id',
        appearance:      'standard',
      },
      {
        name:            'Call Statuses',
        formControlName: 'call_statuses',
        type:            'select',
        label:           'CONFIG.call-status.header',
        multiple:        true,
        fullWidth:       true,
        selectOptions:   this.callStatuses,
        selectLabel:     'name',
        selectValue:     'id',
        appearance:      'standard',
      },
      {
        name:            'Case Invoice Statuses',
        formControlName: 'case_invoice_status_ids',
        type:            'select',
        label:           'CASES.list.filters.case_invoice_status.label',
        multiple:        true,
        fullWidth:       true,
        selectOptions:   this.invoiceStatuses,
        selectLabel:     'name',
        selectValue:     'id',
        condition:       [1, 22, 27, 34, 36, 2497].includes(this.authUser?.id),
        appearance:      'standard',
      },
      {
        name:            'Products',
        formControlName: 'products',
        type:            'select',
        label:           'PRODUCT.model.name',
        multiple:        true,
        fullWidth:       true,
        selectOptions:   this.products,
        selectLabel:     'name',
        selectValue:     'id',
        appearance:      'standard',
      },
      {
        name:            'Product type',
        formControlName: 'product_group_slugs',
        type:            'select',
        label:           'CONFIG.dialer.product_type',
        multiple:        true,
        fullWidth:       true,
        selectOptions:   this.productTypeGroups,
        selectLabel:     'label',
        selectValue:     'value',
        appearance:      'standard',
      },
      {
        name:            'Affiliates',
        formControlName: 'affiliates',
        type:            'select',
        label:           'CASES.affiliates',
        multiple:        true,
        fullWidth:       true,
        selectOptions:   this.affiliates,
        selectLabel:     'name',
        selectValue:     'id',
        searchable:      true,
        appearance:      'standard',
      },
      {
        name:            'Creditors',
        formControlName: 'creditors',
        type:            'select',
        label:           'CASES.single.creditors.heading',
        multiple:        true,
        fullWidth:       true,
        selectOptions:   this.creditors,
        selectLabel:     'name',
        selectValue:     'id',
        searchable:      true,
        appearance:      'standard',
      },
      {
        name:            'Last Action',
        formControlName: 'last_action',
        type:            'number',
        label:           'Last Action',
        fullWidth:       true,
        appearance:      'standard',
        multiple:        false,
        selectOptions:   [],
        selectLabel:     '',
        selectValue:     '',
        searchable:      false,
      },
      {
        name:            'Has distribution',
        formControlName: 'case_distribution_status',
        type:            'select',
        label:           'CASES.list.filters.has_distribution.label',
        fullWidth:       true,
        selectOptions:   this.hasDistributionOptions,
        selectLabel:     'label',
        selectValue:     'value',
        condition:       this.userIsAMami(),
        appearance:      'standard',
      }, {
        name:            'Batch ID',
        formControlName: 'distribution_batch_ids',
        type:            'select',
        label:           'DISTRIBUTION.batch.selector.distribution_batch_id',
        multiple:        true,
        fullWidth:       true,
        selectOptions:   this.distributionBatches,
        selectLabel:     'name',
        selectValue:     'id',
        condition:       this.userIsAMami(),
        appearance:      'standard',
      },
      {
        name:            'Teams',
        formControlName: 'team_ids',
        type:            'select',
        label:           'TEAM.model_name.plural',
        multiple:        true,
        fullWidth:       true,
        selectOptions:   this.teams,
        selectLabel:     'name',
        selectValue:     'id',
        appearance:      'standard',
      },
      {
        name:            'Amount Paid',
        formControlName: 'amount_paid',
        type:            'number',
        label:           'Amount Paid',
        fullWidth:       true,
        condition:       this.type === 'case' || this.type === 'customer_contact' || this.type ===
                           'packager_non_transferred',
        appearance:      'standard',
      },
      {
        name:            'Debt level',
        formControlName: 'debt_level',
        type:            'number',
        label:           'Debt level',
        fullWidth:       true,
        condition:       this.type === 'case',
        appearance:      'standard',
      },
      {
        name:            'Outstanding Balance',
        formControlName: 'outstanding_balance',
        type:            'number',
        label:           'Outstanding Balance',
        fullWidth:       true,
        condition:       this.type === 'case',
        appearance:      'standard',
      },
      {
        name:            'Days No Contact',
        formControlName: 'days_no_contact',
        type:            'number',
        label:           'CASES.list.filters.days_no_contact.label',
        fullWidth:       true,
        appearance:      'standard',
      },
      {
        name:            'Days No Contact Channels',
        formControlName: 'days_no_contact_channels',
        type:            'select',
        label:           'CASES.list.filters.days_no_contact_channels.label',
        multiple:        true,
        fullWidth:       true,
        selectOptions:   this.notificationChannels,
        selectLabel:     'label',
        selectValue:     'value',
        appearance:      'standard',
      },
      {
        name:             'User Department Assignments',
        formControlName:  'user_department_assignments',
        type:             'select',
        label:            'CASES.list.filters.user_case_roles.label',
        multiple:         true,
        fullWidth:        true,
        selectOptions:    this.departmentCategories,
        selectLabel:      'name',
        selectValue:      'id',
        optGroupProperty: 'departments',
        optGroupLabel:    'name',
        appearance:       'standard',
      },
      {
        name:            'Affiliate Users',
        formControlName: 'affiliate_user_ids',
        type:            'select',
        label:           'AFFILIATES.affiliate_users',
        multiple:        true,
        fullWidth:       true,
        selectOptions:   this.affiliateUsers,
        selectLabel:     'name',
        selectValue:     'id',
        appearance:      'standard',
      },
      {
        name:            'Debt status',
        formControlName: 'debt_status',
        type:            'select',
        label:           'CASES.list.filters.debt_status.label',
        multiple:        true,
        fullWidth:       true,
        selectAll:       true,
        selectOptions:   this.debtStatusOptions,
        selectLabel:     'label',
        selectValue:     'value',
        appearance:      'standard',
      },
      {
        name:            'Packagers',
        formControlName: 'packagers',
        type:            'select',
        label:           'PACKAGER.model_name.singular',
        multiple:        true,
        fullWidth:       true,
        selectAll:       true,
        selectOptions:   this.packagers,
        selectLabel:     'name_en',
        selectValue:     'id',
        appearance:      'standard',
      },
      {
        name:            'Digital signature',
        formControlName: 'digital_signature',
        type:            'select',
        label:           'CASES.single.general.status_editor.digital_signature.label',
        multiple:        true,
        fullWidth:       true,
        selectAll:       true,
        selectOptions:   this.digitalSignatureOptions,
        selectLabel:     'label',
        selectValue:     'value',
        appearance:      'standard',
      },
      {
        name:            'Administrators',
        formControlName: 'administrators',
        type:            'select',
        label:           'LEGAL.administrator',
        multiple:        true,
        fullWidth:       true,
        selectAll:       true,
        selectOptions:   this.administrators,
        selectLabel:     'name',
        selectValue:     'id',
        appearance:      'standard',
      },
      {
        name:            'Courts',
        formControlName: 'courts',
        type:            'select',
        label:           'LEGAL.court',
        multiple:        true,
        fullWidth:       true,
        selectAll:       true,
        selectOptions:   this.courts,
        selectLabel:     'name',
        selectValue:     'id',
        appearance:      'standard',
      },
      {
        name:            'Solicitors',
        formControlName: 'solicitors',
        type:            'select',
        label:           'LEGAL.solicitor',
        multiple:        true,
        fullWidth:       true,
        selectAll:       true,
        selectOptions:   this.solicitors,
        selectLabel:     'name',
        selectValue:     'id',
        appearance:      'standard',
      },
      {
        name:            'City',
        formControlName: 'city',
        type:            'text',
        label:           'ADDRESS.city',
        multiple:        true,
        fullWidth:       true,
        selectAll:       true,
        selectOptions:   [],
        selectLabel:     '',
        selectValue:     '',
        appearance:      'standard',
      },
      {
        name:            'Region',
        formControlName: 'region',
        type:            'text',
        label:           'ADDRESS.region',
        multiple:        true,
        fullWidth:       true,
        selectAll:       true,
        selectOptions:   [],
        selectLabel:     '',
        selectValue:     '',
        appearance:      'standard',
      },
      {
        name:            'Notary Appointed',
        formControlName: 'notary_appointed',
        type:            'select',
        label:           'CASES.list.filters.notary_appointed.label',
        multiple:        true,
        fullWidth:       true,
        selectAll:       true,
        selectOptions:   this.notaryAppointedOptions,
        selectLabel:     'label',
        selectValue:     'value',
        appearance:      'standard',
      },
      {
        name:            'Volume Filter',
        formControlName: 'volume_filter',
        type:            'select',
        label:           'CASES.list.filters.volume_filter.label',
        multiple:        true,
        fullWidth:       true,
        selectAll:       true,
        selectOptions:   this.volumeBonusOptions,
        selectLabel:     'label',
        selectValue:     'value',
        appearance:      'standard',
      },
      {
        name:             'No Department Assigned',
        formControlName:  'unassigned_department_ids',
        type:             'select',
        label:            'CASES.no-department-assigned',
        multiple:         true,
        fullWidth:        true,
        selectAll:        true,
        selectOptions:    this.departmentCategories,
        selectLabel:      'name',
        selectValue:      'id',
        optGroupProperty: 'departments',
        optGroupLabel:    'name',
        appearance:       'standard',
      }
    ];
    const departmentFilters = this.generateDepartmentFilterConfigs(this.departmentCategories);
    const combinedFilters   = [...availableFilters, ...departmentFilters];
    if (all) {
      return combinedFilters;
    } else {
      const filteredFilters = combinedFilters.filter(filter => filterNames.includes(filter.name));
      return filteredFilters;
    }
  }

  private generateDepartmentFilterConfigs(departmentCategories: any[]): any[] {
    const filterConfigs = [];
    departmentCategories.forEach(category => {
      category.departments.forEach(department => {
        const filterConfig = {
          name:            department.name_en,
          formControlName: department.type,
          type:            'select',
          label:           department.name,
          multiple:        true,
          fullWidth:       true,
          selectOptions:   department.users,
          selectLabel:     'name',
          selectValue:     'id',
          appearance:      'standard',
        };
        filterConfigs.push(filterConfig);
      });
    });
    return filterConfigs;
  }

  public updateSelectedAffiliateCategories(selectedAffiliateCategoryIds: Array<number> | number): void {
    const selectedAffiliateCategory = this.affiliateCategoryControl.value ?
      (this.statusMultipleSelection ? [this.affiliateCategoryControl.value] : [this.affiliateCategoryControl.value]) :
      [];

    if (typeof selectedAffiliateCategoryIds === 'number') {
      selectedAffiliateCategoryIds = [selectedAffiliateCategoryIds];
    }

    let filteredAffiliateCategories = [];
    const toSelectStatuses = [];

    if (selectedAffiliateCategoryIds.length) {
      selectedAffiliateCategoryIds.forEach(selectedAffiliateId => {
        const affiliateCategory = this.affiliateCategories.find(category => category.id === selectedAffiliateId);

        if (affiliateCategory) {
          filteredAffiliateCategories.push(affiliateCategory);
          affiliateCategory.campaigns.forEach(campaign => {
            toSelectStatuses.push(campaign.id);
          });
        }
      });
    } else {
      filteredAffiliateCategories = this.affiliateCategories;
    }

    this.filteredAffiliateCategories = filteredAffiliateCategories;
    this.form.get(this.afiliateFormControlName).patchValue(this.statusMultipleSelection ? toSelectStatuses : toSelectStatuses[0]);
  }

}
