import { environment } from '../../../environments/environment';
import { CaseDistribution } from '../../../../../_base-shared/models/Distribution/CaseDistribution';
import { DateTime } from 'luxon';

export class DistributionAmountCalculatorService {
  public vatPercentage = environment.VAT_PERCENTAGE;

  public calculateContractAmount(caseDistribution: CaseDistribution): number {
    let totalContractAmount = 0;
    caseDistribution.case.terms.map(term => {
      totalContractAmount += this.reduceVat(term.amount);
    });

    return totalContractAmount;
  }

  public calculateAmountPaid(caseDistribution: CaseDistribution,
                             submittedAt: Date | string,
                             beforeSubmit: boolean): number {
    let amountPaid = 0;

    if (caseDistribution.case.payments?.length && submittedAt) {
      caseDistribution.case.payments.forEach(payment => {
        // tslint:disable-next-line:max-line-length
        if (beforeSubmit &&  (DateTime.fromISO(payment.created_at.toString()) < DateTime.fromISO(submittedAt.toString()))) {
          amountPaid += this.reduceVat(payment.amount);
        }
        if (!beforeSubmit &&  (DateTime.fromISO(payment.created_at.toString()) > DateTime.fromISO(submittedAt.toString()))) {
          amountPaid += this.reduceVat(payment.amount);
        }
      });
    }

    return amountPaid;
  }

  public reduceVat(amount: number): number {
    return +((amount / ((100 + this.vatPercentage) / 100)).toFixed(2));
  }
}
