import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MiDashboardComponent} from '../mi-dashboard/mi-dashboard.component';



const routes: Routes = [
    {
        path:     'mi-dash-details',
        children: [
            {path: '', component: MiDashboardComponent, data: {version: 2}},
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [
        RouterModule,
    ],
})

export class MiDashboardDetailsRoutingModule {
}
