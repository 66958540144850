<div class="row">
  <div class="col-12">
    <h2></h2>
  </div>
  <div class="col-md-8">
    <button mat-raised-button color="primary"
            [disabled]="!(authUser.id === 1 || authUser.id === 6 || authUser.id === 22 || authUser.id === 27 ||
             authUser.id === 36 || authUser.id === 967)"
            routerLink="create" type="button">
      + {{"CONFIG.email-template.add-email-template" | translate}}
    </button>
  </div>
  <div class="col-md-4 pt-md-0 pt-5 pb-3">
    <div class="input-group bg-white shadow-inset-2" [class.has-length]="searchFocus">
      <div class="input-group-prepend">
          <span class="input-group-text bg-transparent border-right-0">
              <i class="fal fa-search"></i>
          </span>
      </div>
      <input [formControl]="search"
             type="text"
             class="form-control border-left-0 bg-transparent pl-0"
             (focus)="toggleFocus(true)"
             (blur)="toggleFocus(false)"
             (change)="onChange()"
             [placeholder]="'SHARED.search' | translate">
    </div>
  </div>
</div>

<div class="mat-elevation-z8">
  <table mat-table matSort [dataSource]="dataSource">

    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="label">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CONFIG.sms-template.label" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.label}} </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CONFIG.sms-template.description" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>

    <!-- Subject -->
    <ng-container matColumnDef="subject">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CONFIG.sms-template.subject" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>

    <!-- Fromable -->
    <ng-container matColumnDef="fromable">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CONFIG.sms-template.fromable" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.fromable_type === 'user'">
          {{ "CONFIG.drip-campaign.editor.drip-notification.fromable_type.options.user" | translate }}
          <span *ngIf="element.fromable"> - {{ element.fromable.name }}</span>
        </span>
        <span *ngIf="element.fromable_type === 'department'">
          {{ "CONFIG.drip-campaign.editor.drip-notification.fromable_type.options.department" | translate }}
          <span *ngIf="element.fromable"> - {{ element.fromable.name }}</span>
        </span>
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="active">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CONFIG.sms-template.status" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <p *ngIf="element.active" class="m-0">{{"AFFILIATES.active" | translate}}</p>
        <p *ngIf="!element.active" class="m-0">{{"AFFILIATES.inactive" | translate}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef style="width: 20%">{{"SHARED.actions" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex">
          <a class="pr-3" routerLink="{{element.id}}/edit">{{"SHARED.edit" | translate}}</a>
          <a href="#" (click)="openDeleteDialog(element.id, $event)">{{"SHARED.delete" | translate}}</a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


  <div *ngIf="isLoading" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="!isLoading && !dataSource.data.length" style="padding: 50px">
    <h3 class="text-center pt-2">{{"CONFIG.sms-template.no-templates" | translate}}</h3>
  </div>

  <mat-paginator
    [length]="paginatorConfig.length"
    [pageSize]="paginatorConfig.pageSize"
    [pageSizeOptions]="[5, 10, 20]"
    (page)="paginatorChange($event)"
    showFirstLastButtons
  ></mat-paginator>
</div>
