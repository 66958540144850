<div [formGroup]="form">
  <div class="row pb-3">
    <div class="col-12">
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.start-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="from" formControlName="from" (dateChange)="onDateChange($event, 'from')">
        <button
          type="button"
          *ngIf="form.value.from"
          mat-button matSuffix mat-icon-button
          aria-label="Clear"
          (click)="clearDatePicker($event, 'from')"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
        <mat-datepicker #from color="primary"></mat-datepicker>
      </mat-form-field>

      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.end-date" | translate }}</mat-label>
        <input id="to" matInput [matDatepicker]="to" formControlName="to" (dateChange)="onDateChange($event, 'to')">
        <button
          type="button"
          *ngIf="form.value.to"
          mat-button matSuffix mat-icon-button
          aria-label="Clear"
          (click)="clearDatePicker($event, 'to')"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
        <mat-datepicker #to color="primary"></mat-datepicker>
      </mat-form-field>

      <div class="d-inline-block pl-5">
        <mat-radio-group formControlName="dateRadio" aria-label="Select an option" (change)="onRadioChange($event)">
          <mat-radio-button value="today">{{ "CASES.single.today" | translate }}</mat-radio-button>
          <mat-radio-button value="this-week">{{ "CASES.single.this-week" | translate }}</mat-radio-button>
          <mat-radio-button value="this-month">{{ "CASES.single.this-month" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</div>

<div class="d-inline-block pb-2">
  <mat-radio-group [formControl]="typeForm" aria-label="Select an option" (change)="onRadioChangeType($event)">
    <mat-radio-button value="lso">LSO</mat-radio-button>
    <mat-radio-button value="dm">UNI</mat-radio-button>
  </mat-radio-group>
</div>

<div class="mat-elevation-z8 mb-5" [hidden]="typeForm.value !== 'lso'">
  <table style="width: 100%" mat-table matSort #lsoSort="matSort" [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name || 'N/A' }}</td>
      <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ typeForm.value.toUpperCase() }}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="cases_assigned">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.cases_assigned" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ element?.grouped_accomplishments['cases-assigned-legal-advisor']?.length || 'N/A' }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotal('cases-assigned-legal-advisor')}}</b></td>
    </ng-container>

    <ng-container matColumnDef="contract_sent">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Enviar</th>
      <td mat-cell *matCellDef="let element">{{ element?.grouped_accomplishments['contract-sent']?.length || 'N/A'  }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotal('contract-sent')}}</b></td>
    </ng-container>

    <ng-container matColumnDef="enviar_contrato_percent_lso">
      <th mat-header-cell *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ getPercent(element.id, 'contract-sent') | number:'1.2-2'}}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="contrato_firmado_lso">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Firmado</th>
      <td mat-cell *matCellDef="let element">{{ element?.grouped_accomplishments['contract-signed']?.length || 'N/A'  }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotal('contract-signed')}}</b></td>
    </ng-container>

    <ng-container matColumnDef="contrato_firmado_percent_lso">
      <th mat-header-cell *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ getPercent(element.id, 'contract-signed') | number:'1.2-2' }}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="pago_lso">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef>Pago</th>
      <td mat-cell *matCellDef="let element">{{ element?.grouped_accomplishments['installment-payment']?.length || 'N/A'  }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotal('installment-payment')}}</b></td>
    </ng-container>

    <ng-container matColumnDef="pago_percent_lso">
      <th mat-header-cell *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ getPercent(element.id, 'installment-payment') | number:'1.2-2' }}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="caso_cancelado_lso">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Cancelado</th>
      <td mat-cell *matCellDef="let element">{{ element?.grouped_accomplishments['canceled-case']?.length || 'N/A'  }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotal('canceled-case')}}</b></td>
    </ng-container>

    <ng-container matColumnDef="caso_cancelado_percent_lso">
      <th mat-header-cell *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ getPercent(element.id, 'canceled-case') | number:'1.2-2' }}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="amount_period_lso">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.total-fees-paid" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ sumAgentAmountPaid(element.id) | number:'1.2-2' }}€</td>
      <td mat-footer-cell *matFooterCellDef>{{sumTotalAmountPaid() | number: '1.2-2'}}€</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>

  <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="isLoading < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
    <h3 class="text-center">{{ "DASHBOARD.no-agents" | translate }}</h3>
  </div>
</div>
