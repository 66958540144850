<div class="card shadow">
  <div class="card-body">
    <div class="user-qualifying-questions d-flex flex-row justify-content-between row mr-1 mt-1">
      <div class="client col-6">
        <p>{{'CASES.single.client' | translate}}</p>
        <div class="d-flex flex-row justify-content-between">
          <small>{{'CASES.model.lso_qualifying_questions.criminal_record' | translate}}</small>
          <span><small>{{getQualifyingQuestionsValue(case.client?.criminal_record)}}</small></span>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <small>{{'CASES.model.lso_qualifying_questions.spanish_resident' | translate}}</small>
          <span><small>{{getQualifyingQuestionsValue(case.client?.spanish_resident)}}</small></span>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <small>{{'CASES.model.lso_qualifying_questions.lso_in_five_years' | translate}}</small>
          <span><small>{{getQualifyingQuestionsValue(case.client?.lso_in_five_years)}}</small></span>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <small>{{'CASES.model.lso_qualifying_questions.deprivation_of_responsibility' | translate}}</small>
          <span><small>{{getQualifyingQuestionsValue(case.client?.derivation_of_responsibility)}}</small></span>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <small>{{'CASES.model.lso_qualifying_questions.more_than_one_creditor' | translate}}</small>
          <span><small>{{getQualifyingQuestionsValue(case.client?.more_than_one_creditor)}}</small></span>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <small>{{'CASES.model.lso_qualifying_questions.public_sanctions' | translate}}</small>
          <span><small>{{getQualifyingQuestionsValue(case.client?.public_sanctions)}}</small></span>
        </div>
      </div>
      <div class="partner col-6" *ngIf="case.partner">
        <p>{{'CASES.single.partner' | translate}}</p>
        <div class="d-flex flex-row justify-content-between">
          <small>{{'CASES.model.lso_qualifying_questions.criminal_record' | translate}}</small>
          <span><small>{{getQualifyingQuestionsValue(case.partner?.criminal_record)}}</small></span>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <small>{{'CASES.model.lso_qualifying_questions.spanish_resident' | translate}}</small>
          <span><small>{{getQualifyingQuestionsValue(case.partner?.spanish_resident)}}</small></span>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <small>{{'CASES.model.lso_qualifying_questions.lso_in_five_years' | translate}}</small>
          <span><small>{{getQualifyingQuestionsValue(case.partner?.lso_in_five_years)}}</small></span>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <small>{{'CASES.model.lso_qualifying_questions.deprivation_of_responsibility' | translate}}</small>
          <span><small>{{getQualifyingQuestionsValue(case.partner?.derivation_of_responsibility)}}</small></span>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <small>{{'CASES.model.lso_qualifying_questions.more_than_one_creditor' | translate}}</small>
          <span><small>{{getQualifyingQuestionsValue(case.partner?.more_than_one_creditor)}}</small></span>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <small>{{'CASES.model.lso_qualifying_questions.public_sanctions' | translate}}</small>
          <span><small>{{getQualifyingQuestionsValue(case.partner?.public_sanctions)}}</small></span>
        </div>
      </div>
    </div>
  </div>
</div>
