import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { CaseService } from '../../../admin/case/case.service';
import { MainBaseApiService } from '../../services/main-base-api.service';

@Component({
  selector:    'app-request-payment-modal',
  templateUrl: './request-payment-modal.component.html',
  styleUrls:   ['./request-payment-modal.component.scss'],
})
export class RequestPaymentModalComponent implements OnInit {
  public form: UntypedFormGroup;
  public formActive = false;

  constructor(private fb: UntypedFormBuilder,
              public translate: TranslateService,
              private caseService: CaseService,
              public dialogRef: MatDialogRef<RequestPaymentModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    const maxPayableAmount = this.data.maxPayableAmount ? Math.min(this.data.maxPayableAmount, 5000) : 5000;

    const amountValidators = !this.data.disabledAmount ?
      [Validators.required, Validators.min(0.01), Validators.max(maxPayableAmount)] :
      [];
    this.form              = this.fb.group({
      amount: [
        {value: this.data.amount, disabled: this.data.disabledAmount},
        amountValidators,
      ],
    });
    this.formActive        = true;
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public sendRequest($event, channels: Array<'email' | 'sms'>) {
    $event.preventDefault();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const data        = {
      payment_method_type:     this.data.paymentMethodType,
      payment_processor_type:  this.data.paymentProcessorType,
      amount:                  this.form.get('amount').value,
      force_default_processor: this.data.forceDefaultProcessor,
      channels,
    };
    const requestData = MainBaseApiService.convertFiltersForRequest(data, 'post');
    this.caseService.requestPayment(this.data.caseId, requestData).subscribe(
      next => this.dialogRef.close({success: true}),
      error => this.dialogRef.close({success: false}),
    );

  }
}
