import {Component, OnInit, ViewChild} from '@angular/core';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {DashboardService} from '../dashboard.service';
import {debounceTime, distinctUntilChanged, finalize} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatSort, Sort} from '@angular/material/sort';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-verifier-dashboard',
  templateUrl: './verifier-dashboard.component.html',
  styleUrls: ['./verifier-dashboard.component.scss']
})
export class VerifierDashboardComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  public isLoading = false;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'first_name', 'last_name', 'total_paid', 'total_signed_lso', 'total_signed_dm'
  ];
  public sortBy                     = 'first_name';
  public sortOrder                  = 'desc';
  public form: UntypedFormGroup;
  public totals = {
    signedCountLSO: 0,
    signedCountDM: 0,
    signedCountDGS: 0,
    totalPaid: 0
  };

  constructor(
      private dashboardService: DashboardService,
      private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.getData();

    this.form.valueChanges
        .pipe(
            debounceTime(200),
            distinctUntilChanged(),
        )
        .subscribe(res => {
          this.getData();
        });
  }

  getData() {
    this.isLoading = true;
    const dateRange = {
      start_date: this.form.value.from ? this.form.value.from.toDateString() : '',
      end_date  : this.form.value.to ? this.form.value.to.toDateString() : '',
    };
    this.dataSource = new MatTableDataSource([]);

    this.dashboardService.getVerifierData(dateRange)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(
            res => {
              console.log(res.data);
              this.dataSource = new MatTableDataSource(res.data);
              this.dataSource.sort      = this.sort;
              this.getTotalNumbers(res.data);
            }
        );
  }

  buildForm() {
    this.form = this.fb.group({
      from: [new Date()],
      to: [new Date()],
      dateRadio: ['today']
    });
  }

  onDateChange($event, type) {
    if (type === 'from') {
      this.form.patchValue({
        from: new Date($event.value),
      });
    }
    if (type === 'to') {
      this.form.patchValue({
        to: new Date($event.value),
      });
    }
  }

  clearDatePicker($event, name) {
    this.form.patchValue({[name]: ''});
  }

  onRadioChange($event) {
    if ($event.value === 'today') {
      this.form.patchValue({
        from: new Date(),
        to  : new Date(),
      });
    }
    if ($event.value === 'this-week') {
      const startOfWeek = DateTime.local().startOf('week').toISO();
      const endOfWeek   = DateTime.local().endOf('week').toISO();
      this.form.patchValue({
        from: new Date(startOfWeek),
        to  : new Date(endOfWeek)
      });
    }
    if ($event.value === 'this-month') {
      const startOfWeek = DateTime.local().startOf('month').toISO();
      const endOfWeek   = DateTime.local().endOf('month').toISO();
      this.form.patchValue({
        from: new Date(startOfWeek),
        to  : new Date(endOfWeek)
      });
    }
  }

  getTotalNumbers(data): void {
    this.totals = {
      signedCountLSO: 0,
      signedCountDM: 0,
      signedCountDGS: 0,
      totalPaid: 0
    };

    data.map(obj => {
      console.log(obj);
      this.totals.signedCountLSO += obj.total_signed_lso;
      this.totals.signedCountDM += obj.total_signed_dm;
      this.totals.signedCountDGS += obj.total_signed_dgs;
      this.totals.totalPaid += obj.total_paid;
    });
  }

}
