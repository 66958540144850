import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationFromEvent } from '../../../../../../../_base-shared/models/Notification/NotificationFromEvent';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UntypedFormControl } from '@angular/forms';
import { NotificationEventService } from '../notification-event.service';
import { ToastrService } from 'ngx-toastr';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { debounceTime, distinctUntilChanged, filter, finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector:    'app-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls:   ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {

    public notifications: NotificationFromEvent[];
    displayedColumns: string[] = [
        'id',
        'title',
        'body',
        'type',
        'actions'
    ];
    actions: string[] = ['Edit', 'Delete'];
    public isLoading = 0;
    public dataSource: MatTableDataSource<NotificationFromEvent>;
    public paginatorConfig = {
        pageIndex: 0,
        pageSize: 10,
        length: 1
    };
    public search = new UntypedFormControl('');
    public searchFocus = false;

    constructor(
        private notificationService: NotificationEventService,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {
    }

    @ViewChild(MatSort, { static: true }) sort: MatSort;

    ngOnInit(): void {
        this.fetchNotifications();
        this.search.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
            )
            .subscribe(() => this.fetchNotifications());
    }

    fetchNotifications() {
        this.dataSource             = new MatTableDataSource<NotificationFromEvent>([]);
        this.isLoading++;
        const data = {
            per_page: this.paginatorConfig.pageSize,
            page: this.paginatorConfig.pageIndex + 1,
            search: this.search.value
        };

        this.notificationService.index(data)
          .pipe(
            finalize(() => this.isLoading--)
          )
          .subscribe(res => {
              this.notifications          = res.data;
              this.dataSource             = new MatTableDataSource<NotificationFromEvent>(res.data);
              this.dataSource.sort        = this.sort;
              this.paginatorConfig.length = res.meta.total;
          });
    }

    openDeleteDialog(notificationId, $event): void {
        $event.preventDefault();
        Swal.fire({
            title: 'Warning!',
            text: this.translate.instant('CONFIG.notifications.delete-notification-confirm'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('SHARED.delete'),
            confirmButtonColor: '#886ab5',
            cancelButtonText: this.translate.instant('SHARED.cancel')
        }).then(res => {
            if (res.isConfirmed) {
                this.deleteNotification(notificationId);
            }
        });
    }

    deleteNotification(notificationId: number) {
        this.notificationService.delete(notificationId)
            .subscribe(res => {
                    this.fetchNotifications();
                    this.toastr.success(
                      this.translate.instant('CONFIG.notifications.delete-notification-success'),
                      this.translate.instant('SHARED.success'));
                },
                error => {
                    this.toastr.error(
                      this.translate.instant('CONFIG.notifications.delete-notification-error'),
                      this.translate.instant('SHARED.error')
                    );
                });
    }

    public paginatorChange($event: PageEvent) {
        this.paginatorConfig.pageIndex = $event.pageIndex;
        this.paginatorConfig.pageSize = $event.pageSize;
        this.paginatorConfig.length = $event.length;
        this.fetchNotifications();
    }

    onChange() {
        this.fetchNotifications();
    }

    toggleFocus(setFocus: boolean) {
        this.searchFocus = setFocus;
    }

}
