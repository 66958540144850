<h3>{{ "CASE_CREDITOR.model.type.options.public" | translate }}</h3>
<button mat-raised-button color="primary" [matMenuTriggerFor]="menuPublic">
  {{ "SHARED.bulk-actions" | translate }}
</button>
<mat-menu #menuPublic="matMenu">
  <button mat-menu-item
          [disabled]="publicDebtsVerifyDisabled" type="button"
          (click)="verifyCreditor( selectionPublicDebts, 'public_debts')">
    {{ "CASES.details.verify-send-mandates" | translate }}
  </button>
  <button mat-menu-item [disabled]="publicDebtsResendDisabled" type="button"
          (click)="verifyCreditor(selectionPublicDebts, 'public_debts')">
    {{ "CASES.details.resend-mandates" | translate }}
  </button>
  <button mat-menu-item (click)="changeStatus($event, selectionPublicDebts, 'public_debts')">
    {{ "CASES.details.change-status" | translate }}
  </button>
</mat-menu>
<div class="mat-elevation-z8"
     style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; bottom: 50px; margin-bottom: 2rem">
  <table style="width: 100%" mat-table matSort [dataSource]="publicDebts">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle(selectionPublicDebts, publicDebts) : null" color="primary"
                      [checked]="selectionPublicDebts.hasValue() && isAllSelected(selectionPublicDebts, publicDebts)"
                      [indeterminate]="selectionPublicDebts.hasValue() && !isAllSelected(selectionPublicDebts, publicDebts)">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                      (change)="$event ? selectionPublicDebts.toggle(row) : null"
                      [checked]="selectionPublicDebts.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell
          *matHeaderCellDef>{{ "CASE_CREDITOR.model.public_organization.label" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.public_organisation === 'social-security'">
          {{ "CASE_CREDITOR.model.public_organization.options.social-security" | translate }}
        </ng-container>
        <ng-container *ngIf="element.public_organisation === 'estate'">
          {{ "CASE_CREDITOR.model.public_organization.options.estate" | translate }}
        </ng-container>
        <ng-container *ngIf="element.public_organisation === 'town-hall'">
          {{ "CASE_CREDITOR.model.public_organization.options.town-hall" | translate }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="ownership">
      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.ownership === 'applicant'">
          {{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}
        </ng-container>
        <ng-container *ngIf="element.ownership === 'partner'">
          {{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}
        </ng-container>
        <ng-container *ngIf="element.ownership === 'joint'">
          {{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="debt_type">
      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.debt_type.label" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.debt_type === 'tax'">
          {{ "CASE_CREDITOR.model.debt_type.options.tax" | translate }}
        </ng-container>
        <ng-container *ngIf="element.debt_type === 'capital-gain'">
          {{ "CASE_CREDITOR.model.debt_type.options.capital-gain" | translate }}
        </ng-container>
        <ng-container *ngIf="element.debt_type === 'penalty-fee'">
          {{ "CASE_CREDITOR.model.debt_type.options.penalty-fee" | translate }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="initial_balance">
      <th mat-header-cell *matHeaderCellDef>
        <span>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element">
        {{element.initial_balance | number: '1.2-2'}}€
      </td>
    </ng-container>

    <ng-container matColumnDef="current_balance">
      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.current_balance | number: '1.2-2'}}€</td>
    </ng-container>

    <ng-container matColumnDef="monthly_payments">
      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.monthly_payments | number: '1.2-2'}}€</td>
    </ng-container>

    <ng-container matColumnDef="reference_number">
      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.reference_number}} </td>
    </ng-container>

    <ng-container matColumnDef="response_received">
      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container>
          {{ ("CASE_CREDITOR.model.response_received.options." + element.response_received) | translate }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="additional_partner">
      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.interested_third_party" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.interested_third_party" class="mat-icon-size" color="primary">check_circle</mat-icon>
        <mat-icon *ngIf="!element.interested_third_party" class="mat-icon-size" color="warn">cancel</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="making_payments">
      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.making_payments" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.making_payments" class="mat-icon-size" color="primary">check_circle</mat-icon>
        <mat-icon *ngIf="!element.making_payments" class="mat-icon-size" color="warn">cancel</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>
        <mat-menu #menu="matMenu" yPosition="above">
          <button *ngIf="!element.verified" mat-menu-item type="button"
                  (click)="verifyCreditorIds([element.id], 'public_debts')">
            {{ "CASES.details.verify-send-mandates" | translate }}
          </button>
          <button *ngIf="element.verified" mat-menu-item type="button"
                  (click)="verifyCreditorIds([element.id], 'public_debts')">
            {{ "CASES.details.resend-mandates" | translate }}
          </button>
          <button mat-menu-item
                  (click)="changeStatus($event, [element.id], 'public_debts')">
            {{ "CASES.details.change-status" | translate }}
          </button>
          <button mat-menu-item
                  *ngIf="element.ownership === 'applicant' || element.ownership === 'joint' "
                  (click)="getRightOfAccess('client', element.id, $event)">
            {{'CASES.details.get-right-of-access' | translate}} 1
          </button>
          <button mat-menu-item
                  *ngIf="element.ownership === 'partner' || element.ownership === 'joint' "
                  (click)="getRightOfAccess('partner', element.id, $event)">
            {{'CASES.details.get-right-of-access' | translate}} 2
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="loader">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px">
        <mat-spinner
          *ngIf="isPublicLoading && isPublicLoadingId.length && isPublicLoadingId.indexOf(element.id) !== -1"
          diameter="20" color="accent"></mat-spinner>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="isFetchingPublicDebt" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="!isFetchingPublicDebt && publicDebts && !publicDebts.data.length" style="padding: 50px">
    <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>
  </div>
</div>
