<h1 mat-dialog-title>Image Editor</h1>
<mat-dialog-content>
  <mat-spinner *ngIf="isLoading" diameter="40" class="m-0 m-auto"></mat-spinner>
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <button mat-raised-button color="primary" class="mr-2"
              (click)="rotateLeft()">{{ 'DOCUMENTS.img-cropper.rotate-left' | translate }}
      </button>
      <button mat-raised-button color="primary"
              (click)="rotateRight()">{{ 'DOCUMENTS.img-cropper.rotate-right' | translate }}
      </button>
    </div>
    <div class="col-6">
      <h2>{{ 'DOCUMENTS.img-cropper.original-image' | translate }}</h2>
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="false"
        [containWithinAspectRatio]="false"
        [imageURL]="storageUrl + data.documentFile.path"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
        [alignImage]="'left'"
        [style.padding]="0"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed($event)">
      </image-cropper>
    </div>
    <div class="col-6">
      <h2>{{ 'DOCUMENTS.img-cropper.cropped-image-preview' | translate }}</h2>
      <img class="img-fluid" [src]="croppedImage" style="max-height: 650px" alt="cropped-preview"/>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close color="primary" class="ml-2">
    {{ "SHARED.close" | translate }}
  </button>
  <button type="button" mat-raised-button color="primary" (click)="submitImage()">
    {{ 'SHARED.submit' | translate }}
  </button>
</mat-dialog-actions>

