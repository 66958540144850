<div *ngIf="isLoading" class="pt-4 pl-4">
  <mat-spinner class="m-0 m-auto"></mat-spinner>
</div>
<div *ngIf="serverResponse" class="row">
  <div class="col-12">
    <app-server-response [response]="serverResponse"></app-server-response>
  </div>
</div>
<div *ngIf="serverResponse" class="row">
  <div class="col-12">
    <app-server-response [response]="serverResponse"></app-server-response>
  </div>
</div>
<div class="mb-4">
  <div class="card-header" style="border: 1px solid #e4e4e4;">
    <h2 class="text-center m-0">{{ "CASES.single.payment-history" | translate }}</h2>
  </div>
  <div class="card-body p-0">
    <div class="mat-elevation-z5" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none">
      <table style="width: 100%" mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.date" | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ element.payment_date | toDateObject | date }}</td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.amount" | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ element.amount | currency: 'EUR' }}</td>
        </ng-container>

        <!-- <ng-container matColumnDef="splitting_type"> -->
        <!--   <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.split-type" | translate }}</th> -->
        <!--   <td mat-cell *matCellDef="let element"> -->
        <!--     {{ element.splitting_type === 'even' ? 'Even Split' : (element.splitting_type === 'reduce' ? 'Reduce Terms' : 'Custom') }} -->
        <!--   </td> -->
        <!-- </ng-container> -->

        <ng-container matColumnDef="payment_method">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.payment-method" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{
              !element.payment_method ?
                'Not Selected' :
                ('PAYMENTS.payment_method.options.' + element.payment_method) | translate
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="transactionable_payment_processor">
          <th mat-header-cell *matHeaderCellDef>
            {{ "CASES.single.payments.payment_list.transactionable_payment_processor" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.transactionable ? element.transactionable?.payment_processor?.name : '' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.note" | translate }}</th>
          <td mat-cell *matCellDef="let element" [innerHTML]="element.note"></td>
        </ng-container>

        <ng-container matColumnDef="for_additional_plan">
          <th mat-header-cell *matHeaderCellDef>{{ 'CASES.single.plan-type' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.for_additional_plan ? 'Additional plan' : 'Debt plan' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="api_source">
          <th mat-header-cell *matHeaderCellDef>{{ 'CASES.single.app_source' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.api_source ? element.api_source : 'N/A' }}</td>
        </ng-container>

        <ng-container matColumnDef="done_by">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.done-by" | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ element.author?.name }}</td>
        </ng-container>

        <!-- Expanded Content Column -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <mat-accordion multi="true">
                <!-- Transaction details -->
                <mat-expansion-panel expanded="true" *ngIf="element.transactionable">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{'CASES.single.transaction_details' | translate}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-transaction-details *ngIf="element.transactionable_type === 'oppwa_order'"
                                           [transaction]="element.transactionable"></app-transaction-details>
                  <app-unax-details *ngIf="element.transactionable_type === 'unnax_order'"
                                    [unnaxOrder]="element.transactionable">
                  </app-unax-details>
                  <app-redsys-details *ngIf="element.transactionable_type === 'redsys_order'"
                                      [redsysOrder]="element.transactionable">
                  </app-redsys-details>
                  <app-mymoid-details *ngIf="element.transactionable_type === 'mymoid_order'"
                                      [mymoidOrder]="element.transactionable"></app-mymoid-details>
                </mat-expansion-panel>
                <!-- Installment allocations -->
                <mat-expansion-panel expanded="true" *ngIf="element.terms.length >= 1">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{'CASES.single.installment_allocations' | translate}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                    <app-allocation-details [allocationDetails]="element.terms"></app-allocation-details>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>

      <div *ngIf="isLoading" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="!isLoading && !dataSource?.data.length">
        <h3 class="text-center pt-3">{{ "CASES.single.no-payments" | translate }}</h3>
      </div>

      <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize"
                     [pageSizeOptions]="[5, 10, 20]" (page)="paginatorChange($event)" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>
