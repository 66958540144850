import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { User } from '../../../../../../_base-shared/models/User/User';
import { AppAlert } from '../../../../../../_base-shared/contracts/app-alert.interface';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { QuickNoteComponent } from '../case-detail/quick-note/quick-note.component';
import { CaseService } from '../case.service';

@Component({
  selector:    'app-case-editor',
  templateUrl: './case-editor.component.html',
  styles:      [
    `
      .mat-icon {
        font-size: 20px;
      }
    `],
})
export class CaseEditorComponent implements OnInit, OnDestroy {
  public caseId: number;
  public case: Case;
  public tabs: Array<{ label: string, path: Array<string | number> | string }>;
  public isLoading                    = 0;
  public serverResponse: LaravelResourceResponse;
  public localAlerts: Array<AppAlert> = [];
  public quickNotesDialogOpened       = false;
  public editorType: 'edit' | 'create';
  public hasUnapproved                = false;
  private authUser: User;

  private subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private translate: TranslateService,
              private caseService: CaseService,
              private globalEventsService: MainGlobalEventService,
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.editorType = data.editorType;
      if (this.editorType === 'edit') {
        this.route.paramMap.subscribe(params => {
          this.caseId = +params.get('id');
          this.buildTabs(this.editorType, this.caseId);
          this.globalEventsService.authUser$.subscribe(user => {
            this.authUser = user;
            this.fetchCase(this.caseId);
          });
        });
      } else {
        this.buildTabs(this.editorType);
      }
    });
  }

  ngOnDestroy(): void {
    console.log('destroying case editor');
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'q') { //  Open dialog on 'ctrl' + 'q'
      if ( ! this.quickNotesDialogOpened) { //  Check if dialog is not already opened
        const dialog = this.dialog.open(QuickNoteComponent, {
          width: '50%',
          data:  { case: this.case },
        });
        dialog.afterOpened().subscribe(result => {
          this.quickNotesDialogOpened = true;
        });
        dialog.afterClosed().subscribe(result => {
          this.quickNotesDialogOpened = false;
        });
      }
    }
  }

  private fetchCase(caseId: number) {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(caseId, ['transfer_request', 'locked_by']).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.case = result.data;
          if (this.case.locked_by_id) {
            let lockedMessage = this.translate.instant('CASES.single.case_flags.locked_by_a_case');
            if (this.authUser.packager.master) {
              lockedMessage = lockedMessage + ' ' +
                this.translate.instant('CASES.single.case_flags.locked_by_a_case_ref', { activeCaseRef: this.case.locked_by?.ref_number });
            }
            Swal.fire({
              title:              this.translate.instant('SHARED.warning'),
              text:               lockedMessage,
              icon:               'warning',
              showCancelButton:   false,
              allowOutsideClick:  false,
              confirmButtonText:  'OK',
              confirmButtonColor: '#886AB5',
              cancelButtonText:   this.translate.instant('SHARED.cancel'),
            }).then(res => {
              if (res.isConfirmed) {
                this.router.navigate(['case-dashboard', 'cases']);
              }
            });
          }
          if ( ! this.authUser.packager.master && !! ( this.case.transfer_request && this.case.transfer_request.accepted !== false )) {
            this.router.navigateByUrl('/case-dashboard/cases', { state: { bypassFormGuard: true } });
          }
        },
        err => this.serverResponse = err.error,
      ),
    );
  }

  // TODO:
  changeUnapprovedStatus(event) {
    this.hasUnapproved = event;
  }

  private buildTabs(editorType: 'edit' | 'create', caseId: number = null): void {
    this.tabs = [];
    if (editorType === 'create') {
      this.tabs = [
        { label: this.translate.instant('CASES.editor.general.heading'), path: ['/cases', 'create', 'general'] },
      ];
    }
    if (editorType === 'edit') {
      this.tabs = [
        {
          label: this.translate.instant('CASES.editor.general.heading'),
          path:  ['/cases', caseId, 'edit', 'general'],
        },
        {
          label: this.translate.instant('CASES.editor.creditors.heading'),
          path:  ['/cases', caseId, 'edit', 'creditors'],
        },
        {
          label: this.translate.instant('CASES.editor.assets.heading'),
          path:  ['/cases', caseId, 'edit', 'assets'],
        },
        {
          label: this.translate.instant('CASES.editor.incomes.heading'),
          path:  ['/cases', caseId, 'edit', 'incomes'],
        },
        {
          label: this.translate.instant('CASES.editor.expenses.heading'),
          path:  ['/cases', caseId, 'edit', 'expenses'],
        },
        {
          label: this.translate.instant('CASES.editor.outcome.heading'),
          path:  ['/cases', caseId, 'edit', 'outcome'],
        },
        {
          label: this.translate.instant('CASES.editor.payment.heading'),
          path:  ['/cases', caseId, 'edit', 'payment'],
        },
      ];
    }
  }
}
