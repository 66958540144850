import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ReportService } from '../../report.service';

@Component({
  selector:    'app-book-value-report',
  templateUrl: './book-value-report.component.html',
  styles:   []
})
export class BookValueReportComponent implements OnInit{
  public form: UntypedFormGroup;
  public isLoading                           = 0;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private toast: ToastrService,
    private reportService: ReportService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<BookValueReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.fb.group({
      start_date: [null, Validators.required],
      end_date:   [null, Validators.required],
    });
  }

  public sendReportData(): void {
    if (!this.form.valid) {
      return;
    }
    const endOfDayISOStringV2 = DateTime.fromISO(this.form.value.end_date.toISOString()).endOf('day').toISO({ includeOffset: false });
    const requestData = {
      start_date: this.form.value.start_date.toISOString(),
      end_date:   endOfDayISOStringV2,
    };
    this.isLoading++;
    this.subscriptions.push(
      this.reportService.generateBookValueReport(requestData).pipe(finalize(() => this.isLoading--))
        .subscribe(res => {
            // const fileName = 'sales_report' + moment().format('YYYY-MM-DD_HH-mm') + '.csv';
            // saveAs(res.body, fileName);
            this.dialogRef.close(true);
            this.toast.success(res.message);

          },
          err => console.error(err),
        )
    );
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
