<div class="pb-4" >
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="d-flex align-items-center justify-content-between" style="width: 100%">
            {{ "PAYMENTS.advanced-filters" | translate }}
            <!--<button mat-stroked-button color="primary" (click)="clearFilters()">-->
            <!--  {{"SHARED.reset-filters" | translate}}-->
            <!--</button>-->
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form *ngIf="filterForm" [formGroup]="filterForm">
        <div class="row">
          <div class="col-3" [hidden]="listType !== 'admin-user-list'">
            <app-input type="select" formControlName="role_ids" appearance="standard" multiple
                       [label]="'User roles'"
                       [selectOptions]="roles" [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>
          </div>
          <div class="col-3">
            <app-input type="select" formControlName="department_ids" appearance="standard" multiple
                       [label]="'USERS.editor.departments' | translate" [searchable]="true"
                       [selectOptions]="departmentCategories" [selectLabel]="'name'" [selectValue]="'id'"
                       [optGroupProperty]="'departments'">
            </app-input>
          </div>
          <div class="col-3">
            <app-input type="select" formControlName="member_in_teams_ids" appearance="standard" multiple
                       [label]="'USERS.model.team' | translate" [searchable]="true"
                       [selectOptions]="teamsCategories" [selectLabel]="'name'" [selectValue]="'id'"
                       >
            </app-input>
          </div>
          <div class="col-3">
            <app-input type="select" formControlName="leader_in_teams_ids" appearance="standard" multiple
                       [label]="'USERS.model.team_leader' | translate" [searchable]="true"
                       [selectOptions]="teamLeaderCategories" [selectLabel]="'name'" [selectValue]="'id'"
                       >
            </app-input>
          </div>
          <div class="row">
            <div class="col-3 ml-3">
              <app-input type="checkbox" formControlName="active" appearance="standard"
                         label="Active">
              </app-input>
            </div>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="pb-4">
  <div class="row">
    <div class="col-md-8" [hidden]="listType !== 'admin-user-list'">
      <button mat-raised-button color="primary" routerLink="create" type="button">
        + {{"USERS.add-user" | translate}}
      </button>
      <button mat-raised-button class="ml-2" (click)="openGroupNotificationsModal()" color="primary" type="button">
        {{"USERS.send-group-notifications" | translate}}
      </button>
    </div>
    <div class="col-md-4">
      <div class="input-group bg-white shadow-inset-2" [class.has-length]="searchFocus">
        <div class="input-group-prepend">
          <span class="input-group-text bg-transparent border-right-0">
              <i class="fal fa-search"></i>
          </span>
        </div>
        <input
                [formControl]="search"
                type="text"
                class="form-control
          border-left-0 bg-transparent pl-0"
                (focus)="onFocus()"
                (blur)="onBlur()"
                [placeholder]="'SHARED.search' | translate">
      </div>
    </div>
  </div>
</div>

<!--USER LIST-->
<div class="mat-elevation-z8" *ngIf="listType === 'admin-user-list'">
  <table mat-table [dataSource]="dataSource" >

    <!-- ID -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.model.name' | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Email -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>{{ 'USERS.model.email' | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <!-- Role -->
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>{{"USERS.role" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.role.name}} </td>
    </ng-container>

    <!-- TEAM -->
    <ng-container matColumnDef="team">
      <th mat-header-cell *matHeaderCellDef>{{"USERS.model.team" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <span class="badge badge-info" *ngFor="let item of element.member_in_teams">
          {{item.name}}
        </span>
      </td>
    </ng-container>

    <!-- TEAM LEADER -->
    <ng-container matColumnDef="team_leader">
      <th mat-header-cell *matHeaderCellDef>{{"USERS.model.team_leader" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <span class="badge badge-primary" *ngFor="let item of element.leader_in_teams">
          {{item.name}}
        </span>
      </td>
    </ng-container>

    <!-- Services -->
    <ng-container matColumnDef="service_integrations">
      <th mat-header-cell *matHeaderCellDef>{{ "USERS.list.table.service_integrations" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.role.is_staff">
          <div *ngIf="element?.service_integrations">
            <div *ngFor="let integration of element.service_integrations">
              <span class="badge badge-success" *ngIf="integration.service">
                {{ integration.service?.name }} connected
              </span>
            </div>
          </div>
          <span *ngIf="!element?.service_integrations?.length" class="badge badge-danger">Gmail not connected</span>
        </div>
        <span *ngIf="!element.role.is_staff">N/A</span>
      </td>
    </ng-container>

    <!-- Created at -->
    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef>{{"USERS.created-at" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.created_at | date }} </td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{"SHARED.actions" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex">
          <a class="pr-3" [routerLink]="[element.id, 'edit']">{{"SHARED.edit" | translate}}</a>
          <a class="pr-3" href="#" (click)="deleteUser($event, element.id)">{{"SHARED.delete" | translate}}</a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="isLoading" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <mat-paginator
          [length]="paginatorConfig.length"
          [pageSize]="paginatorConfig.pageSize"
          [pageIndex]="paginatorConfig.pageIndex"
          [pageSizeOptions]="[5, 10, 20]"
          (page)="paginatorChange($event)"
          showFirstLastButtons
  ></mat-paginator>
</div>

<!--EMPLOYEE LIST-->
<div class="mat-elevation-z8" *ngIf="listType === 'admin-employee-list'">
  <table mat-table [dataSource]="dataSource">

    <!-- FULL NAME-->
    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef>{{"USERS.EMPLOYEES.full_name" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.first_name}} {{element.last_name}} </td>
    </ng-container>

    <!-- EMAIL-->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>{{"USERS.EMPLOYEES.email" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <!-- DEPARTMENT-->
    <ng-container matColumnDef="department">
      <th mat-header-cell *matHeaderCellDef>{{"USERS.EMPLOYEES.department" | translate}}</th>
      <td mat-cell  *matCellDef="let element">
        <span class="badge badge-info" *ngFor="let item of element.departments">
          {{item.name}}
        </span>
      </td>
    </ng-container>

    <!-- TEAM -->
    <ng-container matColumnDef="team">
      <th mat-header-cell *matHeaderCellDef>{{"USERS.EMPLOYEES.team" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <span class="badge badge-info" *ngFor="let item of element.member_in_teams">
          {{item.name}}
        </span>
      </td>
    </ng-container>

    <!-- TEAM LEADER -->
    <ng-container matColumnDef="team_leader">
      <th mat-header-cell *matHeaderCellDef>{{"USERS.EMPLOYEES.team_leader" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <span class="badge badge-primary" *ngFor="let item of element.leader_in_teams">
          {{item.name}}
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="isLoading" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <mat-paginator
          [length]="paginatorConfig.length"
          [pageSize]="paginatorConfig.pageSize"
          [pageIndex]="paginatorConfig.pageIndex"
          [pageSizeOptions]="[5, 10, 20]"
          (page)="paginatorChange($event)"
          showFirstLastButtons
  ></mat-paginator>
</div>

