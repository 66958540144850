<div *ngIf="componentReady" class="d-flex justify-content-between" [ngClass]="{
'align-items-center': flexClass === 'align-items-center',
'align-items-baseline': flexClass !== 'align-items-center',
'client-typography-input' : clientTypography
}">
  <!-- Duplicate Label -->
  <div *ngIf="extraLabel" style="width: 50%">
    <mat-label>{{ label }}</mat-label>
  </div>
  <div [ngStyle]="{width: extraLabel ? '50%' : (fullWidth ? '100%' : 'auto')}">
    <mat-form-field *ngIf="!nonFormField.includes(type) || (type === 'mat-datepicker' && datePickerReady)"
                    [appearance]="appearance" [color]="color" [ngStyle]="{width: width}"
                    [ngClass]="{'input-highlight': highlight, 'full-width': fullWidth}">
      <mat-label *ngIf="showLabel">{{ label }} <span *ngIf="requiredStar" style="color: red">*</span></mat-label>
      <!-- Input -->
      <span *ngIf="prefix && prefixType === 'text'" matPrefix>{{ prefix }}&nbsp;</span>
      <mat-icon *ngIf="prefix && prefixType === 'icon'" matPrefix>{{ prefix }}&nbsp;</mat-icon>
      <input *ngIf="!nonFormField.includes(type) && type !== 'select' && type !== 'textarea'" matInput [type]="type"
             [attr.inputmode]="inputMode" [formControl]="internalFormControl" [readonly]="readonly"
             [placeholder]="placeholder" (ngModelChange)="updateControl($event)" (blur)="onTouched()">
      <!-- Datepicker -->
      <input *ngIf="type === 'mat-datepicker'" matInput [matDatepicker]="matDatePicker"
             [formControl]="internalFormControl" [placeholder]="placeholder"
             (ngModelChange)="updateControl($event)" (blur)="onTouched()">
      <mat-datepicker-toggle *ngIf="type === 'mat-datepicker' && datePickerReady" matSuffix
                             [for]="matDatePicker">
      </mat-datepicker-toggle>
      <span *ngIf="suffix && suffixType === 'text'" matSuffix>{{ suffix }}&nbsp;</span>
      <mat-icon *ngIf="suffixType === 'icon'" matSuffix (click)="toggleIcon()" style="cursor: pointer">{{
          ! suffix ? (type === 'password' ? 'visibility_off' : "visibility") : suffix
        }}&nbsp;
      </mat-icon>
      <!-- Text Area -->
      <textarea *ngIf="type === 'textarea'" matInput [formControl]="internalFormControl" [readonly]="readonly"
                (ngModelChange)="updateControl($event)" (blur)="onTouched()"
                [cols]="textAreaCols" [rows]="textAreaRows">
      </textarea>
      <!-- Select -->
      <mat-select *ngIf="type === 'select'" [formControl]="internalFormControl" [placeholder]="placeholder"
                  aria-labelledby="null" #selectAllMatSelect [multiple]="multiple"
                  (ngModelChange)="updateControl($event)" (blur)="onTouched()">
        <ng-container *ngIf="optGroupProperty">
          <mat-option *ngIf="searchable">
            <ngx-mat-select-search noEntriesFoundLabel="No items found" [formControl]="searchControl"
                                   (ngModelChange)="startFilteringLoader($event)" class="ngx-mat-search"
                                   [placeholderLabel]="emitSearch && !(filteredOptions | async)?.length ?
                                    ('SHARED-COMPONENTS.app_input.async_search' | translate) :
                                     'SHARED.search' | translate">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option [hidden]="!isFiltering" class="h-100">
            <mat-spinner class="m-5 m-auto"></mat-spinner>
          </mat-option>
          <mat-checkbox *ngIf="multiple && selectAll" [color]="color" [formControl]="selectAllFormControl"
                        [labelPosition]="labelPosition" class="select-all-checkbox"
                        [indeterminate]="selectAllIndeterminate"
                        (ngModelChange)="selectAllOptions($event)">
            {{ 'SHARED-COMPONENTS.app_input.select_all' | translate }}
          </mat-checkbox>
          <mat-optgroup *ngFor="let optionGroup of filteredOptions | async" [label]="optionGroup[optGroupLabel]"
                        [hidden]="isFiltering">
            <mat-option *ngFor="let option of optionGroup[optGroupProperty]" [value]="option[selectValue]">
              <span *ngFor="let labelFragment of selectLabels; let i = index">
                <span *ngIf="i > 0">&nbsp;</span>{{ option[labelFragment] }}
              </span>
              <span *ngIf="labelDescriptionField">({{ option[labelDescriptionField] }})</span>
            </mat-option>
          </mat-optgroup>
        </ng-container>
        <ng-container *ngIf="!optGroupProperty">
          <mat-option *ngIf="searchable">
            <ngx-mat-select-search noEntriesFoundLabel="No items found" [formControl]="searchControl"
                                   (ngModelChange)="startFilteringLoader($event)" class="ngx-mat-search"
                                   [placeholderLabel]="emitSearch && !(filteredOptions | async)?.length ?
                                    ('SHARED-COMPONENTS.app_input.async_search' | translate) :
                                     'SHARED.search' | translate">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option [hidden]="!isFiltering" class="h-100">
            <mat-spinner class="m-5 m-auto"></mat-spinner>
          </mat-option>
          <mat-checkbox *ngIf="multiple && selectAll" [color]="color" [formControl]="selectAllFormControl"
                        [labelPosition]="labelPosition" class="select-all-checkbox"
                        [indeterminate]="selectAllIndeterminate"
                        (ngModelChange)="selectAllOptions($event)">
            {{ 'SHARED-COMPONENTS.app_input.select_all' | translate }}
          </mat-checkbox>
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option[selectValue]"
                      [hidden]="isFiltering">
            <span *ngFor="let labelFragment of selectLabels; let i = index">
              <span *ngIf="i > 0">&nbsp;</span>{{ option[labelFragment] }}
            </span>
            <span *ngIf="labelDescriptionField">({{ option[labelDescriptionField] }})</span>
          </mat-option>
        </ng-container>
      </mat-select>
      <!-- Clear -->
      <button type="button" *ngIf="displayClearButton()" mat-button mat-icon-button matSuffix aria-label="Clear"
              [disabled]="internalFormControl.disabled || readonly" (click)="clearSelect($event)">
        <mat-icon class="opacity-50">close</mat-icon>
      </button>
      <mat-spinner [hidden]="!wrapperControl?.pending" diameter="25"></mat-spinner>
      <mat-hint *ngIf="hint" align="end">{{ hint }}</mat-hint>
      <!-- Debug -->
      <!--<pre *ngIf=" label === ('USERS.model.email' | translate)">{{ wrapperControl?.errors | json }}</pre>-->
      <!-- Validation Errors -->
      <app-input-validation-errors [wrapperControl]="wrapperControl" [label]="label" [requiredError]="requiredError"
                                   [emailError]="emailError" [patternError]="patternError"
                                   [minLengthError]="minLengthError" [maxLengthError]="maxLengthError">
      </app-input-validation-errors>
    </mat-form-field>
    <ng-container *ngIf="type === 'checkbox'">
      <div class="checkbox-wrapper d-flex justify-content-center">
        <mat-checkbox [color]="color" [formControl]="internalFormControl" [(indeterminate)]="indeterminate"
                      [labelPosition]="labelPosition"
                      (ngModelChange)="updateControl($event)" (blur)="onTouched()">
          {{ label }}
        </mat-checkbox>
      </div>
    </ng-container>
    <ng-container *ngIf="type === 'switch'">
      <div class="slide-toggle-wrapper">
        <mat-slide-toggle [color]="color" [formControl]="internalFormControl" [labelPosition]="labelPosition"
                          (ngModelChange)="updateControl($event)" (blur)="onTouched()">
          {{ label }}
        </mat-slide-toggle>
      </div>
    </ng-container>
    <ng-container *ngIf="type === 'switch' || type === 'checkbox'">
      <mat-hint *ngIf="hint" align="end">{{ hint }}</mat-hint>
      <!-- Validation Errors -->
      <app-input-validation-errors [wrapperControl]="wrapperControl" [label]="label" [requiredError]="requiredError"
                                   [emailError]="emailError" [patternError]="patternError"
                                   [minLengthError]="minLengthError" [maxLengthError]="maxLengthError">
      </app-input-validation-errors>
    </ng-container>
  </div>
</div>
<mat-datepicker *ngIf="type === 'mat-datepicker'"></mat-datepicker>
