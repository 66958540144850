<div class="d-inline-block pb-2">
  <mat-radio-group aria-label="Select an option" (change)="onRadioChange($event)">
    <mat-radio-button checked value="lso">LSO</mat-radio-button>
    <mat-radio-button value="dm">DM</mat-radio-button>
    <mat-radio-button value="dgs">DGS</mat-radio-button>
    <mat-radio-button value="cajaplus">Cajaplus</mat-radio-button>
  </mat-radio-group>
</div>
<div class="row">
  <div class="col-lg-8">
    <div class="mat-elevation-z8 mb-5">
      <table style="width: 100%" mat-table matSort [dataSource]="dataSourceAllProducts">

        <ng-container matColumnDef="name">
          <th style="width: 200px" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{"DASHBOARD.agent-name" | translate}}
          </th>
          <td mat-cell *matCellDef="let element"
              [style.display]="element.type !== 'DM' ? '' : 'none'">
            {{ element.name || 'N/A' }}</td>
          <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th style="width: 80px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.type | uppercase }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="case_count">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.cases_assigned" | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <ng-container>{{element.case_count}}</ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef><b>{{allProductsTotals[selectedProduct][0]?.caseCount}}</b></td>
        </ng-container>

        <ng-container matColumnDef="impago_percent">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Impago %</th>
          <td mat-cell *matCellDef="let element" [class.red-color]=" element.type === 'LSO' ? element.impago_percent_lso > 12.50 :
             element.type === 'DM' ? element.impago_percent_dm > 12.50 : false">
            <ng-container>{{element.impago_percent | number:'1.2-2'}}%</ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="caso_congelado">
          <th class="custom-center" mat-header-cell mat-sort-header *matHeaderCellDef>Caso Congelado Converted</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            <ng-container>{{element.caso_congelado}}</ng-container>
          </td>
          <td style="text-align: center" mat-footer-cell *matFooterCellDef>
            <b>{{allProductsTotals[selectedProduct][0]?.casoCongelado}}</b></td>
        </ng-container>

        <ng-container matColumnDef="documentacion_completa">
          <th class="custom-center" mat-header-cell mat-sort-header *matHeaderCellDef>Documentacion Completa</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            <ng-container>{{element.documentacion_completa}}</ng-container>
          </td>
          <td style="text-align: center" mat-footer-cell *matFooterCellDef>
            <b>{{allProductsTotals[selectedProduct][0]?.documentacionCompleta}}</b></td>
        </ng-container>

        <ng-container matColumnDef="quota_documentacion_completa">
          <th style="width: 130px" mat-header-cell mat-sort-header *matHeaderCellDef>Documentacion
            Completa {{"DASHBOARD.quota" | translate}}</th>
          <td style="text-align: right" mat-cell *matCellDef="let element"
              [style.display]="element.type !== 'DM' ? '' : 'none'">
            <small class="font-weight-lighter pr-3">
              {{ element.documentacion_completa }} / {{ element.target_documentacion_completa || 0 }}
            </small>
            <div class="progress" style="max-width: 120px">
              <div class="progress-bar progress-bar-striped" role="progressbar"
                   [style]="{width: getPercentage(element)  + '%'}"
                   [attr.aria-valuenow]="getPercentage(element)"
                   [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercentage(element) }}%
              </div>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr style="pointer-events: auto" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>

      <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="isLoading < 1 && dataSourceAllProducts && dataSourceAllProducts.data.length < 1"
           style="padding: 50px">
        <h3 class="text-center">{{ "DASHBOARD.no-agents" | translate }}</h3>
      </div>
    </div>
  </div>
    <div class="col-lg-4">
    <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div style="display: block; position: relative" *ngIf="!isLoading">
      <h2 class="text-center m-0">{{"DASHBOARD.caso-congelado-progress" | translate}}</h2>
      <canvas baseChart
              [data]="doughnutCharts.casoCongelado"
              [options]="doughnutCharts.casoCongelado.options"
              [labels]="doughnutCharts.casoCongelado.labels"
              [plugins]="doughnutCharts.casoCongelado.plugins"
              [type]="'doughnut'">
      </canvas>
      <div class="report top"><b>{{this.totalNumberOfCasoCongelado}}/{{this.monthlyQuotaCasoCongelado}}</b></div>
    </div>
    <div style="display: block; position: relative" *ngIf="!isLoading">
      <h2 class="text-center m-0 pt-3">{{"DASHBOARD.documentacion-completa-progress" | translate}}</h2>
      <canvas
        baseChart
        [data]="doughnutCharts.documentacionCompleta"
        [options]="doughnutCharts.documentacionCompleta.options"
        [labels]="doughnutCharts.documentacionCompleta.labels"
        [plugins]="doughnutCharts.documentacionCompleta.plugins"
        [type]="'doughnut'"
      >
      </canvas>
      <div class="report bottom"><b>{{this.totalNumberOfDocumentacionCompleta}}/{{this.monthlyQuotaDocCompleta}}</b>
      </div>
    </div>
  </div>
</div>
