<h3 *ngIf="userDocuments.length === 0"
    class="text-center user-document-list">{{ "CASES.single.no-files-uploaded" | translate }}
</h3>
<ng-container>
  <mat-expansion-panel *ngFor="let instance of userDocuments | keyvalue"
                       class="{{getDocumentStatus(instance.value)}}" style="transition: none"
                       (click)="openAccordionTab(instance.key)" [expanded]="isAccordionTabOpen[instance.key]">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ getTypeLabel(instance.key) }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <!-- <pre>{{ instance.key | json }}</pre> -->
    <!-- <pre>{{ instance.value | json }}</pre> -->
    <!-- <pre *ngIf="uploadForm">{{ uploadForm.get('slug').value }}</pre> -->
    <!-- <pre>{{ selectedFiles | json }}</pre> -->
    <!-- <pre>{{ uploadForm.value | json }}</pre> -->
    <ng-container>
      <form
        *ngIf="uploadForm && (!uploadForm.get('document_type_id').value || uploadForm.get('slug').value === instance.key)"
        [formGroup]="uploadForm" (ngSubmit)="uploadFiles(instance.key)" class="form">
        <div class="row">
          <div class="col-lg-4">
            <div class="form-group">
              <label [for]="'uploadFile1_' + instance.key">{{ "DOCUMENTS.upload-documents" | translate }}</label>
              <div class="input-group">
                <div class="custom-file">
                  <input multiple type="file" class="custom-file-input" [id]="'uploadFile1_' + instance.key"
                         (change)="onFileChange($event, instance.key)">
                  <label class="custom-file-label" [for]="'uploadFile1_' + instance.key">
                    {{ 'DOCUMENTS.choose-file' | translate }}
                  </label>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center" *ngFor="let file of selectedFiles">
                <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                <!-- <button mat-icon-button color="warn" (click)="removeSelectedFile($event, file.index)"> -->
                <!--   <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon"> -->
                <!--     remove_circle -->
                <!--   </mat-icon> -->
                <!-- </button> -->
              </div>
            </div>
          </div>
          <!-- <div style="padding-top: 25px"> -->
          <!--   <app-spinner-btn [loading]="uploadingFileSpinner" [name]="'SHARED.submit' | translate"></app-spinner-btn> -->
          <!-- </div> -->
        </div>
      </form>
    </ng-container>
    <div class="pt-lg-4 example-list" [formGroup]="docInfo" cdkDropList (cdkDropListDropped)="drop($event, instance)">
      <div class="row align-items-center" *ngFor="let document of instance.value" style="padding-bottom: 15px" cdkDrag>
        <div class="col-md-1">
          <div class="example-handle" cdkDragHandle>
            <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>
        </div>
        <div class="col-md-3">
          <div class="text-right d-flex flex-row align-items-center" *ngIf="document.id === editingDocumentId">
            <label class="pr-3" for="document_type">{{ 'SHARED.model.name' | translate }}: </label>
            <input type="text" class="form-control" formControlName="name">
          </div>
          <ng-container *ngIf="document.id !== editingDocumentId">
            <a target="_blank" href="{{storageUrl + document.location}}"
               (click)="selectFile($event, (storageUrl + document.location), document.mime_type)">{{ document.name }}</a>
            <label class="pl-1">({{ "CASES.single." + document.status | translate }})</label>
            <div>
              <button class="ml-2" mat-button color="primary"
                      (click)="downloadFile($event, document)">{{ "CASES.single.download" | translate }}
              </button>
              <button class="ml-2" mat-button color="primary"
                      (click)="downloadPdfFile($event, document)">{{ "CASES.single.download" | translate }} PDF
              </button>
            </div>
          </ng-container>
        </div>

        <div *ngIf="case" class="col-md-6">
          <div class="row pb-1">
            <div class="col-md-6 text-right d-flex flex-row align-items-center"
                 *ngIf="document.id === editingDocumentId">
              <label class="pr-3" for="document_type">Type: </label>
              <select class="form-control" id="document_type" formControlName="type">
                <optgroup *ngFor="let docTypeCategory of documentTypeCategories" [label]="docTypeCategory.label">
                  <option *ngFor="let docType of docTypeCategory.document_types" value="{{docType.name}}">
                    {{ docType.custom ? docType.name : docType.label }}
                  </option>
                </optgroup>
              </select>
            </div>
            <div class="col-md-6 text-right d-flex flex-row align-items-center"
                 *ngIf="document.id === editingDocumentId">
              <label class="pr-3" for="uploaded_by">Uploaded by: </label>
              <select class="form-control" id="uploaded_by" formControlName="uploaded_by">
                <option value="client">Client</option>
                <option value="partner">Partner</option>
              </select>
            </div>
            <div class="col-md-12" *ngIf="document.id !== editingDocumentId">
              <!-- <button mat-raised-button color="primary" (click)="changeStatus(document.id, $event, 'accepted')"> -->
              <button mat-raised-button color="primary">
                {{ "SHARED.accept" | translate }}
              </button>
              <!-- <button *ngIf="document.status !== 'declined'" mat-raised-button color="warn" (click)="changeStatus(document.id, $event, 'declined')" -->
              <button *ngIf="document.status !== 'declined'" mat-raised-button color="warn" class="ml-2">
                {{ "SHARED.decline" | translate }}
              </button>
              <!-- <button mat-raised-button color="warn" (click)="deleteFile($event, document.id)" class="ml-2"> -->
              <button mat-raised-button color="warn">
                {{ "SHARED.delete" | translate }}
              </button>
              <!-- <button mat-stroked-button color="primary" (click)="resendRequest(document.id, $event)" class="ml-2"> -->
              <button mat-stroked-button color="primary">
                {{ "CASES.single.resend-request" | translate }}
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="case" class="col-md-2 text-right">
          <!-- <button mat-button color="primary" *ngIf="document.id !== editingDocumentId" (click)="editDocument(document)"> -->
          <button mat-button color="primary" *ngIf="document.id !== editingDocumentId">
            {{ "SHARED.edit" | translate }}
          </button>
          <!-- <button mat-button color="primary" *ngIf="document.id === editingDocumentId" (click)="cancelEdit()"> -->
          <button mat-button color="primary" *ngIf="document.id === editingDocumentId">
            {{ "SHARED.cancel" | translate }}
          </button>
          <!-- <button mat-button color="primary" *ngIf="document.id === editingDocumentId" (click)="saveDoc(document, document.type.name)"> -->
          <button mat-button color="primary" *ngIf="document.id === editingDocumentId">
            {{ "SHARED.save" | translate }}
          </button>
        </div>
        <div class="col-md-12 d-flex" *ngIf="document.id === editingDocumentId && document.mime_type.includes('image')">
          <!-- <button class="ml-2" mat-raised-button color="primary" (click)="openEditModal($event, document)"> -->
          <button class="ml-2" mat-raised-button color="primary">
            {{ "CASES.single.edit-image" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-md-4">
        <div class="form-group" [formGroup]="mergedDoc">
          <input type="text" class="form-control" formControlName="fileName" placeholder="File name...">
        </div>
      </div>
      <div class="col-md-4">
        <button type="submit" class="mat-focus-indicator mat-raised-button mat-button-base mat-primary">
                <!-- (click)="saveMergedFiles($event, instance.key)"> -->
          <span *ngIf="!mergingFiles; else notMerging">Merge</span>
          <ng-template #notMerging>
            <mat-spinner diameter="20" color="accent" class="m-2"></mat-spinner>
          </ng-template>
        </button>
      </div>
      <div *ngIf="clientRole === 'client' ? completedForm : completedFormPartner" class="docs-completed"
           [formGroup]="clientRole === 'client' ? completedForm : completedFormPartner">
        <app-input *ngIf="findFileRequestId(instance.key, clientRole === 'partner')" type="checkbox" class="pb-3"
                   [formControlName]="instance.key+'-completed'" [extraLabel]="false"
                   [fullWidth]="true"
                   label="Completed">
                   <!-- label="Completed" (ngModelChange)="setDocAsCompleted($event, instance.key, clientRole)"> -->
        </app-input>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>
