import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { CaseDocumentService } from '../../../case-document.service';
import { CaseService } from '../../../case.service';

@Component({
  selector:    'app-client-installment-list-preview',
  templateUrl: './client-installment-list-preview.component.html',
  styleUrls:   ['./client-installment-list-preview.component.scss'],
})
export class ClientInstallmentListPreviewComponent implements OnInit {
  public case: Case;
  public isLoadingDocumentPreview = false;
  public documentHtml: string;
  public caseUuid: any;
  public isLoading                = 0;

  constructor(private caseService: CaseService,
              private caseDocumentService: CaseDocumentService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(
      (param: any) => {
        this.caseUuid = param.caseUuid;
        this.getClientInstallmentListPreview();
      },
    );
  }

  public getClientInstallmentListPreview(): void {
    this.caseDocumentService.previewClientInstallmentList(this.caseUuid).pipe(finalize(() => {
      this.isLoadingDocumentPreview = false;
    })).subscribe(
      result => {
        this.documentHtml = result.data;
      },
    );
  }
}
