import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Case, CaseVariable } from '../../../../../_base-shared/models/Case/Case';
import { CaseListFilter } from '../../../../../_base-shared/models/Case/CaseListFilter';
import { CasePaymentStatusLog } from '../../../../../_base-shared/models/Case/CasePaymentStatusLog';
import { CaseStatusLog } from '../../../../../_base-shared/models/Case/CaseStatusLog';
import { Proposal } from '../../../../../_base-shared/models/CaseDocument/Proposal';
import { City } from '../../../../../_base-shared/models/Location/City';
import { Payment } from '../../../../../_base-shared/models/Payment/Payment';
import { PaymentListFilter } from '../../../../../_base-shared/models/Payment/PaymentListFilter';
import { PaymentRequest } from '../../../../../_base-shared/models/Payment/PaymentRequest';
import { OppwaOrder } from '../../../../../_base-shared/models/Payment/Transaction/OppwaOrder';
import { RedsysOrder } from '../../../../../_base-shared/models/Payment/Transaction/RedsysOrder';
import { TaskTemplate } from '../../../../../_base-shared/models/Task/TaskTemplate';
import { User } from '../../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class CaseService extends MainBaseApiService {
  index(data): Observable<LaravelResourceResponse<Array<Case>>> {
    return this.http.get<LaravelResourceResponse<Array<Case>>>(`${ this.apiUrl }/cases`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public get(caseId: number, relations: Array<string> = []): Observable<LaravelResourceResponse<Case>> {
    console.log('fetching case ' + caseId);
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Case>>(this.apiUrl + '/cases/' + caseId, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public store(data): Observable<LaravelResourceResponse<Case>> {
    return this.http.post<LaravelResourceResponse<Case>>(`${ this.apiUrl }/cases`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public update(caseId, data, relations: Array<string> = []): Observable<LaravelResourceResponse<Case>> {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.put<LaravelResourceResponse<Case>>(`${ this.apiUrl }/cases/${ caseId }`, data, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public delete(caseId: number): Observable<LaravelResourceResponse<any>> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  public call(data): Observable<LaravelResourceResponse<Case>> {
    return this.http.post<LaravelResourceResponse<Case>>(`${ this.apiUrl }/cases/click-to-call`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  requestPayment(caseId: number, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/request-payment`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendSepaLink(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ data.caseId }/send-sepa-link`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  // Charge user's card for first time (tokenize / partial payment)
  chargeNewCard(caseId, data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/payment/initial/${ caseId }`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  chargeExistingCard(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/payment/charge/${ caseId }`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCaseVariables(): Observable<LaravelResourceResponse<Array<CaseVariable>>> {
    return this.http.get<LaravelResourceResponse<Array<CaseVariable>>>(this.apiUrl + '/cases/notification-variables')
      .pipe(catchError(response => this.handleError(response)));
  }

  indexTaskTemplateWatchers(caseId: number): Observable<LaravelResourceResponse<Array<TaskTemplate>>> {
    return this.http.get<LaravelResourceResponse<Array<TaskTemplate>>>(
      this.apiUrl + '/cases/' + caseId + '/task-templates',
    ).pipe(catchError(response => this.handleError(response)));
  }

  documentRequest(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/document-requests`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  setConfig(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/config`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  changeStatus(caseId, data): Observable<LaravelResourceResponse<Case>> {
    return this.http.post<LaravelResourceResponse<Case>>(`${ this.apiUrl }/cases/${ caseId }/change-status`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  recordPayment(data): Observable<LaravelResourceResponse> {
    return this.http.patch<LaravelResourceResponse>(`${ this.apiUrl }/terms`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public getPaymentHistory(data, relations: Array<string> = []): Observable<LaravelResourceResponse<Array<Payment>>> {
    const params = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Array<Payment>>>(
      this.apiUrl + '/cases/' + data.caseId + '/payment-history', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public editPaymentHistory(caseId, data): Observable<LaravelResourceResponse<Payment>> {
    return this.http.post<LaravelResourceResponse<Payment>>(
      this.apiUrl + '/cases/' + data.caseId + '/payment-history' + data.id, data,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public deletePaymentHistory(caseId, data): Observable<LaravelResourceResponse<Payment>> {
    return this.http.delete<LaravelResourceResponse<Payment>>(
      this.apiUrl + '/cases/' + data.caseId + '/payment-history' + data.id, {params: data},
    ).pipe(catchError(response => this.handleError(response)));
  }

  saveSignature(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/contracts`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  signProposal(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/proposal`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  signAep(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/aep/signature`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendViewedTimeProposal(uuId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ uuId }/proposal/viewed`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendProposal(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/send-proposal`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendNotaryEmail(caseId): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/send-notary-download-link`, null)
      .pipe(catchError(response => this.handleError(response)));
  }

  requestAepSign(caseId): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/aep/request-signature`, null)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendViewedTime(uuId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ uuId }/contracts/viewed`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  caseInfo(caseUuid: string, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Case>>(`${ this.apiUrl }/cases/${ caseUuid }/case-info`, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  getSmsTemplates(): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/sms-templates?all=1`)
      .pipe(catchError(response => this.handleError(response)));
  }

  getEmailTemplates(data): Observable<LaravelResourceResponse> {
    const params = MainBaseApiService.convertFiltersForRequest({...data}, 'get');
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/email-templates?all=1`, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  sendCaseNotifications(data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/cases/notifications', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendTermNotifications(data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/terms/notifications', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendContract(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ data.caseId }/send-contract`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendMandates(caseId): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/creditors/send-mandates`, null)
      .pipe(catchError(response => this.handleError(response)));
  }

  verifyPublicDebt(caseId, debtId, data): Observable<Object> {
    return this.http.post(`${ this.apiUrl }/cases/${ caseId }/public-debts/${ debtId }/verify-send-mandate`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  verifySignature(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/verify-signature', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendCase(caseId: number, data): Observable<LaravelResourceResponse<{ case: Case }>> {
    return this.http.post<LaravelResourceResponse<{ case: Case }>>(this.apiUrl + '/cases/' + caseId + '/send-case',
      data)
      .pipe(catchError(response => this.handleError(response)));
  }

  getStatusLogs(caseId: number, params = {}): Observable<LaravelResourceResponse<Array<CaseStatusLog>>> {
    return this.http.get<LaravelResourceResponse<Array<CaseStatusLog>>>(
      this.apiUrl + '/cases/' + caseId + '/status-logs', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  getCallStatusLogs(caseId: number, params = {}): Observable<LaravelResourceResponse<Array<CaseStatusLog>>> {
    return this.http.get<LaravelResourceResponse<Array<CaseStatusLog>>>(
      this.apiUrl + '/cases/' + caseId + '/call-status-logs', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  getPaymentStatusLogs(caseId: number, params = {}): Observable<LaravelResourceResponse<Array<CasePaymentStatusLog>>> {
    return this.http.get<LaravelResourceResponse<Array<CasePaymentStatusLog>>>(
      this.apiUrl + '/cases/' + caseId + '/payment-status-logs', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  exportCases(filters): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/export', {params: filters})
      .pipe(catchError(response => this.handleError(response)));
  }

  exportCasesCustomerContact(filters): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/export-customer-contact', {params: filters})
      .pipe(catchError(response => this.handleError(response)));
  }

  exportCasesLegal(filters): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/export-legal', {params: filters})
      .pipe(catchError(response => this.handleError(response)));
  }

  exportCasesLastAction(filters): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/last-action-report', {params: filters})
      .pipe(catchError(response => this.handleError(response)));
  }

  exportRunOffReports(filters): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/export-run-off', {params: filters})
      .pipe(catchError(response => this.handleError(response)));
  }

  pushToDialer(filters): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/cases/push-to-dialer', filters)
      .pipe(catchError(response => this.handleError(response)));
  }

  getTransactions(data, relations: Array<string> = []): Observable<LaravelResourceResponse<Array<OppwaOrder>>> {
    const params = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'get');

    return this.http.get<LaravelResourceResponse<Array<OppwaOrder>>>(`${ this.apiUrl }/transactions`, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  getPaymentOrders(caseId: number, data, relations: Array<string> = []): Observable<LaravelResourceResponse<Array<RedsysOrder>>> {
    const params = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'get');

    return this.http.get<LaravelResourceResponse<Array<RedsysOrder>>>(
      this.apiUrl + '/cases/' + caseId + '/payment-orders', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  generateProposal(caseId: number, data): Observable<LaravelResourceResponse<Proposal>> {
    return this.http.post<LaravelResourceResponse<Proposal>>(
      this.apiUrl + '/cases/' + caseId + '/propuesta-modelo', data,
    ).pipe(catchError(response => this.handleError(response)));
  }

  getPropuestaLogs(caseId): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/proposals/history`)
      .pipe(catchError(response => this.handleError(response)));
  }

  getAepHistory(caseId): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/aep-logs`)
      .pipe(catchError(response => this.handleError(response)));
  }

  getProposalData(uuId): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ uuId }/get-propuesta-modelo-data`)
      .pipe(catchError(response => this.handleError(response)));
  }

  getAEPData(caseId): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/aep/${ caseId }/form-data`)
      .pipe(catchError(response => this.handleError(response)));
  }

  getAEPSignatureData(uuid): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ uuid }/aep-data`)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendAEPData(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/aep/${ caseId }/generate`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  downloadAEP(caseId, data): Observable<HttpResponse<Blob>> {
    return this.http.get(`${ this.apiUrl }/aep/${ caseId }/download`, {
      params: data, observe: 'response', responseType: 'blob',
    })
      .pipe(catchError(response => this.handleError(response)));
  }

  // TODO: move to templates service
  parseNotificationVariables(data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/templates/parse-variables', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  getPaymentLinks(data): Observable<LaravelResourceResponse<Array<PaymentRequest>>> {
    return this.http.get<LaravelResourceResponse<Array<PaymentRequest>>>(
      this.apiUrl + `/cases/${ data.caseId }/payment-links`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  getAffiliateCases(data): Observable<LaravelResourceResponse<Array<Case>>> {
    return this.http.get<LaravelResourceResponse<Array<Case>>>(this.apiUrl + `/affiliate/cases`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  getCaseDepartmentAssignments(caseId: number): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/department-assignments')
      .pipe(catchError(response => this.handleError(response)));
  }

  generateAssetList(caseId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(
      this.apiUrl + '/cases/' + caseId + '/generate-asset-list', {observe: 'response', responseType: 'blob'},
    ).pipe(catchError(response => this.handleError(response)));
  }

  generateCreditorList(caseId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(
      this.apiUrl + '/cases/' + caseId + '/generate-creditor-list', {observe: 'response', responseType: 'blob'},
    ).pipe(catchError(response => this.handleError(response)));
  }

  sendCreditorAssetListViewedTime(uuId, data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ uuId }/creditor-asset-list/viewed`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  saveCreditorAssetListSignature(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/creditor-and-asset-list`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  requestCreditorAssetListSignature(caseId): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/creditor-asset-list/request-signature`,
      null)
      .pipe(catchError(response => this.handleError(response)));
  }

  getAutopopulateAddressList(data, relations: Array<string> = []): Observable<LaravelResourceResponse<Array<City>>> {
    data.with    = relations;
    const params = MainBaseApiService.convertFiltersForRequest(data, 'get');
    return this.http.get<LaravelResourceResponse<Array<City>>>(
      this.apiUrl + '/location/cities', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public updateCaseStatus(data: { bulk_status_id: number },
                          filters: CaseListFilter): Observable<LaravelResourceResponse<Array<Case>>> {
    const request = MainBaseApiService.convertFiltersForRequest({...filters, ...data}, 'post');

    return this.http.post<LaravelResourceResponse<Array<Case>>>(this.apiUrl + '/cases/bulk/update-status', request)
      .pipe(catchError(response => this.handleError(response)));
  }

  public updateCaseInvoiceStatus(data: { bulk_case_invoice_status_id: number },
                                 filters: CaseListFilter): Observable<LaravelResourceResponse<Array<Case>>> {
    const request = MainBaseApiService.convertFiltersForRequest({...filters, ...data}, 'post');

    return this.http.post<LaravelResourceResponse<Array<Case>>>(this.apiUrl + '/cases/bulk/update-case-invoice-status',
      request)
      .pipe(catchError(response => this.handleError(response)));
  }

  public updatePaymentStatus(data: { bulk_payment_status_id: number },
                             filters: CaseListFilter): Observable<LaravelResourceResponse<Array<Case>>> {
    const request = MainBaseApiService.convertFiltersForRequest({...data, ...filters}, 'post');

    return this.http.post<LaravelResourceResponse<Array<Case>>>(
      this.apiUrl + '/cases/bulk/update-payment-status', request,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public updateDepartmentAssignment(data: { bulk_department_id: number, bulk_user_id: number },
                                    filters: CaseListFilter): Observable<LaravelResourceResponse<Array<Case>>> {
    const request = MainBaseApiService.convertFiltersForRequest({...filters, ...data}, 'post');

    return this.http.post<LaravelResourceResponse<Array<Case>>>(
      this.apiUrl + '/cases/bulk/update-department-assignments', request,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getAgentsByCategory(departmentId: number): Observable<LaravelResourceResponse<Array<User>>> {
    return this.http.get<LaravelResourceResponse<Array<User>>>(this.apiUrl + '/departments/' + departmentId + '/users')
      .pipe(catchError(response => this.handleError(response)));
  }

  public toggleIsClaimable(caseId: number, caseCreditorId: number,
                           data: { type: string }): Observable<LaravelResourceResponse<Array<User>>> {
    return this.http.post<LaravelResourceResponse<Array<User>>>(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + caseCreditorId, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public togglePackagerUpdatable(caseId: number, data: {
    updatable_by_packager: boolean | 0 | 1
  }): Observable<LaravelResourceResponse<Case>> {
    return this.http.post<LaravelResourceResponse<Case>>(
      this.apiUrl + '/cases/' + caseId + '/set-updatable-by-packager', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public updateProductType(data: { product_id: Array<number> },
                           filters: PaymentListFilter): Observable<LaravelResourceResponse<Array<Case>>> {
    const request = MainBaseApiService.convertFiltersForRequest({...data, ...filters}, 'post');

    return this.http.post<LaravelResourceResponse<Array<Case>>>(
      this.apiUrl + '/cases/bulk/update-product-type', request,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public bulkDocumentsDownload(case_ids: Array<number>): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/bulk/document-download', {case_ids},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public previewCreditorProposal(caseUuid: string): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/documents/proposal-creditor-preview',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public previewCreditorProposalAccord(caseUuid: string, type: string, caseCreditorId: string,
                                       ownership: string): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/client/cases/' + caseUuid +
      '/documents/proposal-creditor-accord-preview?ownership=' + ownership + '&case_creditor_id=' + caseCreditorId,
    ).pipe(catchError(response => this.handleError(response)));

  }

  public duplicateCase(caseId: number): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/duplicate', {},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public acceptTransfer(caseId: number): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/transfer/accept', {},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public rejectTransfer(caseId: number): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/transfer/reject', {},
    ).pipe(catchError(response => this.handleError(response)));
  }


  public getInvoiceStatusLogs(caseId: number, params = {}): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse<Array<CasePaymentStatusLog>>>(
      this.apiUrl + '/cases/' + caseId + '/invoice-status-logs', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public saveLoan(caseId: number, data): Observable<LaravelResourceResponse<Case>> {
    return this.http.post<LaravelResourceResponse<Case>>(`${ this.apiUrl }/cases/${ caseId }/loans`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public exportRecordsDataAnalysis(filters): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/data-analysis-export', {params: filters})
      .pipe(catchError(response => this.handleError(response)));
  }

  public exportDoesntMeetRequirements(filters): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/doesnt-meet-requirements-export', {params: filters})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getInsolvencyOptions(data = {}): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/get-insolvency_causes`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public exportUploadedDocs(filters): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/export-uploaded-docs', {params: filters})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getDialerHistoryLogs(caseId: number, params = {}): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse<Array<CasePaymentStatusLog>>>(
      this.apiUrl + '/cases/' + caseId + '/dialer-history', {params})
      .pipe(catchError(response => this.handleError(response)));
  }
}
