<div class="row">
  <div class="col-12">
    <!-- Loader -->
    <div *ngIf="isLoading" class="col-12 text-center">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>
    <!-- Form -->
    <form class="mt-2" *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm(form)">
      <!-- Campaign Form -->
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex">
            <!-- Header -->
            <div class="flex-grow-1">
              <h3>
                {{ editorType === 'edit' ?
                ('CONFIG.drip-campaign.editor.header-edit' | translate) :
                ('CONFIG.drip-campaign.editor.header-create' | translate) }}
              </h3>
            </div>
            <div>
              <app-spinner-btn [loading]="isSubmitting" [name]="this.editorType === 'edit' ?
                        ('CONFIG.drip-campaign.editor.submit-edit' | translate) :
                        ('CONFIG.drip-campaign.editor.submit-create' | translate)">
              </app-spinner-btn>
            </div>
          </div>
          <div class="row">
            <!-- Name -->
            <div class="col-3">
              <app-input type="text" formControlName="name" appearance="standard" [fullWidth]="true"
                         [label]="'CONFIG.drip-campaign.editor.name.label' | translate">
              </app-input>
            </div>
            <!-- Statusable Type -->
            <div class="col-3">
              <app-input type="select" formControlName="statusable_type" appearance="standard" [fullWidth]="true"
                         [label]="'CONFIG.drip-campaign.editor.statusable_type.label' | translate" [searchable]="true"
                         [selectOptions]="statusableTypes" [selectLabel]="'label'" [selectValue]="'value'"
                         (ngModelChange)="statusableTypeChanged($event)">
              </app-input>
            </div>
            <!-- Statusable ID -->
            <div class="col-3">
              <ng-template [ngIf]="form.get('statusable_type').value === 'status'">
                <app-input type="select" formControlName="statusable_id" appearance="standard" [fullWidth]="true"
                           [label]="'CONFIG.drip-campaign.editor.statusable_id.label' | translate" [searchable]="true"
                           [selectOptions]="statusCategories" [optGroupProperty]="'statuses'" [optGroupLabel]="'name'"
                           [selectLabel]="'name'" [selectValue]="'id'">
                </app-input>
              </ng-template>

              <ng-template [ngIf]="form.get('statusable_type').value === 'payment_status'">
                <app-input type="select" formControlName="statusable_id" appearance="standard" [fullWidth]="true"
                           [label]="'CONFIG.drip-campaign.editor.statusable_id.label' | translate" [searchable]="true"
                           [selectOptions]="paymentStatuses" [selectLabel]="'name'" [selectValue]="'id'">
                </app-input>
              </ng-template>

              <ng-template [ngIf]="form.get('statusable_type').value === 'call_status'">
                <app-input type="select" formControlName="statusable_id" appearance="standard" [fullWidth]="true"
                           [label]="'CONFIG.drip-campaign.list.table-data.statusable_type.call_status' | translate"
                           [selectOptions]="callStatuses" [selectLabel]="'name'" [selectValue]="'id'" [searchable]="true">
                </app-input>
              </ng-template>
            </div>

            <!--Product type-->
            <div class="col-3">
              <app-input type="select" appearance="standard" [label]="'CONFIG.dialer.product_type' | translate"
                         formControlName="product_group_slugs" (ngModelChange)="productGroupUpdated($event)"
                         class="full-width" [showClear]="false" [fullWidth]="true" [showLabel]="true"
                         [selectOptions]="productType" [multiple]="true" [searchable]="true"
                         [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>

            <!--Packager-->
            <div class="col-3">
              <app-input type="select" formControlName="packager_id" appearance="standard" class="mt-2" [extraLabel]="false"
                         [label]="'PACKAGER.model_name.singular' | translate" showLabel="true" fullWidth="true" searchable="true"
                         [selectOptions]="packagers" [selectLabel]="['name_en']" [selectValue]="'id'">
              </app-input>
            </div>

            <!-- Active -->
            <div class="col-3 d-flex align-items-center">
              <app-input type="switch" formControlName="active" [fullWidth]="true"
                         [label]="'CONFIG.drip-campaign.editor.active.label' | translate">
              </app-input>
            </div>

            <!-- Allow on Weekend -->
            <div class="col-3 d-flex align-items-center">
              <app-input type="switch" formControlName="allow_on_weekend" [fullWidth]="true"
                         [label]="'CONFIG.drip-campaign.model.allow_on_weekend' | translate">
              </app-input>
            </div>

            <!-- Include unsubscribe -->
            <div class="col-3 d-flex align-items-center">
              <app-input type="switch" formControlName="include_unsubscribe" [fullWidth]="true"
                         [label]="'CONFIG.drip-campaign.editor.include_unsubscribe' | translate">
              </app-input>
            </div>
          </div>
        </div>
      </div>
      <!-- Drip Notifications Array -->
      <mat-accordion multi class="mt-2">
        <mat-expansion-panel *ngFor="let notification of dripNotificationsArray.controls; let i=index"
                             class="mt-2" formArrayName="drip_notifications">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="d-flex align-items-center justify-content-between" style="width: 100%">
                {{ 'CONFIG.drip-campaign.editor.drip-notification.header' | translate }} {{ i + 1 }}
                <button mat-stroked-button color="warn" (click)="removeDripNotification(i)">
                  {{ 'CONFIG.drip-campaign.editor.drip-notification.remove' | translate }}
                </button>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row drip-notification-{{ i }}" [formGroupName]="i">
            <div class="col-8">
              <!-- Channel -->
              <div class="row mt-2">
                <div class="col-6">
                  <app-input type="select" formControlName="channel" appearance="standard" [fullWidth]="true"
                             [label]="'CONFIG.drip-campaign.editor.drip-notification.channel.label' | translate"
                             [selectOptions]="notificationChannels" [selectLabel]="'name'" [selectValue]="'slug'"
                             (ngModelChange)="channelChanged($event, i)" [searchable]="true">
                  </app-input>
                </div>
                <!-- Delay -->
                <div class="col-3">
                  <app-input type="number" formControlName="delay" appearance="standard" [fullWidth]="true"
                             [label]="'CONFIG.drip-campaign.editor.drip-notification.delay.label' | translate">
                  </app-input>
                </div>
                <div class="col-3">
                  <app-input type="select" formControlName="delay_unit" appearance="standard" [fullWidth]="true"
                             [label]="'TASK-TEMPLATE.editor.delay_unit.label' | translate"
                             [selectOptions]="delayOptions" [selectLabel]="'label'" [selectValue]="'value'">
                  </app-input>
                </div>
                <!-- Subject -->
                <div class="col-6 mt-3" *ngIf="dripNotificationsArray.at(i).get('channel').value === 'email'">
                  <app-input type="text" formControlName="subject" appearance="standard" [fullWidth]="true"
                             [label]="'CONFIG.drip-campaign.editor.drip-notification.subject.label' | translate">
                  </app-input>
                </div>
                <!-- Fromable Type -->
                <div class="col-3 mt-3" *ngIf="dripNotificationsArray.at(i).get('channel').value === 'email'">
                  <app-input type="select" formControlName="fromable_type" appearance="standard" [fullWidth]="true"
                             [label]="'CONFIG.drip-campaign.editor.drip-notification.fromable_type.label' | translate"
                             [selectOptions]="fromableTypes" [selectLabel]="'label'" [selectValue]="'value'"
                             (ngModelChange)="fromableTypeChanged(i)" [searchable]="true">
                  </app-input>
                </div>
                <!-- Fromable ID -->
                <div class="col-3 mt-3" *ngIf="dripNotificationsArray.at(i).get('channel').value === 'email' &&
                dripNotificationsArray.at(i).get('fromable_type').value">
                  <ng-template [ngIf]="dripNotificationsArray.at(i).get('fromable_type').value === 'user'">
                    <app-input type="select" formControlName="fromable_id" appearance="standard" [fullWidth]="true"
                               [label]="'CONFIG.drip-campaign.editor.drip-notification.fromable_id.label' | translate"
                               [selectOptions]="users" [selectLabel]="['first_name', 'last_name']" [selectValue]="'id'"
                               [searchable]="true">
                    </app-input>
                  </ng-template>
                  <ng-template [ngIf]="dripNotificationsArray.at(i).get('fromable_type').value === 'department'">
                    <app-input type="select" formControlName="fromable_id" appearance="standard" [fullWidth]="true"
                               [label]="'CONFIG.drip-campaign.editor.drip-notification.fromable_id.label' | translate"
                               [selectOptions]="departmentCategories" [selectLabel]="'name'" [selectValue]="'id'"
                               [optGroupProperty]="'departments'" [searchable]="true">
                    </app-input>
                  </ng-template>
                </div>
                <!-- BCC -->
                <div class="col-6 mt-3">
                  <app-input type="text" formControlName="bcc" appearance="standard" [fullWidth]="true"
                             [label]="'CONFIG.drip-campaign.editor.drip-notification.bcc.label' | translate">
                  </app-input>
                </div>
                <!-- Sendgrid Template ID -->
                <div class="col-6 mt-3">
                  <app-input type="text" formControlName="sendgrid_template_id" appearance="standard" [fullWidth]="true"
                             [label]="'CONFIG.drip-campaign.editor.drip-notification.sendgrid_template_id.label' | translate">
                  </app-input>
                </div>
              </div>
              <!-- Body -->
              <div class="row mt-2">
                <div class="col-12">
                  <ng-container *ngIf="dripNotificationsArray.at(i).get('channel').value === 'sms'">
                    <app-input type="textarea" formControlName="body" appearance="standard" [fullWidth]="true"
                               [label]="'CONFIG.drip-campaign.editor.drip-notification.body.label' | translate">
                    </app-input>
                  </ng-container>
                  <ng-container *ngIf="dripNotificationsArray.at(i).get('channel').value === 'email'">
                    <label for="body-{{ i }}">
                      {{ 'CONFIG.drip-campaign.editor.drip-notification.body.label' | translate }}
                    </label>
                    <quill-editor *ngIf="dripNotificationsArray.at(i).get('channel').value === 'email'"
                                  formControlName="body" [styles]="{height: '300px'}">
                    </quill-editor>
                  </ng-container>
                </div>
              </div>
              <!-- Attachments -->
              <div class="row mt-2">
                <div class="col-12">
                  <div class="form-group">
                    <label for="new_attachments">{{"CONFIG.sms-template.attachments.label" | translate}}</label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input multiple type="file" class="custom-file-input" name="file" id="new_attachments"
                               (change)="onAttachmentsChange($event, i)">
                        <label
                          class="custom-file-label">{{"CONFIG.sms-template.attachments.placeholder" | translate}}</label>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end align-items-center"
                         *ngFor="let file of pickedFiles[i]; let p=index">
                      <mat-form-field appearance="standard">
                        <input type="text" matInput [value]="file.name" (change)="updateFileName($event, i, p)">
                        <span matSuffix>
                          {{ '.' + file.extension + ' ' }}<small>{{ file.size / 1048576 | number:'1.2-2' }}MB</small>
                        </span>
                      </mat-form-field>
                      <button mat-icon-button color="warn" (click)="removeLocalAttachment(i, p)">
                        <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-12"
                     *ngFor="let attachment of dripNotificationsArray.at(i).get('attachments')['controls']; let j=index"
                     formArrayName="attachments">
                  <div [formGroupName]="j" class="d-flex justify-content-end align-items-center">
                    <mat-form-field appearance="standard">
                      <input type="text" matInput formControlName="name">
                      <span matSuffix>
                        {{ '.' + attachment.get('extension').value + ' ' }}
                        <small>{{ attachment.get('size').value / 1048576 | number:'1.2-2' }}MB</small>
                      </span>
                    </mat-form-field>
                    <button mat-icon-button color="warn" (click)="removeAttachment(i, j)">
                      <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">
                        remove_circle
                      </mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Variables -->
            <div class="col-4">
              <div class="card shadow">
                <div
                  class="card-header">{{ 'CONFIG.drip-campaign.editor.drip-notification.variables' | translate }}</div>
                <div class="card-body" style="max-height: 500px; overflow: auto">
                  <h3 class="text-center" *ngFor="let template of templateVariables">
                    <code (click)="addVariable($event, i)">{{ template.label }}</code>
                    <span class="small" *ngIf="template.description"> - {{ template.description }}</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- Add new notification row action -->
      <div class="row mt-2">
        <div class="col">
          <button mat-raised-button color="primary" (click)="addDripNotification($event)">
            + {{ 'CONFIG.drip-campaign.editor.drip-notification.add-new' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
