<div *ngIf="case" class="card shadow card-height detail-documents mb-4">
  <div class="card-header py-4">
    <div class="d-flex">
      <h1>{{ "CASES.single.documents.heading" | translate }}</h1>
    </div>
    <div class="d-flex get-files-btns">
      <a mat-raised-button color="primary" [disabled]="!latestContract?.pdf_location" target="_blank"
         href="{{storageUrl + latestContract?.pdf_location}}"
         [title]="!latestContract?.pdf_location ?
          ('CASES.single.get-contract-button-disabled' | translate) :
           ('DOCUMENTS.get-contract' | translate)">
        {{ "DOCUMENTS.get-contract" | translate }}
      </a>
      <a mat-raised-button color="primary" class="ml-3" [disabled]="!latestContract?.redacted_pdf_location"
         target="_blank"
         href="{{storageUrl + latestContract?.redacted_pdf_location}}"
      >
        {{ "DOCUMENTS.get-redacted-contract" | translate }}
      </a>
      <!--Get mandate button for single case-->
      <a mat-raised-button color="primary" class="ml-3" *ngIf="!case.joint_application"
         [disabled]="!latestContract?.mandate_location" target="_blank"
         href="{{storageUrl + latestContract?.mandate_location}}"
         [title]="!latestContract?.mandate_location ?
          ('CASES.single.mandates-button-disabled' | translate) :
           ('CASES.single.get-mandates' | translate)">
        {{ "CASES.single.get-mandates" | translate }}
      </a>
      <!--   Get mandate button for joint case   -->
      <a mat-raised-button color="primary" class="ml-3" *ngIf="case.joint_application"
         [disabled]="!latestContract?.mandate_location" target="_blank"
         href="{{storageUrl + latestContract?.mandate_location}}"
         [title]="!latestContract?.mandate_location ?
          ('CASES.single.mandates-button-disabled' | translate) :
           ('CASES.single.get-client-1-mandates' | translate)">
        {{ "CASES.single.get-client-1-mandates" | translate }}
      </a>
      <a mat-raised-button color="primary" class="ml-3" *ngIf="case.joint_application"
         [disabled]="!latestContract?.mandate_location_1" target="_blank"
         href="{{storageUrl + latestContract?.mandate_location_1}}"
         [title]="!latestContract?.mandate_location_1 ?
          ('CASES.single.mandates-button-disabled' | translate) :
           ('CASES.single.get-client-2-mandates' | translate)">
        {{ "CASES.single.get-client-2-mandates" | translate }}
      </a>
      <ng-container>
        <button [matMenuTriggerFor]="clientRoleMenu" mat-raised-button color="primary" class="ml-2"
                *ngIf="authUser.packager.master">
          {{ 'CASES.single.send_auto_de_concurso' | translate }}
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #clientRoleMenu="matMenu">
          <button mat-menu-item (click)="sendAutoDeConscursoDoc('client')" [disabled]="!autoDeConcursoUploaded">
            <span>{{ 'CASES.single.send_to_client' | translate }} 1</span>
          </button>
          <button mat-menu-item (click)="sendAutoDeConscursoDoc('partner')" *ngIf="authUser.packager.master"
                  [disabled]="!case.joint_application || !autoDeConcursoUploaded">
            <span>{{ 'CASES.single.send_to_client' | translate }} 2</span>
          </button>
        </mat-menu>
      </ng-container>
      <button [matMenuTriggerFor]="autoDeExoneracionMenu" mat-raised-button color="primary" class="ml-2"
              *ngIf="authUser.packager.master">
        {{ 'CASES.single.send_auto_de_exoneracion' | translate }}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #autoDeExoneracionMenu="matMenu">
        <button mat-menu-item (click)="sendAutoDeExoneracionDoc('client')"
                [disabled]="!autoDeExoneracionUploadedClient">
          <span>{{ 'CASES.single.send_to_client' | translate }} 1</span>
        </button>
        <button mat-menu-item (click)="sendAutoDeExoneracionDoc('partner')"
                [disabled]="!case.joint_application || !autoDeExoneracionUploadedPartner">
          <span>{{ 'CASES.single.send_to_client' | translate }} 2</span>
        </button>
      </mat-menu>
      <div *ngIf="authUser.role_id === 5 || authUser.role_id === 1 || authUser.role_id === 2"
           class="d-flex justify-content-center flex-wrap" style="min-width: 245px; margin-left: auto">
        <div *ngIf="authUser.id === 1 || authUser.id === 27 || authUser.id === 36 || authUser.id === 3 || authUser.id === 6 || authUser.id === 967
           || authUser.id === 235517|| authUser.id === 582 || authUser.id === 513023 || authUser.id === 34 || authUser.id === 26
           || authUser.role_id === 2 || authUser.id === 705239 || authUser.id === 211750 || authUser.id === 667649"
             class="dropleft d-flex">
          <button [disabled]="!latestContract?.pdf_location" mat-raised-button color="primary" class="btn"
                  [matMenuTriggerFor]="resignContractMenu"
                  type="button" [title]="!latestContract?.pdf_location ? ('DOCUMENTS.resign-contract-disabled' | translate) :
                   ('DOCUMENTS.resign-contract' | translate)">
            <mat-icon style="">chevron_left</mat-icon>
            {{ "DOCUMENTS.resign-contract" | translate }}
          </button>
          <mat-menu #resignContractMenu="matMenu" class="resignContractMenu" xPosition="after">
            <a mat-menu-item (click)="resignContract($event, 'email', 'client')" href="#"> Client Email</a>
            <a mat-menu-item (click)="resignContract($event, 'sms', 'client')" href="#"> Client SMS</a>
            <a mat-menu-item (click)="resignContract($event, 'all', 'client')" href="#"> Client Email & SMS</a>
            <a *ngIf="case.joint_application"
               mat-menu-item (click)="resignContract($event, 'email', 'partner')" href="#"> Client 2 Email</a>
            <a *ngIf="case.joint_application"
               mat-menu-item (click)="resignContract($event, 'sms', 'partner')" href="#"> Client 2 SMS</a>
            <a *ngIf="case.joint_application"
               mat-menu-item (click)="resignContract($event, 'all', 'partner')" href="#"> Client 2 Email &
              SMS</a>
          </mat-menu>
        </div>
        <button
          *ngIf="authUser.id === 1 || authUser.id === 22 || authUser.id === 27 || authUser.id === 36 || authUser.id === 2497
             || authUser.id === 26 || authUser.id === 513023 || authUser.id ===  667646 || authUser.id === 667649 ||
             authUser.id === 2597 || authUser.id === 704999"
          [disabled]="regeneratingContract ||
           !((!case.joint_application && latestContract?.signature) || (case.joint_application && latestContract?.signature && latestContract?.signature_1))"
          class="ml-3"
          mat-raised-button color="primary" (click)="regenerateContract()"
          [title]="!latestContract?.pdf_location ? ('DOCUMENTS.regenerate-contract-disabled' | translate) : ('DOCUMENTS.regenerate-contract' | translate)">
          {{ "CASES.single.regenerate-contract" | translate }}
        </button>
        <mat-spinner *ngIf="regeneratingContract" diameter="30" class="m-0 m-auto"></mat-spinner>
        <button *ngIf="case.client" mat-raised-button color="primary" class="ml-3"
                (click)="verifySignature(case.client)">
          {{
            ! case.client.signature_verified_at ?
              ("CASES.details.signature-verify" | translate) :
              ("CASES.details.view-signature" | translate)
          }}
        </button>
      </div>
    </div>
    <button mat-raised-button color="primary" class="mt-2" (click)="sendSignedContract()"
            *ngIf="authUser.packager.master">
      {{ 'CASES.single.send-signed-contract' | translate }}
    </button>
    <button *ngIf="advicePackVisible" [matMenuTriggerFor]="advicePack" mat-raised-button color="primary"
            class="ml-2 mt-2">
      {{ 'CASES.single.resend_advice_pack' | translate }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #advicePack="matMenu">
      <button mat-menu-item (click)="resendAdvicePackEmail(true)">
        <span>{{ 'CASES.single.generate' | translate }}</span>
      </button>
      <button mat-menu-item (click)="resendAdvicePackEmail(false)">
        <span>{{ 'CASES.single.dont_generate' | translate }}</span>
      </button>
    </mat-menu>
    <!-- Debt Cancellation -->
    <button [matMenuTriggerFor]="debtCancellationRequestMenu" mat-raised-button color="primary" class="ml-2 mt-2"
            *ngIf="authUser.packager.master">
      {{ 'CASES.single.documents.debt_cancellation_request.action' | translate }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #debtCancellationRequestMenu="matMenu">
      <a mat-menu-item [routerLink]="['/client/sign-debt-cancellation-request']" target="_blank"
         [queryParams]="{uuid: case.uuid, type: 'debt-cancellation-request', sign: 'client'}">
        {{ 'CASES.single.documents.debt_cancellation_request.preview' | translate }} 1
      </a>
      <a mat-menu-item [routerLink]="['/client/sign-debt-cancellation-request']" target="_blank"
         [queryParams]="{uuid: case.uuid, type: 'debt-cancellation-request', sign: 'partner'}">
        {{ 'CASES.single.documents.debt_cancellation_request.preview' | translate }} 2
      </a>
      <button mat-menu-item (click)="requestDebtCancellationRequestSignature('client')">
        {{ 'CASES.single.documents.debt_cancellation_request.request_signature.action' | translate }} 1
      </button>
      <button mat-menu-item (click)="requestDebtCancellationRequestSignature('partner')">
        {{ 'CASES.single.documents.debt_cancellation_request.request_signature.action' | translate }} 2
      </button>
      <button mat-menu-item (click)="sendEmailToExperian()" [disabled]="!areExperianAttachmentsReady">
        {{ 'CASES.single.documents.debt_cancellation_request.send_email_to_experian' | translate }}
      </button>
    </mat-menu>
    <!--  Send debt exoneration -->
    <button [matMenuTriggerFor]="debtExonerationMenu" mat-raised-button color="primary" class="ml-2 mt-2"
            *ngIf="authUser.packager.master">
      {{ 'CASES.single.documents.send_debt_exoneration.action' | translate }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #debtExonerationMenu="matMenu">
      <a mat-menu-item [routerLink]="['/public/sign-debt-exoneration']" target="_blank"
         [queryParams]="{uuid: case.uuid, sign: ''}">
        {{ 'CASES.single.documents.send_debt_exoneration.preview' | translate }}
      </a>
      <button mat-menu-item (click)="requestDebtExonerationSignature('sms')">
        {{ 'CASES.single.documents.send_debt_exoneration.request_signature.action-sms' | translate }}
      </button>
      <button mat-menu-item (click)="requestDebtExonerationSignature('email')">
        {{ 'CASES.single.documents.send_debt_exoneration.request_signature.action-email' | translate }}
      </button>
      <a mat-menu-item href="{{storageUrl + debtExonerationDocument?.files[0]?.path}}"
         [disabled]="!debtExonerationDocument?.files[0]?.path">
        {{ 'CASES.single.documents.send_debt_exoneration.download' | translate }}
      </a>
    </mat-menu>
  </div>
  <router-outlet></router-outlet>
</div>
