import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NotificationFromEvent } from '../../../../../../_base-shared/models/Notification/NotificationFromEvent';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CourtService } from '../court/court.service';
import { Court } from '../../../../../../_base-shared/models/Entity/Court';
import { NotariesService } from './notaries.service';
import Swal from 'sweetalert2';

@Component({
  selector   : 'app-notaries',
  templateUrl: './notaries.component.html',
  styleUrls  : ['./notaries.component.scss']
})
export class NotariesComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public dataSource: MatTableDataSource<any>;
  public search              = new UntypedFormControl('');
  public searchFocus         = false;
  displayedColumns: string[] = [
    'id',
    'name',
    'company_name',
    'approved',
    'address',
    'email_general',
    'submission_method',
    'actions',
  ];
  public paginatorConfig     = {
    pageIndex: 0,
    pageSize : 10,
    length   : 1
  };
  public isLoading           = 0;

  constructor(private translate: TranslateService,
              private toastr: ToastrService,
              private notariesService: NotariesService) {
  }

  ngOnInit(): void {
    this.fetchNotaries();

    this.search.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
      )
      .subscribe(res => {
        this.fetchNotaries();
      });
  }

  fetchNotaries() {
    this.dataSource = new MatTableDataSource<any>([]);
    this.isLoading++;
    const data = {
      per_page: this.paginatorConfig.pageSize,
      page    : this.paginatorConfig.pageIndex + 1,
      search  : this.search.value
    };

    this.notariesService.index(data).pipe(finalize(() => this.isLoading--)).subscribe(res => {
      this.dataSource             = new MatTableDataSource<Court>(res.data);
      this.dataSource.sort        = this.sort;
      this.paginatorConfig.length = res.data.total;
    });
  }

  toggleFocus(setFocus: boolean) {
    this.searchFocus = setFocus;
  }

  openDeleteDialog(id, $event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('ADDRESS-BOOK.delete-notary-note'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.delete'),
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.deleteNotary(id);
      }
    });
  }

  public paginatorChange($event: any) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchNotaries();
  }

  private deleteNotary(caseId: number) {
    this.notariesService.delete(caseId).subscribe(
      res => {
        this.fetchNotaries();
        this.toastr.success(this.translate.instant('ADDRESS-BOOK.delete-notary-success'),
          this.translate.instant('SHARED.success'));
      },
      error => {
        this.toastr.error(error.error?.message, this.translate.instant('ADDRESS-BOOK.delete-notary-error'),
          this.translate.instant('SHARED.error'));
      },
    );
  }

}
