import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { UploadService } from '../../../../../app-file/upload.service';
import { CaseService } from '../../../../case.service';

@Component({
  selector:    'app-edit-history-modal',
  templateUrl: './edit-history-modal.component.html',
  styles:      [],
})
export class EditHistoryModalComponent implements OnInit {
  public formReady     = false;
  public isSavingEdits = false;
  public form: UntypedFormGroup;
  public quillModules  = {
    imageUploader: {
      upload: (file) => this.uploadFile(file),
    },
  };

  constructor(private fb: UntypedFormBuilder,
              public uploadService: UploadService,
              private caseService: CaseService,
              private toastr: ToastrService,
              private translateService: TranslateService,
              public dialogRef: MatDialogRef<EditHistoryModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form      = this.fb.group({
      payment_date:   [this.data.payment_date || new Date(), Validators.required],
      amount:         [this.data.amount || 0, Validators.required],
      note:           [this.data.note, null],
      payment_method: [this.data.payment_method, Validators.required],
    });
    this.formReady = true;
  }

  uploadFile(file: any) {
    return this.uploadService.quillImgUpload(file);
  }

  onCancel() {
    this.dialogRef.close();
  }

  saveEdits() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isSavingEdits = true;
    const data         = {
      ...this.form.value,
      id: this.data.id,
    };

    data.payment_date =  DateTime.fromISO(data.payment_date).toFormat('yyyy-LL-dd');

    this.caseService.editPaymentHistory(this.data.case_id, data)
        .pipe(finalize(() => this.isSavingEdits = false))
        .subscribe(
            res => {
              this.dialogRef.close(true);
              this.toastr.success(this.translateService.instant('CASES.single.history-edited'));
            },
            err => {
              console.error(err);
              this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
            },
        );
  }
}
