import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from '../../../../../../../environments/environment';
import { CaseDocumentService } from '../../../../case-document.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LegacyDocumentTypeService } from '../../../../../document/legacy-document-type.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ImgEditModalComponent } from '../../../../../../_shared/components/img-edit-modal/img-edit-modal.component';
import { saveAs } from 'file-saver';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DocumentType } from '../../../../../../../../../_base-shared/models/DocumentType';

@Component({
  selector:    'app-all-creditor-files',
  templateUrl: './all-creditor-files.component.html',
  styleUrls:   ['./all-creditor-files.component.scss']
})
export class AllCreditorFilesComponent implements OnInit {
  @Input() case;
  @Input() type;
  @Input() filesByCreditor;
  @Input() filesByPublicDebt;
  @Input() partnerFilesByCreditor;
  @Input() partnerFilesByPublicDebt;
  @Input() updateFiles;
  @Input() allCreditors;
  @Output() documentInfoChange$: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedFileEvent                      = new EventEmitter<object>();

  public allDocTypes: DocumentType[];
  public newType                = null;
  public storageUrl             = environment.STORAGE_URL + '/';
  public uploadedFiles          = [];
  public uploadedFilesPartner   = [];
  public creditorFiles          = [];
  public creditorFilesPartner   = [];
  public publicDebtFiles        = [];
  public publicDebtFilesPartner = [];
  public creditorFilesFiltered  = [];
  public isAccordionTabOpen     = {};
  public newName                = '';
  public toEdit;
  public docInfo                = this.fb.group({
    name:        '',
    type:        '',
    uploaded_by: '',
  });
  public mergingFiles           = false;
  public mergedDoc              = this.fb.group({
    fileName: '',
    type:     ''
  });

  constructor(
    private fb: UntypedFormBuilder,
    private documentService: CaseDocumentService,
    private documentTypeService: LegacyDocumentTypeService,
    public toastr: ToastrService,
    public dialog: MatDialog,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.documentTypeService.get()
      .subscribe(res => {
        this.allDocTypes = res;
      });

    this.updateFiles.subscribe(next => {
      if (next.data) {
        this.setUploadedDocuments(next.data);
      }
    });
    this.setUploadedDocuments();

    //  Generate object to use to keep accordion open after user accept or decline file
    for (const property in this.creditorFilesFiltered) {
      if (this.creditorFilesFiltered.hasOwnProperty(property)) {
        this.isAccordionTabOpen[property] = false;
      }
    }
  }

  setUploadedDocuments(data = null) {
    if ( ! data) {
      //  Get all files from client and partner
      this.creditorFiles          = this.filesByCreditor;
      this.creditorFilesPartner   = this.partnerFilesByCreditor;
      this.publicDebtFiles        = this.filesByPublicDebt;
      this.publicDebtFilesPartner = this.partnerFilesByPublicDebt;

      //  Merge creditor files and public debt files
      if (this.type === 'client') {
        this.creditorFilesFiltered = { ...this.creditorFiles, ...this.publicDebtFiles };
        if (Object.keys(this.creditorFilesFiltered).length === 0) {
          this.creditorFilesFiltered = [];
        }
      } else {
        this.creditorFilesFiltered = { ...this.creditorFilesPartner, ...this.publicDebtFilesPartner };
        if (Object.keys(this.creditorFilesFiltered).length === 0) {
          this.creditorFilesFiltered = [];
        }
      }
    } else {
      //  Get all files from client and partner
      this.creditorFiles          = data?.files_by_creditor;
      this.creditorFilesPartner   = data?.partner_files_by_creditor;
      this.publicDebtFiles        = data?.files_by_public_debt;
      this.publicDebtFilesPartner = data?.partner_files_by_public_debt;

      //  Merge creditor files and public debt files
      if (this.type === 'client') {
        this.creditorFilesFiltered = { ...this.creditorFiles, ...this.publicDebtFiles };
        if (Object.keys(this.creditorFilesFiltered).length === 0) {
          this.creditorFilesFiltered = [];
        }
      } else {
        this.creditorFilesFiltered = { ...this.creditorFilesPartner, ...this.publicDebtFilesPartner };
        if (Object.keys(this.creditorFilesFiltered).length === 0) {
          this.creditorFilesFiltered = [];
        }
      }
    }
  }

  openAccordionTab(name) {
    //  Update object to keep accordion open
    this.isAccordionTabOpen[name] = true;
  }

  getDocumentStatus(documents) {
    let status = '';
    documents.map(document => {
      if (status !== 'pending' && status !== 'declined') {
        status = document.status;
      }
    });
    if (status === 'pending') {
      return 'pending-document';
    } else if (status === 'accepted') {
      return 'accepted-document';
    } else if (status === 'declined') {
      return 'declined-document';
    } else {
      return 'empty-docs';
    }
  }

  formatFileType(name) {
    if (name === 'social-security') {
      return 'Social security';
    }
    if (name === 'town-hall') {
      return 'Town hall';
    }
    if (name === 'estate') {
      return 'Estate';
    }

    return name;
  }

  editDocument(document) {
    if (document.creditor) {
      this.docInfo.setValue({
        name:        document.name,
        type:        document.creditor.name,
        uploaded_by: document.uploaded_by
      });
    } else if (document.public_debt) {
      this.docInfo.setValue({
        name:        document.name,
        type:        document.public_debt.public_organisation,
        uploaded_by: document.uploaded_by
      });
    } else {
      this.docInfo.setValue({
        name:        document.name,
        type:        document.type.name,
        uploaded_by: document.uploaded_by,
      });
    }
    this.newName = document.name;
    this.toEdit  = document.id;
  }

  cancelEdit() {
    this.docInfo.setValue({
      name:        '',
      type:        '',
      uploaded_by: '',
    });
    this.toEdit  = null;
    this.newType = null;
  }

  documentStatusChange(fileId, $event = null, status) {
    if ($event) {
      $event.preventDefault();
    }
    const data = {
      status,
      caseId: this.case.id,
      fileId
    };
    this.documentService.changeStatus(data)
      .subscribe(
        res => {
          this.setUploadedDocuments(res.data);
          this.toastr.success(this.translate.instant('DOCUMENTS.file-status-changed-success'));
        },
        err => {
          this.toastr.error(this.translate.instant('DOCUMENTS.file-status-changed-error'));
        }
      );
  }

  deleteFile($event, id) {
    $event.preventDefault();

    this.documentService.removeUploadedFile(this.case.uuid, id)
      .subscribe(
        res => {
          this.setUploadedDocuments(res.data);
          this.toastr.success(this.translate.instant('DOCUMENTS.file-deleted'));
        },
        err => {
          this.toastr.error(this.translate.instant('DOCUMENTS.file-deleted-error'));
        }
      );
  }

  saveDoc(document, oldType) {
    if (this.docInfo.value.name === '') {
      return;
    }
    if (this.docInfo.value.type === oldType &&
      this.docInfo.value.name === document.name &&
      this.docInfo.value.uploaded_by === document.uploaded_by
    ) {
      this.toEdit = null;
      this.docInfo.setValue({
        name:        '',
        type:        '',
        uploaded_by: ''
      });
      return;
    }
    const newTypeDoc    = this.allDocTypes.find(type => type.name === this.docInfo.value.type);
    const newTypeCred   = this.allCreditors.find(type => type.name === this.docInfo.value.type);
    const newTypePublic = this.allCreditors.find(type => type.public_organisation === this.docInfo.value.type);

    const data = {
      caseId:              this.case.id,
      fileId:              document.id,
      document_type_id:    newTypeDoc?.id || null,
      creditor_id:         newTypeCred?.id || null,
      case_public_debt_id: newTypePublic?.id || null,
      name:                this.docInfo.value.name,
      uploaded_by:         this.docInfo.value.uploaded_by
    };
    this.documentService.changeStatus(data)
      .subscribe(
        res => {
          this.setUploadedDocuments(res.data); //  Update document list
          this.toastr.success(this.translate.instant('DOCUMENTS.file-status-changed-success'));

          if (newTypeDoc) { // Update personal documents list in parent component
            this.documentInfoChange$.emit({
              document,
              newTypeDoc,
              newTypeCred,
              newTypePublic,
              oldType,
              name: data.name
            });
          }
        },
        err => {
          this.toastr.error(this.translate.instant('DOCUMENTS.file-details-changed-error'));
        }
      );
    this.toEdit = null;
    this.docInfo.setValue({
      name:        '',
      type:        '',
      uploaded_by: ''
    });
  }

  resendRequest(fileId, $event) {
    $event.preventDefault();

    const data = {
      caseId:      this.case.id,
      fileId,
      client_type: this.type
    };
    this.documentService.resendRequest(data)
      .subscribe(
        res => {
          this.toastr.success(this.translate.instant('CASES.single.request-sent-success'), this.translate.instant('SHARED.success'));
        },
        err => {
          this.toastr.error(this.translate.instant('CASES.single.request-sent-error'), this.translate.instant('SHARED.error'));
        }
      );
  }


  public openEditModal(event, document) {
    event.preventDefault();
    //  Open dialog modal
    const dialogRef = this.dialog.open(ImgEditModalComponent, {
      width: '50%',
      data:  {
        document,
        case: this.case
      },
    });
    //  On close fire handle response
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const newTypeDoc    = this.allDocTypes.find(type => type.name === this.docInfo.value.type);
        const newTypeCred   = this.allCreditors.find(type => type.name === this.docInfo.value.type);
        const newTypePublic = this.allCreditors.find(type => type.public_organisation === this.docInfo.value.type);

        this.setUploadedDocuments(result); //  Update document list
        this.toastr.success(this.translate.instant('DOCUMENTS.file-status-changed-success'));
        this.documentInfoChange$.emit({
          document,
          newTypeDoc,
          newTypeCred,
          newTypePublic,
          oldType:     document.creditor ? document.creditor.name : document.public_organisation,
          name:        this.docInfo.value.name,
          uploaded_by: this.docInfo.value.uploaded_by
        });

        this.toEdit = null;
        this.docInfo.setValue({
          name:        '',
          type:        '',
          uploaded_by: ''
        });
      }
    });
  }

  downloadFile($event: MouseEvent, document: any) {
    saveAs(this.storageUrl + document.location, document.name + '.' + document.extension);
  }

  downloadPdfFile($event: MouseEvent, document: any) {
    saveAs(this.storageUrl + document.pdf_location, document.name + '.pdf');
  }

  drop(event: CdkDragDrop<string[]>, instance) {
    moveItemInArray(instance.value, event.previousIndex, event.currentIndex);
  }

  saveMergedFiles($event: MouseEvent, key: string) {
    this.mergingFiles  = true;
    const documentsIds = this.creditorFilesFiltered[key].map((document) => {
      return document.id;
    });

    const data = {
      document_ids:  documentsIds,
      name:          this.mergedDoc.value.fileName,
      document_type: key,
      uploaded_by:   this.type,
    };

    this.documentService.mergeDocuments(this.case.id, data).subscribe(res => {
      this.mergingFiles = false;
      this.toastr.success(this.translate.instant('DOCUMENTS.merged-toastr-success'));
      this.setUploadedDocuments(res.data);
    }, error => {
      this.mergingFiles = false;
      this.toastr.error(this.translate.instant('DOCUMENTS.merged-toastr-error'));
    });
  }

  selectFile(e: Event, url: string, type: string) {
    e.preventDefault();
    this.selectedFileEvent.emit({
      url,
      type: type?.includes('image') ? 'image' : 'object'
    });
  }
}
