<h1 mat-dialog-title>{{ data.relocate ? ('CASES.single.relocate-payment' | translate) : ('CASES.single.record-payment' | translate)}}</h1>
<mat-spinner *ngIf="!form" class="m-0 m-auto"></mat-spinner>
<div mat-dialog-content *ngIf="form">
  <form [formGroup]="form">
    <div *ngIf="!data.relocate" class="row">
      <div class="col-md-7">
        <!-- Amount -->
        <app-input type="number" formControlName="amount" [fullWidth]="true"
                   [label]="('CASES.single.amount' | translate)" [placeholder]="'CASES.single.enter-amount' | translate"
                   [hint]="'CASES.single.refund' | translate">
        </app-input>
        <p class="mt-2">{{ 'CASES.single.split-type' | translate }}</p>
        <mat-radio-group aria-label="Application Type" class="d-flex justify-content-between">
          <mat-radio-button (click)="changeType('even')" value="even">
            {{ 'CASES.single.split-even' | translate }}
          </mat-radio-button>
          <mat-radio-button (click)="changeType('reduce')" value="last">
            {{ 'CASES.single.reduce-term' | translate }}
          </mat-radio-button>
          <mat-radio-button (click)="changeType('custom')" checked value="custom">
            {{ 'CASES.single.custom' | translate }}
          </mat-radio-button>
        </mat-radio-group>
        <div class="row">
          <!-- Payment Method -->
          <app-input type="select" formControlName="payment_method" class="col-4" appearance="standard"
                     [fullWidth]="true" [label]="'CASES.single.payment-method' | translate"
                     (ngModelChange)="paymentMethodChanged($event)" [selectOptions]="paymentMethods"
                     [selectLabel]="'name'" [selectValue]="'slug'" [searchable]="true">
          </app-input>
          <!-- Income Account -->
          <app-input type="select" formControlName="income_account_id" class="col-4" appearance="standard"
                     [fullWidth]="true" [label]="'Bank'" [selectOptions]="incomeBankAccounts" [searchable]="true"
                     [selectLabel]="['bank_name', 'account_number']" [selectValue]="'id'" [showClear]="false">
          </app-input>
        </div>

        <button mat-button color="primary" class=" pt-2" *ngIf="!showNotes"
                (click)="showNotes = true">{{'CASES.single.add-payment-note' | translate}}</button>
        <button mat-button color="primary" class=" pt-2" *ngIf="showNotes"
                (click)="showNotes = false">{{'CASES.single.hide-payment-note' | translate}}</button>
      </div>

      <div class="col-md-5">
        <div class="border border-primary rounded p-3 mx-3 mb-3 mt-0">
          <h2 class="text-primary">{{'CASES.single.unallocated' | translate}}</h2>
          <h1 class="text-primary">{{unallocated.toFixed(2)}}</h1>
        </div>
        <div>
          <small>{{ "CASES.single.payment-date" | translate }}</small><br>
          <span>{{ form.get('payment_date').value ? (form.get('payment_date').value | date: 'd/M/y') : '' }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="data.relocate" class="d-flex flex-column w-100 align-items-center justify-content-center">
      <div class="border border-primary rounded p-3 w-50">
        <h2 class="text-primary">{{'CASES.single.unallocated' | translate}}</h2>
        <h1 class="text-primary">{{unallocated.toFixed(2)}}</h1>
      </div>
      <div class="w-100">
        <label class="pt-2" for="relocate_note">{{'CASES.single.payment-note' | translate}}</label>
        <quill-editor
          id="relocate_note"
          formControlName="note"
          [modules]="quillModules"
          [styles]="{height: '150px'}">
        </quill-editor>
        <mat-error *ngIf="relocateNoteError">{{'SHARED.field-required' | translate}}</mat-error>
      </div>
    </div>
    <div *ngIf="showNotes" class="row pl-3 pr-5">
      <label class="pt-2" for="note">{{'CASES.single.payment-note' | translate}}</label>
      <quill-editor
        id="note"
        formControlName="note"
        [modules]="quillModules"
        [styles]="{height: '150px'}">
      </quill-editor>
    </div>
    <hr>

    <mat-tab-group [selectedIndex]="form.value.phase_one_paid ? 1 : 0">
      <mat-tab [label]="'CASES.single.phase-one' | translate">
        <div *ngIf="form.value.phase_one_paid && form.value.terms?.length > 0 && form.value.amount >= 0">
          <h1 class="text-center">{{'CASES.phase-one-paid' | translate}}</h1>
          <p class="text-center">
            <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="primary">check_circle</mat-icon>
          </p>
        </div>
        <div *ngIf="form.value.terms?.length === 0">
          <h1 class="text-center">{{'CASES.phase-one-paid' | translate}}</h1>
        </div>
        <ng-container *ngIf="!form.value.phase_one_paid || form.value.amount < 0">
          <div class="row pb-3 pl-2">
            <div class="col-2">
              <h3>{{'PAYMENTS.label' | translate}}</h3>
            </div>
            <div class="col-2">
              <h3>{{'PAYMENTS.payment-schedule' | translate}}</h3>
            </div>
            <div class="col-2">
              <h3>{{'PAYMENTS.date-paid' | translate}}</h3>
            </div>
            <div class="col-3">
              <h3>{{'CASES.single.term' | translate}}</h3>
            </div>
            <div class="col-3">
              <h3 *ngIf="!data.relocate">{{'CASES.single.diminish' | translate}}</h3>
              <h3 *ngIf="data.relocate">{{'CASES.single.amount' | translate}}</h3>
            </div>
          </div>
          <ng-container *ngFor="let formGroup of getFormArray('terms').controls let i = index" formArrayName="terms">
            <div class="row record-row pl-2 pt-2"
                 *ngIf="formGroup.value.type === null || formGroup.value.type === 'phase_one' || formGroup.value.type === 'deferred_one'"
                 [formGroupName]="i">
              <div class="col-2">
                <label for="{{i}}">{{formGroup.value.name}}</label>
              </div>
              <div class="col-2">
                <label for="{{i}}">{{formGroup.value.term_date | toDateObject | date}}</label>
              </div>
              <div class="col-2">
                <label for="{{i}}">{{(formGroup.value.date_paid ?
                  (formGroup.value.date_paid | toDateObject | date) :
                  ('PAYMENTS.no-record' | translate))}}</label>
                <ng-container *ngIf="formGroup.value.date_paid">
                  <p class="m-0" style="color: green"
                     *ngIf="formGroup.value.old_amount_paid === formGroup.value.amount">
                    <small>{{"PAYMENTS.paid" | translate }}</small>
                  </p>
                  <p class="m-0" style="color: orange"
                     *ngIf="formGroup.value.old_amount_paid < formGroup.value.amount && formGroup.value.old_amount_paid > 0">
                    <small>{{"PAYMENTS.partial-paid" | translate }}</small>
                  </p>
                </ng-container>
              </div>
              <div class="col-3">
                <small class="font-weight-lighter">{{+formGroup.value.amount_paid +
                formGroup.value.old_amount_paid | number: '1.2-2'}}€ / {{formGroup.value.amount | number: '1.2-2'}}
                  € </small>
                <span class="font-weight-bold">- {{formGroup.value.amount -
                (+formGroup.value.amount_paid + formGroup.value.old_amount_paid) | number: '1.2-2'}}€</span>
                <div class="progress" style="max-width: 120px">
                  <div class="progress-bar progress-bar-striped" role="progressbar"
                       [style]="{width: getPercent(+formGroup.value.amount_paid + formGroup.value.old_amount_paid, formGroup.value.amount)  + '%'}"
                       [attr.aria-valuenow]="getPercent(+formGroup.value.amount_paid + formGroup.value.old_amount_paid, formGroup.value.amount)"
                       [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercent(
                    +formGroup.value.amount_paid + formGroup.value.old_amount_paid, formGroup.value.amount) }}%
                  </div>
                </div>
              </div>
              <div class="col-3 mb-2">
                <input placeholder="0" type="number" max="{{formGroup.value.amount.toFixed(2)}}" id="{{i}}"
                       class="form-control" formControlName="amount_paid">
              </div>
            </div>
          </ng-container>
        </ng-container>
      </mat-tab>

      <mat-tab [label]="'CASES.single.phase-two' | translate">
        <div *ngIf="form.value.phase_two_paid && form.value.terms?.length > 0 && form.value.amount >= 0">
          <h1 class="text-center">{{'CASES.phase-two-paid' | translate}}</h1>
          <p class="text-center">
            <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="primary">check_circle</mat-icon>
          </p>
        </div>
        <ng-container *ngIf="!form.value.phase_two_paid || form.value.amount < 0">
          <div class="row pb-3 pl-2">
            <div class="col-2">
              <h3>{{'PAYMENTS.label' | translate}}</h3>
            </div>
            <div class="col-2">
              <h3>{{'PAYMENTS.payment-schedule' | translate}}</h3>
            </div>
            <div class="col-2">
              <h3>{{'PAYMENTS.date-paid' | translate}}</h3>
            </div>
            <div class="col-3">
              <h3>{{'CASES.single.term' | translate}}</h3>
            </div>
            <div class="col-3">
              <h3 *ngIf="!data.relocate">{{'CASES.single.diminish' | translate}}</h3>
              <h3 *ngIf="data.relocate">{{'CASES.single.amount' | translate}}</h3>
            </div>
          </div>
          <ng-container *ngFor="let formGroup of getFormArray('terms').controls let i = index" formArrayName="terms">
            <div class="row record-row pt-2 pl-2"
                 *ngIf="formGroup.value.type === 'phase_two' || formGroup.value.type === 'deferred_two'"
                 [formGroupName]="i">
              <div class="col-2">
                <label for="phase_two{{i}}">{{formGroup.value.name}}</label>
              </div>
              <div class="col-2">
                <label for="phase_two{{i}}">{{formGroup.value.term_date | toDateObject | date}}</label>
              </div>
              <div class="col-2">
                <label for="phase_two{{i}}">{{(formGroup.value.date_paid ?
                  (formGroup.value.date_paid | toDateObject | date) :
                  ('PAYMENTS.no-record' | translate))}}</label>
              </div>
              <div class="col-3">
                <small class="font-weight-lighter">{{+formGroup.value.amount_paid +
                formGroup.value.old_amount_paid | number: '1.2-2'}}€ / {{formGroup.value.amount | number: '1.2-2'}}
                  € </small>
                <span class="font-weight-bold">- {{formGroup.value.amount -
                (+formGroup.value.amount_paid + formGroup.value.old_amount_paid) | number: '1.2-2'}}€</span>
                <div class="progress" style="max-width: 120px">
                  <div class="progress-bar progress-bar-striped" role="progressbar"
                       [style]="{width: getPercent(+formGroup.value.amount_paid + formGroup.value.old_amount_paid, formGroup.value.amount)  + '%'}"
                       [attr.aria-valuenow]="getPercent(+formGroup.value.amount_paid + formGroup.value.old_amount_paid, formGroup.value.amount)"
                       [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercent(
                    +formGroup.value.amount_paid + formGroup.value.old_amount_paid, formGroup.value.amount) }}%
                  </div>
                </div>
              </div>
              <div class="col-3 mb-2">
                <input placeholder="0" type="number" max="{{formGroup.value.amount.toFixed(2)}}" id="phase_two{{i}}"
                       class="form-control" formControlName="amount_paid">
              </div>
            </div>
          </ng-container>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>
<div mat-dialog-actions class="py-4 justify-content-end">
  <div *ngIf="serverError"
       class="p-2 mr-5 rounded border d-flex justify-content-center align-items-center border-danger">
    <h3 class="text-danger">{{serverError}}</h3>
  </div>
  <h3 *ngIf="error" class="text-danger pr-5">{{error}}</h3>
  <ng-container *ngIf="!isRecordingPayment">
    <button mat-button color="primary" class="mr-3" (click)="onNoClick()">{{"SHARED.cancel" | translate}}</button>
    <button mat-raised-button color="primary" (click)="submitForm($event)"
            style="min-width: 120px">{{"SHARED.save" | translate}}</button>
  </ng-container>
  <ng-container *ngIf="isRecordingPayment">
    <mat-spinner class="mr-5" color="primary" [diameter]="40"></mat-spinner>
  </ng-container>
</div>
