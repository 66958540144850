import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { Payment } from '../../../../../../../../_base-shared/models/Payment/Payment';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { DistributionService } from '../../../../distribution/distribution.service';
import { CaseService } from '../../../case.service';

@Component({
  selector:    'app-case-payment-list',
  templateUrl: './case-payment-list.component.html',
  styleUrls:   ['./case-payment-list.component.scss'],
  animations:  [
    trigger('detailExpand', [
      state('collapsed', style({ display: 'none', minHeight: '0' })),
      state('expanded', style({ display: 'block' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class CasePaymentListComponent implements OnInit {
  @Input() case: Case;
  @Input() fetchHistory: EventEmitter<any>;
  @Input() componentType: 'distribution' | 'admin';

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public dataSource: MatTableDataSource<Payment>;
  public isLoading                  = 0;
  public serverResponse: LaravelResourceResponse;
  public authUser: User;
  public displayedColumns: string[] = ['date', 'amount', 'payment_method',
    'transactionable_payment_processor', 'note', 'for_additional_plan', 'done_by', 'api_source'];
  public paginatorConfig            = {
    pageIndex: 0,
    pageSize:  10,
    length:    1
  };
  public expandedRow: number;
  public expandedElement: Payment | null;

  constructor(
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private globalEventService: MainGlobalEventService,
    private caseService: CaseService,
    private distributionService: DistributionService
  ) {
  }

  ngOnInit(): void {
    this.globalEventService.authUser$.subscribe(user => {
      this.authUser = user;
      this.fetchHistory.subscribe(next => this.fetchPaymentHistory(this.componentType));
      this.fetchPaymentHistory(this.componentType);
    });
  }

  public refreshPayments() {
    this.fetchPaymentHistory(this.componentType);
  }

  private fetchPaymentHistory(componentType: 'admin' | 'distribution') {
    this.isLoading++;
    const data = {
      caseId:   this.case.id,
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1
    };

    const relations = ['author', 'transactionable.payment_processor', 'transactionable.transactions', 'terms.case_payment_plan.type' ,'transactionable.latest_transaction'];

    const observable = componentType === 'admin' ?
                       this.caseService.getPaymentHistory(data, relations) :
                       this.distributionService.indexPayments(this.case.uuid, data);

    this.dataSource = new MatTableDataSource<Payment>([]);
    observable.pipe(finalize(() => this.isLoading--)).subscribe(
      res => {
        this.dataSource             = new MatTableDataSource<Payment>(res.data);
        this.paginatorConfig.length = res.meta.total;
      },
      err => this.serverResponse = err.error
    );
  }

  public paginatorChange($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchPaymentHistory(this.componentType);
  }

  public updateComponentType(type: 'distribution' | 'admin') {
    this.componentType = type;
    this.fetchPaymentHistory(type);
  }
}
