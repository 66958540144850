<form *ngIf="form" [formGroup]="form" #ngForm>
  <div class="row"
       *ngIf="type === 'case' || type === 'customer_contact' || type === 'affiliate_case' || type === 'packager_non_transferred'">
    <div class="col-8">
      <!-- Start Date -->
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.start-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="startDate" formControlName="start_date"
               (dateChange)="dateChanged($event, 'end_date', false)">
        <button *ngIf="form.get('start_date') && form.get('start_date').value" mat-button matSuffix mat-icon-button
                [disabled]="form.get('start_date').disabled" (click)="clearFormControl($event, 'start_date')"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate color="primary"></mat-datepicker>
      </mat-form-field>
      <!-- End Date -->
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.end-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="endDate" formControlName="end_date"
               (dateChange)="dateChanged($event, 'end_date', true)">
        <button *ngIf="form.get('end_date') && form.get('end_date').value" mat-button matSuffix mat-icon-button
                [disabled]="form.get('end_date').disabled" (click)="clearFormControl($event, 'end_date')"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate color="primary"></mat-datepicker>
      </mat-form-field>
      <!-- From/To Shortcut modifiers -->
      <div class="d-inline-block pl-5">
        <mat-radio-group [formControl]="dateRadioControl" aria-label="Select an option"
                         (change)="dateModifierChange($event)">
          <mat-radio-button value="day">{{ "CASES.single.today" | translate }}</mat-radio-button>
          <mat-radio-button value="week">{{ "CASES.single.this-week" | translate }}</mat-radio-button>
          <mat-radio-button value="month">{{ "CASES.single.this-month" | translate }}</mat-radio-button>
        </mat-radio-group>
        <mat-slide-toggle formControlName="only_related" color="primary" class="ml-5"
                          (ngModelChange)="onlyRelatedChange($event)">
          {{ 'CASES.list.filters.only_related.label' | translate }}
        </mat-slide-toggle>
      </div>
    </div>
    <!-- Search -->
    <div class="col-4">
      <mat-form-field appearance="standard" class="input-full-width">
        <mat-label>{{ 'SHARED.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput formControlName="search" type="text">
        <div *ngIf="form.get('search').errors && (form.get('search').touched || ngForm.submitted)">
          <mat-error [hidden]="!form.get('name').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="type === 'case' || type === 'customer_contact' || type === 'affiliate_case'
  || type === 'packager_non_transferred'">
    <!-- Status Date Type -->
    <div class="pl-3">
      <mat-label style="padding-right: 10px; display: block">
        {{ 'CASES.list.filters.status_date_type.label' | translate }}
      </mat-label>
      <mat-radio-group formControlName="status_date_type" aria-label="Select an option">
        <mat-radio-button value="sign_up">
          {{ "CASES.list.filters.status_date_type.options.sign_up" | translate }}
        </mat-radio-button>
        <mat-radio-button value="change_date"
                          [matTooltip]="'CASES.list.filters.status_date_type.option-labels.sign_up' | translate">
          {{ "CASES.list.filters.status_date_type.options.change_date" | translate }}
        </mat-radio-button>
        <mat-radio-button value="was_in_status"
                          [matTooltip]="'CASES.list.filters.status_date_type.option-labels.was_in_status' | translate">
          {{ "CASES.list.filters.status_date_type.options.was_in_status" | translate }}
        </mat-radio-button>
        <mat-radio-button value="conversion_date"
                          *ngIf="type === 'affiliate_case'|| type === 'packager_non_transferred'"
                          [matTooltip]="'CASES.list.filters.status_date_type.options.conversion_date' | translate">
          {{ "CASES.list.filters.status_date_type.options.conversion_date" | translate }}
        </mat-radio-button>
        <mat-radio-button value="secondary_conversion_date"
                          *ngIf="type === 'affiliate_case'|| type === 'packager_non_transferred'"
                          [matTooltip]="'CASES.list.filters.status_date_type.options.secondary_conversion_date' | translate">
          {{ "CASES.list.filters.status_date_type.options.secondary_conversion_date" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <!-- Activity Status -->
    <div class="pl-5">
      <mat-label style="padding-right: 10px; display: block"
                 [matTooltip]="'CASES.list.filters.activity_status.label-tooltip' | translate">
        {{ 'CASES.list.filters.activity_status.label' | translate }}
      </mat-label>
      <mat-radio-group formControlName="activity_status" aria-label="Select an option">
        <mat-radio-button value="active">
          {{ "CASES.list.filters.activity_status.options.active" | translate }}
        </mat-radio-button>
        <mat-radio-button value="inactive">
          {{ "CASES.list.filters.activity_status.options.inactive" | translate }}
        </mat-radio-button>
        <mat-radio-button value="complete">
          {{ 'CASES.list.filters.activity_status.options.completed' | translate }}
        </mat-radio-button>
        <mat-radio-button value="all">
          {{ "CASES.list.filters.activity_status.options.all" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <button style="min-width: 120px" mat-button color="primary" class="btn ml-4" type="button"
            [matMenuTriggerFor]="selectAllMenu">
      <mat-icon style="font-size: 17px;margin-top: 5px;">keyboard_arrow_left</mat-icon>
      Select All
    </button>
    <mat-menu #selectAllMenu="matMenu" xPosition="before" class="selectAllMenu">
      <a mat-menu-item (click)="selectStatuses(true)">
        {{ "CASES.list.filters.status_preset.active" | translate }}
      </a>
      <a mat-menu-item (click)="selectStatuses(false)">
        {{ "CASES.list.filters.status_preset.inactive" | translate }}
      </a>
    </mat-menu>
  </div>
  <!-- Only show on `legal` tab -->
  <div class="row" *ngIf="type === 'legal'">
    <div class="col-8">
      <div class="">
        <mat-label style="padding-right: 10px; display: block"
                   [matTooltip]="'CASES.list.filters.activity_status.label-tooltip' | translate">
          {{ 'CASES.list.filters.activity_status.label' | translate }}
        </mat-label>
        <mat-radio-group formControlName="activity_status" aria-label="Select an option">
          <mat-radio-button value="active">
            {{ "CASES.list.filters.activity_status.options.active" | translate }}
          </mat-radio-button>
          <mat-radio-button value="inactive">
            {{ "CASES.list.filters.activity_status.options.inactive" | translate }}
          </mat-radio-button>
          <mat-radio-button value="all">
            {{ "CASES.list.filters.activity_status.options.all" | translate }}
          </mat-radio-button>
        </mat-radio-group>
        <!--      <mat-slide-toggle formControlName="all_active" color="primary" class="ml-5">-->
        <!--        {{"PAYMENTS.show-all-active" | translate}}-->
        <!--      </mat-slide-toggle>-->
      </div>
    </div>
    <div class="col-4">
      <mat-form-field appearance="standard" class="input-full-width">
        <mat-label>{{ 'SHARED.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput formControlName="search" type="text">
        <div *ngIf="form.get('search').errors && (form.get('search').touched || ngForm.submitted)">
          <mat-error [hidden]="!form.get('name').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </mat-form-field>
    </div>
  </div>
  <!-- Advanced Filters -->
  <div class="filters pb-5 pt-3">
    <div class="advanced-filters">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="d-flex align-items-center justify-content-between" style="width: 100%">
                {{ "PAYMENTS.advanced-filters" | translate }}
                <!--<button mat-stroked-button color="primary" (click)="clearFilters()">
                  {{"SHARED.reset-filters" | translate}}
                </button>-->
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div [hidden]="!isLoadingFilters" class="col-12">
              <mat-spinner class="m-0 m-auto"></mat-spinner>
            </div>
          </div>
          <div class="row" *ngIf="!isLoadingFilters">
            <ng-container>
              <div class="col-3">
                <app-input *ngIf="statusCategories?.length" type="select" [formControl]="statusCategoryControl"
                           appearance="standard" [searchable]="true" [multiple]="statusMultipleSelection"
                           [label]="'CASES.single.general.status_editor.status_category' | translate"
                           [selectOptions]="statusCategories" [selectLabel]="'name'" [selectValue]="'id'"
                           class="full-width" [extraLabel]="false"
                           (ngModelChange)="updateSelectedStatusCategories($event)"
                           [showClear]="false" [fullWidth]="true">
                </app-input>
              </div>
              <div class="col-3">
                <app-input *ngIf="filteredStatusCategories?.length" type="select"
                           [formControlName]="statusFormControlName"
                           appearance="standard" [searchable]="true" [multiple]="statusMultipleSelection"
                           [label]="'CASES.single.general.status_editor.status' | translate"
                           [selectOptions]="filteredStatusCategories" [optGroupProperty]="'statuses'"
                           [optGroupLabel]="'name'" fullWidth="true"
                           [selectLabel]="'name'" [selectValue]="'id'"
                           [showClear]="false">
                </app-input>
              </div>
              <div class="col-3">
                <app-input *ngIf="affiliateCategories?.length" type="select" [formControl]="affiliateCategoryControl"
                           appearance="standard" [searchable]="true"
                           [label]="'Affiliate'" [multiple]="true"
                           [selectOptions]="affiliateCategories" [selectLabel]="'name'" [selectValue]="'id'"
                           class="full-width" [extraLabel]="false"
                           (ngModelChange)="updateSelectedAffiliateCategories($event)"
                           [showClear]="false" [fullWidth]="true">
                </app-input>
              </div>
              <div class="col-3">
                <app-input *ngIf="filteredAffiliateCategories?.length" type="select"
                           [formControlName]="afiliateFormControlName"
                           appearance="standard" [searchable]="true" [multiple]="statusMultipleSelection"
                           [label]="'Campaign' | translate"
                           [selectOptions]="filteredAffiliateCategories" [optGroupProperty]="'campaigns'"
                           [optGroupLabel]="'name'" fullWidth="true"
                           [selectLabel]="'name'" [selectValue]="'id'"
                           [showClear]="false">
                </app-input>
              </div>
              <div class="col-3" *ngFor="let selectedFilter of selectedFilters">
                <app-input [type]="selectedFilter.type"
                           [formControlName]="selectedFilter.formControlName"
                           [appearance]="selectedFilter.appearance"
                           [fullWidth]="selectedFilter.fullWidth"
                           [multiple]="true"
                           [showClear]="selectedFilter.showClear"
                           [selectAll]="selectedFilter.selectAll"
                           [label]="selectedFilter.label | translate"
                           [selectOptions]="selectedFilter.type === 'select' ? selectedFilter.selectOptions : []"
                           [selectLabel]="selectedFilter.type === 'select' ? selectedFilter.selectLabel : ''"
                           [selectValue]="selectedFilter.type === 'select' ? selectedFilter.selectValue : ''"
                           [optGroupProperty]="selectedFilter.optGroupProperty ? selectedFilter.optGroupProperty : ''"
                           [optGroupLabel]="selectedFilter.optGroupLabel ? selectedFilter.optGroupLabel : ''"
                           (ngModelChange)="selectedFilter.name === 'Product type' ? productGroupUpdated($event) : ''"
                           [searchable]="true">
                </app-input>
              </div>
            </ng-container>
          </div>
          <div class="row" *ngIf="type === 'case' && !isLoadingFilters">
            <div class="col-6 col-md-4 col-lg-3 col-xl-3">
              <mat-slide-toggle formControlName="has_property" color="primary" class="ml-5">
                {{ 'CASES.list.filters.has_property.label' | translate }}
              </mat-slide-toggle>
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-xl-3">
              <mat-slide-toggle formControlName="has_pending_docs" color="primary" class="ml-5">
                {{ 'CASES.list.filters.has_pending_docs.label' | translate }}
              </mat-slide-toggle>
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-xl-3">
              <mat-slide-toggle formControlName="has_public_debt" color="primary" class="ml-5">
                {{ 'CASES.list.filters.has_public_debt.label' | translate }}
              </mat-slide-toggle>
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-xl-3" *ngIf="authUser.packager.master">
              <mat-slide-toggle formControlName="has_redsys_has_no_cashflows_token" color="primary" class="ml-5">
                {{ 'CASES.list.filters.has_redsys_has_no_cashflows_token.label' | translate }}
              </mat-slide-toggle>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</form>
