import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PackagerDashboardComponent } from './packager-dashboard.component';

const routes: Routes = [
  { path: 'mi-dash-packager', component: PackagerDashboardComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class PackagerDashboardRoutingModule {
}
