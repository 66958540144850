import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { PaymentActionResponse } from '../../../../../_base-shared/contracts/payment-action-response.interface';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { PaymentCard } from '../../../../../_base-shared/models/Payment/PaymentCard';
import { PaymentListFilter } from '../../../../../_base-shared/models/Payment/PaymentListFilter';
import { PaymentTerm } from '../../../../../_base-shared/models/Payment/PaymentTerm';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';
import { ArcopayOrder } from '../../../../../_base-shared/models/Payment/Transaction/ArcopayOrder';

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends MainBaseApiService {

  public index(data, relations: Array<string> = []): Observable<LaravelResourceResponse<Array<PaymentTerm>>> {
    const params = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Array<PaymentTerm>>>(`${this.apiUrl}/terms`, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public indexCaseInstallments(caseId: number, requestData = {} as any, relations: Array<string> = []): Observable<LaravelResourceResponse<Array<PaymentTerm>>> {
    const params = MainBaseApiService.convertFiltersForRequest({...requestData, with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Array<PaymentTerm>>>(
      this.apiUrl + '/cases/' + caseId + '/installments', {params}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public deleteCaseInstallment(caseId: number, installmentId: number): Observable<LaravelResourceResponse<any>> {
    return this.http.delete<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/installments/' + installmentId,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public caseInstallmentMarkAsPromo(caseId: number, installmentId: number): Observable<LaravelResourceResponse<any>> {
    return this.http.patch<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/installments/' + installmentId + '/mark-as-promo', {},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getBillableAmount(data): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/terms/get-billable-amount`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getAmountBilled(data): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/terms/get-amount-billed`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getActiveClients(data): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/terms/get-active-clients`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public charge(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${this.apiUrl}/terms/charge`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public setStatus(data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/terms/status', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public processTerm(data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/terms/process', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public changeDate(termId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${this.apiUrl}/terms/${termId}/change-date`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public changeValue(termId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${this.apiUrl}/terms/${termId}/change-value`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public changeAdditionalFeeValue(termId: number, data: {
    amount: number
  }): Observable<LaravelResourceResponse<PaymentTerm>> {
    return this.http.post<LaravelResourceResponse<PaymentTerm>>(
      this.apiUrl + '/terms/' + termId + '/change-value-additional', data,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getCreditCards(caseId: number, data, relations: Array<string> = []): Observable<LaravelResourceResponse<Array<PaymentCard>>> {
    const params = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Array<PaymentCard>>>(
      this.apiUrl + '/cases/' + caseId + '/registered-cards', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getTotalAmount(data): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/terms/total-amount`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public rebuildInstallmentPlan(caseId: any): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(`${this.apiUrl}/terms/rebuild`, {case_id: caseId})
      .pipe(catchError(response => this.handleError(response)));
  }

  public rebuildInstallmentPlanManual(data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(`${this.apiUrl}/terms/rebuild-manual`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public exportTerms(data): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/terms/export', {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public pushToDialer(filters): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/terms/push-to-dialer', filters)
      .pipe(catchError(response => this.handleError(response)));
  }

  public deleteAllPayments(caseId: any): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(`${this.apiUrl}/terms/delete`, {case_id: caseId})
      .pipe(catchError(response => this.handleError(response)));
  }

  public prepareTransaction(data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/payment/prepare-transaction', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public updateCaseStatus(data: {
    bulk_status_id: number
  }, filters: PaymentListFilter): Observable<LaravelResourceResponse<Array<Case>>> {
    const request = MainBaseApiService.convertFiltersForRequest({...filters, ...data}, 'post');

    return this.http.post<LaravelResourceResponse<Array<Case>>>(this.apiUrl + '/terms/bulk/update-status', request)
      .pipe(catchError(response => this.handleError(response)));
  }

  public updatePaymentStatus(data: { bulk_payment_status_id: number }, filters: PaymentListFilter)
    : Observable<LaravelResourceResponse<Array<Case>>> {
    const request = MainBaseApiService.convertFiltersForRequest({...data, ...filters}, 'post');

    return this.http.post<LaravelResourceResponse<Array<Case>>>(
      this.apiUrl + '/terms/bulk/update-payment-status', request,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public updateProductType(data: {
    product_id: number
  }, filters: PaymentListFilter): Observable<LaravelResourceResponse<Array<Case>>> {
    const request = MainBaseApiService.convertFiltersForRequest({...data, ...filters}, 'post');

    return this.http.post<LaravelResourceResponse<Array<Case>>>(
      this.apiUrl + '/terms/bulk/update-product-type', request,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public updateDepartmentAssignment(data: { bulk_department_id: number, bulk_user_id: number },
                                    filters: PaymentListFilter): Observable<LaravelResourceResponse<Array<Case>>> {
    const request = MainBaseApiService.convertFiltersForRequest({...filters, ...data}, 'post');

    return this.http.post<LaravelResourceResponse<Array<Case>>>(
      this.apiUrl + '/terms/bulk/update-department-assignments', request,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public deletePayments(caseId: any, installment_ids: Array<number>): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(`${this.apiUrl}/cases/${caseId}/additional-installments/delete`,
      {installment_ids})
      .pipe(catchError(response => this.handleError(response)));
  }

  public makePayment(userUuid: string, caseUuid: string, data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse<PaymentActionResponse>>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid + '/payments-v2', data
    )
      .pipe(catchError(response => this.handleError(response)));
  }

  public submitNewCardPayment(userUuid: string, caseUuid: string, data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse<any>>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid + '/payments-new-card', data
    )
      .pipe(catchError(response => this.handleError(response)));
  }

  public deletePaymentCardPublic(caseUuid: string, cardUuid: string): Observable<LaravelResourceResponse<null>> {
    return this.http.delete<LaravelResourceResponse<null>>(
      this.apiUrl + '/public/cases/' + caseUuid + '/payment-cards/' + cardUuid,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public setDefaultPaymentCard(caseUuid: string, cardUuid: string): Observable<LaravelResourceResponse<Array<PaymentCard>>> {
    return this.http.post<LaravelResourceResponse<Array<PaymentCard>>>(
      this.apiUrl + '/public/cases/' + caseUuid + '/payment-cards/' + cardUuid + '/set-default', {}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getArcopayOrder(orderUuid: string) {
    const params = MainBaseApiService.convertFiltersForRequest({uuid: orderUuid}, 'get',);

    return this.http.get<LaravelResourceResponse<ArcopayOrder>>(this.apiUrl + '/public/payments/arcopay/order-status', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public generateRemittanceRequest(caseId: number, data: any = {}): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + `/cases/${caseId}/send-remittance-request`, data)
      .pipe(catchError(response => this.handleError(response)));
  }
}
