<div class="row">
  <div class="col-12">
    <h2 class="ml-4">{{ editorType === 'edit' ?
        ('CONFIG.status.edit-status' | translate) :
        ('CONFIG.status.add-status' | translate) }}</h2>
  </div>
</div>

<div *ngIf="isLoading" class="row">
  <div class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>

<div class="row" *ngIf="!isLoading">
  <div class="col-12">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="card shadow mb-5">
        <div class="card-body">
          <div class="row">
            <!-- Name -->
            <app-input type="text" formControlName="name" appearance="standard" [fullWidth]="true" class="col-6"
                       [label]="'CONFIG.status.status-name' | translate">
            </app-input>
            <!-- Parent Status -->
            <app-input type="select" formControlName="status_id" appearance="standard" [fullWidth]="true" class="col-6"
                       [label]="'CONFIG.status.parent-status' | translate"
                       [selectOptions]="statusCategories" [searchable]="true"
                       [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>
            <!-- GCLID -->
            <app-input type="text" formControlName="gclid_conversion_name" appearance="standard" [fullWidth]="true" class="col-6"
                       [label]="'CONFIG.status.editor.form.gclid_conversion_name' | translate">
            </app-input>
          </div>
          <div class="row">
            <!-- Flag Case Active -->
            <app-input type="checkbox" formControlName="flag_case_active" appearance="standard" [fullWidth]="true" class="ml-2"
                       [label]="'CONFIG.status.editor.form.flag_case_active' | translate">
            </app-input>
            <!-- Allow affiliate overwrite anytime -->
            <app-input type="checkbox" formControlName="allow_affiliate_overwrite_anytime" appearance="standard" [fullWidth]="true" class="ml-2"
                       [label]="'CONFIG.status.editor.form.allow_affiliate_overwrite' | translate">
            </app-input>
            <!-- Completed -->
            <app-input type="checkbox" formControlName="flag_case_complete" appearance="standard" [fullWidth]="true" class="ml-2"
                       [label]="'CONFIG.status.editor.form.flag_case_complete' | translate">
            </app-input>
          </div>
          <div class="row pt-5">
            <div class="col-md-4">
              <button mat-raised-button color="primary" class="ml-3" type="submit">
                {{ this.editorType === 'edit' ?
                  ('CONFIG.status.edit-status' | translate) :
                  ('CONFIG.status.add-status' | translate) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
