<div [formGroup]="form">
  <div class="row pb-3">
    <div class="col-12">
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.start-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="from" formControlName="from" (dateChange)="onDateChange($event, 'from')">
        <button type="button" *ngIf="form.value.from" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="clearDatePicker($event, 'from')">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
        <mat-datepicker #from color="primary"></mat-datepicker>
      </mat-form-field>

      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.end-date" | translate }}</mat-label>
        <input id="to" matInput [matDatepicker]="to" formControlName="to" (dateChange)="onDateChange($event, 'to')">
        <button type="button" *ngIf="form.value.to" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="clearDatePicker($event, 'to')">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
        <mat-datepicker #to color="primary"></mat-datepicker>
      </mat-form-field>

      <div class="d-inline-block pl-5">
        <mat-radio-group formControlName="dateRadio" aria-label="Select an option" (change)="onRadioChange($event)">
          <mat-radio-button value="today" style="margin-right: 10px">{{ "CASES.single.today" | translate }}</mat-radio-button>
          <mat-radio-button value="this-week" style="margin-right: 10px">{{ "CASES.single.this-week" | translate }}</mat-radio-button>
          <mat-radio-button value="this-month">{{ "CASES.single.this-month" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</div>

<div class="mat-elevation-z8 mb-5">
  <table style="width: 100%" mat-table matSort [dataSource]="dataSource">

    <ng-container matColumnDef="packager_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'DASHBOARD.packager-dashboard.packager-name' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.packager_name || 'N/A' }}</td>
      <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
    </ng-container>

    <ng-container matColumnDef="total_payments_amount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'DASHBOARD.packager-dashboard.paid' | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ element.total_payments_amount }}</td>
      <td mat-footer-cell *matFooterCellDef>{{totalPaymentsAmount}}</td>
    </ng-container>

    <ng-container matColumnDef="transfered_cases_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'DASHBOARD.packager-dashboard.transferred' | translate}}</th>
      <td mat-cell *matCellDef="let element">{{element.transfered_cases_count}}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{totalTransferredCasesCount}}</b></td>
    </ng-container>

    <ng-container matColumnDef="created_cases_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'DASHBOARD.packager-dashboard.cases-created' | translate}}</th>
      <td mat-cell *matCellDef="let element">{{element.created_cases_count}}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{totalCreatedCasesCount}}</b></td>
    </ng-container>

    <ng-container matColumnDef="cases_entered_enviar_contrato_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'DASHBOARD.packager-dashboard.paid' | translate}}</th>
      <td mat-cell *matCellDef="let element">{{element.cases_entered_enviar_contrato_count}}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{totalCasesEnteredEnviarContratoCount}}</b></td>
    </ng-container>

    <ng-container matColumnDef="cases_entered_contrato_firmado_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'DASHBOARD.packager-dashboard.signed' | translate}}</th>
      <td mat-cell *matCellDef="let element">{{element.cases_entered_contrato_firmado_count }}</td>
      <td mat-footer-cell *matFooterCellDef>{{totalCasesEnteredContratoFirmadoCount}}</td>
    </ng-container>

    <ng-container matColumnDef="cases_entered_pago_fraccionado_count">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef>{{'DASHBOARD.packager-dashboard.fees-collected' | translate}}</th>
      <td mat-cell *matCellDef="let element">{{element.cases_entered_pago_fraccionado_count}}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{totalCasesEnteredPagoFraccionadoCount}}</b></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>

  <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="isLoading < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
    <h3 class="text-center">{{ "DASHBOARD.no-agents" | translate }}</h3>
  </div>
</div>
