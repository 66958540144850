import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Trigger } from '../../../../../../../_base-shared/models/Trigger';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NotificationTriggerService } from '../notification-trigger.service';
import Swal from 'sweetalert2';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { debounceTime, distinctUntilChanged, filter, finalize } from 'rxjs/operators';
import { NotificationFromEvent } from '../../../../../../../_base-shared/models/Notification/NotificationFromEvent';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector:    'app-notification-triggers-list',
    templateUrl: './notification-triggers-list.component.html',
    styleUrls:   ['./notification-triggers-list.component.scss']
})
export class NotificationTriggersListComponent implements OnInit {

    public triggers: Trigger[];
    displayedColumns: string[] = [
        'id',
        'to',
        'status',
        'notifications',
        'role',
        'actions'
    ];
    actions: string[] = ['Edit', 'Delete'];
    public isLoading = 0;
    public dataSource: MatTableDataSource<Trigger>;
    public paginatorConfig = {
        pageIndex: 0,
        pageSize: 10,
        length: 1
    };
    public search = new UntypedFormControl('');
    public searchFocus = false;

    constructor(
        private notificationTriggerService: NotificationTriggerService,
        private toastr: ToastrService,
        public translateService: TranslateService
    ) {
    }

    @ViewChild(MatSort, { static: true }) sort: MatSort;

    ngOnInit(): void {
        this.fetchTriggers();
        this.search.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
            )
            .subscribe(() => this.fetchTriggers());
    }

    fetchTriggers() {
        this.isLoading++;
        const data = {
            per_page: this.paginatorConfig.pageSize,
            page: this.paginatorConfig.pageIndex + 1,
            search: this.search.value
        };

        this.notificationTriggerService.index(data, ['notification_message', 'role', 'status'])
          .pipe(
            finalize(() => this.isLoading--)
          )
          .subscribe(
            res => {
                this.triggers               = res.data;
                this.dataSource             = new MatTableDataSource<Trigger>(res.data);
                this.dataSource.sort        = this.sort;
                this.paginatorConfig.length = res.meta.total;
            },
            err => {
                console.log(err);
            }
          );
    }

    openDeleteDialog(triggerId, $event): void {
        $event.preventDefault();
        Swal.fire({
            title: this.translateService.instant('SHARED.warning'),
            text: this.translateService.instant('SHARED.delete-item'),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: this.translateService.instant('SHARED.cancel'),
            confirmButtonText: this.translateService.instant('SHARED.delete'),
            confirmButtonColor: '#886ab5',
        }).then(res => {
            if (res.isConfirmed) {
                this.deleteTrigger(triggerId);
            }
        });
    }

    deleteTrigger(triggerId: number) {
        this.notificationTriggerService.delete(triggerId)
            .subscribe(res => {
                    this.fetchTriggers();
                    this.toastr.success(this.translateService.instant('CONFIG.notifications.delete-trigger'));
                },
                error => {
                    this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
                });
    }

    public paginatorChange($event: PageEvent) {
        this.paginatorConfig.pageIndex = $event.pageIndex;
        this.paginatorConfig.pageSize = $event.pageSize;
        this.paginatorConfig.length = $event.length;
        this.fetchTriggers();
    }

    onChange() {
        this.fetchTriggers();
    }

    toggleFocus(setFocus: boolean) {
        this.searchFocus = setFocus;
    }

}
