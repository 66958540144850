import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { CasePaymentPlan } from '../../../../../_base-shared/models/Payment/CasePaymentPlan';
import { PaymentPlan } from '../../../../../_base-shared/models/Product/PaymentPlan';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class CasePaymentPlanService extends MainBaseApiService {
  public paymentPlanIndex(data, relations: Array<string> = []): Observable<LaravelResourceResponse<Array<PaymentPlan>>> {
    data.with    = relations;
    const params = MainBaseApiService.convertFiltersForRequest(data, 'get');
    return this.http.get<LaravelResourceResponse<Array<PaymentPlan>>>(this.apiUrl + '/payment-plans', {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  public showCasePaymentPlan(caseId: number): Observable<LaravelResourceResponse<CasePaymentPlan>> {
    return this.http.get<LaravelResourceResponse<CasePaymentPlan>>(this.apiUrl + '/cases/' + caseId + '/payment-plan')
        .pipe(catchError(response => this.handleError(response)));
  }

  public storePaymentPlan(caseId: number, requestData): Observable<LaravelResourceResponse<CasePaymentPlan>> {
    return this.http.post<LaravelResourceResponse<CasePaymentPlan>>(
        this.apiUrl + '/cases/' + caseId + '/payment-plan', requestData,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public upsertPaymentPlan(caseId: number, requestData): Observable<LaravelResourceResponse<CasePaymentPlan>> {
    return this.http.put<LaravelResourceResponse<CasePaymentPlan>>(
        this.apiUrl + '/cases/' + caseId + '/payment-plan', requestData,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public upsertPaymentConfig(caseId: number, requestData): Observable<LaravelResourceResponse<CasePaymentPlan>> {
    return this.http.put<LaravelResourceResponse<CasePaymentPlan>>(
        this.apiUrl + '/cases/' + caseId + '/payment-config', requestData,
    ).pipe(catchError(response => this.handleError(response)));
  }
}
