<mat-expansion-panel class="mt-2" [expanded]="false">
  <mat-expansion-panel-header>
    <ng-container *ngIf="title">{{ title }}</ng-container>
    <ng-container *ngIf="!title">
      <mat-panel-title *ngIf="clientRole === 'client'">Client Documents</mat-panel-title>
      <mat-panel-title *ngIf="clientRole === 'partner'">Partner Documents</mat-panel-title>
    </ng-container>
  </mat-expansion-panel-header>
  <mat-accordion multi>
    <ng-container *ngFor="let documentTypeCategory of caseDocumentTypeCategories">
      <mat-expansion-panel class="mt-2" *ngIf="documentTypeCategory?.document_types?.length" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ documentTypeCategory['name_' + currentLanguage] }}
            <mat-icon color="accent" *ngIf="documentTypeCategory.approval_status === 'pending'">error</mat-icon>
            <mat-icon color="primary" *ngIf="documentTypeCategory.approval_status === 'accepted'">check_circle
            </mat-icon>
            <mat-icon color="warn" *ngIf="documentTypeCategory.approval_status === 'rejected'">warning</mat-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- <pre *ngIf="uploadForm">{{ uploadForm.value | json }}</pre> -->
        <mat-expansion-panel *ngFor="let documentType of documentTypeCategory.document_types" style="transition: none"
                             [ngClass]="{'pending-file': !documentType.custom_input_logic && documentType.approval_status === 'pending',
                            'accepted-file': !documentType.custom_input_logic && documentType.approval_status === 'accepted',
                            'rejected-file': !documentType.custom_input_logic && documentType.approval_status === 'rejected'}"
                             [expanded]="documentType.custom_input_logic">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ documentType['name_' + currentLanguage] }}
              <mat-icon color="accent" *ngIf="documentType.has_pending_files">error</mat-icon>
              <!-- <mat-icon color="primary" *ngIf="documentType.approval_status === 'accepted'">check_circle</mat-icon> -->
              <!-- <mat-icon color="warn" *ngIf="documentType.approval_status === 'rejected'">warning</mat-icon> -->
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!--  Custom Documents creator -->
          <form *ngIf="customDocumentForm && documentType.custom_input_logic === 'custom_selection'" class="form"
                [formGroup]="customDocumentForm" (ngSubmit)="addCustomDocument(customDocumentForm, documentType)">
            <mat-form-field [appearance]="'outline'">
              <input matInput type="text" formControlName="document_name">
            </mat-form-field>
            <button type="submit" mat-stroked-button color="primary" class="ms-3 ml-3">
              Add Document
            </button>
          </form>
          <!-- Document Uploader TODO: remove duplication -->
          <div *ngIf="!documentType?.documents?.length" class="row">
            <div class="col-6">
              <form *ngIf="uploadForm && !documentType.custom_input_logic && !documentType?.documents?.length"
                    [formGroup]="uploadForm" (ngSubmit)="uploadFiles(uploadForm, documentType, null)" class="form">
                <div class="row">
                  <div class="col-10">
                    <div class="form-group">
                      <!-- <label -->
                      <!--   [for]="'doc_type_upload_file_' + documentType.id">{{ "DOCUMENTS.upload-documents" | translate }}</label> -->
                      <div class="input-group">
                        <div class="custom-file">
                          <input multiple type="file" class="custom-file-input"
                                 [id]="'doc_type_upload_file_' + documentType.id"
                                 (change)="fileSelected($event, documentType, null)">
                          <label class="custom-file-label" [for]="'doc_type_upload_file_' + documentType.id">
                            {{ 'DOCUMENTS.choose-file' | translate }}
                          </label>
                        </div>
                      </div>
                      <ng-container *ngIf="uploadForm.get('app_document_type_id').value === documentType.id">
                        <div class="d-flex justify-content-between align-items-center"
                             *ngFor="let file of selectedUploadFiles">
                          <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                          <button type="button" mat-icon-button color="warn" (click)="removeSelectedFile(file.index)">
                            <mat-icon class="text-danger mat-icon" aria-hidden="false">
                              remove_circle
                            </mat-icon>
                          </button>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-2">
                    <app-spinner-btn *ngIf="uploadForm.get('app_document_type_id').value === documentType.id"
                                     [loading]="isUploading" [name]="'SHARED.submit' | translate">
                    </app-spinner-btn>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-6 d-flex justify-content-end align-items-center">
              <ng-container *ngIf="documentType?.document_type_requests?.length">
                <button type="button" mat-button color="primary"
                        (click)="deleteDocumentTypeRequest(documentType, documentType.document_type_requests[0])">
                  Cancel document request
                </button>
              </ng-container>
            </div>
          </div>
          <!-- No Data -->
          <h3 *ngIf="!documentType?.documents?.length"
              class="text-center user-document-list">{{ "CASES.single.no-files-uploaded" | translate }}
          </h3>
          <!-- List all documents for document type -->
          <mat-expansion-panel *ngFor="let document of documentType?.documents" style="transition: none"
                               class="mb-1 mat-elevation-z0"
                               [ngClass]="{'pending-file': document.approval_status === 'pending',
                            'accepted-file': document.approval_status === 'accepted',
                            'rejected-file': document.approval_status === 'rejected',
                            'mb-1': documentType.custom_input_logic,
                            'mat-elevation-z0': !documentType.custom_input_logic
                            }"
                               [expanded]="!documentType.custom_input_logic"
                               [hideToggle]="!documentType.custom_input_logic">
            <!-- [expanded]="!!document.compiled_file || !!document.files.length"> -->
            <mat-expansion-panel-header [ngClass]="{'d-none': !documentType.custom_input_logic}">
              <mat-panel-title class="font-weight-bold" *ngIf="documentType.custom_input_logic === 'case_creditor_selection' ||
                  documentType.custom_input_logic === 'case_public_debt_selection' ||
                  documentType.custom_input_logic === 'case_asset_selection' ||
                  documentType.custom_input_logic === 'custom_selection'">
                <ng-container *ngIf="documentType.custom_input_logic === 'case_creditor_selection'">
                  {{ document.documentable?.creditor?.name }}
                </ng-container>
                <ng-container *ngIf="documentType.custom_input_logic === 'case_public_debt_selection'">
                  <ng-container *ngIf="document.documentable?.public_organisation === 'town-hall'">
                    {{ document.documentable?.creditor?.name }}
                  </ng-container>
                  <ng-container *ngIf="document.documentable?.public_organisation !== 'town-hall'">
                    {{ ('CASE_CREDITOR.model.public_organization.options.' + document.documentable?.public_organisation) | translate }}
                  </ng-container>
                  <ng-container *ngIf="document.documentable?.reference_number">
                    ({{ document.documentable.reference_number }})
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="documentType.custom_input_logic === 'case_asset_selection'">
                  {{ document.documentable?.creditor?.name }}
                  <ng-container *ngIf="document.documentable?.account_number">
                    ({{ document.documentable.account_number }})
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="documentType.custom_input_logic === 'custom_selection'">
                  {{ document.name }}
                </ng-container>
                <mat-icon color="accent" *ngIf="document.approval_status === 'pending'">error</mat-icon>
                <mat-icon color="primary" *ngIf="document.approval_status === 'accepted'">check_circle</mat-icon>
                <mat-icon color="warn" *ngIf="document.approval_status === 'rejected'">warning</mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Document Uploader -->
            <form *ngIf="uploadForm" class="form"
                  [formGroup]="uploadForm" (ngSubmit)="uploadFiles(uploadForm, documentType, document)">
              <div class="row">
                <div *ngIf="!document.editing" class="col-12 col-lg-4">
                  <div class="form-group">
                    <!-- <label -->
                    <!--   [for]="'doc_type_upload_file_' + documentType.id">{{ "DOCUMENTS.upload-documents" | translate }}</label> -->
                    <div class="input-group">
                      <div class="custom-file">
                        <input multiple type="file" class="custom-file-input"
                               [id]="'doc_type_upload_file_' + documentType.id"
                               (change)="fileSelected($event, documentType, document)">
                        <label class="custom-file-label" [for]="'doc_type_upload_file_' + documentType.id">
                          {{ 'DOCUMENTS.choose-file' | translate }}
                        </label>
                      </div>
                    </div>
                    <ng-container *ngIf="uploadForm.get('app_document_id').value === document.id">
                      <div class="d-flex justify-content-between align-items-center pl-1 ps-1"
                           *ngFor="let file of selectedUploadFiles">
                        <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                        <button type="button" mat-icon-button color="warn" (click)="removeSelectedFile(file.index)">
                          <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">
                            remove_circle
                          </mat-icon>
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div *ngIf="document.editing" class="col-12 col-lg-4">
                  <app-input type="text" formControlName="name" [appearance]="'outline'" [fullWidth]="true"
                             [label]="'SHARED.model.name' | translate" [extraLabel]="false">
                  </app-input>
                </div>
                <div class="col-12 col-lg-8 d-flex justify-content-end align-items-center">
                  <ng-container *ngIf="document.editing">
                    <button type="button" mat-button color="primary"
                            (click)="editDocument(documentType, document, false)">
                      {{ "SHARED.cancel" | translate }}
                    </button>
                    <button type="button" mat-button color="primary"
                            (click)="updateDocument(documentType, uploadForm, document)">
                      {{ "SHARED.save" | translate }}
                    </button>
                  </ng-container>
                  <ng-container *ngIf="!document.editing && !document.updating">
                    <button type="button" mat-button color="primary"
                            *ngIf="documentType?.document_type_requests?.length &&
                           !(document.files?.length || document.compiled_file)"
                            (click)="deleteDocumentTypeRequest(documentType, document.document_request, document)">
                      {{ 'CASES.single.documents.list.document.actions.cancel_document_request' | translate }}
                    </button>
                    <button type="button" mat-button color="primary" (click)="mergeDocumentFiles(document)">
                      {{ 'CASES.single.documents.list.document.actions.merge' | translate }}
                    </button>
                    <mat-checkbox class="pt-2" color="primary" [checked]="document.completed_at"
                                  [labelPosition]="'after'"
                                  (change)="toggleDocumentComplete($event, documentType, document)">
                      {{ 'CASES.single.documents.list.document.actions.complete.true' | translate }}
                    </mat-checkbox>
                    <button *ngIf="documentType.custom_input_logic === 'custom_selection'" type="button"
                            mat-button color="primary" (click)="editDocument(documentType, document, true)">
                      {{ 'CASES.single.documents.list.document.actions.edit' | translate }}
                    </button>
                    <app-spinner-btn *ngIf="uploadForm.get('app_document_id').value === document.id"
                                     [loading]="isUploading" [name]="'SHARED.submit' | translate">
                    </app-spinner-btn>
                  </ng-container>
                </div>
              </div>
            </form>
            <!-- Compiled File // TODO: remove duplication -->
            <div *ngIf="document.compiled_file" class="row" [formGroup]="formDocumentFile"
                 style="border-bottom: 1px solid gray">
              <div class="col-1"></div>
              <div *ngIf="!document.compiled_file.editing" class="col-8">
                <div>
                  <button type="button" mat-button color="primary" (click)="selectPreviewFile(document.compiled_file)">
                    {{ document.compiled_file.name }}.{{ document.compiled_file.extension }}
                  </button>
                </div>
                <div>
                  <button type="button" mat-button color="primary"
                          (click)="downloadFile(document.compiled_file)">
                    {{ "CASES.single.download" | translate }}
                  </button>
                </div>
              </div>
              <div *ngIf="document.compiled_file.editing" class="col-8">
                <div class="row pt-3">
                  <div class="col-6">
                    <app-input type="text" formControlName="name" [appearance]="'outline'" [fullWidth]="true"
                               [label]="'SHARED.model.name' | translate" [extraLabel]="false">
                    </app-input>
                  </div>
                </div>
              </div>
              <div class="col-3 file-actions d-flex justify-content-end align-items-center">
                <ng-container *ngIf="document.compiled_file.editing">
                  <button type="button" mat-button color="primary"
                          (click)="toggleDocumentFileEdit(documentType, document.compiled_file, false, document)">
                    {{ "SHARED.cancel" | translate }}
                  </button>
                  <button type="button" mat-button color="primary"
                          (click)="updateDocumentFile(documentType, document, document.compiled_file)">
                    {{ "SHARED.save" | translate }}
                  </button>
                </ng-container>
                <ng-container *ngIf="!document.compiled_file.editing">
                  <button type="button" mat-raised-button color="warn" class="ml-2"
                          (click)="deleteFile(documentType, document, document.compiled_file)">
                    {{ "SHARED.delete" | translate }}
                  </button>
                  <button type="button" mat-button color="primary"
                          (click)="toggleDocumentFileEdit(documentType, document.compiled_file, true, document)">
                    {{ "SHARED.edit" | translate }}
                  </button>
                </ng-container>
              </div>
            </div>
            <!-- File List -->
            <div cdkDropList [cdkDropListData]="document.files" (cdkDropListDropped)="updateDropList($event, document)">
              <div *ngFor="let documentFile of document.files" cdkDrag [cdkDragData]="documentFile"
                   [formGroup]="formDocumentFile" class="row mb-3">
                <ng-container *ngIf="!documentFile.compiled_for_doc_id">
                  <div class="col-1 d-flex justify-content-center align-items-center">
                    <div class="document-file-order-handle" cdkDragHandle>
                      <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                        <path
                          d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg>
                    </div>
                  </div>
                  <div *ngIf="!documentFile.editing" class="col-8">
                    <div>
                      <button type="button" mat-button color="primary" (click)="selectPreviewFile(documentFile)">
                        {{ documentFile.name }}.{{ documentFile.extension }}
                      </button>
                      <ng-container>
                        <label *ngIf="documentFile.fileable_approved === null" class="pl-3">
                          {{ 'CASES.single.documents.list.file.approval_status.pending' | translate }}
                        </label>
                        <label *ngIf="documentFile.fileable_approved === true" class="pl-3">
                          {{ 'CASES.single.documents.list.file.approval_status.accepted' | translate }}
                        </label>
                        <label *ngIf="documentFile.fileable_approved === false" class="pl-3">
                          {{ 'CASES.single.documents.list.file.approval_status.rejected' | translate }}
                        </label>
                      </ng-container>
                    </div>
                    <div>
                      <div class="ml-3">
                        <small *ngIf="documentFile.fileable_approved === false">
                          {{ 'CASES.single.documents.list.file.reject_reason' | translate }}
                          : {{ documentFile.fileable_reject_reason }}
                        </small>
                      </div>
                      <button type="button" mat-button color="primary"
                              *ngIf="documentFile.mime_type.includes('image')"
                              (click)="openEditModal(document, documentFile)">
                        {{ "CASES.single.edit-image" | translate }}
                      </button>
                      <button type="button" mat-button color="primary"
                              (click)="downloadFile(documentFile)">
                        {{ "CASES.single.download" | translate }}
                      </button>
                      <button *ngIf="documentFile.converted_pdf" type="button" mat-button color="primary"
                              (click)="downloadFile(documentFile.converted_pdf)">
                        {{ "CASES.single.download" | translate }} PDF
                      </button>
                    </div>
                  </div>
                  <div *ngIf="documentFile.editing" class="col-8">
                    <div class="row pt-3">
                      <div class="col-6">
                        <app-input type="text" formControlName="name" [appearance]="'outline'" [fullWidth]="true"
                                   [label]="'SHARED.model.name' | translate" [extraLabel]="false">
                        </app-input>
                      </div>
                      <div class="col-6">
                        <app-input *ngIf="caseDocumentTypeCategories?.length" type="select"
                                   formControlName="app_document_type_id" appearance="outline" [searchable]="true"
                                   [label]="'Type' | translate"
                                   [selectOptions]="caseDocumentTypeCategories"
                                   [optGroupProperty]="'document_types'" [optGroupLabel]="'name_es'"
                                   [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                                   [showClear]="false" [fullWidth]="true">
                        </app-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <app-input *ngIf="!case.joint_application" type="select" formControlName="user_id"
                                   appearance="outline" fullWidth="false" [searchable]="true"
                                   [label]="'Uploaded by'" [selectLabel]="'label'" [selectValue]="'value'"
                                   [selectOptions]="[{label: 'Client', value: case.user_id}]">
                        </app-input>
                        <app-input *ngIf="case.joint_application" type="select" formControlName="user_id"
                                   appearance="outline" fullWidth="false"
                                   [label]="'Uploaded by'" [selectLabel]="'label'" [selectValue]="'value'"
                                   [selectOptions]="[{label: 'Client', value: case.user_id},{label: 'Partner', value: case.partner_user_id}]">
                        </app-input>
                      </div>
                      <!-- Documentable Selection -->
                      <div class="col-6" *ngIf="formDocumentFile.get('custom_input_logic').value">
                        <app-input *ngIf="formDocumentFile.get('documentable_type').value === 'case_creditor' &&
                                 caseCreditorOptions?.length" type="select" formControlName="documentable_id"
                                   appearance="outline" [searchable]="true" [label]="'Case creditor'"
                                   [selectOptions]="caseCreditorOptions" selectLabel="label"
                                   selectValue="value" class="full-width" [showClear]="false" [fullWidth]="true">
                        </app-input>
                        <app-input *ngIf="formDocumentFile.get('documentable_type').value === 'case_public_debt' &&
                                 casePublicDebtOptions?.length"
                                   type="select" formControlName="documentable_id" appearance="outline"
                                   [searchable]="true"
                                   [label]="'Public debt'" [selectOptions]="casePublicDebtOptions"
                                   selectLabel="label" selectValue="value" class="full-width"
                                   [showClear]="false" [fullWidth]="true">
                        </app-input>
                        <app-input *ngIf="formDocumentFile.get('documentable_type').value === 'case_asset' &&
                                 caseAssetOptions?.length" type="select"
                                   formControlName="documentable_id" appearance="outline" [searchable]="true"
                                   [label]="'Bank account'" [selectOptions]="caseAssetOptions"
                                   selectLabel="label" selectValue="value" class="full-width"
                                   [showClear]="false" [fullWidth]="true">
                        </app-input>
                        <app-input
                          *ngIf="formDocumentFile.get('custom_input_logic').value === 'custom_selection'"
                          type="select"
                          formControlName="app_document_id" appearance="outline" [searchable]="true"
                          [label]="'Custom document'" [selectOptions]="customDocuments"
                          selectLabel="name" selectValue="id" class="full-width"
                          [showClear]="false" [fullWidth]="true">
                        </app-input>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 file-actions d-flex justify-content-end align-items-center">
                    <ng-container *ngIf="documentFile.editing">
                      <button type="button" mat-button color="primary"
                              (click)="toggleDocumentFileEdit(documentType, documentFile, false, document)">
                        {{ "SHARED.cancel" | translate }}
                      </button>
                      <button type="button" mat-button color="primary"
                              (click)="updateDocumentFile(documentType, document, documentFile)">
                        {{ "SHARED.save" | translate }}
                      </button>
                    </ng-container>
                    <ng-container *ngIf="!documentFile.editing">
                      <button type="button" mat-raised-button color="primary"
                              *ngIf="documentType.requestable &&  documentFile.fileable_approved !== true"
                              (click)="updateDocumentFileStatus(documentType, document, documentFile, true)">
                        {{ "SHARED.accept" | translate }}
                      </button>
                      <button type="button" mat-raised-button color="warn" class="ml-2"
                              *ngIf="documentType.requestable &&  documentFile.fileable_approved !== false"
                              (click)="updateDocumentFileStatus(documentType, document, documentFile, false)">
                        {{ "SHARED.decline" | translate }}
                      </button>
                      <button type="button" mat-raised-button color="warn" class="ml-2"
                              (click)="deleteFile(documentType, document, documentFile)">
                        {{ "SHARED.delete" | translate }}
                      </button>
                      <button type="button" mat-button color="primary"
                              (click)="toggleDocumentFileEdit(documentType, documentFile, true, document)">
                        {{ "SHARED.edit" | translate }}
                      </button>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-expansion-panel>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</mat-expansion-panel>
