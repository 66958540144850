<div class="d-flex flex-row justify-content-between align-items-center">
  <h2 *ngIf="noteType === 'note'">{{ 'CASES.single.general.relation_list.notes.heading' | translate }}</h2>
  <h2 *ngIf="noteType === 'payment'">{{ 'CASES.single.general.relation_list.notes.heading_payment' | translate }}</h2>
  <h2 *ngIf="noteType === 'draft'">{{ 'CASES.single.general.relation_list.notes.heading_draft' | translate }}</h2>
  <h2
    *ngIf="noteType === 'distribution'">{{ 'CASES.single.general.relation_list.notes.heading_distribution' | translate }}</h2>
  <button *ngIf="!editorType" mat-raised-button color="primary" (click)="addNewNote()">
    + {{ 'CASES.single.general.relation_list.notes.actions.create' | translate }}
  </button>
</div>
<form *ngIf="editorType" [formGroup]="form" class="form" (ngSubmit)="submitForm(form, noteType)">
  <div class="form-group">
    <label class="form-label" for="note">
      {{ 'CASES.single.general.relation_list.notes.actions.create' | translate }}
    </label>
    <quill-editor id="note" [modules]="quillModules" formControlName="note" [styles]="{height: '250px'}"></quill-editor>
  </div>
  <div *ngIf="noteType === 'note'" class="row">
    <div class="col-6 d-flex align-items-center">
      <div class="form-group w-100">
        <select name="customer_contact_type" class="form-control" formControlName="customer_contact_type">
          <option
            value="no_contact">{{ "TASK-TEMPLATE.editor.task-template-notification.contact.options.not-contact" | translate }}</option>
          <option
            value="call_contact">{{ "TASK-TEMPLATE.editor.task-template-notification.contact.options.call-contact" | translate }}</option>
          <option
            value="sms_contact">{{ "TASK-TEMPLATE.editor.task-template-notification.contact.options.sms-contact" | translate }}</option>
          <option
            value="email_contact">{{ "TASK-TEMPLATE.editor.task-template-notification.contact.options.email-contact" | translate }}</option>
          <option
            value="whatsapp_contact">{{ "TASK-TEMPLATE.editor.task-template-notification.contact.options.whatsapp-contact" | translate }}</option>
        </select>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <mat-form-field color="primary" appearance="standard" class="input-full-width">
          <mat-label>{{ 'SHARED.model.created_at' | translate }}</mat-label>
          <input matInput [ngxMatDatetimePicker]="from" formControlName="contacted_at">
          <button *ngIf="form.get('contacted_at').value" mat-button matSuffix mat-icon-button aria-label="Clear"
                  (click)="clearDatePicker()">
            <mat-icon>{{ 'SHARED.close' | translate }}</mat-icon>
          </button>
          <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #from></ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-end align-items-end">
    <button mat-button color="primary" class="mr-3" (click)="cancelNote()">{{ 'SHARED.cancel' | translate }}</button>
    <app-spinner-btn type="submit" [loading]="isSubmitting" class="mr-2"
                     [name]="(editorType === 'create' ? 'SHARED.save' : 'SHARED.update') | translate">
    </app-spinner-btn>
  </div>
</form>
<div class="pt-4" *ngIf="isLoading">
  <mat-spinner class="m-0 m-auto"></mat-spinner>
</div>
<app-empty-dataset [show]="!isLoading && !notes?.length"></app-empty-dataset>
<div class="pt-4 scroll-notes" *ngIf="!isLoading && notes.length > 0">
  <div *ngFor="let note of notes" class="card mb-5">
    <div class="card-body" [ngStyle]="{backgroundColor: note.sticky ? '#ffffbb' : '#ffffff'}">
      <div [innerHTML]="note.note"></div>
      <div class="pt-4 d-flex flex-row justify-content-between align-items-center">
        <p class="m-0">{{ note.user?.name }}, {{ note.created_at| toDateObject | date: 'medium' }}</p>
        <div>
          <!--          <button mat-button color="warn" class="mr-2" (click)="deleteNote(note.id)">-->
          <!--            {{ 'CASES.single.general.relation_list.notes.actions.delete' | translate }}-->
          <!--          </button>-->
          <button mat-stroked-button color="primary" class="mr-1" (click)="toogleSticky(note)"
                  *ngIf="((!note.sticky) || (authUser.id ===  15 || authUser.id === 97609 || authUser.id === 97606 || authUser.id === 302686 ||
                   authUser.id === 595404 || authUser.id === 3 || authUser.id === 1 || authUser.id === 22 || authUser.id === 25 ||
                   authUser.id === 27 || authUser.id === 34 || authUser.id === 36 || authUser.id === 2403 || authUser.id === 2497 ||
                   authUser.id === 967 || authUser.id === 20 || authUser.id === 2 || authUser.id === 2590 || authUser.id === 613764 || authUser.id === 21 || isUserLawyer))">
            {{note.sticky ?
            ('CASES.single.general.relation_list.notes.unsticky' | translate) :
            ('CASES.single.general.relation_list.notes.sticky' | translate) }}
          </button>
          <button mat-stroked-button color="primary" (click)="editNote(note)">
            {{ 'CASES.single.general.relation_list.notes.actions.edit' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
