<h1 mat-dialog-title>{{ 'DISTRIBUTION.batch.selector.heading' | translate }}</h1>
<mat-dialog-content>
  <mat-spinner *ngIf="isLoading" diameter="40" class="m-0 m-auto"></mat-spinner>
  <div class="row">
    <div class="col-12">
      <!-- Batch ID -->
      <app-input type="select" [formControl]="batchSelector" appearance="standard" [fullWidth]="true"
                 [label]="'DISTRIBUTION.batch.selector.distribution_batch_id' | translate" [searchable]="true"
                 [selectOptions]="distributionBatches" [selectLabel]="'name'" [selectValue]="'id'">
      </app-input>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" mat-raised-button color="primary" (click)="selectBatch(batchSelector.value)">
    {{ 'SHARED.submit' | translate }}
  </button>
  <button mat-stroked-button mat-dialog-close color="primary" class="ml-2">
    {{ "SHARED.close" | translate }}
  </button>
</mat-dialog-actions>
