import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { MainGlobalEventService } from '../../../../../../_shared/services/main-global-event.service';
import { DocumentTypeService } from '../../../../../document/document-type.service';
import { CaseAssetService } from '../../../../case-asset.service';
import { CaseCreditorService } from '../../../../case-creditor.service';
import {
  BaseDocumentFileUploaderComponent
} from '../base-document-file-uploader/base-document-file-uploader.component';

@Component({
  selector:    'app-case-court-document-list-v3',
  templateUrl: '../base-document-file-uploader/base-document-file-uploader.component.html',
  styleUrls:   ['../base-document-file-uploader/base-document-file-uploader.component.scss']
})
export class CaseCourtDocumentListV3Component extends BaseDocumentFileUploaderComponent implements OnInit, OnDestroy {
  constructor(
    protected fb: FormBuilder,
    protected dialog: MatDialog,
    protected toastr: ToastrService,
    protected cookieService: CookieService,
    protected translate: TranslateService,
    protected globalEventsService: MainGlobalEventService,
    protected documentTypeService: DocumentTypeService,
    protected caseCreditorService: CaseCreditorService,
    protected caseAssetService: CaseAssetService
  ) {
    super(fb, dialog, toastr, cookieService, translate, globalEventsService, documentTypeService, caseCreditorService, caseAssetService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const requestData: any = {
      types:              ['client_case_court', 'client_case_court_custom'],
      client_role:        this.clientRole,
      show_unrequestable: true
    };
    const relations        = ['document_types', 'document_types.documents'];

    if (this.documentableType && this.documentableId) {
      requestData.documentable_type = this.documentableType;
      requestData.documentable_id   = this.documentableId;
    }

    this.fetchDocumentTypeCategories(this.case, requestData, relations);
  }
}
