import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AppSelectOption } from '../../../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseAsset } from '../../../../../../../../../_base-shared/models/Case/CaseAsset';
import { CaseCreditor } from '../../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePublicDebt } from '../../../../../../../../../_base-shared/models/Case/CasePublicDebt';
import { AppDocument } from '../../../../../../../../../_base-shared/models/Document/AppDocument';
import { AppDocumentRequest } from '../../../../../../../../../_base-shared/models/Document/AppDocumentRequest';
import { AppDocumentType } from '../../../../../../../../../_base-shared/models/Document/AppDocumentType';
import {
  AppDocumentTypeCategory
} from '../../../../../../../../../_base-shared/models/Document/AppDocumentTypeCategory';
import { AppFile } from '../../../../../../../../../_base-shared/models/File/AppFile';
import { User } from '../../../../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../../../../environments/environment';
import {
  ImageEditorModalV2Component
} from '../../../../../../_shared/components/image-editor-modal-v2/image-editor-modal-v2.component';
import { MainGlobalEventService } from '../../../../../../_shared/services/main-global-event.service';
import { DocumentTypeService } from '../../../../../document/document-type.service';
import { CaseAssetService } from '../../../../case-asset.service';
import { CaseCreditorService } from '../../../../case-creditor.service';


@Component({
  selector:    'app-base-document-file-uploader',
  templateUrl: './base-document-file-uploader.component.html',
  styles:      []
})
export class BaseDocumentFileUploaderComponent implements OnInit, OnDestroy {
  @Input() title!: string;
  @Input() case: Case;
  @Input() documentableType!: 'case_creditor' | 'case_public_debt' | 'case_asset';
  @Input() documentableId!: number;
  @Input() clientRole!: 'client' | 'partner';
  @Input() docRequestCompletedStatus!: 'complete' | 'incomplete';
  @Output() filePreviewRequested: EventEmitter<AppFile>  = new EventEmitter(null);
  @Output() fileChangedClientRole: EventEmitter<AppFile> = new EventEmitter<AppFile>();
  public prependContractTypes                            = false;

  public authUser: User;
  public customDocumentForm: FormGroup;
  public uploadForm: FormGroup;
  public selectedUploadFiles: Array<any>                            = [];
  public isUploading: boolean;
  public formDocumentFile: FormGroup;
  public caseDocumentTypeCategories: Array<AppDocumentTypeCategory> = [];
  public caseDocumentTypes: Array<AppDocumentType>                  = [];
  public customDocuments: Array<AppDocument>                        = [];
  public currentLanguage                                            = 'es';
  public isLoading                                                  = 0;

  public caseCreditors: Array<CaseCreditor>;
  public caseCreditorOptions: Array<AppSelectOption>;
  public casePublicDebts: Array<CasePublicDebt>;
  public casePublicDebtOptions: Array<AppSelectOption>;
  public caseAssets: Array<CaseAsset>;
  public caseAssetOptions: Array<AppSelectOption>;

  protected storageUrl                         = environment.STORAGE_URL + '/';
  protected subscriptions: Array<Subscription> = [];

  constructor(
    protected fb: FormBuilder,
    protected dialog: MatDialog,
    protected toastr: ToastrService,
    protected cookieService: CookieService,
    protected translate: TranslateService,
    protected globalEventsService: MainGlobalEventService,
    protected documentTypeService: DocumentTypeService,
    protected caseCreditorService: CaseCreditorService,
    protected caseAssetService: CaseAssetService
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);

    const storageLanguage = this.cookieService.get('lang');
    this.currentLanguage  = (storageLanguage === 'es' || storageLanguage === 'en') ? storageLanguage : 'es';
    this.translate.onLangChange.subscribe(next => this.currentLanguage = next.lang);

    const requestData      = {select_all: 1};
    const caseCreditors$   = this.caseCreditorService.indexCaseCreditors(this.case.id, ['creditor'], requestData);
    const casePublicDebts$ = this.caseCreditorService.indexCasePublicDebts(this.case.id, ['town_hall'], requestData);
    const caseAssets$      = this.caseAssetService.index(this.case.id, ['entity'], {
      'types[]': 'bank_accounts',
      ...requestData
    });
    this.isLoading += 3;
    const resources$       = forkJoin([caseCreditors$, casePublicDebts$, caseAssets$])
      .pipe(finalize(() => this.isLoading -= 3))
      .subscribe(result => {
        this.caseCreditors       = result[0].data;
        this.caseCreditorOptions = [];
        this.caseCreditors.forEach(caseCreditor => {
          this.caseCreditorOptions.push({
            value: caseCreditor.id,
            label: caseCreditor.creditor.name + (caseCreditor.reference_number ? (' (' + caseCreditor.reference_number) + ')' : '')
          });
        });
        this.casePublicDebts       = result[1].data;
        this.casePublicDebtOptions = [];
        this.casePublicDebts.forEach(publicDebt => {
          let label = '';
          if (publicDebt.public_organisation === 'town-hall' && publicDebt.town_hall) {
            label = publicDebt.town_hall.name;
          } else {
            label = this.translate.instant('CASE_CREDITOR.model.public_organization.options.' + publicDebt.public_organisation);
          }
          if (publicDebt.reference_number) {
            label += ' (' + publicDebt.reference_number + ')';
          }
          this.casePublicDebtOptions.push({
            value: publicDebt.id,
            label
          });
        });
        this.caseAssets       = result[2].data;
        this.caseAssetOptions = [];
        this.caseAssets.forEach(caseAsset => {
          this.caseAssetOptions.push({
            value: caseAsset.id,
            label: caseAsset.entity?.name + (caseAsset.account_number ? ' (' + caseAsset.account_number + ')' : '')
          });
        });
      });
    this.subscriptions.push(resources$);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  protected fetchDocumentTypeCategories(clientCase: Case, requestData: any, relations: Array<string>): void {
    this.isLoading++;
    this.subscriptions.push(
      this.documentTypeService.indexCaseDocumentTypeCategories(clientCase.id, requestData, relations)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          if (this.prependContractTypes) {
            this.caseDocumentTypeCategories = this.getCustomLegalDocumentCategory();
          } else {
            this.caseDocumentTypeCategories = [];
          }
          this.caseDocumentTypeCategories = this.caseDocumentTypeCategories.concat(result.data);
          this.caseDocumentTypes          = [];
          this.caseDocumentTypeCategories.forEach(category => {
            if (category.hasOwnProperty('document_types')) {
              category.document_types.forEach(docType => {
                this.caseDocumentTypes.push(docType);
                if (docType.hasOwnProperty('documents') && docType.documents.length) {
                  docType.documents.forEach(document => {
                    if (docType.custom_input_logic === 'custom_selection') {
                      this.customDocuments.push(document);
                    }
                  });
                }
              });
            }
            this.updateCategoryApprovalStatus(category)
          });
          this.buildForms();
        })
    );
  }

  public deleteDocumentTypeRequest(documentType: AppDocumentType, documentRequest: AppDocumentRequest, document: AppDocument = null): void {
    this.documentTypeService.deleteDocumentRequest(this.case.id, documentRequest.id)
      .pipe(finalize(() => documentType.updating = false))
      .subscribe(
        result => {
          if (document) {
            const docIndex = documentType.documents.findIndex(searchDoc => searchDoc.id === document.id)
            if (docIndex >= 0) {
              documentType.documents.splice(docIndex, 1);
            }
          } else {
            this.caseDocumentTypeCategories.forEach(category => {
              const docTypeIndex = category.document_types.findIndex(searchDocType => searchDocType.id === documentType.id);
              if (docTypeIndex >= 0) {
                category.document_types.splice(docTypeIndex, 1);
              }
            })
          }
          this.toastr.success(this.translate.instant('CASES.single.documents.list.document.update.response.success'));
        },
        () => this.toastr.error(this.translate.instant('CASES.single.documents.list.document.update.response.error'))
      );
  }

  public updateDocument(documentType: AppDocumentType, form: FormGroup, document: AppDocument = null): void {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    document.updating = true;
    this.documentTypeService.updateDocument(this.case.id, document.id, form.value)
      .pipe(finalize(() => {
        form.reset();
        document.updating = false;
      }))
      .subscribe(
        result => {
          document.name         = result.data.name;
          document.completed_at = result.data.completed_at;
          this.toastr.success(this.translate.instant('CASES.single.documents.list.document.update.response.success'));
          this.editDocument(documentType, document, false);
        },
        error => {
          this.toastr.error(this.translate.instant('CASES.single.documents.list.document.update.response.error'));
        });
  }

  public toggleDocumentComplete(event: MatLegacyCheckboxChange, documentType: AppDocumentType, document: AppDocument): void {
    this.setUploadFormForDocumentType(documentType, document);
    this.selectedUploadFiles = [];
    this.uploadForm.get('files').patchValue([]);
    this.uploadForm.get('completed').patchValue(event.checked);
    this.updateDocument(documentType, this.uploadForm, document);
  }

  public mergeDocumentFiles(document: AppDocument) {
    document.updating = true;
    this.documentTypeService.mergeDocumentFiles(this.case.id, document.id, {})
      .pipe(finalize(() => document.updating = false))
      .subscribe(
        result => {
          document.compiled_file = result.data;
          document.files.forEach(file => {
            file.compiled_file = result.data
          })
          this.toastr.success(this.translate.instant('CASES.single.documents.list.document.update.response.success'));
        },
        error => {
          this.toastr.error(this.translate.instant('CASES.single.documents.list.document.update.response.error'));
        });
  }

  public updateDocumentFileStatus(docType: AppDocumentType, document: AppDocument, documentFile: AppFile, approvedState: null | boolean): void {
    const requestData = {
      fileable_approved:      approvedState,
      fileable_reject_reason: null
    };

    if (approvedState === false) {
      Swal.fire({
        title:             this.translate.instant('DOCUMENTS.rejection-warning') + '?',
        text:              this.translate.instant('DOCUMENTS.reason-of-rejection') + ':',
        input:             'text',
        showCancelButton:  true,
        showConfirmButton: true,
        inputValidator:    (value) => {
          return new Promise((resolve) => {
            if (value.length === 0) {
              resolve(this.translate.instant('DOCUMENTS.reason-of-rejection-required'));
            } else {
              resolve('');
            }
          });
        }

      }).then((result) => {
        if (result.value) {
          requestData.fileable_reject_reason = result.value;
          this.updateDocumentFileStatusApi(docType, document, documentFile, requestData);
        }
      });
    }

    if (approvedState === true) {
      this.updateDocumentFileStatusApi(docType, document, documentFile, requestData);
    }
  }

  public updateDocumentFile(docType: AppDocumentType, document: AppDocument, documentFile: AppFile): void {
    if (this.formDocumentFile.invalid) {
      this.formDocumentFile.markAsPristine();
      this.toastr.error('Please select all required fields');
      return;
    }
    const fileData = this.formDocumentFile.value;

    if (fileData.name === '') {
      return;
    }

    if (fileData.app_document_type_id === document.app_document_type_id &&
      fileData.documentable_type === document.documentable_type &&
      fileData.documentable_id === document.documentable_id &&
      fileData.user_id === document.user_id &&
      fileData.name === documentFile.name &&
      fileData.app_document_id === document.id
    ) {
      documentFile.editing = false;
      this.resetDocumentFileForm();

      return;
    }

    this.documentTypeService.updateDocumentFile(this.case.id, documentFile.id, fileData, ['fileable', 'fileable.document_type']).pipe(
      finalize(() => {
        documentFile.editing = false;
        this.resetDocumentFileForm();
      })
    ).subscribe(
      result => {
        const updatedFile    = result.data;
        updatedFile.fileable = updatedFile.fileable as AppDocument;

        //console.log(updatedFile.fileable);
        //console.log(updatedFile.fileable.user_id);
        //console.log(document.user_id);
        //console.log(updatedFile.fileable.app_document_type_id);
        //console.log(document.app_document_type_id);
        //console.log( !! docType.custom_input_logic);
        //console.log((documentFile.fileable_type !== updatedFile.fileable_type || document.id !== updatedFile.fileable.id));

        if (updatedFile.fileable &&
          (
            updatedFile.fileable.user_id !== document.user_id ||
            updatedFile.fileable.app_document_type_id !== document.app_document_type_id ||
            ( !! docType.custom_input_logic && (documentFile.fileable_type !== updatedFile.fileable_type || document.id !== updatedFile.fileable.id))
          )
        ) {
          const fileIndex = document.files.findIndex(searchFile => searchFile.id === documentFile.id);
          if (fileIndex >= 0) {
            document.files.splice(fileIndex, 1);
          }
          const oldUserId = document.user_id;
          if ( ! docType.custom_input_logic && ! document.files.length) {
            const docIndex = docType.documents.findIndex(searchDoc => searchDoc.id === document.id);
            if (docIndex >= 0) {
              docType.documents.splice(fileIndex, 1);
            }
          } else {
            console.log(document.files);
          }
          const oldCategory = this.caseDocumentTypeCategories.find(searchCat => searchCat.id === docType.category_id);

          if (updatedFile.fileable.user_id !== oldUserId) {
            console.log('user does not match');
            this.fileChangedClientRole.emit(updatedFile);
            this.updateCategoryApprovalStatus(oldCategory);
          } else {
            console.log('user matches');
            this.appendUpdatedFile(updatedFile, oldCategory);
          }
        } else {
          console.log('only updating doc name');
          documentFile.name = updatedFile.name;
        }
        this.toastr.success(this.translate.instant('DOCUMENTS.file-details-changed-success'));
      },
      err => this.toastr.error(this.translate.instant('DOCUMENTS.file-details-changed-error'))
    );
  }

  public openEditModal(document: AppDocument, documentFile: AppFile): void {
    const dialogRef = this.dialog.open(ImageEditorModalV2Component, {
      width: '50%',
      data:  {
        document,
        documentFile,
        case: this.case
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.data) {
        const remoteFile  = result.data;
        documentFile.path = remoteFile.path;
      }
    });
  }

  public editDocument(documentType: AppDocumentType, document: AppDocument, state: boolean): void {
    this.setUploadFormForDocumentType(documentType, document);
    this.selectedUploadFiles = [];
    this.uploadForm.get('files').patchValue([]);
    document.editing = state;
  }

  public selectPreviewFile(documentFile: AppFile): void {
    this.filePreviewRequested.emit(documentFile);
  }

  public uploadFiles(form: FormGroup, documentType: AppDocumentType, document: AppDocument = null): void {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    if ( ! this.selectedUploadFiles.length) {
      return;
    }

    const formData  = new FormData();
    const formValue = form.value;

    let observable;
    if (formValue.app_document_type_id > 0 && formValue.app_document_type_id < 1) {
      const customDocType = this.caseDocumentTypes.find(docType => docType.id === formValue.app_document_type_id);
      formData.append('type', customDocType.slug);
      formData.append('uploaded_by', formValue.client_role);
      observable = this.documentTypeService.adminUploadContract(this.case.id, formData)
    } else {
      formData.append('app_document_type_id', formValue.app_document_type_id);
      formData.append('client_role', formValue.client_role);

      if (formValue.documentable_type && formValue.documentable_id) {
        formData.append('documentable_type', formValue.documentable_type);
        formData.append('documentable_id', formValue.documentable_id.toString());
      }

      if (formValue.app_document_id) {
        formData.append('app_document_id', formValue.app_document_id.toString());
      }
      observable = this.documentTypeService.adminUploadAppDocument(this.case.id, formData)
    }

    this.selectedUploadFiles.forEach(file => formData.append('files[]', file));

    this.isUploading = true;
    this.subscriptions.push(
      observable
        .pipe(finalize(() => {
          this.isUploading         = false;
          this.selectedUploadFiles = [];
          form.reset();
        }))
        .subscribe(
          result => this.handleUploadSuccess(result, documentType, document),
          () => this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'))
        )
    )
  }

  protected handleUploadSuccess(result: LaravelResourceResponse, documentType: AppDocumentType = null, document: AppDocument = null): void {
    this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
    if (document) {
      document.files = [...document.files, ...result.data.files];
    } else if (documentType) {
      document               = result.data.document;
      document.files         = result.data.files;
      documentType.documents = [...documentType.documents, document];
    }

    if (documentType) {
      this.caseDocumentTypeCategories.forEach(category => {
        if (category.id === documentType.category_id) {
          this.updateCategoryApprovalStatus(category);
        }
      })
    }
  }

  public toggleDocumentFileEdit(documentType: AppDocumentType, documentFile: AppFile, state: boolean, document: AppDocument): void {
    if (state) {
      this.caseDocumentTypeCategories.forEach(typeCategory => {
        typeCategory.document_types.forEach(docType => {
          docType.documents[0]?.files.map(docFile => {
            if (docFile.id !== documentFile.id) {
              docFile.editing = false;
            }
            return docFile;
          });
        });
      });
      this.formDocumentFile.patchValue({
        app_document_id:      document.id,
        app_document_type_id: document.app_document_type_id,
        documentable_type:    document.documentable_type,
        documentable_id:      document.documentable_id,
        user_id:              document.user_id,
        name:                 documentFile.name,
        custom_input_logic:   documentType.custom_input_logic
      });
    } else {
      this.resetDocumentFileForm();
    }
    documentFile.editing = state;
  }

  public fileSelected(event, documentType: AppDocumentType = null, document: AppDocument | null): void {
    if (documentType) {
      this.setUploadFormForDocumentType(documentType, document);
    }

    const files      = event.target.files;
    const filesArray = [...this.selectedUploadFiles];
    if (files?.length) {
      const arr = [...files];
      arr.forEach(file => filesArray.push(file));
    }
    filesArray.map((file, index) => file.index = index);
    this.selectedUploadFiles = filesArray;
  }

  public removeSelectedFile(index: number): void {
    this.selectedUploadFiles = this.selectedUploadFiles.filter(file => file.index !== index);
  }

  public downloadFile(documentFile: AppFile): void {
    saveAs(this.storageUrl + documentFile.path, documentFile.name + '.' + documentFile.extension);
  }

  public deleteFile(documentType: AppDocumentType, document: AppDocument, documentFile: AppFile): void {
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('SHARED.action.delete_confirmation',
        {model: 'document'}),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.delete'),
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
      confirmButtonColor: '#886ab5'
    }).then(res => {
      if (res.isConfirmed) {
        documentFile.deleting = true;
        this.documentTypeService.deleteDocumentFile(this.case.id, documentFile.id)
          .pipe(finalize(() => documentFile.deleting = false))
          .subscribe(
            () => {
              this.toastr.success(this.translate.instant('DOCUMENTS.file-deleted'));
              const index = document.files.findIndex(file => file.id === documentFile.id);
              if (index >= 0) {
                document.files.splice(index, 1);
              }
              this.caseDocumentTypeCategories.forEach(category => {
                if (category.id === documentType.category_id) {
                  this.updateCategoryApprovalStatus(category);
                }
              })
            },
            err => this.toastr.error(this.translate.instant('DOCUMENTS.file-deleted-error'))
          );
      }
    });
  }

  public addCustomDocument(customDocumentForm: FormGroup, documentType: AppDocumentType): void {
    const requestData = {
      case_id:           this.case.id,
      user_id:           this.clientRole ? (this.clientRole === 'partner' ? this.case.partner_user_id : this.case.user_id) : null,
      documentable_type: this.documentableType,
      documentable_id:   this.documentableId,
      name:              customDocumentForm.get('document_name').value
    };
    this.documentTypeService.storeDocument(documentType.id, requestData).subscribe(result => {
      documentType.documents = [...documentType.documents, result.data];
      this.customDocuments.push(result.data);
    });
  }

  public updateDropList(event: CdkDragDrop<Array<AppFile>>, document: AppDocument): void {
    const file: AppFile = event.item.data;
    const requestData   = {fileable_order: event.currentIndex};
    moveItemInArray(document.files, event.previousIndex, event.currentIndex);
    this.documentTypeService.updateDocumentFile(this.case.id, file.id, requestData).subscribe(result => {
      this.toastr.success(this.translate.instant('DOCUMENTS.file-details-changed-success'));
    }, error => {
      this.toastr.error(this.translate.instant('DOCUMENTS.file-details-error'));
    });
  }

  public appendUpdatedFile(file: AppFile, oldCategory: AppDocumentTypeCategory = null): void {
    console.log('appending', file);
    file.fileable               = file.fileable as AppDocument;
    const document: AppDocument = file.fileable;
    this.caseDocumentTypeCategories.forEach(category => {
      let foundDocType = false;
      category.document_types.forEach(documentType => {
        if (documentType.id === document.app_document_type_id) {
          console.log('found doc type', documentType);
          foundDocType = true;
          if ( ! documentType.documents?.length) {
            documentType.documents = [];
          }

          let documentFound = false;
          documentType.documents.forEach(searchDoc => {
            if (searchDoc.id === document.id) {
              console.log('found doc', searchDoc);
              documentFound = true;
              if ( ! searchDoc.files?.length) {
                searchDoc.files = [];
              }
              searchDoc.files.push(file);
            }
          });

          if ( ! documentFound) {
            console.log('setting docs as empty array');
            if (file.hasOwnProperty('fileable')) {
              if (documentType.custom_input_logic === 'custom_selection') {
                this.customDocuments.push(document);
              }
              document.files = [file];
              documentType.documents.push(document);
            }
          }

          this.updateCategoryApprovalStatus(category);
          if (oldCategory && category.id !== oldCategory.id) {
            this.updateCategoryApprovalStatus(oldCategory);
          }
        }
      })
      if ( ! foundDocType) {
        if (category.id === document?.document_type?.category_id) {
          const newDocType     = document.document_type;
          document.files       = [file];
          newDocType.documents = [document];
          category.document_types.push(newDocType)
          this.updateCategoryApprovalStatus(category);
        }
      }
      console.log('finished searching doc types');
    });
  }

  private getUpdatedApprovalStatus(parentStatus: string | null, childStatus: string | null): string | null {
    if (parentStatus === null && childStatus === 'accepted') {
      parentStatus = 'accepted';
    }
    if (parentStatus !== 'rejected' && childStatus === 'pending') {
      parentStatus = 'pending';
    }
    if (childStatus === 'rejected') {
      parentStatus = 'rejected';
    }

    return parentStatus;
  }

  protected buildForms(): void {
    this.uploadForm = this.fb.group({
      app_document_id:      [null],
      app_document_type_id: [null, [Validators.required]],
      client_role:          [this.clientRole, [Validators.required]],
      documentable_type:    [null],
      documentable_id:      [null],
      name:                 [null],
      completed:            [null],
      files:                [[]]
    });

    this.formDocumentFile = this.fb.group({
      app_document_id:      [null, [Validators.required]],
      app_document_type_id: [null, [Validators.required]],
      documentable_type:    [null],
      documentable_id:      [null],
      user_id:              [null, [Validators.required]],
      name:                 [null, [Validators.required]],
      custom_input_logic:   [null]
    });

    this.formDocumentFile.get('app_document_type_id').valueChanges.subscribe(result => {
      this.documentTypeChanged(result);
    });

    this.customDocumentForm = this.fb.group({
      document_name: [null, [Validators.required]]
    });
  }

  private updateDocumentFileStatusApi(docType: AppDocumentType, document: AppDocument, documentFile: AppFile, data): void {
    this.documentTypeService.updateDocumentFile(this.case.id, documentFile.id, data).subscribe(
      res => {
        documentFile.fileable_approved      = res.data.fileable_approved;
        documentFile.fileable_reject_reason = res.data.fileable_reject_reason;
        this.toastr.success(this.translate.instant('DOCUMENTS.file-details-changed-success'));
        const category = this.caseDocumentTypeCategories.find(searchCat => searchCat.id === docType.category_id);
        this.updateCategoryApprovalStatus(category);
      },
      err => this.toastr.error(this.translate.instant('DOCUMENTS.file-details-changed-error'))
    );
  }

  private documentTypeChanged(documentTypeId: number) {
    const documentType = this.caseDocumentTypes.find(docType => docType.id === documentTypeId);
    if ( ! documentType) {
      return;
    }

    this.formDocumentFile.get('custom_input_logic').patchValue(documentType.custom_input_logic);

    if (documentType.custom_input_logic === 'custom_selection') {
      this.formDocumentFile.get('app_document_id').setValidators([Validators.required]);
    } else {
      this.formDocumentFile.get('app_document_id').patchValue(null);
      this.formDocumentFile.get('app_document_id').setValidators([]);
    }

    if (documentType?.custom_input_logic === 'case_creditor_selection' ||
      documentType?.custom_input_logic === 'case_public_debt_selection' ||
      documentType?.custom_input_logic === 'case_asset_selection'
    ) {
      this.formDocumentFile.get('documentable_type').patchValue(documentType.custom_input_logic.replace('_selection', ''));
      this.formDocumentFile.get('documentable_type').setValidators([Validators.required]);
      this.formDocumentFile.get('documentable_id').setValidators([Validators.required]);
    } else {
      this.formDocumentFile.get('documentable_type').patchValue(null);
      this.formDocumentFile.get('documentable_type').setValidators([]);
      this.formDocumentFile.get('documentable_id').setValidators([]);
    }
    this.formDocumentFile.get('app_document_id').updateValueAndValidity();
    this.formDocumentFile.get('documentable_type').updateValueAndValidity();
    this.formDocumentFile.get('documentable_id').updateValueAndValidity();
  }

  private setUploadFormForDocumentType(documentType: AppDocumentType, document: AppDocument | null): void {
    if (this.uploadForm.get('app_document_type_id').value !== documentType.id) {
      this.selectedUploadFiles = [];
      this.uploadForm.reset();
    }

    if (document && this.uploadForm.get('app_document_id').value !== document.id) {
      this.selectedUploadFiles = [];
      this.uploadForm.reset();
    }

    this.uploadForm.get('client_role').patchValue(this.clientRole);
    this.uploadForm.get('app_document_type_id').patchValue(documentType.id);

    if (document?.id) {
      this.uploadForm.get('app_document_id').patchValue(document.id);
      this.uploadForm.get('completed').patchValue( !! document.completed_at);
    }

    if (document?.documentable_type && document?.documentable_id) {
      this.uploadForm.get('documentable_type').patchValue(document.documentable_type);
      this.uploadForm.get('documentable_id').patchValue(document.documentable_id);
    }

    if (document && documentType.custom_input_logic === 'custom_selection') {
      this.uploadForm.get('name').patchValue(document.name);
    }
  }

  private getCustomLegalDocumentCategory(): Array<AppDocumentTypeCategory> {
    return [
      {
        id:                 null,
        uuid:               null,
        parent_category_id: null,
        type:               'client_case_legal_custom',
        order:              0,
        slug:               'case-legal-custom-documents',
        name_en:            'Legal',
        name_es:            'Legalo',
        active:             true,
        requestable:        false,
        client_visible:     false,
        document_types:     [
          {
            id:                      0.1,
            uuid:                    null,
            category_id:             null,
            parent_document_type_id: null,
            type:                    'client_case',
            order:                   0,
            slug:                    'contract',
            name_en:                 'Contract',
            name_es:                 'Contrato',
            preselected:             false,
            active:                  true,
            requestable:             false,
            custom_input_logic:      null,
            client_visible:          false
          },
          {
            id:                      0.2,
            uuid:                    null,
            category_id:             null,
            parent_document_type_id: null,
            type:                    'client_case',
            order:                   0,
            slug:                    'mandate',
            name_en:                 'Mandate',
            name_es:                 'Mandato',
            preselected:             false,
            active:                  true,
            requestable:             false,
            custom_input_logic:      null,
            client_visible:          false
          }
        ]
      }
    ]
  }

  private updateCategoryApprovalStatus(category: AppDocumentTypeCategory): void {
    let categoryStatus = null;
    if (category.hasOwnProperty('document_types')) {
      category.document_types.forEach(docType => {
        this.updateDocumentTypeApprovalStatus(category, docType);
        categoryStatus = this.getUpdatedApprovalStatus(categoryStatus, docType.approval_status);
      });
    }

    category.approval_status = categoryStatus;
  }

  private updateDocumentTypeApprovalStatus(category: AppDocumentTypeCategory, docType: AppDocumentType) {
    let docTypeStatus = null;
    if (docType.hasOwnProperty('documents') && docType.documents.length) {
      docType.documents.forEach(document => {
        this.updateDocumentApprovalStatus(category, docType, document);
        docTypeStatus = this.getUpdatedApprovalStatus(docTypeStatus, document.approval_status);
        if ( ! docType.has_pending_files && docTypeStatus === 'pending') {
          docType.has_pending_files = true;
        }
      });
    }
    docType.approval_status = docTypeStatus;
  }

  private updateDocumentApprovalStatus(category: AppDocumentTypeCategory, docType: AppDocumentType, document: AppDocument) {
    let docStatus = null;
    if (document.compiled_file) {
      this.updateFileApprovalStatus(category, docType, document, document.compiled_file)

      docStatus = this.getUpdatedApprovalStatus(docStatus, document.compiled_file.approval_status);
    }
    if (document.hasOwnProperty('files') && document.files.length) {
      document.files.forEach(file => {
        this.updateFileApprovalStatus(category, docType, document, file)
        docStatus = this.getUpdatedApprovalStatus(docStatus, file.approval_status);
      });
    }
    document.approval_status = docStatus;
  }

  private updateFileApprovalStatus(category: AppDocumentTypeCategory, docType: AppDocumentType, document: AppDocument, file: AppFile): void {
    file.approval_status = file.fileable_approved === null ?
      'pending' :
      (file.fileable_approved ? 'accepted' : 'rejected');
  }

  private resetDocumentFileForm() {
    this.formDocumentFile.patchValue({
      app_document_id:      null,
      app_document_type_id: null,
      documentable_type:    null,
      documentable_id:      null,
      user_id:              null,
      name:                 null,
      custom_input_logic:   null
    });
  }
}
