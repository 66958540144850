import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {VerifierDashboardComponent} from './verifier-dashboard.component';


const routes: Routes = [
  { path: 'verifier-dashboard', component: VerifierDashboardComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VerifierDashboardRoutingModule { }
