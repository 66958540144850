import { EnvironmentInterface } from './EnvironmentInterface';

export const environment: EnvironmentInterface = {
  APP_NAME:              'Deudafix',
  APP_ENV:               'production',
  production:            true,
  devMode:               false,
  API_DOMAIN:            'api.deudanet.es',
  API_SECURE_PROTOCOL:   true,
  API_URL:               'https://api.deudanet.es',
  STORAGE_URL:           'https://docs.deudanet.es/storage',
  APP_URL:               'https://app.deudanet.es',
  CLIENT_APP_URL:        'https://clientes.deudanet.es',
  REFRESH_NOTIFICATIONS: true,
  GENERAL_SITE_URL:      'www.deudafix.es',
  DISTRIBUTION_USER_IDS: [1, 21, 22, 27, 36, 2403, 2497, 295932, 336521, 769709],

  COMPANY_NAME:        'Deudafix',
  COMPANY_NAME_LEGAL:  'Deudafix Reparadora Legal Group, S.L.',
  COMPANY_ID_CIF:      'B88315890',
  COMPANY_ADDRESS:     'Avenida de la Vega, núm. 15, Edificio 2, planta 4ª, Alcobendas (Madrid), CP 28108]',
  COMPANY_BANK_NAME:   'Caixabank',
  COMPANY_BANK_IBAN:   'ES06 2100 4910 3613 0024 6367',
  COMPANY_PHONE_LEGAL: '910 053 393',
  VAT_PERCENTAGE:       21,

  EMAIL_INFO:                 'info@deudafix.es',
  EMAIL_LEGAL:                'legal@deudafix.es',
  EMAIL_ADMINISTRATION:       'administracion@deudafix.es',
  EMAIL_CANCELLATIONS:        'cancelaciones@deudafix.es',
  EMAIL_CONTRACT_CONTACT:     'contract@deudafix.es',
  EMAIL_SUPPORTING_DOCUMENTS: 'justificantes@deudafix.es',
  EMAIL_GDPR:                 'dataprotectionofficer@deudafix.es',

  GOOGLE_ANALYTICS_CODE:    'G-NPW7ZJLJHP',
  GOOGLE_ANALYTICS_ENABLED: true,
};
