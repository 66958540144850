<h1 mat-dialog-title>{{'REPORTS.financial-reports.list.packager-sales-report.title' | translate}}</h1>
<div class="pt-3">
  <form [formGroup]="form" (ngSubmit)="sendReportData()">
    <mat-form-field appearance="fill" class="mr-1">
      <mat-label>
        {{ "REPORTS.financial-reports.list.packager-sales-report.fields.start-date" | translate }}
      </mat-label>
      <input matInput [matDatepicker]="start_date" formControlName="start_date">
      <mat-datepicker-toggle matSuffix [for]="start_date"></mat-datepicker-toggle>
      <mat-datepicker #start_date></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>
        {{ "REPORTS.financial-reports.list.packager-sales-report.fields.end-date" | translate }}
      </mat-label>
      <input matInput [matDatepicker]="end_date" formControlName="end_date">
      <mat-datepicker-toggle matSuffix [for]="end_date"></mat-datepicker-toggle>
      <mat-datepicker #end_date></mat-datepicker>
    </mat-form-field>

    <!--Packagers-->
    <div style="width: 486px;">
      <app-input type="select" appearance="standard" [label]="'REPORTS.financial-reports.list.packager-sales-report.chose-packager' | translate"
                 formControlName="packager_ids" [showClear]="false" [showLabel]="true" [fullWidth]="true" [extraLabel]="true"
                 [selectOptions]="packagers" [multiple]="true" [selectLabel]="['name_en']" [selectValue]="'id'" [searchable]="true">
      </app-input>
    </div>
    <div class='pt-2 text-right'>
      <button type="button" mat-button color="primary" class="mt-3 ml-2" (click)="dialogRef.close()">
        {{ "REPORTS.financial-reports.list.packager-sales-report.actions.close" | translate }}
      </button>
      <button type="submit" mat-raised-button color="primary" class="mt-3">
        {{ "REPORTS.financial-reports.list.sales-report.actions.submit" | translate }}
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-white" role="status">
                    <span class="sr-only">Loading...</span>
                </span>
      </button>
    </div>
  </form>
</div>
