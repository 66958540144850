import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HeadsUpDashComponent} from './heads-up-dash.component';

const routes: Routes = [
    { path: 'heads-up-dash', component: HeadsUpDashComponent },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [
        RouterModule,
    ],
})

export class HeadsUpDashRoutingModule {
}
