import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from '../../_shared/shared.module';
import { AppDocumentModule } from '../app-document/app-document.module';
import { DocumentComponent } from '../document/document.component';
import { TaskModule } from '../task/task.module';
import { UserModule } from '../user/user.module';
import { CaseBulkActionModalComponent } from './case-bulk-action-modal/case-bulk-action-modal.component';
import { CaseDashboardComponent } from './case-dashboard/case-dashboard.component';
import { CaseCreditorDetailComponent } from './case-detail/case-creditor-detail/case-creditor-detail.component';
import {
  CaseCreditorDocumentListComponent
} from './case-detail/case-creditor-detail/case-creditor-document-list/case-creditor-document-list.component';
import {
  CaseCreditorGeneralDetailComponent
} from './case-detail/case-creditor-detail/case-creditor-general-detail/case-creditor-general-detail.component';
import { AdditionalInputComponent } from './case-detail/case-creditor-list/additional-input/additional-input.component';
import { CaseCreditorListComponent } from './case-detail/case-creditor-list/case-creditor-list.component';
import {
  CaseDepartmentUserEditorComponent
} from './case-detail/case-detail-general/case-department-user-editor/case-department-user-editor.component';
import { CaseGeneralDetailComponent } from './case-detail/case-detail-general/case-general-detail.component';
import {
  CaseQuickClientEditorComponent
} from './case-detail/case-detail-general/case-quick-client-editor/case-quick-client-editor.component';
import {
  CaseAepListComponent
} from './case-detail/case-detail-general/case-relation-list/case-aep-list/case-aep-list.component';
import {
  CaseCallStatusLogComponent
} from './case-detail/case-detail-general/case-relation-list/case-call-status-log/case-call-status-log.component';
import {
  CaseContractListComponent
} from './case-detail/case-detail-general/case-relation-list/case-contract-list/case-contract-list.component';
import {
  CaseCreditorNoteListComponent
} from './case-detail/case-detail-general/case-relation-list/case-creditor-note-list/case-creditor-note-list.component';
import {
  CaseNotesComponent
} from './case-detail/case-detail-general/case-relation-list/case-notes/case-notes.component';
import {
  CaseNotificationListComponent
} from './case-detail/case-detail-general/case-relation-list/case-notification-list/case-notification-list.component';
import {
  EmailShadowDomComponent
} from './case-detail/case-detail-general/case-relation-list/case-notification-list/email-shadow-dom/email-shadow-dom.component';
import {
  CasePaymentStatusLogComponent
} from './case-detail/case-detail-general/case-relation-list/case-payment-status-log/case-payment-status-log.component';
import {
  CaseProposalListComponent
} from './case-detail/case-detail-general/case-relation-list/case-proposal-list/case-proposal-list.component';
import {
  CaseRelationListComponent
} from './case-detail/case-detail-general/case-relation-list/case-relation-list.component';
import {
  CaseStatusLogComponent
} from './case-detail/case-detail-general/case-relation-list/case-status-log/case-status-log.component';
import {
  CaseTaskListComponent
} from './case-detail/case-detail-general/case-relation-list/case-task-list/case-task-list.component';
import {
  InvoiceStatusLogComponent
} from './case-detail/case-detail-general/case-relation-list/invoice-status-log/invoice-status-log.component';
import {
  PackagerStatusLogComponent
} from './case-detail/case-detail-general/case-relation-list/packager-status-log/packager-status-log.component';
import {
  CaseStatusEditorComponent
} from './case-detail/case-detail-general/case-status-editor/case-status-editor.component';
import {
  CaseUserPasswordModalComponent
} from './case-detail/case-detail-general/case-user-password-modal/case-user-password-modal.component';
import {
  CaseUserQualifyingDetailsComponent
} from './case-detail/case-detail-general/case-user-qualifying-details/case-user-qualifying-details.component';
import { CaseDetailComponent } from './case-detail/case-detail.component';
import {
  AllBankFilesComponent
} from './case-detail/case-documents/case-document-list-v1/all-bank-files/all-bank-files.component';
import {
  AllCourtFilesComponent
} from './case-detail/case-documents/case-document-list-v1/all-court-files/all-court-files.component';
import {
  AllCreditorFilesComponent
} from './case-detail/case-documents/case-document-list-v1/all-creditor-files/all-creditor-files.component';
import { AllFilesComponent } from './case-detail/case-documents/case-document-list-v1/all-files/all-files.component';
import {
  AllLegalFilesComponent
} from './case-detail/case-documents/case-document-list-v1/all-legal-files/all-legal-files.component';
import {
  CaseDocumentListV1Component
} from './case-detail/case-documents/case-document-list-v1/case-document-list-v1.component';
import { RequestComponent } from './case-detail/case-documents/case-document-list-v1/request/request.component';
import { RequiredComponent } from './case-detail/case-documents/case-document-list-v1/required/required.component';
import {
  AllFilesV2Component
} from './case-detail/case-documents/case-document-list-v2/all-files-v2/all-files-v2.component';
import {
  CaseDocumentListV2Component
} from './case-detail/case-documents/case-document-list-v2/case-document-list-v2.component';
import {
  RequiredV2Component
} from './case-detail/case-documents/case-document-list-v2/required-v2/required-v2.component';
import {
  CaseDocumentListV3WrapperComponent
} from './case-detail/case-documents/case-document-list-v3-wrapper/case-document-list-v3-wrapper.component';
import {
  BaseDocumentFileUploaderComponent
} from './case-detail/case-documents/case-document-list-v3/base-document-file-uploader/base-document-file-uploader.component';
import {
  CaseApplicationDocumentListV3Component
} from './case-detail/case-documents/case-document-list-v3/case-application-document-list-v3/case-application-document-list-v3.component';
import {
  CaseClientRequestedDocumentListV3Component
} from './case-detail/case-documents/case-document-list-v3/case-client-requested-document-list-v3/case-client-requested-document-list-v3.component';
import {
  CaseCourtDocumentListV3Component
} from './case-detail/case-documents/case-document-list-v3/case-court-document-list-v3/case-court-document-list-v3.component';
import {
  CaseDocumentAgentFileUploaderV3Component
} from './case-detail/case-documents/case-document-list-v3/case-document-agent-file-uploader-v3/case-document-agent-file-uploader-v3.component';
import {
  CaseDocumentListV3Component
} from './case-detail/case-documents/case-document-list-v3/case-document-list-v3.component';
import {
  CaseDocumentRequestListComponent
} from './case-detail/case-documents/case-document-list-v3/case-document-request-list/case-document-request-list.component';
import { CaseDocumentsComponent } from './case-detail/case-documents/case-documents.component';
import {
  AdministratorModalComponent
} from './case-detail/case-draft/administrator-modal/administrator-modal.component';
import { AppointCourtModalComponent } from './case-detail/case-draft/appoint-court-modal/appoint-court-modal.component';
import {
  AppointedCourtEditorComponent
} from './case-detail/case-draft/appointed-court-editor/appointed-court-editor.component';
import { CaseAepCreatorComponent } from './case-detail/case-draft/case-aep-creator/case-aep-creator.component';
import { CaseDraftComponent } from './case-detail/case-draft/case-draft.component';
import { DemandGeneratorComponent } from './case-detail/case-draft/demand-generator/demand-generator.component';
import { EpiRequestGeneratorComponent } from './case-detail/case-draft/epi-request/epi-request-generator.component';
import { LsoGeneratorComponent } from './case-detail/case-draft/lso-generator/lso-generator.component';
import { NotaryModalComponent } from './case-detail/case-draft/notary-modal/notary-modal.component';
import { ProposalModalComponent } from './case-detail/case-draft/proposal-modal/proposal-modal.component';
import { SolicitorModalComponent } from './case-detail/case-draft/solicitor-modal/solicitor-modal.component';
import { CaseLoanCalculatorComponent } from './case-detail/case-loan-calculator/case-loan-calculator.component';
import {
  CaseInstallmentAmountEditorComponent
} from './case-detail/case-payment/case-installment-amount-editor/case-installment-amount-editor.component';
import {
  CaseInstallmentListComponent
} from './case-detail/case-payment/case-installment-list/case-installment-list.component';
import {
  RecordPaymentModalComponent
} from './case-detail/case-payment/case-installment-list/record-payment-modal/record-payment-modal.component';
import {
  AllocationDetailsComponent
} from './case-detail/case-payment/case-payment-list/allocation-details/allocation-details.component';
import { CasePaymentListComponent } from './case-detail/case-payment/case-payment-list/case-payment-list.component';
import {
  EditHistoryModalComponent
} from './case-detail/case-payment/case-payment-list/edit-history-modal/edit-history-modal.component';
import {
  MymoidDetailsComponent
} from './case-detail/case-payment/case-payment-list/mymoid-details/mymoid-details.component';
import {
  RedsysDetailsComponent
} from './case-detail/case-payment/case-payment-list/redsys-details/redsys-details.component';
import {
  TransactionDetailsComponent
} from './case-detail/case-payment/case-payment-list/transaction-details/transaction-details.component';
import { UnaxDetailsComponent } from './case-detail/case-payment/case-payment-list/unax-details/unax-details.component';
import {
  CasePaymentPlanGeneratorComponent
} from './case-detail/case-payment/case-payment-plan-generator/case-payment-plan-generator.component';
import {
  CasePaymentRequestListComponent
} from './case-detail/case-payment/case-payment-request-list/case-payment-request-list.component';
import { CasePaymentComponent } from './case-detail/case-payment/case-payment.component';
import {
  CaseRedsysOrdersListComponent
} from './case-detail/case-payment/case-redsys-orders-list/case-redsys-orders-list.component';
import {
  CaseTransactionListComponent
} from './case-detail/case-payment/case-transaction-list/case-transaction-list.component';
import {
  ClientInstallmentListPreviewComponent
} from './case-detail/case-payment/client-installment-list-preview/client-installment-list-preview.component';
import {
  RebuildPaymentPlanComponent
} from './case-detail/case-payment/rebuild-payment-plan/rebuild-payment-plan.component';
import { FloatingNoteComponent } from './case-detail/floating-note/floating-note.component';
import { QuickNoteComponent } from './case-detail/quick-note/quick-note.component';
import { CaseEditorPrefillComponent } from './case-editor-prefill/case-editor-prefill.component';
import { CaseAssetEditorComponent } from './case-editor/case-asset-editor/case-asset-editor.component';
import { CaseCreditorEditorComponent } from './case-editor/case-creditor-editor/case-creditor-editor.component';
import { CaseEditorComponent } from './case-editor/case-editor.component';
import { CaseExpenseEditorComponent } from './case-editor/case-expense-editor/case-expense-editor.component';
import { CaseFinanceOverviewComponent } from './case-editor/case-finance-summary/case-finance-overview.component';
import { CaseGeneralEditorComponent } from './case-editor/case-general-editor/case-general-editor.component';
import { CaseIncomeEditorComponent } from './case-editor/case-income-editor/case-income-editor.component';
import { CaseOutcomeComponent } from './case-editor/case-outcome/case-outcome.component';
import {
  CaseIdCardEditorComponent
} from './case-editor/case-payment-editor/case-id-card-editor/case-id-card-editor.component';
import {
  CasePaymentEditorComponent as CasePaymentEditorComponent
} from './case-editor/case-payment-editor/case-payment-editor.component';
import { CaseListFiltersComponent } from './case-list/case-list-filters/case-list-filters.component';
import { CaseListComponent } from './case-list/case-list.component';
import { CaseRoutingModule } from './case-routing.module';
import { AssetsSnapshotListComponent } from './case-snapshot/assets-snapshot-list/assets-snapshot-list.component';
import { CaseSnapshotComponent } from './case-snapshot/case-snapshot.component';
import { ClientSnapshotListComponent } from './case-snapshot/client-snapshot-list/client-snapshot-list.component';
import { CreditorSnapshotListComponent } from './case-snapshot/creditor-snapshot-list/creditor-snapshot-list.component';
import { ExpancesSnapshotListComponent } from './case-snapshot/expances-snapshot-list/expances-snapshot-list.component';
import { GeneralSnapshotListComponent } from './case-snapshot/general-snapshot-list/general-snapshot-list.component';
import { IncomesSnapshotListComponent } from './case-snapshot/incomes-snapshot-list/incomes-snapshot-list.component';
import { GclidConversionModalComponent } from './gclid-conversion-modal/gclid-conversion-modal.component';
import { CaseCreditorComponent } from './case-detail/case-creditor-list/case-creditor/case-creditor.component';
import { LoanCaseCreditorComponent } from './case-detail/case-creditor-list/loan-case-creditor/loan-case-creditor.component';
import { BaseCaseCreditorComponent } from './case-detail/case-creditor-list/base-case-creditor/base-case-creditor.component';
import { PublicDebtComponent } from './case-detail/case-creditor-list/public-debt/public-debt.component';
import { DialerHistoryLogComponent } from './case-detail/case-detail-general/case-relation-list/dialer-history-log/dialer-history-log.component';


@NgModule({
  declarations: [
    CaseListComponent,
    CaseEditorComponent,
    CaseGeneralEditorComponent,
    CaseCreditorEditorComponent,
    CaseAssetEditorComponent,
    CaseIncomeEditorComponent,
    CaseExpenseEditorComponent,
    CaseOutcomeComponent,
    CasePaymentEditorComponent,
    CaseDetailComponent,
    CaseGeneralDetailComponent,
    CaseDocumentListV1Component,
    RequestComponent,
    AllFilesComponent,
    RequiredComponent,
    CasePaymentListComponent,
    RecordPaymentModalComponent,
    CaseNotesComponent,
    CaseInstallmentListComponent,
    EditHistoryModalComponent,
    CaseCreditorNoteListComponent,
    CaseStatusLogComponent,
    CasePaymentStatusLogComponent,
    CaseFinanceOverviewComponent,
    AllCreditorFilesComponent,
    AllLegalFilesComponent,
    CaseTransactionListComponent,
    CaseRedsysOrdersListComponent,
    CaseCreditorListComponent,
    AdditionalInputComponent,
    CaseDraftComponent,
    ProposalModalComponent,
    CaseContractListComponent,
    RebuildPaymentPlanComponent,
    CaseTaskListComponent,
    CaseEditorPrefillComponent,
    CaseProposalListComponent,
    CaseListFiltersComponent,
    QuickNoteComponent,
    AdministratorModalComponent,
    CaseNotificationListComponent,
    CaseDashboardComponent,
    AllBankFilesComponent,
    CaseAepCreatorComponent,
    NotaryModalComponent,
    CaseAepListComponent,
    AllCourtFilesComponent,
    CasePaymentRequestListComponent,
    CaseDepartmentUserEditorComponent,
    CaseIdCardEditorComponent,
    CaseRelationListComponent,
    CaseStatusEditorComponent,
    CaseQuickClientEditorComponent,
    CasePaymentComponent,
    AppointCourtModalComponent,
    AppointedCourtEditorComponent,
    CasePaymentPlanGeneratorComponent,
    DemandGeneratorComponent,
    CaseBulkActionModalComponent,
    SolicitorModalComponent,
    CaseInstallmentAmountEditorComponent,
    LsoGeneratorComponent,
    CaseUserPasswordModalComponent,
    CaseCallStatusLogComponent,
    CaseUserQualifyingDetailsComponent,
    FloatingNoteComponent,
    DocumentComponent,
    CaseSnapshotComponent,
    GeneralSnapshotListComponent,
    ClientSnapshotListComponent,
    AssetsSnapshotListComponent,
    IncomesSnapshotListComponent,
    ExpancesSnapshotListComponent,
    CreditorSnapshotListComponent,
    EmailShadowDomComponent,
    CaseDocumentRequestListComponent,
    CaseCreditorDetailComponent,
    CaseCreditorGeneralDetailComponent,
    CaseCreditorDocumentListComponent,
    PackagerStatusLogComponent,
    CaseDocumentListV2Component,
    RequiredV2Component,
    AllFilesV2Component,
    CaseDocumentListV3WrapperComponent,
    CaseDocumentListV3Component,
    CaseDocumentAgentFileUploaderV3Component,
    CaseClientRequestedDocumentListV3Component,
    CaseCourtDocumentListV3Component,
    CaseApplicationDocumentListV3Component,
    BaseDocumentFileUploaderComponent,
    InvoiceStatusLogComponent,
    EpiRequestGeneratorComponent,
    GclidConversionModalComponent,
    CaseLoanCalculatorComponent,
    MymoidDetailsComponent,
    RedsysDetailsComponent,
    TransactionDetailsComponent,
    UnaxDetailsComponent,
    CaseDocumentsComponent,
    AllocationDetailsComponent,
    ClientInstallmentListPreviewComponent,
    CaseCreditorComponent,
    LoanCaseCreditorComponent,
    BaseCaseCreditorComponent,
    PublicDebtComponent,
    DialerHistoryLogComponent
  ],
  imports:      [
    SharedModule,
    MatMenuModule,
    TaskModule,
    ImageCropperModule,
    NgxMatSelectSearchModule,
    NgxMatDatetimePickerModule,
    UserModule,
    AppDocumentModule,
    DragDropModule,
    CaseRoutingModule,
    MatAutocompleteModule
  ],
  exports:      [
    AllFilesComponent,
    AllFilesV2Component,
    CaseInstallmentListComponent,
    CasePaymentListComponent,
    CaseTransactionListComponent,
    CaseRedsysOrdersListComponent,
    CasePaymentRequestListComponent
  ]
})
export class CaseModule {
}
