<h2>{{"USERS.send-group-notifications" | translate}}</h2>
<!-- Loader -->
<div class="row">
  <div *ngIf="isLoading" class="col-12 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>
<form *ngIf="formReady" [hidden]="isLoading" [formGroup]="form" (ngSubmit)="submit(form)">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <app-input type="select" formControlName="assigned_users" appearance="standard" [searchable]="true"
                   fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                   [label]="'TASK-TEMPLATE.editor.assigned_users.label' | translate"
                   [selectOptions]="users" [selectLabel]="'name'" [selectValue]="'id'">
        </app-input>
        <div *ngIf="form.get('assigned_users').errors && (form.get('assigned_users').touched)">
          <mat-error [hidden]="!form.get('assigned_users').errors.required">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </div>
    </div>
    <div class="col-6">
      <app-input *ngIf="departmentCategories?.length" type="select" formControlName="assigned_departments"
                 appearance="standard" multiple [searchable]="true"
                 [label]="'TASK-TEMPLATE.editor.assigned_departments.label' | translate"
                 [selectOptions]="departmentCategories" [selectLabel]="'name'" [selectValue]="'id'"
                 [optGroupProperty]="'departments'">
      </app-input>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <app-input *ngIf="channels?.length" type="select" formControlName="channels" appearance="standard" multiple
                 [label]="'NOTIFICATION.notification-modal.form.channels.label' | translate" [searchable]="true"
                 [selectOptions]="channels" [selectLabel]="'name'" [selectValue]="'slug'">
      </app-input>
    </div>
    <div class="col-6">
      <app-input type="text" formControlName="subject" appearance="standard"
                 [label]="'NOTIFICATION.notification-modal.form.subject' | translate">
      </app-input>
    </div>
    <div class="col-12">
      <div>
        <label for="message">{{ 'NOTIFICATION.notification-modal.form.body' | translate }}</label>
      </div>
      <quill-editor id="message" [modules]="quillModules" formControlName="message" [styles]="{height: '250px'}">
      </quill-editor>
      <div *ngIf="form.get('message').errors && (form.get('message').touched)">
        <mat-error [hidden]="!form.get('message').errors.required">
          {{ "SHARED.field-required" | translate }}
        </mat-error>
      </div>
    </div>
  </div>
  <div class="mt-3 text-right">
    <button mat-raised-button color="primary" type="submit">{{ "SHARED.submit" | translate }}</button>
  </div>
</form>
