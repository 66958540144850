import { Component, OnInit, ViewChild } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { TaskTemplate } from '../../../../../../_base-shared/models/Task/TaskTemplate';
import { TaskTemplateService } from '../task-template.service';

@Component({
  selector:    'app-task-template-list',
  templateUrl: './task-template-list.component.html',
  styleUrls:   ['./task-template-list.component.scss'],
})
export class TaskTemplateListComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public displayedColumns: Array<string> = [
    'id',
    'name',
    'active',
    'statusable_type',
    'statusable',
    'assigned_users',
    'assigned_departments',
    'delay',
    'tasks_count',
    'template_notifications_count',
    'created_at',
    'actions',
  ];
  public actions: Array<string>          = ['Delete'];
  public isLoading                       = 0;

  public autoTaskTemplates: Array<TaskTemplate>;
  public autoTaskTemplateSource: MatTableDataSource<TaskTemplate>;
  public autoTaskTemplatePaginatorConfig = {pageIndex: 0, pageSize: 10, length: 1};

  public assistedTaskTemplates: Array<TaskTemplate>;
  public assistedTaskTemplateSource: MatTableDataSource<TaskTemplate>;
  public assistedTaskTemplatePaginatorConfig = {pageIndex: 0, pageSize: 10, length: 1};

  constructor(private toastr: ToastrService,
              public translateService: TranslateService,
              private taskTemplateService: TaskTemplateService) {
  }

  ngOnInit(): void {
    this.fetchTaskTemplates();
    this.fetchAssistedTaskTemplates();
  }

  public fetchTaskTemplates() {
    this.autoTaskTemplateSource = new MatTableDataSource<TaskTemplate>([]);
    this.isLoading++;
    const paginator = {
      per_page:      this.autoTaskTemplatePaginatorConfig.pageSize,
      page:          this.autoTaskTemplatePaginatorConfig.pageIndex + 1,
      user_assisted: 0,
    };

    this.taskTemplateService.index(paginator, ['statusable', 'assigned_users', 'assigned_departments'])
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.autoTaskTemplates                      = result.data;
        this.autoTaskTemplateSource                 = new MatTableDataSource<TaskTemplate>(this.autoTaskTemplates);
        this.autoTaskTemplateSource.sort            = this.sort;
        this.autoTaskTemplatePaginatorConfig.length = result.meta.total;
      });
  }

  public fetchAssistedTaskTemplates() {
    this.assistedTaskTemplateSource = new MatTableDataSource<TaskTemplate>([]);
    this.isLoading++;
    const paginator = {
      per_page:      this.assistedTaskTemplatePaginatorConfig.pageSize,
      page:          this.assistedTaskTemplatePaginatorConfig.pageIndex + 1,
      user_assisted: 1,
    };

    this.taskTemplateService.index(paginator).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.assistedTaskTemplates                      = result.data;
      this.assistedTaskTemplateSource                 =
        new MatTableDataSource<TaskTemplate>(this.assistedTaskTemplates);
      this.assistedTaskTemplateSource.sort            = this.sort; // TODO: check if it works properly
      this.assistedTaskTemplatePaginatorConfig.length = result.meta.total;
    });
  }

  public openDeleteDialog(id: number, $event): void {
    $event.preventDefault();
    Swal.fire({
      title:              this.translateService.instant('SHARED.warning'),
      text:               this.translateService.instant('SHARED.delete-item'),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translateService.instant('SHARED.cancel'),
      confirmButtonText:  this.translateService.instant('SHARED.delete'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.deleteTaskTemplate(id);
      }
    });
  }

  private deleteTaskTemplate(id: number) {
    this.taskTemplateService.destroy(id).subscribe(
      result => {
        this.fetchTaskTemplates();
        this.toastr.success(this.translateService.instant('CONFIG.status.status-deleted'));
      },
      error => this.toastr.error(this.translateService.instant('SHARED.went-wrong')),
    );
  }

  public paginatorChange($event: PageEvent, assisted: boolean = false) {
    const paginator     = assisted ? this.assistedTaskTemplatePaginatorConfig : this.autoTaskTemplatePaginatorConfig;
    paginator.pageIndex = $event.pageIndex;
    paginator.pageSize  = $event.pageSize;
    paginator.length    = $event.length;

    assisted ? this.fetchAssistedTaskTemplates() : this.fetchTaskTemplates();
  }

}
