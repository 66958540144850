import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { Affiliate } from '../../../../../../_base-shared/models/Affiliate/Affiliate';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { AffiliateService } from '../affiliate.service';

@Component({
  selector:    'app-aff-list',
  templateUrl: './affiliate-list.component.html',
  styleUrls:   ['./affiliate-list.component.scss']
})
export class AffiliateListComponent implements OnInit {
  public authUser: User;
  public displayedColumns: string[] = ['id', 'track_id', 'name', 'packager', 'active', 'created_at', 'actions'];
  public actions: string[]          = ['Edit', 'Delete'];
  public affiliates: MatTableDataSource<Affiliate>;
  public paginatorConfig            = {
    pageIndex: 0,
    pageSize:  10,
    length:    1
  };
  public isLoading                  = 0;
  public search                     = new UntypedFormControl('');
  public searchFocus                = false;

  public allowedUsers = [1, 22, 34, 36, 27, 2403, 382, 660671, 336521, 211750];

  constructor(
    private affService: AffiliateService,
    private toastr: ToastrService,
    public globalEventsService: MainGlobalEventService,
    private translate: TranslateService
  ) {
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    this.getAffiliates();
    this.search.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(() => this.getAffiliates());

  }

  getAffiliates() {
    this.affiliates = new MatTableDataSource<Affiliate>([]);
    this.isLoading++;
    const data = {
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1,
      search:   this.search.value
    };

    this.affService.index(data, ['packager', 'default_campaign']).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.affiliates             = new MatTableDataSource<Affiliate>(result.data);
      this.affiliates.sort        = this.sort;
      this.paginatorConfig.length = result.meta.total;
    });
  }

  openDeleteDialog(affiliateId, $event): void {
    $event.preventDefault();
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('AFFILIATES.delete-aff-confirm'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.delete'),
      confirmButtonColor: '#886ab5',
      cancelButtonText:   this.translate.instant('SHARED.cancel')
    }).then(res => {
      if (res.isConfirmed) {
        this.deleteAffiliate(affiliateId);
      }
    });
  }

  deleteAffiliate(affiliateId) {
    this.affService.delete(affiliateId)
      .subscribe(
        res => {
          this.getAffiliates();
          this.toastr.success(
            this.translate.instant('AFFILIATES.delete-aff-success'),
            this.translate.instant('SHARED.success')
          );
        },
        error => {
          this.toastr.error(
            this.translate.instant('AFFILIATES.delete-aff-error'),
            this.translate.instant('SHARED.error')
          );
        });
  }

  public paginatorChange($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.getAffiliates();
  }

  onChange() {
    this.getAffiliates();
  }

  toggleFocus(setFocus: boolean) {
    this.searchFocus = setFocus;
  }

}

