import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { Product } from '../../../../../../../../_base-shared/models/Product';
import { CaseDocumentService } from '../../../case-document.service';
import { CaseService } from '../../../case.service';
import { ProductService } from '../../../product.service';

@Component({
  selector:    'app-epi-request',
  templateUrl: './epi-request-generator.component.html',
  styles:      [],
})
export class EpiRequestGeneratorComponent implements OnInit, OnDestroy {
  @Input() case: Case;
  public isLoading                           = 0;
  public form: UntypedFormGroup;
  public isSubmitting: boolean;
  public serverResponse: LaravelResourceResponse;
  public products: Array<Product>;
  private subscriptions: Array<Subscription> = [];
  public selectedProduct: Product;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: { case: Case },
              @Optional() public dialogRef: MatDialogRef<EpiRequestGeneratorComponent>,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private translate: TranslateService,
              private caseDocumentService: CaseDocumentService,
              private productService: ProductService,
              private caseService: CaseService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (this.data && this.data.case) {
      this.case = this.data.case;
      if (this.case.product) {
        this.buildForm();
      } else {
        this.fetchCaseProduct(this.case);
      }
    } else {
      this.route.parent.paramMap.subscribe(params => {
        this.fetchCase(+params.get('id'));
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public submitForm(form: UntypedFormGroup): void {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    this.caseDocumentService.generateDocument(this.case.id, form.value)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(result => {
        if (result.type === 4) {
          const fileName = 'epi_request_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') +
            '.' + (form.get('type').value === 'pdf' ? 'pdf' : 'doc');
          saveAs(result.body, fileName);
          this.toastr.success('Downloaded EPI request document');
        }
      }, err => {
        this.toastr.error('Failed to generate EPI request document');
      });
  }

  public changeFormForDocumentType(documentTypeSlug: string): void {
    this.form = null;
    this.isLoading++;
    setTimeout(() => {
      this.buildForm();
      this.isLoading--;
    }, 500);
  }

  private buildForm(): void {
    this.form = this.fb.group({
      document_type_slug:      ['epi-lso'],
      number_of_cars:          [null, [Validators.required]],
      public_debt_information: [null],
      city:                    [null, [Validators.required]],
      procurator:              [null, [Validators.required]],
      signature_date:          [null, [Validators.required]],
      lawyer:                  [null, [Validators.required]],
      type:                    [null],
    });
  }

  private fetchCaseProduct(clientCase: Case): void {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(clientCase.id, ['product']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.buildForm();
        }),
    );
  }

  private fetchCase(caseId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(caseId, ['product']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.case = result.data;
          this.updateSelectedProduct(this.case.product);
          this.buildForm();
        }),
    );
  }

  public chooseDocsType(type: 'doc' | 'pdf', submitedForm: UntypedFormGroup): void {
    this.form.get('type').patchValue(type);
    this.form.get('type').updateValueAndValidity();
    this.submitForm(submitedForm);
  }

  private updateSelectedProduct(product: Product): void {
    this.selectedProduct = product;
  }
}
