import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { environment } from '../../../../../../environments/environment';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { CreditorService } from '../../../../creditor/creditor.service';
import { StatusService } from '../../../../status/status.service';
import { CaseCreditorService } from '../../../case-creditor.service';
import { CaseDocumentService } from '../../../case-document.service';
import { CaseService } from '../../../case.service';
import { BaseCaseCreditorComponent } from '../base-case-creditor/base-case-creditor.component';

@Component({
  selector:    'app-loan-case-creditor',
  templateUrl: './loan-case-creditor.component.html',
  styleUrls:   ['../base-case-creditor/base-case-creditor.component.scss'],
})
export class LoanCaseCreditorComponent extends BaseCaseCreditorComponent implements OnInit {
  @Input() case: Case;
  @Input() loanCreditors: MatTableDataSource<any>;
  @Input() originalLoanCreditors: Array<any>;
  @Input() missingAepFiles: boolean;
  @Input() isSigned: boolean;
  @Output() loanEditToggle: EventEmitter<any> = new EventEmitter<any>();

  public loanCreditorsForm: UntypedFormGroup;
  public displayedColumnsLoanCreditors: string[] = [];
  public isLoading                               = 0;
  public isFetching                              = 0;
  public isLoadingIds: Array<number>             = [];
  public selectionLoanCreditors                  = new SelectionModel<any>(true, []);
  public storageUrl                              = environment.STORAGE_URL + '/';
  public currentRow: number;
  public isEmailEnabled                          = false;
  public isEditing                               = false;

  constructor(
    protected router: Router,
    protected fb: UntypedFormBuilder,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    protected toast: ToastrService,
    protected translate: TranslateService,
    protected toastr: ToastrService,
    protected globalEventService: MainGlobalEventService,
    protected caseService: CaseService,
    protected creditorService: CreditorService,
    protected caseCreditorService: CaseCreditorService,
    protected caseDocumentService: CaseDocumentService,
    protected statusService: StatusService,
    protected el: ElementRef,
  ) {
    super(router, fb, route, dialog, toast, translate, toastr, globalEventService, caseService, creditorService,
      caseCreditorService
      , caseDocumentService, statusService, el);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildDisplayColumns();
    this.buildLoanCreditorsForm();
  }

  private buildDisplayColumns(): void {
    this.displayedColumnsLoanCreditors = [
      'select', 'name', 'ownership', 'debt_amount', 'negotiated_amount', 'total_reduction', 'percent_reduction',
      'response_received', 'actions', 'loader',
    ];
  }

  buildLoanCreditorsForm(): void {
    this.loanCreditorsForm = this.fb.group({
      debt_amount:       new UntypedFormControl(''),
      negotiated_amount: new UntypedFormControl(''),
    });
  }

  public toggleLoanEditMode(index: number, element): void {
    this.currentRow = index;
    this.isEditing  = !this.isEditing;
    if (this.isEditing) {
      this.loanCreditorsForm.get('debt_amount').patchValue(element.debt_amount);
      this.loanCreditorsForm.get('negotiated_amount').patchValue(element.negotiated_amount);
      this.el.nativeElement.querySelector('td').classList.add('.p-r-10');
    }
    if (!this.isEditing) {
      this.loanEditToggle.emit({isEditing: this.isEditing, element, loanCreditorForm: this.loanCreditorsForm});
      this.loanCreditorsForm.get('debt_amount').patchValue(null);
      this.loanCreditorsForm.get('negotiated_amount').patchValue(null);
    }
  }

  public returnLenderType(lenderType: 'standard' | 'high_interest' | 'collections'): string {
    const types = {
      collections:   '',
      standard:      '',
      high_interest: '#FFFF63',
    };
    return types[lenderType];
  }

  public getTotal(columnName): number {
    let total = 0;
    let count = 0;
    if (this.loanCreditors) {
      if (columnName === 'debt_amount') {
        this.loanCreditors.data.forEach(data => {
          total += +(data.debt_amount || 0);
        });
      }
      if (columnName === 'negotiated_amount') {
        this.loanCreditors.data.forEach(data => {
          total += +(data.negotiated_amount || 0);
        });
      }
      if (columnName === 'total_reduction') {
        this.loanCreditors.data.forEach(data => {
          total += +((data.debt_amount - data.negotiated_amount) || 0);
        });
      }
      if (columnName === 'percent_reduction') {
        this.loanCreditors.data.forEach(data => {
          count++;
          total += +(+(+(data.debt_amount - data.negotiated_amount) / data.debt_amount) *
            100);
        });
      }
      return columnName === 'percent_reduction' ? (count > 0 ? total / count : 0) : total;
    }
  }
}
