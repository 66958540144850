<div [formGroup]="form">
    <div class="row-pb-3">
        <mat-form-field color="primary" appearance="standard">
            <mat-label>{{"PAYMENTS.start-date" | translate}}</mat-label>
            <input matInput [matDatepicker]="from" formControlName="from" (dateChange)="onDateChange($event, 'form')">
            <button
              type="button"
              *ngIf="form.value.from"
              mat-button matSuffix mat-icon-button
              aria-label="Clear"
              (click)="clearDatePicker($event, 'from')"
            >
            <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
            <mat-datepicker #from color="primary"></mat-datepicker>
        </mat-form-field>

        <mat-form-field color="primary" appearance="standard">
            <mat-label>{{ "PAYMENTS.end-date" | translate }}</mat-label>
            <input id="to" matInput [matDatepicker]="to" formControlName="to" (dateChange)="onDateChange($event, 'to')">
            <button
                    type="button"
                    *ngIf="form.value.to"
                    mat-button matSuffix mat-icon-button
                    aria-label="Clear"
                    (click)="clearDatePicker($event, 'to')"
            >
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
            <mat-datepicker #to color="primary"></mat-datepicker>
        </mat-form-field>

        <div class="d-inline-block pl-5">
            <mat-radio-group formControlName="dateRadio" aria-label="Select an option" (change)="onRadioChange($event)">
                <mat-radio-button value="today">{{ "CASES.single.today" | translate }}</mat-radio-button>
                <mat-radio-button value="this-week">{{ "CASES.single.this-week" | translate }}</mat-radio-button>
                <mat-radio-button value="this-month">{{ "CASES.single.this-month" | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-8">
        <div class="mat-elevation-z8 mb-5">
            <table style="width: 100%" mat-table matSort [dataSource]="dataSource">
                <ng-container matColumnDef="first_name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'USERS.model.first_name' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.first_name}}</td>
                    <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
                </ng-container>

                <ng-container matColumnDef="last_name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'USERS.model.last_name' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.last_name}}</td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <ng-container matColumnDef="total_paid">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CLIENT.payment.payment-info.shared.total_paid" | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.total_paid}}</td>
                    <td mat-footer-cell *matFooterCellDef>{{totals.totalPaid}}</td>
                </ng-container>

                <ng-container matColumnDef="total_signed_lso">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.total-signed-lso" | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.total_signed_lso}}</td>
                    <td mat-footer-cell *matFooterCellDef>{{totals.signedCountLSO}}</td>
                </ng-container>

                <ng-container matColumnDef="total_signed_dm">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.total-signed-dm" | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.total_signed_dm}}</td>
                    <td mat-footer-cell *matFooterCellDef>{{totals.signedCountDM}}</td>
                </ng-container>

<!--              <ng-container matColumnDef="total_signed_dgs">-->
<!--                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'DASHBOARD.total-signed-dgs' | translate}}</th>-->
<!--                <td mat-cell *matCellDef="let element">{{element.total_signed_dgs}}</td>-->
<!--                <td mat-footer-cell *matFooterCellDef>{{totals.signedCountDGS}}</td>-->
<!--              </ng-container>-->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
        </div>
    </div>
    <div class="col-12">
        <div *ngIf="isLoading" class="text-center" style="padding: 50px">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>
