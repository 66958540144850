import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseDepartmentAssignment } from '../../../../../../../_base-shared/models/Department/CaseDepartmentAssignment';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { CaseService } from '../../case.service';

@Component({
  selector: 'app-case-general-detail',
  templateUrl: './case-general-detail.component.html',
  styles: [],
})
export class CaseGeneralDetailComponent implements OnInit, OnDestroy {
  public case: Case;
  public isLoading                           = 0;
  public serverResponse: LaravelResourceResponse;
  private subscriptions: Array<Subscription> = [];
  private authUser: User;

  constructor(private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private translate: TranslateService,
              private globalEventsService: MainGlobalEventService,
              private caseService: CaseService,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      this.route.parent.paramMap.subscribe(params => {
        const caseId = +params.get('id');
        this.isLoading++;
        this.subscriptions.push(
          this.caseService.get(caseId, ['packager', 'transfer_request', 'original_packager', 'product'])
            .pipe(finalize(() => this.isLoading--))
            .subscribe(result => {
              this.case = result.data;
              if (!this.authUser.packager.master &&
                  !!(this.case.transfer_request && this.case.transfer_request.accepted !== false)) {
                this.router.navigate(['cases', this.case.id, 'snapshot']);
              }
            }, error => {
              this.serverResponse = error.error;
            }),
        );
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public updateCaseClients(updatedCase: Case): void {
    this.case.lead            = updatedCase.lead;
    this.case.client          = updatedCase.client;
    this.case.partner         = updatedCase.partner;
    this.case.linked_by_cases = updatedCase.linked_by_cases;
    this.case.linked_cases    = updatedCase.linked_cases;
  }

  public updateCaseDepartmentAssignments(caseDepartmentAssignments: Array<CaseDepartmentAssignment>): void {
    this.case.department_assignments = caseDepartmentAssignments;
  }

  public updateCaseStatusAndProduct(updatedCase: Case): void {
    if (this.case.status_id !== updatedCase.status_id) {
      this.case.status_id = updatedCase.status_id;
      this.case.status    = updatedCase.status;
    }
    if (this.case.payment_status_id !== updatedCase.payment_status_id) {
      this.case.payment_status_id = updatedCase.payment_status_id;
      this.case.payment_status    = updatedCase.payment_status;
    }
    if (this.case.product_id !== updatedCase.product_id) {
      this.case.product_id = updatedCase.product_id;
      this.case.product    = updatedCase.product;
    }
  }

  public updateCaseCreditors(updatedCase: Case): void {
    this.case.secured_creditors   = updatedCase.secured_creditors;
    this.case.unsecured_creditors = updatedCase.unsecured_creditors;
    this.case.public_debts        = updatedCase.public_debts;
  }
}
