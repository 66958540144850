<mat-card class="mat-elevation-z3">
  <mat-card-header>
    <mat-card-title class="mb-3">
      <h1>{{ 'LEGAL_ENTITY.appointed_court.editor.heading' | translate }}</h1>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
    <form *ngIf="form" [formGroup]="form" class="p-2">
      <div class="row">
        <!-- Court -->
        <div class="col-3">
          <app-input type="select" formControlName="court_id" [searchable]="true"
                     [label]="'LEGAL_ENTITY.appointed_court.editor.court_id' | translate"
                     [fullWidth]="true" readonly="true" [selectOptions]="courts"
                     [selectValue]="'id'" [selectLabel]="'name'">
          </app-input>
        </div>
        <!-- Status -->
        <div class="col-3">
          <app-input type="select" appearance="outline" [searchable]="true" [fullWidth]="true"
                     formControlName="status" [extraLabel]="false" [selectOptions]="statusOptions"
                     [label]="'LEGAL_ENTITY.appointed_court.model.status.label' | translate"
                     [selectLabel]="['label']" [selectValue]="'value'">
          </app-input>
        </div>
        <!-- Outcome -->
        <div class="col-3">
          <app-input type="select" appearance="outline" [searchable]="true" [fullWidth]="true"
                     formControlName="outcome" [extraLabel]="false" [selectOptions]="outcomeOptions"
                     [label]="'LEGAL_ENTITY.appointed_court.model.outcome.label' | translate"
                     [selectLabel]="['label']" [selectValue]="'value'">
          </app-input>
        </div>
        <!-- Exoneration Amount -->
        <div class="col-3">
          <app-input type="number" appearance="outline" formControlName="exoneration_amount" [fullWidth]="true"
                     [label]="'LEGAL_ENTITY.appointed_court.model.exoneration_amount' | translate">
          </app-input>
        </div>
        <!-- Presented Date -->
        <div class="col-3">
          <app-input type="mat-datepicker" formControlName="presented_date"
                     [label]="'LEGAL_ENTITY.appointed_court.model.presented_date' | translate" [fullWidth]="true">
          </app-input>
        </div>
        <!-- Accepted Date -->
        <div class="col-3">
          <app-input type="mat-datepicker" formControlName="accepted_date"
                     [label]="'LEGAL_ENTITY.appointed_court.model.accepted_date' | translate" [fullWidth]="true">
          </app-input>
        </div>
        <!-- Application Date -->
        <div class="col-3">
          <app-input type="mat-datepicker" formControlName="application_date"
                     [label]="'LEGAL_ENTITY.appointed_court.model.application_date' | translate" [fullWidth]="true">
          </app-input>
        </div>
        <!-- Concession Date -->
        <div class="col-3">
          <app-input type="mat-datepicker" formControlName="concession_date"
                     [label]="'LEGAL_ENTITY.appointed_court.model.concession_date' | translate" [fullWidth]="true">
          </app-input>
        </div>
        <!-- Administrator -->
        <div class="col-3" *ngIf="isStatusDemandaTerminada">
          <p>{{'LEGAL_ENTITY.appointed_court.model.administrator' | translate}}</p>
          <p style="cursor: pointer;text-decoration: underline;"
             [routerLink]="['/administrators', caseEntities?.administrator?.id,'edit']">
            {{caseEntities?.administrator?.name}}
          </p>
        </div>
        <!-- Solicitor -->
        <div class="col-3" *ngIf="isStatusDemandaTerminada">
          <p>{{'LEGAL_ENTITY.appointed_court.model.solicitor' | translate}}</p>
          <p style="cursor: pointer;text-decoration: underline;"
             [routerLink]="['/solicitors', caseEntities?.solicitor?.id,'edit']">
            {{caseEntities?.solicitor?.name}}
          </p>
        </div>
        <!-- Actions -->
        <div class="col-12 d-flex justify-content-end">
          <button mat-raised-button color="primary" (click)="submitForm(form)">
            {{ 'SHARED.save' | translate }}
          </button>
          <button mat-raised-button color="warn" class="ml-3" (click)="openDeleteDialog($event)">
            {{ 'SHARED.delete' | translate }}
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
