import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Task } from '../../../../../../_base-shared/models/Task/Task';
import { TaskEditorComponent } from '../task-editor/task-editor.component';
import { TaskService } from '../task.service';

@Component({
  selector:    'app-task-detail',
  templateUrl: './task-detail.component.html',
  styles:      [],
})
export class TaskDetailComponent implements OnInit {
  public task: Task;
  public isLoading = 0;
  public form: UntypedFormGroup;
  public formSubmitted: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              @Optional() public dialogRef: MatDialogRef<TaskDetailComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private translateService: TranslateService,
              private toastr: ToastrService,
              private taskService: TaskService) {
    this.data = data ? data : null;
  }

  ngOnInit(): void {
    if (this.data && this.data.task) {
      // TODO: check if relations are missing and load if so
      this.task = this.data.task;
      this.buildForm(this.task);
    } else {
      this.route.paramMap.subscribe(params => this.fetchTask(+params.get('id')));
    }
  }

  public viewCase(task: Task) {
    this.router.navigate(['cases', task.case.id]);
  }

  public editTask(task: Task) {
    this.dialog.open(TaskEditorComponent, {
      width:     '40%',
      autoFocus: false,
      data:      {
        case:       task.case,
        task,
        editorType: 'edit',
      },
    });
  }

  public confirmCompleteTask(task: Task, form: UntypedFormGroup) {
    this.formSubmitted = true;
    if (form.invalid) {
      return;
    }
    this.dialogRef.close();
    Swal.fire({
      title:              this.translateService.instant('SHARED.warning'),
      text:               this.translateService.instant('TASK.detail.actions.confirm-complete.text'),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translateService.instant('SHARED.cancel'),
      confirmButtonText:  this.translateService.instant('TASK.detail.actions.confirm-complete.confirm-button'),
      confirmButtonColor: '#886ab5',
    }).then(result => {
      if (result.isConfirmed) {
        this.completeTask(task, form);
      }
    });
  }

  public completeTask(task: Task, form: UntypedFormGroup) {
    const requestData = {...form.value, is_completed: 1};
    this.taskService.completeCaseTask(task.case_id, task.id, requestData).subscribe(
        result => {
          this.task = result.data;
          this.toastr.success(this.translateService.instant('TASK.detail.complete.response-success'));
          if (this.data?.reFetchTasks$) {
            this.data.reFetchTasks$.next('reFetch');
          }
          if (this.dialogRef) {
            this.dialogRef.close();
          }
        },
        error => this.toastr.error('Could not complete task'),
    );
  }

  private fetchTask(taskId: number) {
    this.isLoading++;
    this.taskService.show(taskId).pipe(finalize(() => this.isLoading--)).subscribe(res => {
      this.task = res.data;
      this.buildForm(this.task);
    });
  }

  private buildForm(task: Task) {
    this.form = this.fb.group({
      completion_note: [
        {value: task.completion_note, disabled: task.completed_at},
        task.require_completion_note ? [Validators.required] : [],
      ],
    });
  }
}
