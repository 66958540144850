import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { Court } from '../../../../../../../../_base-shared/models/Entity/Court';
import { CourtService } from '../../../../address-book/court/court.service';

@Component({
  selector:    'app-appoint-court-modal',
  templateUrl: './appoint-court-modal.component.html',
  styles:      [],
})
export class AppointCourtModalComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public case: Case;
  public courts: MatTableDataSource<Court>;
  public requestFilters = {
    page: 1,
    per_page: 10,
    search: ''
  };
  public isLoading                       = 0;
  public isSubmitting                    = false;
  public searchFocus                     = false;
  public displayedColumns: Array<string> = ['select', 'name', 'address'];
  public selection                       = new SelectionModel<any>(false, null);
  public searchControl: UntypedFormControl      = new UntypedFormControl();
  public courtIdControl: UntypedFormControl     = new UntypedFormControl(null, [Validators.required]);

  private totalResults: number;
  private totalPages: number;
  public paginatorConfig = {
    pageIndex: 0,
    pageSize:  10,
    length:    1,
  };

  constructor(private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private translate: TranslateService,
              private courtService: CourtService,
              public dialogRef: MatDialogRef<AppointCourtModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.case = this.data.case;
    this.fetchCourts();

    this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(keyword => {
      this.requestFilters.search = keyword;
      this.fetchCourts();
    });
  }

  public selectCourt(court: Court) {
    this.courtIdControl.patchValue(court.id);
    this.courtIdControl.updateValueAndValidity();
    this.selection.clear();
    this.selection.toggle(court);
  }

  public submitSelectedCourt() {
    if (this.courtIdControl.invalid) {
      this.courtIdControl.markAsTouched();
      return;
    }

    this.isSubmitting = true;
    this.courtService.saveAppointedCourt(this.case.id, {court_id: this.courtIdControl.value})
        .pipe(finalize(() => this.isSubmitting = false))
        .subscribe(
            result => {
              const appointedCourt = result.data;
              this.toastr.success(this.translate.instant('LEGAL_ENTITY.appointed_court.editor.result.success'));
              this.closeModal(false, appointedCourt);
              this.dialogRef.close({dismissed: false, data: appointedCourt});
            },
            error => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
  }

  private fetchCourts() {
    this.selection.clear();
    this.courtIdControl.patchValue(null);
    this.courtIdControl.markAsUntouched();

    this.isLoading++;
    this.courtService.index(this.requestFilters).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.selection.clear();
      this.courts                 = new MatTableDataSource<Court>(result.data);
      this.courts.sort            = this.sort;
      this.paginatorConfig.length = result.meta.total;
      this.totalResults           = result.meta.total;
      this.totalPages             = result.meta.last_page;
    });
  }

  public closeModal(dismissed = true, data = null) {
    this.dialogRef.close({dismissed, data});
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.requestFilters.page     = this.paginatorConfig.pageIndex + 1;
    this.requestFilters.per_page = this.paginatorConfig.pageSize;
    this.fetchCourts();
  }
}
