<div *ngIf="serverResponse" class="row">
  <div class="col-12">
    <app-server-response [response]="serverResponse"></app-server-response>
  </div>
</div>
<div *ngIf="case">
  <app-case-installment-list [case]="case" [fetchInstalments]="fetchInstalments$" [openRecordPaymentDialog]="openDialog$"
                             (paymentRecorded)="paymentRecorded()" [componentType]="componentType">
  </app-case-installment-list>
  <app-case-payment-list [case]="case" [fetchHistory]="fetchInstalments$" [componentType]="componentType"></app-case-payment-list>
  <app-case-redsys-orders-list [case]="case" [reFetchOrders]="fetchInstalments$" [componentType]="componentType"></app-case-redsys-orders-list>
  <app-case-transaction-list [case]="case" [reFetchTransactions]="fetchInstalments$" [componentType]="componentType"></app-case-transaction-list>
  <app-case-payment-request-list [case]="case" [componentType]="componentType"></app-case-payment-request-list>
</div>
