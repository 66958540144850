import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppJob } from '../../../../../../_base-shared/models/App/AppJob';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { BookFeesReportComponent } from './book-fees-report/book-fees-report.component';
import { ReportService } from '../report.service';
import { RunOffExportComponent } from '../run-off-export/run-off-export.component';
import { BookValueReportComponent } from './book-value-report/book-value-report.component';
import { CollectionsReportComponent } from './collections-report/collections-report.component';
import { PackagerSalesReportComponent } from './packager-sales-report/packager-sales-report.component';
import { PaymentReceivedModalComponent } from './payment-received-modal/payment-received-modal.component';
import { PaymentReportComponent } from './payment-report/payment-report.component';
import { SalesReportModalComponent } from './sales-report-modal/sales-report-modal.component';
export const REPORT_DATA_TEMPLATE = [
  {
    reportName: 'REPORTS.financial-reports.list.payment-received-report.title',
    description: 'REPORTS.financial-reports.list.payment-received-report.description',
    actionName: 'paymentReceivedModal',
    actionText: 'REPORTS.financial-reports.list.payment-received-report.actions.submit',
  },
  {
    reportName: 'REPORTS.financial-reports.list.sales-report.title',
    description: 'REPORTS.financial-reports.list.sales-report.description',
    actionName: 'salesReportModal',
    actionText: 'REPORTS.financial-reports.list.sales-report.actions.submit',
  },
  {
    reportName: 'REPORTS.financial-reports.list.run-off-report.title',
    description: 'REPORTS.financial-reports.list.run-off-report.description',
    actionName: 'runOffReportModal',
    actionText: 'REPORTS.financial-reports.list.run-off-report.actions.submit',
  },
  {
    reportName: 'REPORTS.financial-reports.list.packager-sales-report.title',
    description: 'REPORTS.financial-reports.list.packager-sales-report.description',
    actionName: 'packagerSalesReportModal',
    actionText: 'REPORTS.financial-reports.list.packager-sales-report.actions.submit',
  },
  {
    reportName: 'REPORTS.financial-reports.list.collections-report.title',
    description: 'REPORTS.financial-reports.list.collections-report.description',
    actionName: 'collectionsReportModal',
    actionText: 'REPORTS.financial-reports.list.collections-report.actions.submit',
  },
  {
    reportName: 'REPORTS.financial-reports.list.payment-report.title',
    description: 'REPORTS.financial-reports.list.payment-report.description',
    actionName: 'paymentReportModal',
    actionText: 'REPORTS.financial-reports.list.payment-report.actions.submit',
  },
  {
    reportName: 'REPORTS.financial-reports.list.book-fees-report.title',
    description: 'REPORTS.financial-reports.list.book-fees-report.description',
    actionName: 'bookFeesReportModal',
    actionText: 'REPORTS.financial-reports.list.book-fees-report.actions.submit',
  },
  {
    reportName: 'REPORTS.financial-reports.list.book-value-report.title',
    description: 'REPORTS.financial-reports.list.book-value-report.description',
    actionName: 'bookValueReportModal',
    actionText: 'REPORTS.financial-reports.list.book-value-report.actions.submit',
  },
  {
    reportName: 'REPORTS.financial-reports.list.breakage-report.title',
    description: 'REPORTS.financial-reports.list.breakage-report.breakage-report-desc',
    actionName: 'generateBreakageReport',
    actionText: 'REPORTS.financial-reports.list.breakage-report.actions.submit',
  }
];

@Component({
  selector:    'app-financial-report-list',
  templateUrl: './financial-report-list.component.html',
  styles: [
    `
      table {
        width: 100%;
      }
      .ml-20 {
        margin-left: 20px;
      }
      .mr-20 {
        margin-right: 20px;
      }
    `
  ]
})

export class FinancialReportListComponent implements OnInit {
  private authUser: User;
  public appJobs: Array<AppJob> = [];
  public isLoadingJobs          = 0;
  public generatingBreakageReport: boolean;
  public displayedColumns: string[] = ['reportName', 'description', 'actions'];
  public dataSource: MatTableDataSource<any>;

  constructor(private toastr: ToastrService,
              private globalEventsService: MainGlobalEventService,
              private reportService: ReportService,
              public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    this.dataSource = new MatTableDataSource(REPORT_DATA_TEMPLATE);
    this.fetchQueuedJobs();
  }

  public generateBreakageReport(): void {
    const requestData             = {
      user_id: this.authUser.id,
    };
    this.generatingBreakageReport = true;
    this.reportService.generateBreakageReport(requestData)
        .pipe(finalize(() => this.generatingBreakageReport = false))
        .subscribe(
            result => {
              this.toastr.success(result.message);
              this.fetchQueuedJobs();
            },
        );
  }

  public fetchQueuedJobs(): void {
    this.isLoadingJobs++;
    this.reportService.indexQueuedExports({queue: 'export', 'with[]': 'user'}).pipe(finalize(() => this.isLoadingJobs--))
        .subscribe(
            result => {
              this.toastr.success(result.message);
              this.appJobs = result.data;
            },
        );
  }

  public paymentReceivedModal($event): void {
    console.log('poz');
    this.dialog.open(PaymentReceivedModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public salesReportModal($event): void {
    this.dialog.open(SalesReportModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public runOffReportModal(): void {
    this.dialog.open(RunOffExportComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public packagerSalesReportModal($event): void {

    this.dialog.open(PackagerSalesReportComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public collectionsReportModal($event): void {


    this.dialog.open(CollectionsReportComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public paymentReportModal($event): void {


    this.dialog.open(PaymentReportComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public bookFeesReportModal($event): void {

    this.dialog.open(BookFeesReportComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public bookValueReportModal($event): void {


    this.dialog.open(BookValueReportComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public handleElementAction( $event: MouseEvent, action: string): void {
    if (action === 'paymentReceivedModal') {
      this.paymentReceivedModal($event);
    } else if (action === 'salesReportModal') {
      this.salesReportModal($event);
    } else if (action === 'runOffReportModal') {
      this.runOffReportModal();
    } else if (action === 'packagerSalesReportModal') {
      this.packagerSalesReportModal($event);
    } else if (action === 'collectionsReportModal') {
      this.collectionsReportModal($event);
    } else if (action === 'paymentReportModal') {
      this.paymentReportModal($event);
    } else if (action === 'bookFeesReportModal') {
      this.bookFeesReportModal($event);
    } else if (action === 'bookValueReportModal') {
      this.bookValueReportModal($event);
    } else if (action === 'generateBreakageReport') {
      this.generateBreakageReport();
    } else {
      console.error('Unknown action:', action);
    }
  }


}
