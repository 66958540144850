import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { DepartmentCategory } from '../../../../../../_base-shared/models/Department/DepartmentCategory';
import { DialerList } from '../../../../../../_base-shared/models/Dialer/DialerList';
import { DepartmentService } from '../../department/department.service';
import { DialerService } from '../../dialer/dialer.service';

@Component({
  selector:    'app-dial-lead-list-selector',
  templateUrl: './dial-lead-list-selector.component.html',
  styles:      [],
})
export class DialLeadListSelectorComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading          = 0;
  public dialerLists: Array<DialerList>;
  public categoryDepartments: Array<DepartmentCategory>     = [];
  public departmentIds = new UntypedFormControl('', Validators.required);
  public sendOwner = new UntypedFormControl(false);

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private fb: UntypedFormBuilder,
              private dialogRef: MatDialogRef<DialLeadListSelectorComponent>,
              private dialerService: DialerService,
              private departmentService: DepartmentService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.fetchDepartments();
    this.fetchDialerLists();
    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group({
      list_id: ['', [Validators.required]],
      owned_by: ['']
    });
    this.sendOwner.valueChanges.subscribe(value => {
      if (value === true){
        console.log(this.departmentIds);
        this.form.get('owned_by').setValidators([Validators.required]);
        this.form.get('owned_by').updateValueAndValidity();
      }
      else {
        this.form.get('owned_by').clearValidators();
        this.form.get('owned_by').updateValueAndValidity();
      }
    });
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      this.toastr.error(this.translate.instant('LEAD.dialer-list-id-selector.form.form_invalid'));
      return;
    }
    this.dialogRef.close({params: form.value});
  }

  private fetchDialerLists(): void {
    this.isLoading++;
    this.dialerService.indexLists({select_all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.dialerLists = result.data;
      });
  }

  private fetchDepartments() {
    this.isLoading++;
    this.departmentService.categoryIndex({}, ['departments']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.categoryDepartments = result.data);
  }

  public onDepartmentChange($event) {
    this.form.get('owned_by').setValue($event);
  }
}
